import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Text from '../text';
import StyleConstants from '../../shared/styleConstants/styles';
import { updateSelectedImprovementType } from '../../actions/index';

class ImprovementSideBar extends PureComponent {
  renderImprovement(improvementType, index) {
    const isSelected = this.props.selectedImprovementType === improvementType;
    const circleColor = StyleConstants.sideBarBorderColor[`color${index + 1}`];
    return (
      <div
        role="presentation"
        id={`Improvement-Type-${improvementType}`}
        key={`ImprovementType_${improvementType}`}
        className="flexcontainer Sidebar_Padding"
        style={{ alignItems: 'center', backgroundColor: isSelected && StyleConstants.backgroundColor.testAttempted, cursor: 'pointer' }}
        onClick={() => {
          this.props.updateSelectedImprovementType(improvementType);
        }}
      >
        <div
          className="Circle"
          style={{
            width: 10, height: 10, border: `3px solid ${circleColor}`, marginRight: 20,
          }}
        />
        <Text
          color={isSelected ? StyleConstants.color.primary : StyleConstants.textColor.inactive}
          fontWeight={isSelected ? StyleConstants.textWeight.bold : ''}
          text={improvementType}
        />
      </div>
    );
  }

  render() {
    const { improvements } = this.props;
    return (
      <div>
        <div className="Sidebar_Padding">
          <Text
            color={StyleConstants.textColor.primary}
            fontWeight={StyleConstants.textWeight.bold}
            fontSize={StyleConstants.textSize.headerSmall}
            text="Improvement Areas"
          />
        </div>
        {Object.keys(improvements).map((improvementType, index) => this.renderImprovement(improvementType, index))}
      </div>
    );
  }
}

ImprovementSideBar.propTypes = {
  updateSelectedImprovementType: PropTypes.func.isRequired,
  improvements: PropTypes.object,
  selectedImprovementType: PropTypes.string,
};

ImprovementSideBar.defaultProps = {
  improvements: {},
  selectedImprovementType: '',
};

const mapStateToProps = ({ report }) => ({
  improvements: report.improvements,
  selectedImprovementType: report.selectedImprovementType,
});

export default connect(mapStateToProps, { updateSelectedImprovementType })(ImprovementSideBar);
