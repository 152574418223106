import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Text from '../text';
import StyleConstants from '../../shared/styleConstants/styles';
import { updateCurrentVideoQuestionIndex } from '../../actions/index';

class VideoListSideBar extends Component {
  state = {
    screenHeight: 0,
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight })
  }

  renderQuestions(question, index) {
    const isQuestionSelected = this.props.currentVideoQuestion.id === question.id;
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
    return (
      <div
        id={`Question-SideBar-${index}`}
        className="d-flex Test_Sidebar_Padding"
        style={{ border: "1px solid rgba(221, 221, 221, 0.5)", paddingTop: 10, paddingBottom: 10, backgroundColor: isQuestionSelected && StyleConstants.questionView.Selected, cursor: 'pointer' }}
        onClick={() => this.props.updateCurrentVideoQuestionIndex(index - 1)}
      >
        <Text fontWeight={question.fontWeight} fontSize={StyleConstants.textSize.small} text={`${index}.`} />
        <div className="ml-1"> <Text fontSize={StyleConstants.textSize.small} text={question.question_text} /> </div>
      </div>
    );
  }

  render() {
    const { videoQuestionList } = this.props;
    return (
      <div>
        <div className="Test_Sidebar_Padding">
          <Text
            fontWeight={StyleConstants.textWeight.bold}
            color={StyleConstants.textColor.primary}
            fontSize={StyleConstants.textSize.headerSmall}
            text={"Videos"} />
        </div>
        {videoQuestionList.length > 0 && videoQuestionList.map((question, index) => this.renderQuestions(question, index + 1))}
      </div>
    )
  }
}

VideoListSideBar.propTypes = {
  updateCurrentVideoQuestionIndex: PropTypes.func.isRequired,
  currentVideoQuestion: PropTypes.object.isRequired,
  videoQuestionList: PropTypes.array
}

VideoListSideBar.defaultProps = {
  currentVideoQuestion: {},
  videoQuestionList: []
}

const mapStateToProps = ({ home }) => ({
  videoQuestionList: home.videoQuestionList,
  currentVideoQuestion: home.currentVideoQuestion
});

export default connect(mapStateToProps, {updateCurrentVideoQuestionIndex})(VideoListSideBar);
