import {
  GET_VIRTUAL_MEETINGS, UPDATE_OPTION_PRESS, UPDATE_QUIZ_QUESTIONS,
  UPDATE_QUESTION_INDEX, UPDATE_QUESTION_RESPONSE, TOGGLE_SHOW_QUIZ,
  SHOW_QUIZ_REPORT, HIDE_QUIZ_QUESTION_LIST, TOGGLE_EXPAND_STATUS, UPDATE_QUIZ_SCORES,
  UPDATE_ATTENDANCE
} from '../actions/virtualMeeting';

const INITIAL_STATE = {
  meetings: [],
  video: '',
  title: '',
  userName: 'user',
  academicStartDate: '',
  useZoom: false,
  active: true,
  showQuizQuestionsList: false,
  timeTabledata: {},
  startDate: '',
  endDate: '',
  zones: [],
  isHigherEducation: false,
  showQuizQuestionsListOutside: false,
  showReport: false,
  quizScore: {},
  quizQuestionData: [],
  questionIndex: 0,
  questionDuration: {},
  showQuiz: false,
  quizId: '',
  verfiedQuestions: [],
  dataUpdateCount: 0,
  selectedOptions: {},
  recordedMeeting: '',
  isRecorded: false,
  quizScores: [],
  totalQuizScores: {},
  attendance: [],
  totalAttendance: {},
};

export default (state = INITIAL_STATE, action) => {
  const newState = { ...state };
  newState.dataUpdateCount += 1;
  switch (action.type) {
    case GET_VIRTUAL_MEETINGS:
      return { ...state, ...action.payload };
    case UPDATE_QUIZ_SCORES:
      return { ...state, ...action.payload };
    case UPDATE_ATTENDANCE:
      return { ...state, ...action.payload };
    case UPDATE_QUESTION_INDEX:
      newState.dataUpdateCount -= 1;
      newState.questionIndex += action.payload.switchIndex;
      return { ...newState };
    case UPDATE_QUIZ_QUESTIONS:
      newState.quizQuestionData = [...action.payload.quizQuestionData];
      action.payload.responseData.map((response) => {
        if (response.result === 0 || response.result) {
          newState.verfiedQuestions = [...newState.verfiedQuestions, response.id];
          newState.selectedOptions[response.id] = response.response;
          newState.questionDuration[response.id] = response.duration;
        }
      });
      newState.isRecorded = action.payload.isRecorded;
      if (newState.useZoom) {
        newState.showQuizQuestionsList = true;
      } else {
        newState.showQuizQuestionsListOutside = true;
      }
      newState.quizId = action.payload.quizId;
      return { ...newState };
    case TOGGLE_EXPAND_STATUS:
      newState.active = !newState.active;
      return { ...newState };
    case TOGGLE_SHOW_QUIZ:
      newState.showQuiz = !newState.showQuiz;
      newState.active = !newState.showQuiz || newState.active;
      return { ...newState };
    case HIDE_QUIZ_QUESTION_LIST:
      newState.showQuizQuestionsList = false;
      return { ...newState };
    case UPDATE_OPTION_PRESS:
      newState.dataUpdateCount -= 1;
      if (newState.selectedOptions[action.payload.questionId] === action.payload.optionIndex) {
        delete newState.selectedOptions[action.payload.questionId];
      } else {
        newState.selectedOptions[action.payload.questionId] = action.payload.optionIndex;
      }
      newState.selectedOptions = { ...newState.selectedOptions };
      return { ...newState };
    case SHOW_QUIZ_REPORT:
      newState.quizScore = { ...action.payload };
      newState.showReport = true;
      newState.active = true;
      return { ...newState };
    case UPDATE_QUESTION_RESPONSE:
      newState.dataUpdateCount -= 1;
      newState.verfiedQuestions = [...newState.verfiedQuestions, action.payload.questionId];
      return { ...newState };

    default:
      return state;
  }
};
