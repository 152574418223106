import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { getLiveVideo } from '../actions/index';
import { getVimeoVideoId } from '../helpers/Utils';
import StyleConstants from '../shared/styleConstants/styles';
import Text from './text';
import Layout from './layout';
import SideBar from './sidebar';

const history = createBrowserHistory({
  forceRefresh: true,
});

const PAGE_WIDTH = 700;

class LiveVideo extends PureComponent {
  componentDidMount() {
    this.props.getLiveVideo();
  }

  render() {
    const { videoDetails } = this.props;

    if (!videoDetails.has_live_video) {
      return (
        <Layout hideFooter>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: window.innerHeight - 150 }}>
            <Text
              fontSize={StyleConstants.textSize.headerSmall}
              fontWeight={StyleConstants.textWeight.semiBold}
              text="No live classes are conducted at this moment! Come back later."
            />
            <div
              onClick={() => history.push('/')}
              style={{ margin: 20, padding: '0 20px' }}
              className="Button Primary-Button"
            > Go to HomeScreen </div>
          </div>
        </Layout>
      );
    }
    const videoHeight = window.innerHeight - 200 > PAGE_WIDTH ? PAGE_WIDTH : window.innerHeight - 200;
    const videoId = getVimeoVideoId(videoDetails.video_url);
    return (
      <Layout hideFooter>
        <SideBar showBackNavigation navigateTo="/" />
        <div className="MainPage" style={{ marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <Text
            fontSize={StyleConstants.textSize.header}
            fontWeight={StyleConstants.textWeight.semiBold}
            text={videoDetails.video_title}
          />
          <div style={{ width: PAGE_WIDTH, height: videoHeight }}>
            <iframe title="qp_generation" src={`https://player.vimeo.com/video/${videoId}`} frameBorder="0" allow="autoplay; fullscreen" style={{ height: '100%', width: '100%' }} allowFullScreen />
          </div>
        </div>
      </Layout>
    );
  }
}

LiveVideo.propTypes = {
  getLiveVideo: PropTypes.func.isRequired,
  videoDetails: PropTypes.object,
};

LiveVideo.defaultProps = {
  videoDetails: {},
};

const mapStateToProps = ({ home }) => ({
  videoDetails: home.videoDetails,
});

export default connect(mapStateToProps, { getLiveVideo })(LiveVideo);
