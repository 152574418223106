import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SideBar from './sidebar';
import { getVimeoVideoId } from '../helpers/Utils';
import { SIDEBAR_TYPES } from '../shared/constants/fieldTypes';
import { getChapterVideoSolutionList } from '../actions/index';
import Layout from './layout';

const PAGE_WIDTH = 700;

class ChapterVideoList extends PureComponent {
  componentDidMount() {
    const { id } = this.props.match.params;
    const { chapter } = this.props;
    if (chapter && chapter.id !== parseInt(id)) {
      this.props.getChapterVideoSolutionList(id);
    }
  }

  render() {
    const { chapter, currentVideoQuestion } = this.props;
    if (!currentVideoQuestion.id) {
      return <div />
    }
    const videoHeight = window.innerHeight - 200 > PAGE_WIDTH ? PAGE_WIDTH : window.innerHeight - 200;
    const videoId = getVimeoVideoId(currentVideoQuestion.video_solution_uri);

    return (
      <Layout hideFooter>
        <SideBar pageType={SIDEBAR_TYPES.CHAPTER_VIDEO_LIST} showBackNavigation navigateTo={chapter.id ? `/chapter/${chapter.id}` : '/'} />
        <div className="MainPage" style={{ marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', marginBottom: 20 }}>
          <div style={{ width: PAGE_WIDTH, height: videoHeight }}>
            <iframe title="qp_generation" src={`https://player.vimeo.com/video/${videoId}`} frameBorder="0" allow="autoplay; fullscreen" style={{ height: '100%', width: '100%' }} allowFullScreen />
          </div>
        </div>
      </Layout>
    );
  }
}

ChapterVideoList.propTypes = {
  getChapterVideoSolutionList: PropTypes.func.isRequired,
  currentVideoQuestion: PropTypes.object,
  chapter: PropTypes.object,
  match: PropTypes.object,
};

ChapterVideoList.defaultProps = {
  chapter: {},
  currentVideoQuestion: {},
};

const mapStateToProps = ({ home }) => ({
  chapter: home.chapter,
  currentVideoQuestion: home.currentVideoQuestion,
});

export default connect(mapStateToProps, { getChapterVideoSolutionList })(ChapterVideoList);
