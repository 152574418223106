import { scroller } from 'react-scroll';
import {
  GET_TEST_INSTRUCTIONS, GET_TEST_QUESTIONS, UPDATE_CURRENT_QUESTION_INDEX, UPDATE_QUESTION_MARKED_LIST, UPDATE_QUESTION_SELECTED_OPTION, GET_TEST_PROGRESS_DATA, UPDATE_QUESTION_TIME_SPENT, CLEAR_UPLOADED_RESULTS, FINISH_TEST, TEST_UPDATE_QUESTION_BOOKMARK, SHOW_TEST_SESSION_FINISH_MODAL, UPDATE_TEST_QUESTION_LIST, UPDATE_TEST_NUMERICAL_QUESTION_DATA, UPDATE_TEST_TIME_REMAINING, UPDATE_TEST_LOCK_STATE,
} from '../actions';
import {
  getQuestionIndexMapping, getQuestionSideBarData, getAttendedQuestionlist, getTestProgressData, calculateCountdown, updateQuestionCache, calculateTime,
} from '../helpers/Utils';
import { QUESTION_TYPES, SUBJECT_MAPPING } from '../shared/constants/fieldTypes';

const INITIAL_STATE = {
  testInfo: {},
  questions: [],
  redirectToReport: false,
  isTestReview: false,
  testDuration: new Date().toString(),
  questionTimeSpent: {},
  questionVerifiedAt: {},
  resultData: {},
  richResponse: '',
  questionCount: 0,
  markedQuestionList: [],
  optionSelectedQuestionList: {},
  currentIndex: 0,
  currentQuestion: {},
  attendedQuestionList: {},
  attendingCount: {},
  selectedOption: [],
  questionSubjectMapping: {},
  isCurrentQuestionMarked: false,
  isCurrentQuestionVisited: false,
  questionIds: [],
  questionType: [],
  questionResponseList: [],
  questionSideBarData: [],
  questionsVisited: [],
  shouldUploadResponses: false,
  unAttemptedCount: 0,
  unVisitedCount: 0,
  timeRemaining: '',
  startTime: new Date(),
  currentQuestionTimeSpent: {},
  comprehensionText: {},
  bookmarkedQuestionIds: [],
  isCurrentQuestionBlocked: false,
  currentSubjectName: '',
  currentQuestionLimitedCount: 0,
  questionsVerified: {},
  isCurrentQuestionBookmarked: false,
  showSessionFinishModal: false,
  questionIndexMapping: {},
  richResponses: {},
  isOtpRequired: false,
  canLockTest: false,
};

const updateCache = (state, updateFields = {}, cacheName = 'QuestionResponses', updateAll = false) => {
  if (state.isTestReview) {
    return;
  }
  const payload = {
    testInfo: state.testInfo,
    sessionId: state.testInfo.session_id,
    currentIndex: state.currentIndex,
    questionsVisited: state.questionsVisited,
    markedQuestionList: state.markedQuestionList,
    optionSelectedQuestionList: state.optionSelectedQuestionList,
    questionTimeSpent: state.questionTimeSpent,
    questionVerifiedAt: state.questionVerifiedAt,
    questionType: state.questionType,
    questionsVerified: state.questionsVerified,
    richResponses: state.richResponses,
    questionSubjectMapping: state.questionSubjectMapping,
    timeRemaining: calculateCountdown(new Date(new Date().setSeconds(new Date().getSeconds() + state.testDuration)), true),
    questionSessionItem: {},
    questionResponseVersion: {},
  };

  Object.keys(updateFields).forEach((type) => {
    payload[type] = updateFields[type];
  });

  updateQuestionCache(state.questionIds, payload, cacheName, updateAll);
};

export default (state = INITIAL_STATE, action) => {
  let { attendedQuestionList, attendingCount } = state;
  switch (action.type) {
    case GET_TEST_INSTRUCTIONS:
      return { ...state, ...action.payload };
    case GET_TEST_QUESTIONS:
      return { ...state, ...action.payload, startTime: new Date() };
    case UPDATE_CURRENT_QUESTION_INDEX:
      if (action.currentIndex < 0) {

        return { ...state, currentIndex: action.currentIndex };
      }
      let question = state.questions[action.currentIndex];
      let { currentIndex } = action;
      if (action.isQuestionReference) {
        question = state.allQuestions[currentIndex];
        currentIndex = state.questions.indexOf(question);
      }
      if (!question) {
        return { ...state, questionSideBarData: getQuestionSideBarData(state.allQuestions, [], [], [], '', {}) };
      }
      const questionsVisited = state.isTestReview ? state.questionsVisited : [...new Set([...state.questionsVisited, question.id])];
      updateCache(state, {
        currentIndex,
        questionsVisited,
      });
      scroller.scrollTo(`question_ref_${question.id}`, {
        offset: -15,
        containerId: 'questionListView',
      });
      const isCurrentQuestionVisited = questionsVisited.includes(question.id);
      const { questionTimeSpent } = state;
      const currentQuestionTimeSpent = calculateTime(Math.ceil(questionTimeSpent[question.id] / 1000) || 0);
      if (!state.isTestReview) {
        questionTimeSpent[state.currentQuestion.id] = ((questionTimeSpent[state.currentQuestion.id] || 0) + (((new Date() - state.startTime) > 0) ? (new Date() - state.startTime) : 0));
      }
      let isCurrentQuestionBlocked = false
      const currentSubjectName = SUBJECT_MAPPING[question.subject_id];
      let currentQuestionLimitedCount = 0;
      const questionType = question.question_type.toString()
      const subjectId = question.subject_id.toString()
      if(state.testInfo.is_choice && QUESTION_TYPES.NUMERICAL && !state.isTestReview){
         attendedQuestionList = attendedQuestionList || {}
         attendedQuestionList[subjectId] = attendedQuestionList[subjectId] || {}
         attendedQuestionList[subjectId][questionType] = attendedQuestionList[subjectId][questionType] || []
         attendingCount = attendingCount || {}
         attendingCount[subjectId] = attendingCount[subjectId] || {}
         attendingCount[subjectId][questionType] = attendingCount[subjectId][questionType] || 0
         currentQuestionLimitedCount = attendingCount[subjectId][questionType];
         if((!attendedQuestionList[subjectId][questionType].includes(question.id)) && attendedQuestionList[subjectId][questionType].length === attendingCount[subjectId][questionType]){
          isCurrentQuestionBlocked = true
         }
      } else{
        isCurrentQuestionBlocked = false
        attendedQuestionList = {}
        attendingCount = {}
      }
      window.scrollTo(0, 0);
      return {
        ...state,
        currentQuestionTimeSpent,
        currentQuestionLimitedCount,
        currentSubjectName,
        questionsVisited,
        questionTimeSpent,
        isCurrentQuestionVisited,
        currentIndex,
        startTime: new Date(),
        attendedQuestionList: {...attendedQuestionList},
        attendingCount: {...attendingCount},
        questionIndex: state.allQuestions.indexOf(question),
        currentQuestion: question,
        isCurrentQuestionBlocked: isCurrentQuestionBlocked,
        isCurrentQuestionMarked: state.markedQuestionList.includes(question.id),
        richResponse: state.richResponses[question.id] ? state.richResponses[question.id] : '',
        selectedOption: state.optionSelectedQuestionList[question.id] || [],
        isCurrentQuestionBookmarked: state.bookmarkedQuestionIds.includes(question.id),
        questionSideBarData: getQuestionSideBarData(state.allQuestions, state.questions.map((value) => value.id), state.markedQuestionList, state.optionSelectedQuestionList, question.id, state.richResponses),
        questionIndexMapping: getQuestionIndexMapping(state.allQuestions, state.markedQuestionList, state.optionSelectedQuestionList, questionsVisited, state.richResponses, !state.isTestReview, state.resultData),
      };
    case UPDATE_QUESTION_MARKED_LIST:
      let { markedQuestionList } = state;
      if (state.isCurrentQuestionMarked) {
        markedQuestionList = markedQuestionList.filter((item) => item !== parseInt(state.currentQuestion.id));
      } else {
        markedQuestionList = [...state.markedQuestionList, parseInt(state.currentQuestion.id)];
      }
      updateCache(state, { markedQuestionList });
      return {
        ...state,
        markedQuestionList,
        questionIndexMapping: getQuestionIndexMapping(state.allQuestions, markedQuestionList, state.optionSelectedQuestionList, state.questionsVisited, state.richResponses, !state.isTestReview, state.resultData),
        questionSideBarData: getQuestionSideBarData(state.allQuestions, state.questions.map((value) => value.id), markedQuestionList, state.optionSelectedQuestionList, state.currentQuestion.id, state.richResponses),
        isCurrentQuestionMarked: !state.isCurrentQuestionMarked,
      };
    case UPDATE_QUESTION_SELECTED_OPTION:
      const { currentQuestion, questionVerifiedAt } = state;
      const selectedList = state.optionSelectedQuestionList;
      let selectedOption = state.optionSelectedQuestionList[currentQuestion.id] || [];
      if (selectedOption.includes(action.payload.optionIndex)) {
        const index = selectedOption.indexOf(action.payload.optionIndex);
        selectedOption.splice(index, 1);
      } else if (currentQuestion.question_type === QUESTION_TYPES.MULTIPLE_OPTION) {
        selectedOption.push(action.payload.optionIndex);
      } else {
        selectedOption = [action.payload.optionIndex];
      }
      selectedList[currentQuestion.id] = selectedOption || [];
      questionVerifiedAt[currentQuestion.id] = new Date().toJSON();
      attendedQuestionList = getAttendedQuestionlist(currentQuestion, state.attendedQuestionList, {...selectedList}, {...state.richResponses})
      updateCache(state, { optionSelectedQuestionList: selectedList });
      let shouldUploadResponses = false;
      const questionResponseList = [...new Set([...state.questionResponseList, currentQuestion.id])];
      if (questionResponseList.length >= 15) {
        shouldUploadResponses = true;
        updateCache(state, { questionsVisited: questionResponseList, questionVerifiedAt }, 'UploadResult');
      }
      return {
        ...state,
        shouldUploadResponses,
        questionVerifiedAt,
        attendedQuestionList: getAttendedQuestionlist(currentQuestion, state.attendedQuestionList, {...selectedList}, {...state.richResponses}),
        questionResponseList: [...new Set([...state.questionResponseList, currentQuestion.id])],
        questionIndexMapping: getQuestionIndexMapping(state.allQuestions, state.markedQuestionList, selectedList, state.questionsVisited, state.richResponses, !state.isTestReview, state.resultData),
        questionSideBarData: getQuestionSideBarData(state.allQuestions, state.questions.map((value) => value.id), state.markedQuestionList, selectedList, state.currentQuestion.id, state.richResponses),
        selectedOption: [...(selectedList[currentQuestion.id] || [])],
        optionSelectedQuestionList: { ...selectedList },
      };
    case UPDATE_TEST_NUMERICAL_QUESTION_DATA:
      const { richResponses } = state;
      const responseList = state.optionSelectedQuestionList;
      const verifiedAt = state.questionVerifiedAt;
      richResponses[state.currentQuestion.id] = action.payload.richResponse;
      if (action.payload.richResponse.length > 0) {
        responseList[state.currentQuestion.id] = 0;
        verifiedAt[state.currentQuestion.id] = new Date().toJSON();
      } else {
        delete responseList[state.currentQuestion.id];
      }
      attendedQuestionList = getAttendedQuestionlist(state.currentQuestion, state.attendedQuestionList, {...state.optionSelectedQuestionList}, {...richResponses})
      updateCache(state, { optionSelectedQuestionList: { ...responseList }, richResponses: { ...state.richResponses } });
      const newQuestionResponseList = [...new Set([...state.questionResponseList, state.currentQuestion.id])];
      const shouldUploadQuestionResponses = newQuestionResponseList.length >= 5;
      if (shouldUploadQuestionResponses) {
        updateCache(state, { questionsVisited: newQuestionResponseList, questionVerifiedAt: verifiedAt }, 'UploadResult');
      }
      return {
        ...state,
        richResponses: { ...richResponses },
        questionVerifiedAt: verifiedAt,
        richResponse: action.payload.richResponse,
        attendedQuestionList: getAttendedQuestionlist(state.currentQuestion, state.attendedQuestionList, {...state.optionSelectedQuestionList}, {...richResponses}),
        shouldUploadResponses: shouldUploadQuestionResponses,
        questionResponseList: [...new Set([...state.questionResponseList, state.currentQuestion.id])],
        questionIndexMapping: getQuestionIndexMapping(state.allQuestions, state.markedQuestionList, responseList, state.questionsVisited, state.richResponses, !state.isTestReview, state.resultData),
        questionSideBarData: getQuestionSideBarData(state.allQuestions, state.questions.map((value) => value.id), state.markedQuestionList, responseList, state.currentQuestion.id, state.richResponses),
        optionSelectedQuestionList: { ...responseList },
      };
    case GET_TEST_PROGRESS_DATA:
      updateCache(state);
      const progressData = getTestProgressData(state.questionCount, Object.keys(state.optionSelectedQuestionList).length, state.questionsVisited.length, state.testDuration);
      return { ...state, ...progressData };
    case UPDATE_QUESTION_TIME_SPENT:
      const timeSpent = state.questionTimeSpent;
      timeSpent[state.currentQuestion.id] = ((timeSpent[state.currentQuestion.id] || 0) + (((new Date() - state.startTime) > 0) ? (new Date() - state.startTime) : 0));
      updateCache(state, { questionTimeSpent: timeSpent });
      return {
        ...state,
        questionTimeSpent: timeSpent,
        startTime: new Date(),
      };
    case CLEAR_UPLOADED_RESULTS:
      return { ...state, questionResponseList: [] };
    case FINISH_TEST:
      updateCache(state, {}, 'QuestionResponses', true);
      return state;
    case TEST_UPDATE_QUESTION_BOOKMARK:
      const { bookmarkedQuestionIds } = state;
      const { questionId, isBookmarked } = action.payload;
      if (isBookmarked) {
        bookmarkedQuestionIds.push(questionId);
      } else {
        bookmarkedQuestionIds.splice(bookmarkedQuestionIds.indexOf(questionId), 1);
      }
      return { ...state, bookmarkedQuestionIds, isCurrentQuestionBookmarked: isBookmarked };
    case SHOW_TEST_SESSION_FINISH_MODAL:
      return { ...state, showSessionFinishModal: action.showModal };
    case UPDATE_TEST_LOCK_STATE:
      return { ...state, ...action.payload };
    case UPDATE_TEST_QUESTION_LIST:
      const { subjects, selectedTypes } = action.payload;
      const availableQuestions = [];
      state.allQuestions.forEach((question, index) => {
        let shouldFilterQuestion = false;
        if (subjects.length > 0) {
          shouldFilterQuestion = !subjects.includes(`${question.subject_id}`);
        }
        if (!shouldFilterQuestion && selectedTypes.length > 0) {
          const mappings = {};
          Object.values(state.questionIndexMapping).forEach((values) => Object.keys(values).forEach((qIndex) => mappings[qIndex] = values[qIndex]));
          shouldFilterQuestion = !mappings[index + 1].some((type) => selectedTypes.includes(type));
        }
        if (!shouldFilterQuestion) {
          availableQuestions.push(question);
        }
      });
      return { ...state, questions: availableQuestions, questionCount: availableQuestions.length };
    case UPDATE_TEST_TIME_REMAINING:
      return { ...state, testDuration: action.payload.timeRemainingInSeconds };
    default:
      return state;
  }
};
