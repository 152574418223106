import Api from '../helpers/Api';
import { updateLoaderState } from './global';

export const UPDATE_SUBJECT_LIST = 'bookmark/UPDATE_SUBJECT_LIST';
export const GET_BOOKMARK_QUESTIONS = 'bookmark/GET_BOOKMARK_QUESTIONS';
export const ADD_BOOKMARK_QUESTIONS = 'bookmark/ADD_BOOKMARK_QUESTIONS';
export const UPDATE_BOOKMARK_QUESTION_LOADER = 'bookmark/UPDATE_BOOKMARK_QUESTION_LOADER';

export const getBookmarkQuestions = (type, tag, page = 1) => async (dispatch) => {
  if (page > 1) {
    dispatch({ type: UPDATE_BOOKMARK_QUESTION_LOADER, showLoader: true });
  } else {
    dispatch(updateLoaderState(true));
  }
  const response = await Api({
    method: 'get',
    url: `/student_app/get_bookmarked_question?subject=${type}&page=${page}&tag=${tag}`,
  });
  if (response.success) {
    dispatch({
      type: UPDATE_SUBJECT_LIST,
      payload: {
        subjects: response.subjects,
        pageType: 'Bookmark',
      },
    });
    const payload = {
      questions: response.questions,
      bookmarkedQuestionIds: response.questions.map((question) => question.id),
      selectedSubject: response.selected_subject,
      renderMoreQuestions: response.render_more_questions,
    };
    dispatch({
      type: page === 1 ? GET_BOOKMARK_QUESTIONS : ADD_BOOKMARK_QUESTIONS,
      payload,
    });
  }
  if (page > 1) {
    dispatch({ type: UPDATE_BOOKMARK_QUESTION_LOADER, showLoader: false });
  } else {
    dispatch(updateLoaderState(false));
  }
};
