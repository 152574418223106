import { UPDATE_SEARCH_RESULTS, UPDATE_SEARCH_STRING, RESET_QUESTION_LIST, GET_COURSES_AND_PREVIOUS_PAPER, 
  UPDATE_APPLIED_FILTERS, RESET_CHAPTERS, RESET_SUBJECTS, RESET_TOPICS, RESET_COURSES, UPDATE_PREVIOUS_PAPERS, 
  UPDATE_FILTER_LIST, FILTER_CATEGORIES, UPDATE_SOLUTION_BOARD_LOADER_STATE } from '../actions';

const INITIAL_STATE = {
  searchText: '',
  loaderState: false,
  questions: [],
  questionIds: [],
  renderNext: false,
  lastViewed: 0,
  totalSearchCount: 0,
  totalCount: 0,
  page: 0,
  fetching: false,
  previousSearchText: '',
  subjectsList: [],
  chaptersList: [],
  topics: [],
  courses: [],
  previousPapers: [],
  appliedFilters: {
    [FILTER_CATEGORIES.COURSE]: {},
    [FILTER_CATEGORIES.SUBJECT]: {},
    [FILTER_CATEGORIES.CHAPTER]: {},
    [FILTER_CATEGORIES.TOPIC]: {},
    [FILTER_CATEGORIES.PREVIOUS_PAPERS]: [],
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_RESULTS:
      return { ...state, ...action.payload };
    case UPDATE_SEARCH_STRING:
      return { ...state, ...action.payload };
    case UPDATE_SOLUTION_BOARD_LOADER_STATE:
      return { ...state, ...action.payload };
    case UPDATE_FILTER_LIST:
      return { ...state, ...action.payload };
    case RESET_QUESTION_LIST:
      return { ...state, searchText: '', questions: [], questionIds: [], renderNext: false, lastViewed: 0, totalSearchCount: 0, totalCount: 0, page: 0, fetching: false, previousSearchText: '' }
    case RESET_COURSES:
      return { ...state, appliedFilters: INITIAL_STATE.appliedFilters, chaptersList: [], subjectsList: [], topics: []}
    case GET_COURSES_AND_PREVIOUS_PAPER:
      return { ...state, ...action.payload }
    case RESET_SUBJECTS:
      let resetSubjects = state.appliedFilters;
      resetSubjects[FILTER_CATEGORIES.SUBJECT] = {};
      resetSubjects[FILTER_CATEGORIES.CHAPTER] = {};
      resetSubjects[FILTER_CATEGORIES.TOPIC] = {};
      resetSubjects[FILTER_CATEGORIES.PREVIOUS_PAPERS] = [];
      return { ...state, appliedFilters: resetSubjects, chaptersList: [], topics: [] }
    case RESET_CHAPTERS:
      let resetChapters = state.appliedFilters;
      resetChapters[FILTER_CATEGORIES.CHAPTER] = {};
      resetChapters[FILTER_CATEGORIES.TOPIC] = {};
      return { ...state, appliedFilters: resetChapters,  topics: [] }
    case RESET_TOPICS:
      let resetTopics = state.appliedFilters;
      resetTopics[FILTER_CATEGORIES.TOPIC] = {};
      return { ...state, appliedFilters: resetTopics}
    case UPDATE_PREVIOUS_PAPERS:
      let selectedPreviousPapers = state.appliedFilters;
      selectedPreviousPapers[FILTER_CATEGORIES.PREVIOUS_PAPERS] = [...action.payload.testNames];
      return { ...state, appliedFilters: selectedPreviousPapers}
    case UPDATE_APPLIED_FILTERS:
      let filters = state.appliedFilters;
      Object.keys(action.payload).forEach((type) => {
        filters[type] = action.payload[type]
      });
      return { ...state, appliedFilters: filters }
    default:
      return state;
  }
};
