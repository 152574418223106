import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MathJax from 'react-mathjax2';
import { asciiForMathJax } from '../../helpers/Utils';
import StyleConstant from '../../shared/styleConstants/styles.json';
import {
  updateQuestionOptionSelection, onClickVerify, updateQuestionIndex, showReport,
} from '../../actions/virtualMeeting';
import { showToast } from '../../actions/global';
import PreviousQuestion from '../../shared/images/common/prev-question.svg';
import NextQuestion from '../../shared/images/common/next-question.svg';

const MATHJAX_SCRIPT = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML';

const nextStyle = {
  width: 50,
  padding: 10,
  border: '1px solid #45454566',
  background: '#FFFFFF',
  borderRadius: 2,
  cursor: 'pointer',
  color: 'red',
};

const BACKGROUND = {
  UNANSWERED: '#fff',
  SELECTED: '#b8c5ebbd',
  CORRECT: '#9EE69EA6',
  WRONG: '#FFB6B6D6',
};

const BORDER = {
  UNANSWERED: '#45454566',
  SELECTED: '#a6bae785',
  CORRECT: '#92DB927A',
  WRONG: '#45454566',
};

class QuizQuestion extends React.Component {
  renderMathJax(text) {
    return (
      <MathJax.Context
        input={asciiForMathJax()}
        onError={() => {
          MathJax.Hub && MathJax.Hub.Queue(
            MathJax.Hub.Typeset(),
          );
        }}
        script={MATHJAX_SCRIPT}
        options={{
          asciimath2jax: {
            useMathMLspacing: true,
            delimiters: [['$$', '$$']],
            preview: 'none',
          },
        }}
      >
        <MathJax.Text text={text} />
      </MathJax.Context>
    );
  }

  renderOption(text, optionIndex) {
    const {
      question, selectedOption, complete, verfiedQuestions,
    } = this.props;
    let questionStatus = selectedOption === optionIndex ? 'SELECTED' : 'UNANSWERED';
    const wrongStatus = selectedOption === optionIndex ? 'WRONG' : 'UNANSWERED';
    const answerStatus = optionIndex === parseInt(question.answer, 10) ? 'CORRECT' : wrongStatus;
    const isAnswerable = (!question.is_locked);
    questionStatus = (complete || verfiedQuestions.includes(question.id))
      ? answerStatus : questionStatus;
    const image = question[`image_option_${optionIndex}`] && question[`image_option_${optionIndex}`].url;
    if (image) {
      return (
        <div
          role="presentation"
          onClick={
          () => {
            (isAnswerable)
          && this.props.updateQuestionOptionSelection(
            question.id,
            optionIndex,
          );
          }
          }
          style={{
            display: 'flex', background: BACKGROUND[questionStatus], marginBottom: 20, padding: '10px 5px',
          }}
        >
          <div style={{ marginRight: 5, color: '#454545' }}>
            {optionIndex}
            )
          </div>
          <img
            src={image}
            alt=""
            height="150px"
            width="250px"
          />
        </div>
      );
    }
    return (
      <div
        role="presentation"
        onClick={
          () => {
            isAnswerable
          && this.props.updateQuestionOptionSelection(
            question.id,
            optionIndex,
          );
          }
          }
        style={{
          padding: 6,
          overflowWrap: 'anywhere',
          color: questionStatus === 'CORRECT' ? 'black' : '#2E2D2D',
          fontFamily: questionStatus !== 'CORRECT' && 'Nunito',
          fontWeight: questionStatus === 'CORRECT' ? 'bold' : 400,
          border: `1px solid ${BORDER[questionStatus]}`,
          backgroundColor: BACKGROUND[questionStatus],
          borderRadius: 2,
          marginBottom: 10,
          cursor: isAnswerable && 'pointer',
          userSelect: 'none',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {this.renderMathJax(text)}
      </div>
    );
  }

  renderSupportingPicture() {
    const { question } = this.props;
    const image = question.supporting_picture && question.supporting_picture.url;
    if (image) {
      return (
        <img
          src={image}
          alt=""
          style={{ marginBottom: 30, background: '#FFFFFF' }}
          height="150px"
          width="250px"
        />
      );
    }
    return <div />;
  }

  render() {
    const {
      question, complete, next, prev,
      selectedOption, quizId, verfiedQuestions,
      isRecorded, showReport, showToast,
    } = this.props;
    const isAnswerable = (!question.is_locked);
    return (
      <>
        <div
          style={{
            color: '#373737',
            padding: '20px 15px',
            position: 'relative',
            width: '100%',
            height: 425,
            cursor: question.is_locked && 'not-allowed',
          }}
        >
          <div style={{ maxWidth: 1000, color: StyleConstant.color.white }}>
            <div
              className="frame"
              style={{
                overflow: 'auto',
                height: 375,
                padding: 10,
                border: '1px solid #0095ffad',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              onClick={question.is_locked ? () => { this.props.showToast('Your teacher has locked the question !'); } : () => {}}
            >
              <div style={{
                marginBottom: 20,
                overflowWrap: 'anywhere',
                paddingLeft: 0,
                padding: 10,
                color: '#373737',
              }}
              >
                {this.renderMathJax(question.question_text)}
              </div>
              {this.renderSupportingPicture()}
              <div style={{
                display: 'flex', flexDirection: 'column', width: 'fit-content', minWidth: 300,
              }}
              >
                {this.renderOption(question.option_1, 1)}
                {this.renderOption(question.option_2, 2)}
                {this.renderOption(question.option_3, 3)}
                {this.renderOption(question.option_4, 4)}
              </div>
            </div>

            <div
              style={{
                marginTop: 20,
                display: 'flex',
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  width: '25%',
                  cursor: prev && 'pointer',
                }}
                onClick={() => { prev && this.props.updateQuestionIndex(-1); }}
              >
                {prev && (
                <img
                  role="presentation"
                  src={PreviousQuestion}
                  alt="Previous Question"
                  style={{
                    ...nextStyle,
                    border: '',
                    color: '#454545',
                  }}
                />
                )}
              </div>
              <div style={{
                width: '50%',
              }}
              >
                {(((selectedOption > 0
            && !(verfiedQuestions.includes(question.id))) && !complete) || (question.is_locked)) && (
              <div
                style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
              >
                <div
                  role="presentation"
                  onClick={question.is_locked ? () => { showToast('Your teacher has locked the question.'); } : () => {
                    this.props.onClickVerify({
                      question_id: question.id,
                      selected_option: selectedOption,
                      quiz_id: quizId,
                    });
                  }}
                  className="green-white-button"
                  style={{
                    padding: 10,
                    width: 250,
                    color: '#373737',
                    borderRadius: 2,
                    textAlign: 'center',
                    marginBottom: 10,
                    height: 50,
                    cursor: question.is_locked ? 'not-allowed' : 'pointer',
                  }}
                >
                  {question.is_locked ? 'LOCKED' : 'VERIFY'}
                </div>
              </div>
                )}
              </div>
              <div
                style={{
                  width: '25%',
                  cursor: next && 'pointer',
                }}
                onClick={() => { next && this.props.updateQuestionIndex(1); }}
              >
                {next && (
                <img
                  role="presentation"
                  src={NextQuestion}
                  alt="Next Question"
                  style={{
                    ...nextStyle,
                    border: '',
                    color: '#454545',
                  }}
                />
                )}
              </div>
            </div>
            <div>
              {!next && isRecorded && quizId && (
                <div
                  role="presentation"
                  onClick={() => {
                    showReport(quizId);
                  }}
                  style={{
                    ...nextStyle,
                    marginLeft: 'auto',
                    color: 'green',
                  }}
                >
                View Report
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

QuizQuestion.propTypes = {
  question: PropTypes.object,
  verfiedQuestions: PropTypes.array,
  quizId: PropTypes.number,
  complete: PropTypes.bool,
  selectedOption: PropTypes.number,
};

QuizQuestion.defaultProps = {
  question: {},
  verfiedQuestions: [],
  selectedOption: 0,
  quizId: 0,
  complete: false,
};

export default connect(null, {
  updateQuestionOptionSelection, onClickVerify, updateQuestionIndex, showReport, showToast,
})(QuizQuestion);
