import React, { PureComponent } from 'react';
import Text from './text';
import StyleConstants from '../shared/styleConstants/styles';
import Layout from './layout';

class VideoPage extends PureComponent {
  render() {
    return (
      <Layout hideFooter>
        <div style={{
          paddingTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
        >
          <Text
            color={StyleConstants.textColor.primary}
            fontWeight={StyleConstants.textWeight.semiBold}
            fontSize={StyleConstants.textSize.header}
            text="IdeaBoard App Tutorial"
          />
          <iframe
            title="Tutorial"
            className="player"
            type="text/html"
            width="85%"
            height={500}
            src="https://www.youtube.com/embed/Ft_KImcrcWc?rel=0&amp;autoplay=1"
            frameBorder="0"
            style={{ marginTop: 10 }}
          />
        </div>
      </Layout>
    );
  }
}

export default VideoPage;
