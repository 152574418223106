import React, { PureComponent } from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SideBar from './sidebar';
import Layout from './layout';
import { getQuizScoreData } from '../actions/index';
import { SIDEBAR_TYPES } from '../shared/constants/fieldTypes';
import noQuizTaken from '../shared/images/common/noData.svg';
import Loader from './loader';

const cookies = new Cookies();
const user = cookies.get('user');
class InstituteFile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    this.props.getQuizScoreData().then(() => this.setState({ showLoader: false }));
  }

  renderSubjectQuizScore(subject, highlight = false) {
    const isHigherEducation = user.is_higher_education;

    const style = { padding: 20, whiteSpace: 'nowrap', fontWeight: highlight ? 'bold' : '' };
    const score = subject && subject.total_attempted ? (Math.ceil(((subject.correct || 0) * 100) / (subject.total_attempted))) : 0;
    return (
      <tr>
        {isHigherEducation && <td style={style}>{subject && subject.subject_code}</td>}
        <td style={style}>{subject && subject.subject_name}</td>
        <td style={style}>{subject && subject.total_attempted}</td>
        <td style={style}>{subject && subject.correct}</td>
        <td style={style}>{`${score} %`}</td>
      </tr>
    );
  }

  renderTable() {
    const { quizScores, totalQuizScores } = this.props;
    const isHigherEducation = user.is_higher_education;
    const style = { padding: 20, whiteSpace: 'nowrap', borderRight: '1px solid #DFE3EA' };
    if (quizScores.length === 0) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column', height: global.innerHeight - 80, alignItems: 'center', justifyContent: 'center',
        }}
        >
          <img alt="no_quiz_icon" height={200} width={200} src={noQuizTaken} style={{ marginRight: 5 }} />
          <div>No quiz taken</div>
        </div>
      );
    }
    return (
      <>
        <div style={{ marginLeft: '5%', marginTop: 30, fontSize: 26 }}>
          Quiz Scores
        </div>
        <table
          className="table table-hover"
          style={{
            boxShadow: '1px 2px 6px #8B9DAF33', width: '90%', marginTop: 20, marginLeft: '5%', marginRight: '5%', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
          }}
        >
          <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
            <tr>
              {isHigherEducation && <th style={style}>Code</th>}
              <th style={style}>Subject</th>
              <th style={style}>Total Attempted</th>
              <th style={style}>Answered Correctly</th>
              <th style={style}>Score</th>
            </tr>
          </thead>
          <tbody>
            {quizScores.map((subject) => {
              return this.renderSubjectQuizScore(subject);
            })}
            {this.renderSubjectQuizScore(totalQuizScores, true)}
          </tbody>
        </table>
      </>
    );
  }

  renderQuizScores() {
    const { showLoader } = this.state;
    return (
      <div className="MainPage" style={{ marginTop: 0, padding: 0 }}>
        {showLoader ? (
          <Loader />
        )
          : this.renderTable()}
      </div>
    );
  }

  render() {
    const {
      pageContentHeight,
    } = this.state;

    return (
      <Layout hideFooter>
        <SideBar pageType={SIDEBAR_TYPES.VIRTUAL_MEETINGS} />
        <div style={{ height: pageContentHeight }}>
          {this.renderQuizScores()}
        </div>
      </Layout>
    );
  }
}

InstituteFile.propTypes = {
  quizScores: PropTypes.array.isRequired,
  totalQuizScores: PropTypes.string.isRequired,

};

InstituteFile.defaultProps = {
};

const mapStateToProps = ({ virtualMeeting }) => ({
  quizScores: virtualMeeting.quizScores,
  totalQuizScores: virtualMeeting.totalQuizScores,
});

export default connect(mapStateToProps, { getQuizScoreData })(InstituteFile);
