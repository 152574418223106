import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SideBar from './sidebar';
import Overview from './overview';
import Text from './text';
import QuestionView from './questionView';
import {
  getTrainData, updateTrainCurrentQuestionIndex, uploadResponses, updateTrainQuestionSelectedOptions,
  updateTrainQuestionTimeSpent, verifyQuestion, updateSolutionRequestStatus,
  showTrainSessioFinishModal, updateTrainRichResponse,
} from '../actions/index';
import nextIcon from '../shared/images/common/next.svg';
import backIcon from '../shared/images/common/back.svg';
import StyleConstants from '../shared/styleConstants/styles';
import { OBJECT_TYPES } from '../shared/constants/fieldTypes';
import { sendEvent } from '../helpers/Analytics';
import Layout from './layout';

class Train extends PureComponent {
  componentDidMount() {
    const { id } = this.props.match.params;
    const {
      questionCount, objectType, sessionType, testInfo,
    } = this.props;
    if (questionCount === 0 || parseInt(id, 10) !== parseInt(testInfo.id, 10)) {
      this.props.getTrainData(id, objectType, sessionType);
    }
  }

  componentWillUnmount() {
    this.props.uploadResponses('QuestionResponses');
  }

  renderQuestionNavigation() {
    const {
      selectedOption, richResponses, currentquestionVerified,
      currentIndex, questionCount, testInfo, currentQuestion,
    } = this.props;
    if ((selectedOption.length > 0
      || (richResponses[currentQuestion.id] && richResponses[currentQuestion.id].length > 0))
      && !currentquestionVerified && !testInfo.is_session_review) {
      return (
        <div role="presentation" id="Verify-Question" className="Button Primary-Button" style={{ width: '100%' }} onClick={() => this.props.verifyQuestion()}>
          Verify
        </div>
      );
    }
    return (
      <div className="d-flex" style={{ justifyContent: 'space-between' }}>
        <div
          role="presentation"
          id="Previous-Question-Button"
          className="Button pl-5 pr-5 m-1"
          style={{ width: '100%', color: StyleConstants.textColor.primary, opacity: currentIndex === 0 && 0.5 }}
          onClick={() => {
            if (currentIndex > 0) {
              this.props.updateTrainCurrentQuestionIndex(currentIndex - 1);
            }
          }}
        >
          <img alt="Previous icon" height={19} width={19} src={backIcon} style={{ marginRight: 5 }} />
          Prev
        </div>
        <div
          role="presentation"
          id="Next-Question-Button"
          className="Button pl-5 pr-5 m-1"
          style={{ width: '100%', color: StyleConstants.textColor.primary, opacity: questionCount === currentIndex + 1 && 0.5 }}
          onClick={() => {
            if (questionCount > currentIndex + 1) {
              this.props.updateTrainCurrentQuestionIndex(currentIndex + 1);
            }
          }}
        >
          Next
          <img alt="Next icon" height={19} width={19} src={nextIcon} style={{ marginLeft: 5 }} />
        </div>
      </div>
    );
  }

  renderView() {
    const {
      currentIndex, questionsVisited, questionsVerified, selectedOption,
      solutionRequestTime, questionCount, testInfo, currentQuestion,
      shouldUploadResponses, currentQuestionTimeSpent,
      bookmarkedQuestionIds, richResponses,
    } = this.props;
    if (questionCount === 0) {
      return (
        <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
          <Text text="No questions" fontWeight={StyleConstants.textWeight.semiBold} fontSize={StyleConstants.textSize.headerSmall} />
        </div>
      );
    }
    return (
      <div>
        <QuestionView
          key={currentQuestion.id}
          showSolutionIcon
          showVideoSolutionIcon
          showResult={questionsVerified[currentQuestion.id] || testInfo.is_session_review}
          questionsVisited={[...questionsVisited]}
          currentQuestion={{ ...currentQuestion }}
          currentIndex={currentIndex}
          richResponse={richResponses[currentQuestion.id] || ''}
          selectedOption={[...selectedOption]}
          shouldUploadResponses={shouldUploadResponses}
          currentQuestionTimeSpent={currentQuestionTimeSpent}
          solutionRequested={parseInt(solutionRequestTime[currentQuestion.id], 10) > 0}
          sessionInfo={testInfo}
          isCurrentQuestionBookmarked={bookmarkedQuestionIds.includes(currentQuestion.id)}
          updateSolutionRequestStatus={this.props.updateSolutionRequestStatus}
          updateQuestionTimeSpent={this.props.updateTrainQuestionTimeSpent}
          updateQuestionSelectedOptions={this.props.updateTrainQuestionSelectedOptions}
          updateRichResponse={this.props.updateTrainRichResponse}
        />
        <div style={{ marginTop: 30 }}>
          {this.renderQuestionNavigation()}
        </div>
      </div>
    );
  }

  render() {
    const { id } = this.props.match.params;
    const {
      objectType, sessionType, testInfo, currentIndex, currentQuestion, selectedOption, currentQuestionTimeSpent, currentquestionVerified, questionSolutionRequested, isCurrentQuestionBookmarked,
    } = this.props;
    sendEvent('Questions - QuestionDisplayed', {
      ...testInfo, index: currentIndex, question: currentQuestion.id, selectedOption, currentQuestionTimeSpent, currentquestionVerified, questionSolutionRequested, isCurrentQuestionBookmarked,
    });
    return (
      <Layout hideFooter>
        <div>
          <SideBar
            showBackNavigation
            navigateTo={testInfo.is_session_review ? `/session_report/${id}?object_type=${objectType}&session_type=${sessionType}` : objectType === OBJECT_TYPES.ASSIGNMENT ? '/institute?type=assignment' : [OBJECT_TYPES.CHECKPOINT, OBJECT_TYPES.CHAPTER].includes(objectType) ? `/chapter/${testInfo.chapter_id}` : ''}
            functionCall={objectType === OBJECT_TYPES.PERSONALISED_TEST && !testInfo.is_session_review && this.props.showTrainSessioFinishModal}
          />
          <Overview pageType="train" objectType={objectType} sessionType={sessionType} />
          <div className="MainPage RightOverview" style={{ marginTop: 20 }}>
            {this.renderView()}
          </div>
        </div>
      </Layout>
    );
  }
}

Train.propTypes = {
  getTrainData: PropTypes.func.isRequired,
  verifyQuestion: PropTypes.func.isRequired,
  uploadResponses: PropTypes.func.isRequired,
  updateSolutionRequestStatus: PropTypes.func.isRequired,
  updateTrainQuestionTimeSpent: PropTypes.func.isRequired,
  showTrainSessioFinishModal: PropTypes.func.isRequired,
  updateTrainCurrentQuestionIndex: PropTypes.func.isRequired,
  updateTrainQuestionSelectedOptions: PropTypes.func.isRequired,
  updateTrainRichResponse: PropTypes.func.isRequired,
  match: PropTypes.object,
  testInfo: PropTypes.object,
  objectType: PropTypes.number,
  sessionType: PropTypes.number,
  currentIndex: PropTypes.number,
  questionCount: PropTypes.number,
  selectedOption: PropTypes.array,
  currentQuestion: PropTypes.object,
  questionsVisited: PropTypes.object,
  questionsVerified: PropTypes.object,
  solutionRequestTime: PropTypes.object,
  shouldUploadResponses: PropTypes.bool,
  bookmarkedQuestionIds: PropTypes.array,
  currentquestionVerified: PropTypes.bool,
  questionSolutionRequested: PropTypes.bool,
  currentQuestionTimeSpent: PropTypes.object,
  isCurrentQuestionBookmarked: PropTypes.bool,
  richResponses: PropTypes.object,
};

Train.defaultProps = {
  objectType: 0,
  testInfo: {},
  questionsVerified: {},
  bookmarkedQuestionIds: [],
  solutionRequestTime: {},
  questionCount: 0,
  sessionType: 0,
  currentIndex: 0,
  match: {},
  currentQuestion: {},
  questionsVisited: {},
  selectedOption: [],
  shouldUploadResponses: false,
  currentQuestionTimeSpent: {},
  currentquestionVerified: false,
  questionSolutionRequested: false,
  isCurrentQuestionBookmarked: false,
  richResponses: {},
};

const mapStateToProps = ({ train }) => ({
  testInfo: train.testInfo,
  questionCount: train.questionCount,
  currentIndex: train.currentIndex,
  questionIndex: train.questionIndex,
  richResponses: train.richResponses,
  isCurrentQuestionVisited: train.isCurrentQuestionVisited,
  questionsVisited: train.questionsVisited,
  allQuestions: train.allQuestions,
  currentQuestion: train.currentQuestion,
  selectedOption: [...train.selectedOption],
  questionsVerified: train.questionsVerified,
  solutionRequestTime: train.solutionRequestTime,
  optionSelectedQuestionList: train.optionSelectedQuestionList,
  shouldUploadResponses: train.shouldUploadResponses,
  bookmarkedQuestionIds: train.bookmarkedQuestionIds,
  currentQuestionTimeSpent: train.currentQuestionTimeSpent,
  currentquestionVerified: train.currentquestionVerified,
  questionSolutionRequested: train.questionSolutionRequested,
  isCurrentQuestionBookmarked: train.isCurrentQuestionBookmarked,
});

export default connect(mapStateToProps, {
  getTrainData,
  updateTrainCurrentQuestionIndex,
  uploadResponses,
  updateTrainQuestionSelectedOptions,
  updateTrainQuestionTimeSpent,
  verifyQuestion,
  updateSolutionRequestStatus,
  showTrainSessioFinishModal,
  updateTrainRichResponse,
})(Train);
