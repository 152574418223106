import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text from './text';
import Loader from './loader';
import queryString from 'query-string';
import SolutionBoardQuestionView from './solutionBoardQuestionView';
import StyleConstants from '../shared/styleConstants/styles';
import { isMobileDevice } from '../helpers/Utils';
import Layout from './layout';
import Constants from '../shared/constants';
import SolutionBoardMenubar from './solutionBoardMenuBar';
import { sendEvent } from '../helpers/Analytics';
import { searchQuestions, updateSearchString, resetQuestionsListReducer, getCourses, updateFilters, FILTER_CATEGORIES } from '../actions';

const IS_MOBILE = isMobileDevice();

class SolutionBoard extends PureComponent {
  constructor(props) {
    super(props);
    this.iScroll = React.createRef();
    this.state = {
      searchString: '',
      pageStyle: {},
      screenHeight: '',
      screenWidth: '',
      urlText: '',
      selectedCourse: null,
      selectedSubject: null,
      selectedChapter: null,
      selectedTopic: null,
      selectedPreviousPaper: null,
      filterType: '',
      filterValue: null,
      subjectsList: props.subjectsList,
      chaptersList: props.chaptersList,
      topics: props.topics,
      courses: props.courses,
      showMenuContentId: null,
      allowRenderMobileFilterPage: false,
      mobileFilterList: [],
      currentMobileFilterType: '',
      currentFilterHeader: '',
      showMobileFilters: true,
    }
    this.searchThrottle = _.debounce(() => this.searchQuestions(false), 500);
    this.renderContent = this.renderContent.bind(this);
    this.scrollListener = this.scrollListener.bind(this);
    this.renderWebView = this.renderWebView.bind(this);
    this.renderMobileView = this.renderMobileView.bind(this);
    this.renderMobileFilterPage = this.renderMobileFilterPage.bind(this);
    this.renderMobileFilterCategories = this.renderMobileFilterCategories.bind(this);
    this.renderMenuListItems = this.renderMenuListItems.bind(this);
  }
  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    const { search_question_text: searchText = '', test_type_id: testTypeId = '', chapter_id: chapterId = '', subject_id: subjectId = '', checkpoint_id: checkpointId = '', previous_papers: previousPapers = '' } = queryParams;
    const filterData = {
      [FILTER_CATEGORIES.COURSE]: {
        value: testTypeId,
      },
      [FILTER_CATEGORIES.SUBJECT]: {
        value: subjectId,
      },
      [FILTER_CATEGORIES.CHAPTER]: {
        value: chapterId,
      },
      [FILTER_CATEGORIES.TOPIC]: {
        value: checkpointId,
      },
      [FILTER_CATEGORIES.PREVIOUS_PAPERS]: {
        value: previousPapers ? previousPapers : '',
      }
    }
    this.setState({ urlText: searchText }, () => {
      this.props.updateSearchString({ searchText: searchText });
      this.props.updateFilters(filterData);
      this.props.searchQuestions(false);
    });
    const pageStyle = { height: global.innerHeight * 1, overflow: "auto", overflowX: 'hidden', zIndex: 5, width: '100%' };
    this.setState({ pageStyle, screenHeight: global.innerHeight, screenWidth: global.innerWidth });
    this.iScroll.current && this.iScroll.current.scrollTo({ y: 0 });
    {false &&  this.props.getCourses();}
  }

  showMenuComponents = (event) => {
    event.preventDefault();
    document.addEventListener('click', this.closeMenuComponents);
  }

  closeMenuComponents = (event) => {
    if (event && (event.target.className !== 'show-options')) {
      this.setState({ showMenuContentId: null }, () =>
        document.removeEventListener('click', this.closeMenuComponents));
      }
  }

  searchQuestions(load) {
    const { searchText } = this.props;
    this.setState({ urlText: searchText }, () => this.props.searchQuestions(load))
  }
  scrollListener() {
    const scrollField = this.iScroll.current;
    if (scrollField.scrollTop + scrollField.clientHeight >= scrollField.scrollHeight) {
      this.searchQuestions(true);
    }
  }

  renderMenuListItems(label, filterType, list) {
    const { appliedFilters } = this.props;
    let isSelected = false;
    let value = null;
    let allPreviousPapers = [];
    const isPreviousPaper = filterType === FILTER_CATEGORIES.PREVIOUS_PAPERS;
    const appliedFilterContent = appliedFilters[filterType];
    if (isPreviousPaper) {
      allPreviousPapers = list.map((paper) => paper.name).join(",");
    }
    return (
      <div className="show-options" style={{ cursor: 'pointer', marginTop: isPreviousPaper ? 10 : 0 }} onClick={() => this.props.updateFilters({ [filterType]: { value: isPreviousPaper ? allPreviousPapers : null }})}>
        <Text
          text={label}
          color={StyleConstants.solutionBoard.primary}
          style={{ fontWeight: StyleConstants.textWeight.bold }}
        />
        {list.map((listItem) => {
          if (isPreviousPaper) {
            isSelected = appliedFilterContent.length > 0 && appliedFilterContent.includes(listItem.name);
            value = isSelected ? appliedFilterContent.filter((selectedPaper) => selectedPaper !== listItem.name) : [ ...appliedFilterContent, listItem.name];
          }
          else {
            isSelected = appliedFilterContent.key === listItem.id;
          }
          return (
            <div style={{ padding: 10, cursor: 'pointer', display: 'flex' }} onClick={(event) => { event.stopPropagation(); this.setState({ showMenuContentId: null }, () => this.props.updateFilters({ [filterType]: { value: isPreviousPaper ? listItem.name : listItem.id, text: listItem.name }}));} }>
              {isPreviousPaper &&
                <input
                  checked={isSelected}
                  type="checkbox"
                  style={{ margin: '5px 5px 0px 0px', width: 'fit-content', height: 'fit-content', cursor: 'pointer' }}
                />
              }
              <Text
                text={listItem.name}
                color={isSelected ? StyleConstants.solutionBoard.answerColor : StyleConstants.solutionBoard.optionColor}
                style={{ fontWeight: isSelected ? StyleConstants.textWeight.bold : '' }}
              />
            </div>
          )
        })
      }
      </div>
    );
  }

  renderMenuContents() {
    const { previousPapers, subjectsList,  } = this.props;
    return (
      <div style={{ width: 260, maxHeight: 500, overflowY: 'scroll', position: 'fixed', top: 70, padding: 10, left: IS_MOBILE ? 30: 300, zIndex: 1, boxShadow: '1px 2px 6px #8B9DAF26', backgroundColor: 'white', borderRadius: '0px 0px 4px 4px' }} onClick={() => this.setState({ showMenuContentId: null })}>
        {previousPapers.length > 0 && this.renderMenuListItems("Previous Papers", FILTER_CATEGORIES.PREVIOUS_PAPERS, previousPapers)}
      </div>
    );
  }

  renderMenus(courses) {
    const { showMenuContentId, selectedPreviousPaper } = this.state;
    const { appliedFilters } = this.props;
    return(
     <div style={{ display: 'flex', alignItems: 'center', height: IS_MOBILE && 70 }}>
        {
          courses.map((course) => {
            const isSelected = appliedFilters[FILTER_CATEGORIES.COURSE] && (parseInt(appliedFilters[FILTER_CATEGORIES.COURSE].key) === course.id);
            return (
              <>
                <div className={`${isSelected ? 'Primary-Button' : 'Secondary-Button' } show-options`} style={{ height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 15, padding: '10px 20px', borderRadius: 4, cursor: 'pointer', border: isSelected ? '' : '1px solid' }} onClick={(event) => {this.showMenuComponents(event); this.setState({ showMenuContentId: (showMenuContentId === course.id) ? null : course.id, selectedPreviousPaper: isSelected ? selectedPreviousPaper  : null}, () => this.props.updateFilters( {[FILTER_CATEGORIES.COURSE]: { value: (showMenuContentId === course.id) ? null : course.id }}))}}> {course.name} </div>
                {(showMenuContentId === course.id) && !IS_MOBILE && this.renderMenuContents()}
              </>
            );
          })
        }
     </div>
    );
  }

  renderContent() {
    const { questions, searchText, totalSearchCount, fetching, renderNext, appliedFilters, loaderState } = this.props;
    const { urlText } = this.state;
    if (loaderState) {
      return (
        <Loader height={400}/>
      );
    }
    if ((!searchText || !urlText) && !appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS].length && IS_MOBILE) {
      return (
        <div style={{ textAlign: 'center', color: '#ccc', padding: 10, fontSize: 20, fontWeight: StyleConstants.textWeight.bold, marginTop: 20 }}>
          Search for any question from our database of 120k+ questions!
        </div>
      );
    }
    if ((!totalSearchCount && !fetching && (searchText || urlText)) || (appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS].length && !totalSearchCount)) {
      return (
          <div className="m-5" style={{ textAlign: 'center', color: '#ccc', padding: 10, fontSize: 20, fontWeight: 'bold' }}>
          No matches Found!
        </div>
      );
    }

    return (
      <>
        <div className="header" style={{ marginTop: 10 }}>
          {totalSearchCount > 0 && searchText &&
            <Text
            text={`Results For "${urlText}"`}
            color={StyleConstants.solutionBoard.primary}
            fontSize={StyleConstants.textSize.headerSmall}
            />
          }
          {totalSearchCount > 0 &&
            <Text
            text={`(${totalSearchCount} Questions)`}
            color={StyleConstants.solutionBoard.primary}
            fontSize={StyleConstants.textSize.headerSmall}
            />
          }
        </div>
        {questions.length > 0 && questions.map((question, index) => (
            <SolutionBoardQuestionView
              currentQuestion={question}
              updateQuestionTimeSpent={() => ''}
              currentIndex={index+1}
            />
        ))}
        {renderNext &&
          <div style={{ textAlign: 'center', color: '#ccc', padding: 10, fontSize: StyleConstants.textSize.header, fontWeight: 'bold' }}>
            Loading... <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" />
          </div>
        }
        {!fetching && !renderNext && totalSearchCount > 0 &&
          <div className="m-5" style={{ textAlign: 'center', color: '#ccc', padding: 10, fontSize: StyleConstants.textSize.header, fontWeight: 'bold' }}>
            No more questions.
          </div>
        }
      </>
    );
  }

  renderMobileFilterPage(list) {
    const { currentMobileFilterType, currentFilterHeader } = this.state;
    const { appliedFilters } = this.props;
    const isPreviousPaper = currentMobileFilterType === FILTER_CATEGORIES.PREVIOUS_PAPERS;
    return (
      <div style={{ width: '100%', height: '100%', padding: '2% 3%' }}>
        <div style={{ height: 60, widh: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <div onClick={() => this.setState({ allowRenderMobileFilterPage: false })}>
              <img src="/images/common/arrow-left.svg" alt="NEET_JEE_left" style={{ marginRight: 10 }} />
            </div>
            {currentFilterHeader}
          </div>
          <img src="/images/landing_institute/x-mark.svg" alt="NEET_JEE_close" style={{ marginRight: 10 }} onClick={() => this.setState({allowRenderMobileFilterPage: false }, () => this.props.updateFilters({[currentMobileFilterType]: { value: currentMobileFilterType === FILTER_CATEGORIES.PREVIOUS_PAPERS ? '' : null }}))} />
        </div>
        {list.map((item) => {
          let isSelected = appliedFilters[currentMobileFilterType] && parseInt(appliedFilters[currentMobileFilterType].key) === item.id;
          if (isPreviousPaper) {
            isSelected = appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS].length > 0 && appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS].includes(item.name);
          }
          return (
            <div style={{ marginTop: 10 }} onClick={() => this.setState({ allowRenderMobileFilterPage: false, currentMobileFilterType: '', currentFilterHeader: '', mobileFilterList: []}, () => this.props.updateFilters({[currentMobileFilterType]: { value: isPreviousPaper ? item.name : item.id, text: isSelected ? '' : item.name || ''} }))}>
              {isPreviousPaper &&
                <input
                  checked={isSelected}
                  type="checkbox"
                  style={{ margin: '5px 5px 0px 0px', width: 'fit-content', height: 'fit-content', cursor: 'pointer' }}
                />
              }
              <Text
                text={item.name}
                color={isSelected ? StyleConstants.solutionBoard.answerColor : StyleConstants.solutionBoard.optionColor}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderMobileFilterCategories(text, list, type) {
    const { appliedFilters } = this.props;
    let selectedKey = null;
    let selectedValue = appliedFilters[type];
    let filterTags = [];
    if (appliedFilters[type] && !appliedFilters[type].value) {
      selectedKey = parseInt(appliedFilters[type].key);
      list.map((item) => {
        if(item.id === selectedKey) {
          filterTags.push(item.name)
        }
      })
    }
    if (type === FILTER_CATEGORIES.PREVIOUS_PAPERS){
       appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS].map((paper) => {
        list.map((item) => {
          if(item.name === paper) {
            filterTags.push(item.name)
          }
        })
      })
    }

    return (
      <div style={{ width: '100%', borderBottom: '1px solid #CBCBCB', marginBottom: text === "Topics" ? 70 : 40  }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} onClick={() => this.setState({ allowRenderMobileFilterPage: true, mobileFilterList: list, currentMobileFilterType: type, currentFilterHeader: text })}>
          <Text
          text={text}
          style={{ fontSize: StyleConstants.textSize.headerSmall, fontWeight: StyleConstants.textWeight.semiBold }}
          />
        <img src="/images/common/arrow-right-black.svg" alt="NEET_JEE_right" style={{ marginRight: 10 }} />
        </div>
        <Text
          text={selectedValue ? selectedValue.value : ''}
          style={{ color: StyleConstants.solutionBoard.answerColor,
            }}
        />
        {filterTags.length > 0 && filterTags.map((tags, index) =>
            <Text
            text={`${tags} ${index+1 < filterTags.length ? ',' : ''}`}
            style={{ color: StyleConstants.solutionBoard.answerColor }}
            />
          )
        }
      </div>
    );
  }

  renderSearchBar = () => {
    const { searchText } = this.props;
    const { showMobileFilters } = this.state;
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ margin: '10px 0px', display: 'flex', border: '1px solid #4C5E6F', borderRadius: 4, width: '100%', height: 40 }}>
          <img src="/images/common/search.svg" alt="NEET_JEE_search" style={{ padding: 10 }} />
          <div style={{ width: '100%', display: 'flex' }}>
            <input
              id="searchText"
              type="text"
              autoComplete="off"
              placeholder="Search Questions"
              style={{ border: 0, marginBottom: 0, height: 40, width: '100%', backgroundColor: "inherit", margin: 0, outline: 'none' }}
              onChange={(e) => {
                this.props.updateSearchString({ searchText:e.target.value, fetching: e.target.value !== '' });
                if (e.target.value === '') {
                  this.setState({ urlText: '' })
                  this.props.resetQuestionsListReducer();
                  return;
                }
                if (IS_MOBILE) {
                  this.searchThrottle();
                }
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.searchQuestions(false);
                  sendEvent('SolutionsBoard - SearchButtonTapped', { searchText }, true);
                }}}
              value={searchText}
              autoFocus={!IS_MOBILE}
            />
            <div style={{ width: 25, display: (searchText.length > 0) ? "flex" : "none", alignItems: "center", cursor: "pointer" }}  onClick={() => {this.setState({ urlText: '' }, () => this.props.resetQuestionsListReducer())}}>
              <img src="/images/landing_institute/x-mark.svg" width="16" height="16" alt="NEET_JEE_x_mark"/>
            </div>
          </div>
        </div>
        {!IS_MOBILE && <div className="Primary-Button" style={{ width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 4, margin: '10px 0px 10px 10px', cursor: 'pointer', padding: 10, height: 40 }} onClick={() => {sendEvent('SolutionsBoard - SearchButtonTapped', { searchText }, true); this.searchQuestions(false)}}>Search</div>}
      </div>
    );
  }

  renderMobileView() {
    const { subjectsList, chaptersList, topics, courses, previousPapers, appliedFilters, searchText } = this.props;
    const { allowRenderMobileFilterPage, mobileFilterList, screenHeight, showMobileFilters } = this.state;
    const selectedCourse = appliedFilters[FILTER_CATEGORIES.COURSE];
    const allowApplyFilters = searchText || ( appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS] && appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS].length);
    if (allowRenderMobileFilterPage) {
      return (this.renderMobileFilterPage(mobileFilterList))
    }
    else {
      return (
        <>
          <div style={{ height: 60, zIndex: 1, position: 'fixed', top: 0, width: '100%', padding: '15px 0px 15px 15px', boxShadow: '0px 3px 6px #343B400D', backgroundColor: StyleConstants.solutionBoard.menuBarBackgroundDark, display: 'flex', alignItems: 'center' }}>
           {!showMobileFilters &&
              <div onClick={() => this.setState({ showMobileFilters: true })}>
                <img src="/images/common/arrow-left-white.svg" alt="NEET_JEE_left_icon" style={{ marginRight: 10 }} />
              </div>
            }
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Text
                text="IDEA"
                color={StyleConstants.solutionBoard.headerTextPrimaryColor}
                fontSize="20px"
                style={{ paddingRight: 0 }}
                fontWeight={StyleConstants.textWeight.bold}
                />
              <Text
                text="BOARD"
                color={StyleConstants.color.white}
                style={{ paddingLeft: 0 }}
                fontSize="20px"
              />
              <div style={{ position: 'fixed', right: 15, marginTop: 5 }}>
                <a target="_blank" href={Constants().youtubeChannelUrl}>
                  <img src="/images/landing_institute/youtube.svg" alt="NEET_JEE_you_tube" height="50px" />
                </a>
              </div>
            </div>
          </div>
          <div className="static-pages" style={{ backgroundColor: '#F5F8FA', width: '100%', marginTop: 70 }}>
          <div style={{ width: '100%', height: 75, zIndex: 1, position: 'fixed', top: 60, padding: '2% 3%',  display: 'flex', backgroundColor: '#F5F8FA', display: 'flex', flexDirection: 'column' }}>
            {this.renderSearchBar()}
          </div>
          <div style={{ ...this.state.pageStyle, padding: '2% 3%', marginTop: IS_MOBILE ? 0 : 30, height: screenHeight - 70 }}  onScroll={() => this.scrollListener()} ref={this.iScroll}>
            {showMobileFilters && false &&
              <div>
                {courses && this.renderMenus(courses)}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 20 }}>
                  {selectedCourse.key && this.renderMobileFilterCategories("Previous Paper", previousPapers, FILTER_CATEGORIES.PREVIOUS_PAPERS)}
                  {selectedCourse.key && this.renderMobileFilterCategories("Subjects", subjectsList, FILTER_CATEGORIES.SUBJECT)}
                  {chaptersList.length > 0 && this.renderMobileFilterCategories("Chapters", chaptersList, FILTER_CATEGORIES.CHAPTER)}
                  {topics.length > 0 && this.renderMobileFilterCategories("Topics", topics, FILTER_CATEGORIES.TOPIC)}
                </div>
              </div>
            }
            <div style={{ marginTop: 70 }}>
              {this.renderContent()}
            </div>
          </div>
          {false && showMobileFilters &&
            <div onClick={allowApplyFilters ? () => this.setState({ showMobileFilters: false, urlText: searchText }, () => this.props.searchQuestions(false)) : () => '' } style={{ height: 60, zIndex: 1, position: 'fixed', bottom: 0, width: '100%', padding: '15px 0px 15px 15px', boxShadow: '0px 3px 6px #343B400D', color: StyleConstants.color.white, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: StyleConstants.solutionBoard.menuBarBackgroundDark }}>
              {searchText ? 'Search Questions' : 'Apply Filters'}
            </div>
          }
        </div>
        </>
      );
    }
  }

  renderWebView() {
    const { subjectsList, chaptersList, topics, courses, previousPapers, appliedFilters } = this.props;
    const { screenWidth } = this.state;
    return (
      <>
        <div style={{ width: 250, padding: 0, zIndex: 1 }}>
          <SolutionBoardMenubar
            courses={courses}
            subjectsList={subjectsList}
            chaptersList={chaptersList}
            topics={topics}
            width={250}
            updateFilters={(type, value, text='') => this.props.updateFilters({ [type]: { value: value, text: text }})}
          />
        </div>
        <div className="static-pages" style={{ backgroundColor: '#F5F8FA', height: '100%', width: IS_MOBILE ? '100%' : screenWidth - 250 }}>
          {false && <div style={{ height: 70, zIndex: 1, position: 'fixed', top: 0, width: '100%', padding: '15px 0px 15px 15px', boxShadow: '0px 3px 6px #343B400D', display: 'flex', backgroundColor: StyleConstants.color.white }}>
            { false && courses && this.renderMenus(courses)}
          <div style={{ position: 'fixed', right: 15 }}><a target="_blank" href={Constants().youtubeChannelUrl}><img src="/images/landing_institute/youtube.svg" alt="NEET_JEE_you_tube" height="50px" /></a></div>
        </div>}
        <div style={{ ...this.state.pageStyle, marginTop: 0 }}  onScroll={() => this.scrollListener()} ref={this.iScroll}>
          <div style={{ height: 160, zIndex: 1, position: 'fixed', top: 0, width: screenWidth - 250, padding: '50px 50px 0px 50px',  display: 'flex', backgroundColor: '#F5F8FA', display: 'flex', flexDirection: 'column' }}>
              <Text
                text="Search for any NEET / JEE question:"
                color={StyleConstants.solutionBoard.primary}
                fontSize={IS_MOBILE ? StyleConstants.textSize.header : StyleConstants.textSize.headerBig}
              />
              <div style={{ height: 50 }}>
                {this.renderSearchBar()}
              </div>
              <div style={{ display: 'flex' }}>
                {appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS].length > 0 && previousPapers.map((paper) => {
                  if(appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS].includes(paper.name)) {
                    return (
                      <div style={{ height: 30, marginRight: 10,  borderRadius: 4, backgroundColor: '#8B9DAF26', width: 'fit-content', padding: '0px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {paper.name}
                      </div>
                    )
                  }
                })}
              </div>
            </div>
            <div style={{ padding: IS_MOBILE ? '2% 3%' : '25px 50px', marginTop: 140 }}>
              {this.renderContent()}
            </div>
        </div>
      </div>
      </>
    );
  }

  render() {
    return (
      <Layout hideFooter isSolutionBoard>
         <div style={{ width: '100%' , display: 'flex'}}>
        {IS_MOBILE ? this.renderMobileView() : this.renderWebView()}
        </div>
      </Layout>
    )
  }
}

SolutionBoard.propTypes = {
  searchQuestions: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  renderNext: PropTypes.bool.isRequired,
  updateSearchString: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  totalSearchCount: PropTypes.number.isRequired,
  resetQuestionsListReducer: PropTypes.func.isRequired,
};

SolutionBoard.defaultProps = {
  questions: [],
  renderNext: false,
  searchText: '',
  totalSearchCount: 0,
};

const mapStateToProps = ({ solutionBoard }) => ({
  questions: solutionBoard.questions,
  fetching: solutionBoard.fetching,
  renderNext: solutionBoard.renderNext,
  searchText: solutionBoard.searchText,
  totalSearchCount: solutionBoard.totalSearchCount,
  subjectsList: solutionBoard.subjectsList,
  chaptersList: solutionBoard.chaptersList,
  topics: solutionBoard.topics,
  courses: solutionBoard.courses,
  previousPapers: solutionBoard.previousPapers,
  appliedFilters: solutionBoard.appliedFilters,
  loaderState: solutionBoard.loaderState,
});

export default connect (mapStateToProps, {
  searchQuestions,
  updateSearchString,
  resetQuestionsListReducer,
  getCourses,
  updateFilters
})(SolutionBoard);
