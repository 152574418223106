import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Text from './text';

import { logout } from '../actions/index';
import constants from '../shared/constants';
import StyleConstants from '../shared/styleConstants/styles';
import profile from '../shared/images/header/profile.svg';
import arrow from '../shared/images/common/up.svg';
import Layout from './layout';

const Constants = constants();

class Profile extends PureComponent {
  render() {
    const { userProfile } = this.props;
    return (
      <Layout hideFooter>
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 60,
        }}
        >
          <img alt="NEET_JEE_Profile Icon" src={profile} height={100} width={100} />
          <div style={{ marginTop: 20 }} />
          <Text
            color={StyleConstants.textColor.primary}
            fontSize={StyleConstants.textSize.header}
            fontWeight={StyleConstants.textWeight.bold}
            text={userProfile.name ? userProfile.name : 'User'}
          />
          <div />
          <Text
            color={StyleConstants.textColor.primary}
            fontSize={StyleConstants.textSize.headerSmall}
            text={`+91 - ${userProfile.phone}`}
          />
          <div className="cardView" style={{ marginTop: 30, width: 480, padding: 0 }}>
            <div className="bottomBorderView" style={{ display: 'flex', justifyContent: 'space-between', padding: 20 }}>
              <Text
                color={StyleConstants.textColor.primary}
                fontSize={StyleConstants.textSize.headerSmall}
                text="Support Contact"
              />
              <Text
                color={StyleConstants.textColor.primary}
                fontSize={StyleConstants.textSize.headerSmall}
                fontWeight={StyleConstants.textWeight.bold}
                text={Constants.contact}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 20 }}>
              <Text
                color={StyleConstants.textColor.primary}
                fontSize={StyleConstants.textSize.headerSmall}
                text="Support Email"
              />
              <Text
                color={StyleConstants.textColor.primary}
                fontSize={StyleConstants.textSize.headerSmall}
                fontWeight={StyleConstants.textWeight.bold}
                text={Constants.email}
              />
            </div>
          </div>
          {
            !userProfile.has_only_test_board_access && (
            <Link
              id="Payment-Button"
              to="/payment"
              className="cardView cardViewHover"
              style={{
                marginTop: 10, width: 480, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Text
                color={StyleConstants.textColor.primary}
                fontSize={StyleConstants.textSize.headerSmall}
                text="Subscription"
              />
              <img alt="NEET_JEE_Arrow" src={arrow} width={20} height={20} style={{ transform: 'rotate(90deg)' }} />
            </Link>
            )
          }
          <div
            id="Logout-Button"
            role="presentation"
            className="Button"
            style={{
              width: 480, marginTop: 20, padding: 20, color: StyleConstants.textColor.inactive,
            }}
            onClick={() => this.props.logout()}
          >
          Logout
          </div>
        </div>
      </Layout>
    );
  }
}

Profile.propTypes = {
  logout: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
};

Profile.defaultProps = {
  userProfile: {},
};

export default connect(null, { logout })(Profile);
