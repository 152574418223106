import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createBrowserHistory } from 'history';
import Pagination from 'react-js-pagination';
import ReactTooltip from 'react-tooltip';

import Text from './text';
import SideBar from './sidebar';
import Loader from './loader';
import Layout from './layout';
import Folder from '../shared/images/file_types/folder.svg';
import Excel from '../shared/images/file_types/excel.svg';
import Powerpoint from '../shared/images/file_types/powerpoint.svg';
import Pdf from '../shared/images/file_types/pdf.svg';
import Video from '../shared/images/file_types/video.svg';
import Word from '../shared/images/file_types/word.svg';
import File from '../shared/images/file_types/file.svg';
import Image from '../shared/images/file_types/image.svg';
import RightArrow from '../shared/images/common/right-arrow.svg';
import LeftArrow from '../shared/images/common/left-arrow.svg';
import { getFileDetails } from '../actions/index';
import StyleConstants from '../shared/styleConstants/styles';
import { SIDEBAR_TYPES } from '../shared/constants/fieldTypes';

const IMAGES = {
  Excel, Powerpoint, Pdf, Video, Word, File, Image,
};
const FILE_IMAGES = {
  png: 'Image', jpeg: 'Image', svg: 'Image', bmp: 'Image', doc: 'Word', docx: 'Word', txt: 'Word', mp4: 'Video', avi: 'Video', mpg: 'Video', mpeg: 'Video', mkv: 'Video', ppt: 'Powerpoint', pptx: 'Powerpoint', pps: 'Powerpoint', xlsx: 'Excel', xlsm: 'Excel', xls: 'Excel', pdf: 'Pdf', default: 'File',
};

const history = createBrowserHistory({
  forceRefresh: true,
});

const PAGE_COUNT = 10;

class InstituteFile extends PureComponent {
  constructor(props) {
    super(props);
    const { type, id } = this.props.match.params;
    let typeData = type === 'all' ? -1 : type;
    let isMeet = false;
    if (type === 'virtual_meeting') {
      typeData = -2;
      isMeet = true;
    } else {
      typeData = type === 'common' ? 0 : typeData;
    }
    this.state = {
      id: parseInt(id, 10),
      selectedSubject: typeData,
      showLoader: true,
      isMeet,
      page: 1,
    };
  }

  componentDidMount() {
    this.props.getFileDetails().then(() => this.setState({ showLoader: false }));
  }

  folderView(subject, subjectFiles) {
    const fileSize = subjectFiles && subjectFiles.files ? subjectFiles.files.length : 0;
    return (
      <div
        role="presentation"
        style={{
          padding: 20,
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          opacity: fileSize ? 1 : 0.5,
          boxShadow: '0px 1px 4px #0000003d',
          cursor: fileSize ? 'pointer' : 'not-allowed',
          width: '100%',
          background: '#FFFFFF',
        }}
        onClick={() => fileSize && history.push(`/institute_files/${subject.id || 'common'}`)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="" src={Folder} height="40px" width="40px" />
          <div style={{ marginRight: 30, marginLeft: 30 }}>{subject.name}</div>
        </div>
        <div style={{ whiteSpace: 'nowrap' }}>
          {fileSize > 1 ? `${fileSize} Files` : `${fileSize} File`}
        </div>
      </div>
    );
  }

  renderPagination(subjectFiles) {
    const { page } = this.state;
    if (subjectFiles && subjectFiles.length > 0 && subjectFiles.length > PAGE_COUNT) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            activePage={page}
            itemsCountPerPage={PAGE_COUNT}
            totalItemsCount={subjectFiles.length}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => this.setState({ page: pageNumber })}
          />
        </div>
      );
    }
    return <div />;
  }

  renderFiles(file) {
    const style = { padding: 20, height: '100%', verticalAlign: 'middle' };
    const fileExtension = file.file_url.split('.')[(file.file_url.split('.').length) - 1];
    const fileimage = FILE_IMAGES[fileExtension] || FILE_IMAGES.default;
    return (
      <tr onClick={() => { window.open(file.file_url); }}>
        <td style={style}>
          <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ verticalAlign: 'middle', alignSelf: 'center', marginRight: 10 }}><img src={IMAGES[fileimage]} alt="file" style={{ height: 30, width: 30, marginRight: 10 }} /></div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  data-tip={file.description}
                  style={{
                    width: 'fit-content', cursor: 'pointer', textOverflow: 'ellipsis',
                  }}
                >
                  <Text
                    text={file.title.charAt(0).toUpperCase() + file.title.slice(1)}
                    color={StyleConstants.textColor.primary}
                    fontSize={StyleConstants.textSize.headerSmall}
                    fontWeight={StyleConstants.textWeight.semiBold}
                  />
                </div>
                <ReactTooltip effect="solid" />
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                  {file.module && (
                  <div style={{ marginRight: 5 }}>
                    {`Module ${file.module}`}
                  </div>
                  )}
                  {file.module && file.lecture && (
                    <div style={{ marginRight: 5 }}>
                      |
                    </div>
                  )}
                  {file.lecture && (
                    <div>
                      {`Lecture ${file.lecture}`}
                    </div>
                  )}
                  <div />
                </div>
              </div>
            </div>
          </>
        </td>
        <td style={style}>
          {file.shared_date && <div><Text style={{ fontSize: '16px' }} text={moment(file.shared_date).format('DD MMM YYYY')} /></div>}
          <div><Text text={file.teacher_name.charAt(0).toUpperCase() + file.teacher_name.slice(1)} /></div>
        </td>
        <td style={style}>
          <img alt="" src={RightArrow} height="20px" width="20px" />
        </td>
      </tr>
    );
  }

  renderTableHeader() {
    const style = { padding: 20, whiteSpace: 'nowrap', borderRight: '1px solid #DFE3EA' };
    return (
      <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
        <tr>
          <th style={{ width: '80%', ...style }}>NAME</th>
          <th style={{ width: '25%', ...style, borderRight: 0 }}>SHARED BY</th>
          <th style={{ width: '5%', ...style, borderRight: 0 }} />
        </tr>
      </thead>
    );
  }

  renderTableData(name, files) {
    const { page } = this.state;
    const minCount = page >= 2 ? (page - 1) * PAGE_COUNT : 0;
    const maxCount = (page) * PAGE_COUNT;
    return (
      <div style={{ width: '100%' }}>
        <div style={{ fontSize: 26, marginTop: -20 }}>
          <img alt="backIcon" role="presentation" src={LeftArrow} height="20px" width="20px" style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => { history.push('/institute_files/all'); }} />
          {name}
        </div>
        <div>
          <table
            className="table table-hover"
            style={{
              boxShadow: '1px 2px 6px #8B9DAF33', width: '100%', marginTop: 30, borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
            }}
          >
            {this.renderTableHeader()}
            <tbody>
              {files
                ? files.slice(minCount, maxCount).map((file) => {
                  return this.renderFiles(file);
                })
                : <div>No Files Are Uploaded</div> }
            </tbody>
          </table>
          {this.renderPagination(files.files)}
        </div>
      </div>

    );
  }

  renderMapFiles(subject) {
    const { selectedSubject } = this.state;
    const { filesData } = this.props;
    const subjectFiles = filesData.filter((file) => (file.subject_id || 0) === subject.id)[0];
    if (parseInt(selectedSubject, 10) === -1) {
      return this.folderView(subject, subjectFiles);
    }
    if ((parseInt(selectedSubject, 10) === parseInt(subject.id, 10))) {
      if (subjectFiles && subjectFiles.files && subjectFiles.files.length > 0) {
        return this.renderTableData(subject.name, subjectFiles.files);
      }
      return <div>No files to show</div>;
    }
    return <div />;
  }

  renderPageContent() {
    const { isMeet, id } = this.state;
    const { subjects, virtualFileData } = this.props;
    if (isMeet) {
      const data = virtualFileData.filter((file) => parseInt(file.id, 10) === id)[0];
      return this.renderTableData(data.title, data.files);
    }
    return (
      subjects && subjects.map((subject) => {
        return this.renderMapFiles(subject);
      }));
  }

  renderFilesPage() {
    const { selectedSubject } = this.state;
    return (
      <div style={{ margin: '5%', marginTop: 30 }}>
        { (selectedSubject !== 0) && (
        <div style={{ fontSize: 26 }}>
          Files
        </div>
        )}
        <div
          style={{
            paddingTop: selectedSubject !== 0 ? 0 : 30, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20, marginRight: 20,
          }}
        >
          {this.renderMapFiles({ id: 0, name: 'Common' })}
          {this.renderPageContent()}
        </div>
      </div>
    );
  }

  InstituteFiles() {
    const { showLoader } = this.state;
    return (
      <div className="MainPage" style={{ marginTop: 0, padding: 0 }}>
        {showLoader ? (
          <Loader />
        )
          : this.renderFilesPage()}
      </div>
    );
  }

  render() {
    const {
      pageContentHeight,
    } = this.state;

    return (
      <Layout hideFooter>
        <SideBar pageType={SIDEBAR_TYPES.VIRTUAL_MEETINGS} />
        <div style={{ height: pageContentHeight }}>
          {this.InstituteFiles()}
        </div>
      </Layout>
    );
  }
}

InstituteFile.propTypes = {
  getFileDetails: PropTypes.func.isRequired,
  filesData: PropTypes.array,
  subjects: PropTypes.array,
  virtualFileData: PropTypes.array,
};

InstituteFile.defaultProps = {
  filesData: [],
  subjects: [],
  virtualFileData: [],
};

const mapStateToProps = ({ global }) => ({
  filesData: global.filesData,
  subjects: global.subjects,
  virtualFileData: global.virtualFileData,
});

export default connect(mapStateToProps, { getFileDetails })(InstituteFile);
