import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { QuizQuestion } from '.';
import { isMobileDevice } from '../../helpers/Utils';
import StyleConstant from '../../shared/styleConstants/styles.json';
import { changeShowQuizStatus, changeExpandStatus } from '../../actions/virtualMeeting';
import ShowIcon from '../../shared/images/common/show-icon.svg';
import HideIcon from '../../shared/images/common/hide-icon.svg';
import Shrink from '../../shared/images/common/shrink.svg';
import Expand from '../../shared/images/common/expand.svg';

const isMobile = isMobileDevice();

class ShowQuizQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.showQuiz = this.showQuiz.bind(this);
  }

  quizQuestion(quizQuestion) {
    const {
      dataUpdateCount, selectedOptions, questionIndex,
      quizId, verfiedQuestions, quizQuestionData, showReport,
      isRecorded,
    } = this.props;
    return (
      <QuizQuestion
        key={dataUpdateCount}
        question={{ ...quizQuestion }}
        quizId={quizId}
        complete={showReport}
        next={quizQuestionData.length !== (questionIndex + 1)}
        prev={questionIndex !== 0}
        verfiedQuestions={[...verfiedQuestions]}
        selectedOption={selectedOptions[quizQuestion.id]}
        isRecorded={isRecorded}
      />
    );
  }

  showReport() {
    const { quizScore, isRecorded } = this.props;
    return (
      <div style={{
        padding: 10, paddingBottom: 20, color: isRecorded && '#454545', fontFamily: '#454545', fontWeight: 'bold', fontSize: 16,
      }}
      >
        <div style={{
          marginTop: 20, marginBottom: 20, textAlign: 'center', fontSize: 20,
        }}
        >
          Quiz Report
        </div>
        <div style={{ margin: 10 }}>
          Correct: &nbsp;&nbsp;
          {quizScore.correct || 0}
        </div>
        <div style={{ margin: 10 }}>
          Incorrect:&nbsp;&nbsp;
          {quizScore.incorrect || 0}
        </div>
        <div style={{ margin: 10 }}>
          Unattempted:&nbsp;&nbsp;
          {quizScore.unattempted || 0}
        </div>
      </div>
    );
  }

  showQuiz() {
    const {
      quizQuestionData, questionIndex, showQuiz, active,
    } = this.props;
    const text = showQuiz ? 'Hide' : 'Show';
    const image = showQuiz ? HideIcon : ShowIcon;
    const resizeImage = active ? Expand : Shrink;
    return (
      <>
        <div
          style={{
            display: 'flex', justifyContent: 'space-between', fontSize: 24, fontWeight: 'bold', alignItems: 'center',
          }}
        >
          <div
            role="presentation"
            onClick={() => this.props.changeShowQuizStatus()}
            style={{ padding: 10, cursor: 'pointer' }}
          >
            {text}
            &nbsp;Quiz Question
          </div>
          <img
            alt=""
            src={image}
            height="35px"
            width="35px"
            role="presentation"
            style={{ cursor: 'pointer' }}
            onClick={() => this.props.changeShowQuizStatus()}
          />
          {showQuiz && (
          <img
            role="presentation"
            src={resizeImage}
            alt=""
            height="35px"
            width="35px"
            style={{ marginLeft: 'auto', paddingRight: 10, cursor: 'pointer' }}
            onClick={() => this.props.changeExpandStatus()}
          />
          )}
        </div>
        {showQuiz && quizQuestionData[questionIndex]
          && this.quizQuestion(quizQuestionData[questionIndex])}
      </>
    );
  }

  render() {
    const {
      showQuiz, showReport, quizQuestionData, isRecorded, active, integrated,
    } = this.props;
    const width = active ? 420 : 1000;
    if (Object.keys(quizQuestionData).length > 0) {
      return (
        <div style={{
          top: 90, left: !isRecorded && 20, right: isRecorded && 40, position: integrated && 'fixed', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end', width: active ? width : '100%', placeContent: 'center', zIndex: 1001, padding: '0px 20px',
        }}
        >
          <div style={{
            color: '#343737',
            fontFamily: 'Roboto',
            border: '1px solid #343737',
            borderRadius: 4,
            boxShadow: '0px 3px 15px rgba(65, 61, 74, 0.1)',
            background: showReport ? '#75F36199' : 'white',
            width,
            height: (showQuiz && !showReport) ? 575 : 'fit-content',
            userSelect: 'none',
            overflow: 'scroll',
          }}
          >
            {showReport ? this.showReport() : this.showQuiz()}
          </div>
        </div>
      );
    }
    return (
      <div />
    );
  }
}

ShowQuizQuestion.propTypes = {
  quizQuestionData: PropTypes.array,
  selectedOptions: PropTypes.object,
  quizId: PropTypes.number,
  verfiedQuestions: PropTypes.array,
  dataUpdateCount: PropTypes.number,
  questionIndex: PropTypes.number,
  showReport: PropTypes.bool,
  showQuiz: PropTypes.bool,
  active: PropTypes.bool,
  quizScore: PropTypes.object,
  integrated: PropTypes.bool,
  isRecorded: PropTypes.bool,
};

ShowQuizQuestion.defaultProps = {
  quizQuestionData: [],
  selectedOptions: {},
  quizId: 0,
  verfiedQuestions: [],
  showReport: false,
  dataUpdateCount: 0,
  active: true,
  quizScore: {},
  showQuiz: false,
  questionIndex: 0,
  integrated: false,
  isRecorded: false,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  quizQuestionData: virtualMeeting.quizQuestionData,
  selectedOptions: virtualMeeting.selectedOptions,
  active: virtualMeeting.active,
  quizId: virtualMeeting.quizId,
  showReport: virtualMeeting.showReport,
  showQuiz: virtualMeeting.showQuiz,
  verfiedQuestions: virtualMeeting.verfiedQuestions,
  dataUpdateCount: virtualMeeting.dataUpdateCount,
  questionIndex: virtualMeeting.questionIndex,
  quizScore: virtualMeeting.quizScore,
  isRecorded: virtualMeeting.isRecorded,
});

export default connect(mapStateToProps, { changeShowQuizStatus, changeExpandStatus })(ShowQuizQuestion);
