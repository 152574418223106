import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Text from './text';
import SideBar from './sidebar';
import { getCheatSheetData, updateCheatsheetTag, updateCheatSheetPageType } from '../actions/index';
import StyleConstants from '../shared/styleConstants/styles';
import Layout from './layout';

const PAGE_WIDTH = 700;

class CheatSheet extends PureComponent {
  componentDidMount() {
    const { id } = this.props.match.params;
    const { chapter } = this.props;
    if (chapter && chapter.id !== parseInt(id)) {
      this.props.getCheatSheetData(id);
    }
  }

  renderQuickpoint(quickpoint, index) {
    const { knownQuickpointIds, unknownQuickpointIds } = this.props;
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
    const isKnownQuickpoint = knownQuickpointIds.indexOf(quickpoint.id) > -1;
    const isUnknownQuickpoint = unknownQuickpointIds.indexOf(quickpoint.id) > -1;
    return (
      <div
        key={`Quickpoint_id_${quickpoint.id}`}
        className="cardView"
        style={{ display: 'flex', marginTop: 10 }}
      >
        <div style={{ width: 40, paddingTop: 2 }}>
          <div className="Circle" style={{ width: 25, height: 25, backgroundColor: StyleConstants.backgroundColor.highlight }}>
            <Text text={`${index}`} fontWeight={StyleConstants.textWeight.semiBold} fontSize={StyleConstants.textSize.small} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text text={quickpoint.title} color={StyleConstants.textColor.primary} fontSize={StyleConstants.textSize.headerSmall} fontWeight={StyleConstants.textWeight.semiBold} />
          <Text text={quickpoint.text} />
          {quickpoint.supporting_picture && quickpoint.supporting_picture.url && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                alt="NEET_JEE_supportig_picture"
                src={quickpoint.supporting_picture.url}
                height="inherit"
                width="inherit"
                style={{ maxWidth: 350, maxHeight: 350 }}
              />
            </div>
          )}
          {quickpoint.supporting_text && <Text text={quickpoint.supporting_text} />}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <div
              className="CheatSheetUnKnownButton"
              onClick={() => this.props.updateCheatsheetTag(quickpoint.id, 'UNKNOWN')}
              style={{ width: '49%', backgroundColor: isUnknownQuickpoint && '#EF9943', color: isUnknownQuickpoint && 'white' }}
            > Unknown </div>
            <div
              className="CheatSheetKnownButton"
              onClick={() => this.props.updateCheatsheetTag(quickpoint.id, 'KNOWN')}
              style={{ width: '49%', backgroundColor: isKnownQuickpoint && '#32BE3D', color: isKnownQuickpoint && 'white' }}
            > Known </div>
          </div>
        </div>
      </div>
    );
  }

  renderHeaderElement(text, count, stateValue) {
    const { cheatSheetPageType } = this.props;
    const elementWidth = (PAGE_WIDTH / 3);
    const isSelected = cheatSheetPageType === stateValue;
    return (
      <div
        onClick={() => this.props.updateCheatSheetPageType(stateValue)}
        style={{ width: elementWidth, cursor: 'pointer' }}
      >
        <div style={{ height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text text={`${text} (${count})`} color={isSelected ? StyleConstants.color.primary : StyleConstants.textColor.secondary} />
        </div>
        <div style={{ marginTop: 4, paddingTop: 1, borderTop: isSelected && `3px solid ${StyleConstants.color.primary}`, borderRadius: 4 }} />
      </div>
    );
  }

  renderPageHeader() {
    const { allQuickpoints, knownQuickpointIds, unknownQuickpointIds } = this.props;
    return (
      <div style={{
        position: 'fixed',
        left: 300,
        zIndex: 1,
        width: window.innerWidth - 300,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '0px 3px 15px rgba(65, 61, 74, 0.1)',
      }}
      >
        <div style={{ display: 'flex', width: PAGE_WIDTH }}>
          {this.renderHeaderElement('All', allQuickpoints.length, '')}
          {this.renderHeaderElement('Known', knownQuickpointIds.length, 'KNOWN')}
          {this.renderHeaderElement('Unknown', unknownQuickpointIds.length, 'UNKNOWN')}
        </div>
      </div>
    );
  }

  render() {
    const { chapter, quickpointList, allQuickpoints } = this.props;
    if (allQuickpoints.length === 0) {
      return (
        <Layout hideFooter>
          <SideBar showBackNavigation navigateTo={chapter.id ? `/chapter/${chapter.id}` : '/'} />
          <div className="MainPage" style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', marginBottom: 20 }}>
            <Text text="No Cheet Sheet is currently available for this chapter." />
          </div>
        </Layout>
      );
    }
    return (
      <Layout hideFooter>
        <SideBar showBackNavigation navigateTo={chapter.id ? `/chapter/${chapter.id}` : '/'} />
        {this.renderPageHeader()}
        <div className="MainPage" style={{ marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', marginBottom: 20 }}>
          <div style={{ width: PAGE_WIDTH }}>
            {quickpointList.map((quickpoint, index) => this.renderQuickpoint(quickpoint, index + 1))}
          </div>
        </div>
      </Layout>
    );
  }
}

CheatSheet.propTypes = {
  getCheatSheetData: PropTypes.func.isRequired,
  updateCheatsheetTag: PropTypes.func.isRequired,
  updateCheatSheetPageType: PropTypes.func.isRequired,
  match: PropTypes.object,
  chapter: PropTypes.object,
  allQuickpoints: PropTypes.array,
  quickpointList: PropTypes.array,
  knownQuickpointIds: PropTypes.array,
  cheatSheetPageType: PropTypes.string,
  unknownQuickpointIds: PropTypes.array,
};

CheatSheet.defaultProps = {
  chapter: {},
  allQuickpoints: [],
  quickpointList: [],
  cheatSheetPageType: '',
  knownQuickpointIds: [],
  unknownQuickpointIds: [],
};

const mapStateToProps = ({ home }) => ({
  chapter: home.chapter,
  quickpointList: home.quickpointList,
  allQuickpoints: home.allQuickpoints,
  cheatSheetPageType: home.cheatSheetPageType,
  knownQuickpointIds: home.knownQuickpointIds,
  unknownQuickpointIds: home.unknownQuickpointIds,
});

export default connect(mapStateToProps, { getCheatSheetData, updateCheatsheetTag, updateCheatSheetPageType })(CheatSheet);
