import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Loader from './loader';
import SideBar from './sidebar';
import Text from './text';
import QuestionView from './questionView';
import { sendEvent } from '../helpers/Analytics';
import StyleConstants from '../shared/styleConstants/styles';
import { SIDEBAR_TYPES, BOOKMARK_TYPES } from '../shared/constants/fieldTypes';
import { getBookmarkQuestions } from '../actions/index';
import upArrow from '../shared/images/common/upArrow.svg';
import Layout from './layout';

class Bookmark extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expandedQuestionList: [],
      questionSolutionList: [],
      bookmarkedQuestionIds: props.bookmarkedQuestionIds,
      bookmarkSelectedTag: BOOKMARK_TYPES.REVISE,
    };
    this.toggleQuestionView = this.toggleQuestionView.bind(this);
    this.updateBookmarkQuestionList = this.updateBookmarkQuestionList.bind(this);
  }

  resize = () => this.forceUpdate()

  componentDidMount() {
    const type = this.props.match.params.type ? this.props.match.params.type : '';
    const { selectedSubject, subjects } = this.props;
    window.addEventListener('resize', this.resize);
    if (!selectedSubject || (subjects.length === 0)) {
      this.props.getBookmarkQuestions(type, BOOKMARK_TYPES.REVISE);
    }
    this.refs.iScroll.addEventListener("scroll", () => {
      if ((this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight - 200) && this.props.renderMoreQuestions && !this.props.showLoader){
        this.loadMoreItems();
      }
    });
  }

  componentWillUnmount() {
    this.refs.iScroll.removeEventListener("scroll");
    window.removeEventListener('resize', this.resize);
  }

  loadMoreItems() {
    const { page, selectedSubject } = this.props;
    const { bookmarkSelectedTag } = this.state;
    this.props.getBookmarkQuestions(selectedSubject, bookmarkSelectedTag, page+1);
  }

  renderSubject(subject) {
    const isSelected = subject.name === this.props.selectedSubject;
    return (
      <Link id={`Bookmark-Subject-${subject.name}`} to={`/bookmark/${subject.name}`} key={`${subject.id}_BookmarkQuestions`} style={{ display: 'flex', alignItems: 'center' ,height: 50, borderBottom: isSelected && `3px solid ${StyleConstants.color.primary}` }}>
        <Text text={subject.name} color={isSelected ? StyleConstants.color.primary : StyleConstants.textColor.inactive} fontWeight={isSelected && StyleConstants.textWeight.bold} />
      </Link>
    )
  }

  toggleQuestionView(questionId, showSolution=false) {
    let { expandedQuestionList, questionSolutionList } = this.state
    const { selectedSubject } = this.props;
    const questionIndex = expandedQuestionList.indexOf(questionId);
    if (questionIndex > -1 && !showSolution) {
      expandedQuestionList.splice(questionIndex, 1);
    } else if (questionIndex === -1) {
      expandedQuestionList.push(questionId);
      sendEvent('Bookmark - QuestionView', { questionId, selectedSubject });
    }
    if (showSolution && !questionSolutionList.includes(questionId)) {
      questionSolutionList.push(questionId);
    }
    this.setState({ expandedQuestionList, questionSolutionList });
  }

  updateBookmarkQuestionList(questionId, isBookmarked) {
    this.forceUpdate();
  }

  renderQuestions(question, index) {
    const { questionSolutionList, expandedQuestionList } = this.state;
    const isCurrentQuestionBookmarked = this.props.bookmarkedQuestionIds.includes(question.id);
    const showSolution = questionSolutionList.includes(question.id);
    const showOptions = expandedQuestionList.includes(question.id);
    return (
      <div key={`BookmarkQuestion${question.id}`} style={{ marginBottom: 20 }}>
        <QuestionView
          isBookmarkView
          showSolutionIcon
          currentQuestion={question}
          currentIndex={index}
          showOptions={showOptions}
          showResult={showSolution}
          solutionRequested={showSolution}
          toggleQuestionView={this.toggleQuestionView}
          isCurrentQuestionBookmarked={isCurrentQuestionBookmarked}
          updateBookmarkQuestionList={this.updateBookmarkQuestionList}
        />
      </div>
    )
  }

  updateBookmarkTag(bookmarkTag) {
    this.setState({ bookmarkSelectedTag: bookmarkTag });
    const { selectedSubject } = this.props;
    this.props.getBookmarkQuestions(selectedSubject, bookmarkTag);
  }

  render() {
    const { subjects, questions, selectedSubject, showLoader } = this.props;
    const type = this.props.match.params.type ? this.props.match.params.type : '';

    const { bookmarkSelectedTag } = this.state;
    if (!selectedSubject || (type && selectedSubject !== type)) {
      this.props.getBookmarkQuestions(type, bookmarkSelectedTag);
    }
    const buttonStyle = { display: 'flex', height: 35, justifyContent: 'center', alignItems: 'center', width: '49%', cursor: 'pointer', borderRadius: 4 };
    return (
      <Layout hideFooter>
        <div>
          <SideBar pageType={SIDEBAR_TYPES.HOME} />
          <div className="MainPage" style={{ marginTop: 0, display: 'flex', flexDirection: 'column', padding: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', backgroundColor: '#F4F4F4' }}>
              <div style={{ width: 800, display: 'flex', justifyContent: 'space-evenly' }}>
                {subjects.map(subject => this.renderSubject(subject))}
              </div>
            </div>
          </div>
          <div className="MainPage" style={{ marginTop: 0, paddingBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: 20, width: 800 }}>
              <div style={{ ...buttonStyle, border: '1px solid #237F8E', color: bookmarkSelectedTag === BOOKMARK_TYPES.REVISE ? 'white' : '#237F8E', backgroundColor: bookmarkSelectedTag === BOOKMARK_TYPES.REVISE && '#237F8E' }} onClick={() => this.updateBookmarkTag(BOOKMARK_TYPES.REVISE)}> Revise </div>
              <div style={{ ...buttonStyle, border: '1px solid #FF5252', color: bookmarkSelectedTag === BOOKMARK_TYPES.DOUBTS ? 'white' : '#FF5252', backgroundColor: bookmarkSelectedTag === BOOKMARK_TYPES.DOUBTS && '#FF5252' }} onClick={() => this.updateBookmarkTag(BOOKMARK_TYPES.DOUBTS)}> Doubts </div>
            </div>
            {questions.length === 0 &&
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                <Text text="No questions added." fontSize={StyleConstants.textSize.headerSmall} color={StyleConstants.textColor.inactive} fontWeight={StyleConstants.textWeight.semiBold} />
              </div>
            }
            <div ref="iScroll" className="HorizontalScroll" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: global.innerHeight - 175, alignItems: 'center', paddingTop: 20 }} >
              <div style={{ width: 800 }}>
                {questions.map((question, index) => this.renderQuestions(question, index))}
              </div>
              {!this.props.renderMoreQuestions &&
                <div id='Bookmark-Scroll-to-top' className="Button pl-3 pr-3" onClick={() => this.refs.iScroll.scrollTop = 0} style={{ fontWeight: StyleConstants.textWeight.semiBold}}>
                  Scroll To Top
                  <img alt="NEET_JEE_Scroll_Up" height={12} width={12} src={upArrow} style={{ marginLeft: 5 }} />
                </div>
              }
            </div>
            {showLoader && <Loader height={50} /> }
          </div>
        </div>
      </Layout>
    )
  }
}

Bookmark.propTypes = {
  getBookmarkQuestions: PropTypes.func.isRequired,
  match: PropTypes.object,
  questions: PropTypes.array,
  subjects: PropTypes.array,
  bookmarkedQuestionIds: PropTypes.array,
  selectedSubject: PropTypes.string,
  page: PropTypes.number,
  renderMoreQuestions: PropTypes.bool,
  showLoader: PropTypes.bool
}

Bookmark.defaultProps = {
  page: 1,
  questions: [],
  subjects: [],
  bookmarkedQuestionIds: [],
  selectedSubject: '',
  renderMoreQuestions: false,
  showLoader: false
}

const mapStateToProps = ({ bookmark, home }) => ({
  subjects: home.subjects,
  page: bookmark.page,
  questions: bookmark.questions,
  bookmarkedQuestionIds: bookmark.bookmarkedQuestionIds,
  selectedSubject: bookmark.selectedSubject,
  renderMoreQuestions: bookmark.renderMoreQuestions,
  showLoader: bookmark.showLoader
});

export default connect(mapStateToProps, { getBookmarkQuestions })(Bookmark);
