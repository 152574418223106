import Cookies from 'universal-cookie';
import {
  UPDATE_HOME_DATA, UPDATE_CHECKPOINT_DATA, UPDATE_RECENT_CHAPTER_LIST, UPDATE_SUBJECT_LIST, UPDATE_PAGE_TYPE, UPDATE_NOTIFICATION_READ_STATUS, UPDATE_LANDING_PAGE_DATA, GET_NOTIFICATIONS, UPDATE_QUESTION_DATA, UPDATE_CHEAT_SHEET_LIST, UPDATE_CHEAT_SHEET_TAG, UPDATE_CHEAT_SHEET_PAGE_TYPE, UPDATE_OVERALL_PROGRESS_VIEW, UPDATE_VIDEO_SOLUTION_LIST, UPDATE_VIDEO_SOLUTION_QUESTION_INDEX, UPDATE_LIVE_VIDEO_DETAILS
} from '../actions';
import constants from '../shared/constants';

const cookies = new Cookies();
const Constants = constants();

const INITIAL_STATE = {
  homeData: {
    questionCount: 0,
    testCount: 0,
    responseCount: 0,
  },
  pageType: '',
  testList: [],
  unitChapterData: [],
  isMockTestLocked: false,
  subjects: [],
  chapters: [],
  recentChapterList: [],
  chapter: {},
  checkpoints: [],
  notifications: [],
  hasPremiumAccess: false,
  isTestTetakable: false,
  isInstituteAssignment: false,
  numberOfAnswers: {},
  numberOfQuestions: {},
  numberOfTests: {},
  quickpointList: [],
  allQuickpointList: [],
  knownQuickpointIds: [],
  unknownQuickpointIds: [],
  subjectList: [],
  overallData: {},
  previousWeekData: {},
  currentWeekData: {},
  graphData: {},
  videoDetails: {},
  trainLoader: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_QUESTION_DATA: {
      return {
        ...state,
        numberOfQuestions: action.numberOfQuestions,
        numberOfTests: action.numberOfTests,
        numberOfAnswers: action.numberOfAnswers,
      };
    }
    case UPDATE_LANDING_PAGE_DATA:
      return { ...state, homeData: action.payload.homeData };
    case UPDATE_PAGE_TYPE:
      return { ...state, pageType: action.pageType };
    case UPDATE_HOME_DATA:
      return { ...state, ...action.payload };
    case UPDATE_CHECKPOINT_DATA:
      return { ...state, ...action.payload };
    case UPDATE_RECENT_CHAPTER_LIST:
      const { recentChapterList } = state;
      const { chapterId } = action.payload;
      const chapterIndex = recentChapterList.indexOf(chapterId);
      if (chapterIndex > -1) {
        recentChapterList.splice(chapterIndex, 1);
      } else if (recentChapterList.length > 2) {
        recentChapterList.pop();
      }
      recentChapterList.unshift(chapterId);
      return { ...state, recentChapterList };
    case UPDATE_SUBJECT_LIST:
      return { ...state, subjects: action.payload.subjects, pageType: action.payload.pageType };
    case UPDATE_NOTIFICATION_READ_STATUS:
      const notifications = cookies.get('notifications');
      notifications.forEach((notification, index) => {
        if (notification.id === action.notificationId) {
          notifications[index].is_read = true;
        }
      });
      cookies.set('notifications', notifications, { path: '/', secure: Constants.cookie.secure });
      return { ...state, notifications };
    case GET_NOTIFICATIONS:
      return { ...state, notifications: action.payload.notifications };
    case UPDATE_CHEAT_SHEET_LIST:
      return { ...state, ...action.payload };
    case UPDATE_CHEAT_SHEET_TAG:
      const newKnownIds = [...state.knownQuickpointIds];
      const newUnknownIds = [...state.unknownQuickpointIds];
      const cheatsheetId = action.payload.id;
      if (action.payload.tag === 'KNOWN') {
        if (newUnknownIds.indexOf(cheatsheetId) > -1) {
          newUnknownIds.splice(newUnknownIds.indexOf(cheatsheetId), 1);
        }
        if (newKnownIds.indexOf(cheatsheetId) < 0) {
          newKnownIds.push(cheatsheetId);
        }
      } else if (action.payload.tag === 'UNKNOWN') {
        if (newKnownIds.indexOf(cheatsheetId) > -1) {
          newKnownIds.splice(newKnownIds.indexOf(cheatsheetId), 1);
        }
        if (newUnknownIds.indexOf(cheatsheetId) < 0) {
          newUnknownIds.push(cheatsheetId);
        }
      } else {
        newUnknownIds.splice(newUnknownIds.indexOf(cheatsheetId), 1);
        newKnownIds.splice(newKnownIds.indexOf(action.payload.id), 1);
      }
      return {
        ...state,
        knownQuickpointIds: newKnownIds,
        unknownQuickpointIds: newUnknownIds,
      };
    case UPDATE_CHEAT_SHEET_PAGE_TYPE:
      const pageType = action.payload.cheatSheetPageType;
      let quickpointList = [];
      if (action.payload.cheatSheetPageType === 'KNOWN' || action.payload.cheatSheetPageType === 'UNKNOWN') {
        const quickpointIds = action.payload.cheatSheetPageType === 'KNOWN' ? state.knownQuickpointIds : state.unknownQuickpointIds;
        quickpointList = state.allQuickpoints.filter((quickpoint) => quickpointIds.indexOf(quickpoint.id) > -1);
      } else {
        quickpointList = state.allQuickpoints;
      }
      return {
        ...state,
        quickpointList,
        cheatSheetPageType: pageType,
      };
    case UPDATE_OVERALL_PROGRESS_VIEW:
      return { ...state, ...action.payload };
    case UPDATE_VIDEO_SOLUTION_LIST:
      return { ...state, ...action.payload };
    case UPDATE_VIDEO_SOLUTION_QUESTION_INDEX:
      return {
        ...state,
        currentVideoQuestion: state.videoQuestionList[action.payload.videoQuestionIndex],
      };
    case UPDATE_LIVE_VIDEO_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
