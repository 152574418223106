import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import Text from './text';
import SideBar from './sidebar';
import Overview from './overview';
import QuestionView from './questionView';
import Countdown from './countDown';
import EnterTestLockedOtp from './enterTestLockedOtp';
import {
  getTestData, updateCurrentQuestionIndex, uploadResponses, updateQuestionMarkedList, updateQuestionTimeSpent, updateQuestionSelectedOptions, updateRichResponse, showToast, lockTestByOtp,
} from '../actions/index';
import nextIcon from '../shared/images/common/next.svg';
import backIcon from '../shared/images/common/back.svg';
import StyleConstants from '../shared/styleConstants/styles';
import { sendEvent } from '../helpers/Analytics';
import { TEST_MODE_TYPES, TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import Layout from './layout';
import StyleConstant from '../shared/styleConstants/styles.json';

const history = createBrowserHistory({
  forceRefresh: true,
});

class Test extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: '',
      selectedOption: '',
    };
    this.handleVisiblityChange = this.handleVisiblityChange.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { testInfo } = this.props;
    if (!testInfo.id || testInfo.id !== parseInt(id)) {
      this.props.getTestData(id);
    }
    if ((typeof window !== 'undefined')) {
      window.addEventListener('blur', this.handleVisiblityChange);
    }
  }

  componentWillUnmount() {
    if (!this.props.isTestReview) {
      this.props.uploadResponses('QuestionResponses');
    }
    if (this.props.canLockTest) {
      window.removeEventListener('blur', this.handleVisiblityChange);
    }
  }

  handleVisiblityChange() {
    const { id } = this.props.match.params;
    const { testInfo, canLockTest, isOtpRequired } = this.props;
    if (canLockTest && testInfo.is_institute_test && !isOtpRequired) {
      this.props.lockTestByOtp(id);
    }
  }

  shouldCaptureEvent() {
    const { currentIndex, selectedOption } = this.props;
    if (this.state.currentIndex !== currentIndex || this.state.selectedOption !== selectedOption) {
      this.setState({ currentIndex, selectedOption });
      return true;
    }
    return false;
  }

  renderView() {
    const {
      questionCount, currentIndex, currentQuestion, showResult, isCurrentQuestionBlocked,
      bookmarkedQuestionIds, isCurrentQuestionMarked, currentQuestionTimeSpent,
      sessionInfo, richResponses, comprehensionText, shouldUploadResponses, questionsVisited,
    } = this.props;
    if (questionCount === 0) {
      return (
        <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
          <Text text="No questions" fontWeight={StyleConstants.textWeight.semiBold} fontSize={StyleConstants.textSize.headerSmall} />
        </div>
      );
    }
    const { selectedOption } = this.props;
    return (
      <div>
        <QuestionView
          key={currentIndex}
          selectedOption={[...selectedOption]}
          currentQuestion={{ ...currentQuestion }}
          currentIndex={currentIndex}
          showMarkIcon
          showResult={showResult}
          isCurrentQuestionBlocked={isCurrentQuestionBlocked}
          sessionInfo={sessionInfo}
          richResponse={richResponses[currentQuestion.id] || ''}
          questionsVisited={[...questionsVisited]}
          comprehensionText={comprehensionText}
          shouldUploadResponses={shouldUploadResponses}
          isCurrentQuestionMarked={isCurrentQuestionMarked}
          currentQuestionTimeSpent={currentQuestionTimeSpent}
          isCurrentQuestionBookmarked={bookmarkedQuestionIds.includes(currentQuestion.id)}
          updateQuestionTimeSpent={this.props.updateQuestionTimeSpent}
          updateQuestionSelectedOptions={this.props.updateQuestionSelectedOptions}
          updateQuestionMarkedList={this.props.updateQuestionMarkedList}
          updateRichResponse={this.props.updateRichResponse}
        />
        <div className="d-flex" style={{ justifyContent: 'space-between', marginTop: 30 }}>
          <div
            role="presentation"
            id="Previous-Question-Button"
            className="Button pl-5 pr-5 m-1"
            style={{ width: '100%', color: StyleConstants.textColor.primary, opacity: currentIndex === 0 && 0.5 }}
            onClick={() => {
              if (currentIndex > 0) {
                this.props.updateCurrentQuestionIndex(currentIndex - 1);
              }
            }}
          >
            <img alt="NEET_JEE_Previous_icon" height={19} width={19} src={backIcon} style={{ marginRight: 5 }} />
            Prev
          </div>
          <div
            role="presentation"
            id="Next-Question-Button"
            className="Button pl-5 pr-5 m-1"
            style={{ width: '100%', color: StyleConstants.textColor.primary, opacity: questionCount === currentIndex + 1 && 0.5 }}
            onClick={() => {
              if (questionCount > currentIndex + 1) {
                this.props.updateCurrentQuestionIndex(currentIndex + 1);
              }
            }}
          >
            Next
            <img alt="NEET_JEE_Next_icon" height={19} width={19} src={nextIcon} style={{ marginLeft: 5 }} />
          </div>
        </div>
      </div>
    );
  }

  renderPageContent() {
    const { id } = this.props.match.params;
    const { testInfo, isOtpRequired, isTestReview } = this.props;
    if (isOtpRequired && testInfo.is_institute_test) {
      return (
        <EnterTestLockedOtp testId={id} />
      );
    }
    return (
      <>
        <SideBar showBackNavigation={isTestReview} navigateTo={isTestReview ? (testInfo.is_missed_test ? `/${TEST_MODE_TYPES[testInfo.test_mode_type]}` : `/test_report/${testInfo.id}`) : '/'} />
        <Overview />
        <div className="MainPage RightOverview" style={{ marginTop: 0 }}>
          {this.renderView()}
        </div>
      </>
    );
  }

  render() {
    const {
      redirectToReport, testInfo, currentIndex, isTestReview, isCurrentQuestionBlocked,
      currentQuestion, selectedOption, isCurrentQuestionMarked, currentQuestionTimeSpent,
      isCurrentQuestionBookmarked, testDuration, isOtpRequired,
      currentQuestionLimitedCount, currentSubjectName,
    } = this.props;
    if (testInfo && redirectToReport) {
      this.props.showToast('To revise, your institute needs to publish the question paper. Please contact your admin.', TOAST_MESSAGE_TYPES.ERROR);
      history.push(`/test_report/${testInfo.id}`);
    }
    if (this.shouldCaptureEvent()) {
      sendEvent('Questions - QuestionDisplayed', {
        ...testInfo, index: currentIndex, question: currentQuestion.id, selectedOption, currentQuestionTimeSpent, isTestReview, isCurrentQuestionMarked, isCurrentQuestionBookmarked, testDuration,
      });
    }

    return (
      <Layout hideFooter>
        {isCurrentQuestionBlocked && <div className="MainPage" style={{padding: '20px 370px 20px 30px', marginTop: 0, backgroundColor: '#FF5F5F', color: StyleConstant.color.white, fontWeight: StyleConstant.staticPages.fontWeight.header, fontSize: 18}}>
          This question is locked.<br/>
          You can answer a maximum of {currentQuestionLimitedCount} numerical questions in {currentSubjectName}.<br/>
          To answer this question, un-answer another numerical question in {currentSubjectName}.
          </div>}
        {!isTestReview && testInfo.id && (
          <div className={isOtpRequired && testInfo.is_institute_test ? '' : 'MainPage RightOverview'} style={{ marginTop: 20, paddingBottom: 0, marginBottom: 20 }}>
            <Countdown timeRemainingInSeconds={testDuration} isOtpPage={isOtpRequired && testInfo.is_institute_test} />
          </div>
        )}
        {this.renderPageContent()}
      </Layout>
    );
  }
}

Test.propTypes = {
  showToast: PropTypes.func.isRequired,
  getTestData: PropTypes.func.isRequired,
  uploadResponses: PropTypes.func.isRequired,
  updateRichResponse: PropTypes.func.isRequired,
  updateCurrentQuestionIndex: PropTypes.func.isRequired,
  updateQuestionMarkedList: PropTypes.func.isRequired,
  updateQuestionTimeSpent: PropTypes.func.isRequired,
  updateQuestionSelectedOptions: PropTypes.func.isRequired,
  match: PropTypes.object,
  testInfo: PropTypes.object,
  canLockTest: PropTypes.bool,
  isTestReview: PropTypes.bool,
  isOtpRequired: PropTypes.bool,
  currentIndex: PropTypes.number,
  testDuration: PropTypes.number,
  questionCount: PropTypes.number,
  selectedOption: PropTypes.number,
  redirectToReport: PropTypes.bool,
  currentQuestion: PropTypes.object,
  isCurrentQuestionMarked: PropTypes.bool,
  currentQuestionTimeSpent: PropTypes.object,
  isCurrentQuestionBookmarked: PropTypes.bool,
  bookmarkedQuestionIds: PropTypes.array,
  showResult: PropTypes.bool,
  isCurrentQuestionBlocked: PropTypes.bool,
  currentQuestionLimitedCount: PropTypes.number,
  currentSubjectName: PropTypes.string,
  sessionInfo: PropTypes.object,
  richResponses: PropTypes.object,
  questionsVisited: PropTypes.object,
  comprehensionText: PropTypes.object,
  shouldUploadResponses: PropTypes.bool,
};

Test.defaultProps = {
  testInfo: {},
  isOtpRequired: false,
  isTestReview: false,
  questionCount: 0,
  currentIndex: 0,
  testDuration: null,
  match: {},
  currentQuestion: {},
  selectedOption: [],
  sessionInfo: {},
  richResponses: {},
  questionsVisited: {},
  comprehensionText: {},
  shouldUploadResponses: false,
  redirectToReport: false,
  isCurrentQuestionMarked: false,
  currentQuestionTimeSpent: {},
  bookmarkedQuestionIds: [],
  currentSubjectName: '',
  currentQuestionLimitedCount: 0,
  isCurrentQuestionBookmarked: false,
  isCurrentQuestionBlocked: false,
  canLockTest: false,
  showResult: false,
};

const mapStateToProps = ({ test }) => ({
  testInfo: test.testInfo,
  isTestReview: test.isTestReview,
  sessionInfo: test.testInfo,
  richResponses: test.richResponses,
  questionsVisited: test.questionsVisited,
  comprehensionText: test.comprehensionText,
  shouldUploadResponses: test.shouldUploadResponses,
  showResult: test.isTestReview,
  questionCount: test.questionCount,
  currentIndex: test.currentIndex,
  testDuration: test.testDuration,
  redirectToReport: test.redirectToReport,
  currentQuestionLimitedCount: test.currentQuestionLimitedCount,
  currentSubjectName: test.currentSubjectName,
  isCurrentQuestionBlocked: test.isCurrentQuestionBlocked,
  currentQuestion: test.currentQuestion,
  bookmarkedQuestionIds: test.bookmarkedQuestionIds,
  selectedOption: test.selectedOption,
  isCurrentQuestionMarked: test.isCurrentQuestionMarked,
  currentQuestionTimeSpent: test.currentQuestionTimeSpent,
  isCurrentQuestionBookmarked: test.isCurrentQuestionBookmarked,
  isOtpRequired: test.isOtpRequired,
  canLockTest: test.canLockTest,
});

export default connect(mapStateToProps, {
  getTestData,
  updateCurrentQuestionIndex,
  uploadResponses,
  updateQuestionMarkedList,
  updateQuestionTimeSpent,
  updateQuestionSelectedOptions,
  updateRichResponse,
  showToast,
  lockTestByOtp,
})(Test);
