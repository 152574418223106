import { UPDATE_AUTH, USER_REGISTRY } from '../actions';

const INITIAL_STATE = {
  userTypes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH:
      return { ...state, ...action.payload };
    case USER_REGISTRY:
      return { ...state, pageType: 'register' };
    default:
      return state;
  }
};
