module.exports = {
  sentryConnectionUri: 'https://a7311f4dafba4889b3509c297fa1dcfe@sentry.io/1422786',
  amplitudeApiKey: 'f215ee9a465112f130081d47e590b36d',
  google_analytics_key: 'UA-108501037-6',
  googleClientId: '209262896750-60etdape7bo12a8ualqpedkcpqaed95i.apps.googleusercontent.com',
  razorPayPublicKey: 'rzp_test_3wZnpT7NZ582LD',
  publicKey: '-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA7dmIwFWEmOw6Lwu/RkUh+Adflsd+x0GVgIA6N9afzkuMwv39Qm/asqnOoYJJ1l/Anug5QV+1I2qjA8lgelAWfmgi7xJIkQMluKy65w1kSjfWlX9eNUICQj3FnfPZjV1v0k4KTAJeXzmxS9wqSb1/TE8Bco519uTd3Ty7YPrYgHgRAljJX6/XB9phl+Qq6IX9986Qmf4qx6J5AGy3MldDpvSwpT2iMRGfhSoJnYHoKw6hJhj7l3DanFNp1P/E6oRe3WYnlREXAEckOQYXAh2JqpulJgW2zY5LzvQ5Xeo/0lSL/4MpppalR3DBDTXcMurgBUHowykDgeNc7SkKfQxRApMhLb6DAGryc0yjpFcvvw7zYJkjZ3AtHRUShp13mhMN4wYyBoqPHvrfepIrdPbVPU2eAzLitpN+X6eoEiZKTJ4r3Z8NFWKvZ/72fsgBhbzfOEHDylt33PhDcWm99YEHPCTZ3aOzxIRyuXGbP/pJza7FRmj7yY94RgZsa+B8GGyPjM6DjswoBuhkCBW+7Jy1tsCcC7TjGp6ddrGwTeau5+Q6C417eq/L3LhC7kgrjnsE9O3tXaqgN9mb3oydRMCO49+Gq/x4dKz/7amcwZ4CATWg/DiWZIPcrWGnfhhDye97CHcNKMfSNIXgNeXgWoyzv3BJVTa7Ybh55ZAriOALAMsCAwEAAQ==-----END PUBLIC KEY-----',
  appId: 'com.ideaboard',
  defaultReferrer: 'referrer=utm_source%3DTrafficAds%26utm_medium%3DWebsiteRedirect%26utm_campaign%3DAppInstall',
  appDeepLink: 'ideaboard://ideaboard',
};
