module.exports = {
api: {
    url: `https://api.${process.env.REACT_APP_DOMAIN}`,
    ws_url: `wss://api.${process.env.REACT_APP_DOMAIN}/cable`,
    responseType: 'json',
    timeout: 40000,
    withCredentials: true,
},
cookie: {
    secure: true,
    subDomain: `www.${process.env.REACT_APP_DOMAIN}`,
    domainRegex: `.${process.env.REACT_APP_DOMAIN}`,
    accountsDomain: `accounts.${process.env.REACT_APP_DOMAIN}`,
    homeDomain: `www.${process.env.REACT_APP_DOMAIN}`,
    instituteDomain: `qbank.${process.env.REACT_APP_DOMAIN}`,
    parentPortalDomain: `parent.${process.env.REACT_APP_DOMAIN}`,
},
accountsUrl: `https://accounts.${process.env.REACT_APP_DOMAIN}`,
searchEndpoint: `https://search.${process.env.REACT_APP_DOMAIN}`,
"webAppVersionCode": "1.0.0",
"contact": "080-471-87111",
"email":"support@ideaboard.xyz",
"companyUrl": "www.ideaboard.xyz",
"products": {"ideaboard": "0", "community": "1", "mobile": "2", "dashboard": "3"},
"talentUrl": "https://forms.gle/xAuz1gpfqpRuXW377",
"chatBotScript": "//js.hs-scripts.com/6410710.js",
"videoUrl": "https://player.vimeo.com/video/365964066",
"youtubeChannelUrl": "https://www.youtube.com/channel/UCVzzTmIWLd5uKUlU7LuakSQ",
"toastMessages": {
    "networkError": "Network Error,please try again later!",
    "thankYouResponse": "Thank you for your interest, we will get back to you soon!"
},
"playStoreUrl": "https://play.google.com/store/apps/details?id=com.ideaboard&referrer",
"landingUrl": "/"
}
