import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import StyleConstants from '../shared/styleConstants/styles';
import Text from './text';
import Layout from './layout';
import SideBar from './sidebar';
import { TestSummaryHeader } from './common/index';
import {
  getTestReports, removeAllData,
} from '../actions/index';

const maxWidth = 500;

class Leaderboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubject: '',
      showFullLeaderboard: false,
      activePage: 1,
      itemsCountPerPage: 100,
    };
    this.fetchMoreData = this.fetchMoreData.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { data } = this.props;
    if (data && Object.keys(data).length === 0) {
      this.props.getTestReports(id);
    }
  }

  updatePageState() {
    this.props.history.goBack();
  }

  fetchMoreData() {
    const { itemsCountPerPage } = this.state;
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState({
        itemsCountPerPage: itemsCountPerPage + 100,
      });
    }, 500);
  }

  renderSubjectHeader() {
    const { data } = this.props;
    if (data.subject_ids) {
      const width = `calc(100%/${data.subject_ids.length})`;
      return (
        <div style={{
          position: 'fixed',
          top: 70,
          width: '80%',
          background: StyleConstants.color.testReportHeader,
          height: 44,
          zIndex: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', zIndex: 10, width: maxWidth, minWidth: maxWidth,
            }}
            >
              {this.renderSubject('overall', 'Overall_Total', '', width)}
              {data.subject_ids.map((subjectId, index) => this.renderSubject(data.subject_mapping[subjectId], `${data.subject_mapping[subjectId]}_Total`, data.subject_mapping[subjectId], width, (index + 1) !== data.subject_ids.length))}
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }

  renderDots(specificBottomList, newList) {
    if (specificBottomList && specificBottomList.length > 0 && specificBottomList[0].rank > 4) {
      return (
        <div>
          {newList.map(() => (
            <div
              className="Circle"
              style={{
                width: 5, height: 5, marginLeft: '25px', marginBottom: 5, backgroundColor: '#D1D1D1',
              }}
            />
          ))}
        </div>
      );
    }
    return <div />;
  }

  renderSubject(subjectName, key, stateValue, width, showBorder = true) {
    const { selectedSubject } = this.state;
    const isSelected = stateValue === selectedSubject;
    return (
      <div
        key={key}
        role="presentation"
        style={{
          display: 'flex', alignItems: 'center', flexDirection: 'column', width, justifyContent: 'space-between', cursor: 'pointer', height: '100%', paddingTop: 10,
        }}
        onClick={() => this.setState({ showFullLeaderboard: false },
          () => this.setState({ selectedSubject: stateValue }))}
      >
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', cursor: 'pointer', borderRight: showBorder && '1px solid #CBCBCB',
        }}
        >
          <Text
            text={subjectName.replace(/\b(\w)/g, (s) => s.toUpperCase())}
            style={{
              fontSize: isSelected ? StyleConstants.textSize.headerSmall : StyleConstants.textSize.text, display: 'flex', justifyContent: 'center', color: isSelected ? StyleConstants.color.selected : StyleConstants.color.inactive,
            }}
          />
        </div>
        <div style={{
          marginTop: 5, borderTop: isSelected && `3px solid ${StyleConstants.color.selected}`, width: '50%', borderRadius: 4, borderTopLeftRadius: 15, borderTopRightRadius: 15,
        }}
        />
      </div>
    );
  }


  renderStudentDetail(details, show) {
    if (!show) {
      return (
        <div
          className="Simple-Card-View"
          style={{
            padding: 0, marginTop: 5, height: 45, display: 'flex', backgroundColor: 'white', alignItems: 'center', justifyContent: 'space-between', width: 485, maxWidth: 'inherit', boxShadow: '0px 0px 12px  rgba(65, 61, 74, 0.1)',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              className="Circle"
              style={{
                margin: '8px 0px 8px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: StyleConstants.color.dark, fontSize: StyleConstants.textSize.text, backgroundColor: '#F0F0F0',
              }}
            >
              <Text text={details.rank / 10 >= 1 ? details.rank : `0${details.rank}`} style={{ color: '#4D4D4D' }} />

            </div>
            <Text
              text={(
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  { details.name && details.name.length > 15 ? `${details.name.substring(0, 15)}...` : details.name}
                </span>
                )}
              style={{
                marginLeft: 15,
                color: StyleConstants.color.dark,
                fontSize: StyleConstants.textSize.text,
              }}
            />
          </div>
          <Text text={`Score : ${details.total}`} style={{ color: StyleConstants.color.mediumLight, marginRight: 15, fontSize: StyleConstants.textSize.text }} />
        </div>
      );
    }
    return (
      <div
        className="Simple-Card-View"
        style={{
          padding: 0, marginTop: 5, height: 45, display: 'flex', backgroundColor: StyleConstants.color.testReportHeader, borderColor: StyleConstants.color.testReportHeader, alignItems: 'center', justifyContent: 'space-between', width: 485, maxWidth: 'inherit',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            className="Circle"
            style={{
              margin: '8px 0px 8px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: StyleConstants.color.dark, fontSize: StyleConstants.textSize.text, backgroundColor: 'white',
            }}
          >
            <Text text={details.rank / 10 >= 1 ? details.rank : `0${details.rank}`} style={{ color: StyleConstants.color.testReportHeader }} />

          </div>
          <Text
            text={(
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {details.name && details.name.length > 15 ? `${details.name.substring(0, 15)}...` : details.name}
              </span>
              )}
            style={{ marginLeft: 15, color: 'white', fontSize: StyleConstants.textSize.text }}
          />
        </div>
        <Text text={`Score : ${details.total}`} style={{ color: '#EDEDED', marginRight: 15, fontSize: StyleConstants.textSize.text }} />
      </div>
    );
  }

  renderLeaderboard() {
    const { selectedSubject } = this.state;
    const { data } = this.props;
    if ((typeof data !== 'undefined')) {
      const specificBottomList = selectedSubject !== '' && data[selectedSubject] ? data[selectedSubject].specific_bottom_list : (data.overall ? data.overall.specific_bottom_list : []);
      const bottomList = selectedSubject !== '' && data[selectedSubject] ? data[selectedSubject].bottom_list : (data.overall ? data.overall.bottom_list : []);
      return (
        <div
          className="MainPage"
          style={{
            display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 0,
          }}
        >
          <div className="Header-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {
                  specificBottomList && specificBottomList.length > 0
                  && specificBottomList.map((details) => (
                    details.name === data.student.first_name ? (
                      this.renderStudentDetail(details, true)
                    ) : (
                      this.renderStudentDetail(details, false)
                    )))
                  }
          </div>
          {bottomList.length > specificBottomList.length
          && (
          <div
            style={{
              display: 'flex', width: '100%', justifyContent: 'center', marginTop: 10,
            }}
            role="presentation"
            onClick={() => this.setState({ showFullLeaderboard: true })}
          >
            <Text
              text="View All"
              style={{
                color: StyleConstants.color.testReportHeader, cursor: 'pointer', fontFamily: StyleConstants.textFont.text, fontSize: StyleConstants.textSize.text, fontWeight: StyleConstants.textWeight.bold,
              }}
            />
          </div>
          )}
        </div>

      );
    }
    return <div />;
  }

  renderFullLeaderboardView() {
    const { selectedSubject, itemsCountPerPage, activePage } = this.state;
    const { data } = this.props;
    const minCount = activePage >= 2 ? (activePage - 1) * itemsCountPerPage : 0;
    const maxCount = (activePage) * itemsCountPerPage;
    if (typeof data !== 'undefined') {
      const bottomList = selectedSubject !== '' && data[selectedSubject] ? data[selectedSubject].bottom_list : (data.overall ? data.overall.bottom_list : []);
      return (
        <div
          className="MainPage"
          style={{
            display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 0,
          }}
        >
          <div className="Header-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <InfiniteScroll
              dataLength={itemsCountPerPage}
              loadMore={this.fetchMoreData}
              hasMore
            >
              {
                  bottomList && bottomList.length > 0
                  && bottomList.slice(minCount, maxCount).map((details) => {
                    return (
                      details.student_id === data.student.id ? (
                        this.renderStudentDetail(details, true)
                      ) : (
                        this.renderStudentDetail(details, false)
                      ));
                  })
                  }
            </InfiniteScroll>
          </div>
        </div>

      );
    }
    return <div />;
  }

  hideLeaderBoard() {
    return (
      <div className="MainPage">
        <div style={{
          paddingBottom: 25,
          marginLeft: 10,
          fontWeight: StyleConstants.staticPages.fontWeight.header,
          textAlign: 'center',
        }}
        >
          <Text text="LeaderBoard will be available shortly..!" fontSize={StyleConstants.textSize.headerBig} />
        </div>
      </div>
    );
  }

  renderLeaderBoard() {
    const { showFullLeaderboard, selectedSubject } = this.state;
    const { id } = this.props.match.params;
    const { data } = this.props;
    const newList = [1, 2, 3];
    return (
      <div>
        <div style={{ marginLeft: 300 }}>
          {this.renderSubjectHeader()}
          <TestSummaryHeader
            selectedTestId={id}
            selectedSubject={selectedSubject}
            data={data}
            leaderBoard
            renderStudentDetail={this.renderStudentDetail}
          />
        </div>
        {showFullLeaderboard ? this.renderFullLeaderboardView()
          : this.renderLeaderboard(newList)}
      </div>
    );
  }

  render() {
    const { data, hideLeaderBoard } = this.props;
    const { id } = this.props.match.params;
    if (data && data.overall) {
      return (
        <Layout hideFooter>
          <div>
            <SideBar showBackNavigation navigateTo={`/test_report/${id}`} width="20%" />
            { hideLeaderBoard
              ? this.hideLeaderBoard()
              : this.renderLeaderBoard()}
          </div>
        </Layout>
      );
    }
    return (<div />);
  }
}

Leaderboard.propTypes = {
  data: PropTypes.object,
  hideLeaderBoard: PropTypes.bool,
  isDataNotPresent: PropTypes.bool,
};

Leaderboard.defaultProps = {
  data: {},
  hideLeaderBoard: true,
  isDataNotPresent: true,
};

const mapStateToProps = ({ report }) => ({
  data: report.data,
  hideLeaderBoard: report.hideLeaderBoard,
  isDataNotPresent: report.isDataNotPresent,
});

export default connect(mapStateToProps, { getTestReports, removeAllData })(Leaderboard);
