import {
  UPDATE_LOADER_STATE, SERVER_ERROR, SHOW_TOAST, HIDE_TOAST, UPDATE_TIMER_EXPIREY, UPDATE_SUBSCRIPTION_DETAILS, VERIFY_COUPON_CODE, REMOVE_COUPON_CODE, UPDATE_COUPON_SPINNER_STATE, UPDATE_REFERRAL_SPINNER_STATE, REMOVE_REFERRAL, VERIFY_REFERRAL_CODE, REMOVE_PAYMENT_HIGHLIGHT, GET_INSTITUTE_FILES, SERVER_UNDER_MAINTENANCE, GET_OFFLINE_TEST_SUBJECTS, GET_OFFLINE_TEST, SET_STATUS_OFFLINE_TEST, FILE_UPLOAD_PERCENTAGE,
} from '../actions';
import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';

const INITIAL_STATE = {
  isServerError: false,
  isServerUnderMaintenance: false,
  loaderState: false,
  toastMessage: '',
  subjects: [],
  virtualFileData: [],
  showToast: false,
  hasTimerExpired: false,
  toastMessageType: TOAST_MESSAGE_TYPES.SUCCESS,
  payment: {},
  couponDiscount: 0,
  highlightCouponCard: false,
  highlightReferralCard: false,
  showCouponSpinner: false,
  showReferralSpinner: false,
  isValidCoupon: false,
  isValidReferral: false,
  filesData: [],
  trainLoader: true,
  offlineTestListSubjectList: [],
  offlineTestLists: [],
  offlineTestSubject: '',
  fileUploadedPercentage: 0,
  updatedCount: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LOADER_STATE:
      return { ...state, ...action.payload };
    case SERVER_UNDER_MAINTENANCE:
      return { ...state, isServerUnderMaintenance: true };
    case SERVER_ERROR:
      return { ...state, isServerError: true };
    case UPDATE_SUBSCRIPTION_DETAILS:
      return { ...state, payment: action.payload };
    case SHOW_TOAST:
      return {
        ...state, showToast: true, toastMessage: action.message, toastMessageType: action.messageType,
      };
    case HIDE_TOAST:
      return {
        ...state, showToast: false, toastMessage: '', toastMessageType: TOAST_MESSAGE_TYPES.SUCCESS,
      };
    case UPDATE_TIMER_EXPIREY:
      return { ...state, ...action.payload };
    case VERIFY_COUPON_CODE:
      return { ...state, ...action.payload };
    case REMOVE_COUPON_CODE:
      return { ...state, ...action.payload };
    case UPDATE_COUPON_SPINNER_STATE:
      return { ...state, showCouponSpinner: action.showCouponSpinner };
    case VERIFY_REFERRAL_CODE:
      return { ...state, ...action.payload };
    case REMOVE_REFERRAL:
      return { ...state, ...action.payload };
    case UPDATE_REFERRAL_SPINNER_STATE:
      return { ...state, showReferralSpinner: action.showReferralSpinner };
    case REMOVE_PAYMENT_HIGHLIGHT:
      return { ...state, [action.payload.type]: false };
    case GET_INSTITUTE_FILES:
      return { ...state, ...action.payload };
    case GET_OFFLINE_TEST_SUBJECTS:
      return { ...state, ...action.payload };
    case GET_OFFLINE_TEST:
      return { ...state, ...action.payload };
    case SET_STATUS_OFFLINE_TEST:
      return { ...state, ...action.payload };
    case FILE_UPLOAD_PERCENTAGE: {
      return { ...state, fileUploadedPercentage: action.payload };
    }
    default:
      return state;
  }
};
