import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Text from '../text';
import { sendEvent } from '../../helpers/Analytics';
import noTest from '../../shared/images/common/noData.svg';
import StyleConstants from '../../shared/styleConstants/styles';
import { OBJECT_TYPES, SESSION_TYPES } from '../../shared/constants/fieldTypes';
import Layout from '../layout';

const testConfig = {
  0: { url: 'test', text: 'Continue' },
  1: { url: 'test_instruction', text: 'Retake' },
};

class TestPage extends PureComponent {
  renderTestList(test) {
    const { isMockTestLocked, isInstituteAssignment } = this.props;
    const testStatus = test.status === 0 ? 0 : 1;
    return (
      <div className="cardView boxElement d-flex flex-column" styles={{ height: 60 }} key={test.id}>
        <Text
          color={StyleConstants.textColor.primary}
          fontWeight={StyleConstants.textWeight.semiBold}
          text={test.name}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text text={test.date_of_test} />
          <Text text={test.start_time} />
        </div>

        {test.is_test_missed && (
          <Text text="Missed" color={StyleConstants.color.error} fontWeight={StyleConstants.textWeight.semiBold} />
        )}
        <Link
          to={test.is_test_missed ? `/test/${test.id}` : (test.status !== 0 && isMockTestLocked ? '/payment' : isInstituteAssignment && test.status === 0 ? `/assignment/${test.id}` : `/${testConfig[testStatus].url}/${test.id}`)}
          id={`Test-${test.id}`}
          onClick={() => sendEvent(`${test.is_test_missed ? 'Tests - MissedTestViewQuestionsTapped' : (test.status === 0 ? 'Tests - ContinueTapped' : 'Tests - BeginFromTestListTapped')}`, { ...test, testType: this.props.pageType, isMockTestLocked })}
        >
          <div className="Button Primary-Button" style={{ width: '100%', marginTop: 20 }}>
            {test.is_test_missed ? 'View Questions' : (test.status === 0 ? 'Continue' : (isMockTestLocked ? 'Subscribe' : 'Begin'))}
          </div>
        </Link>
      </div>
    );
  }

  renderCompletedTestList(test) {
    const testStatus = test.status && test.status === 0 ? 0 : 1;
    const { isTestTetakable, isInstituteAssignment } = this.props;
    return (
      <div className="cardView selectedCardView boxElement d-flex flex-column" key={test.id} style={{ height: 150 }}>
        <Text
          color={StyleConstants.textColor.primary}
          fontWeight={StyleConstants.textWeight.semiBold}
          text={test.name}
        />
        <div style={{ display: 'flex' }}>
          <Text
            text={test.date_of_test}
          />
          <div style={{ marginLeft: 30 }} />
          {!isInstituteAssignment && test.score
            && (
            <Text
              color={StyleConstants.textColor.primary}
              fontWeight={StyleConstants.textWeight.bold}
              text={`Score: ${test.score}`}
            />
            )}
        </div>
        <div className="flexcontainer" style={{ justifyContent: 'space-between' }}>
          <Link
            id="Test-Report"
            to={isInstituteAssignment ? `/session_report/${test.id}?object_type=${OBJECT_TYPES.ASSIGNMENT}&session_type=${SESSION_TYPES.ADAPTIVE}` : `/test_report/${test.id}`}
            onClick={() => sendEvent('Tests - ViewReportTapped', { ...test, testType: this.props.pageType })}
          >
            <div
              className="Button Secondary-Button"
              style={{ height: StyleConstants.fieldHeight.normal, width: isTestTetakable ? 125 : 260 }}
            >
              Report
            </div>
          </Link>
          {isTestTetakable
            && (
            <Link
              key={test.id}
              id={`Test-${testConfig[testStatus].text}`}
              to={`/${testConfig[testStatus].url}/${test.id}`}
              onClick={() => sendEvent('Tests - RetakeTapped', { ...test, testType: this.props.pageType })}
            >
              <div
                className="Button Primary-Button"
                style={{ height: StyleConstants.fieldHeight.normal, width: 125 }}
              >
                {testConfig[testStatus].text}
              </div>
            </Link>
            )}
        </div>
      </div>
    );
  }

  render() {
    const {
      testList, completedTestList, isIdeaboardStudent, pageType, isInstituteAssignment,
    } = this.props;
    if (isIdeaboardStudent && pageType === 'institute') {
      return (
        <Layout hideFooter>
          <div className="MainPage">
            <Text
              color={StyleConstants.textColor.primary}
              fontSize={StyleConstants.textSize.header}
              fontWeight={StyleConstants.textWeight.semiBold}
              text="Is your institute conducting online tests with IdeaBoard?"
            />
            <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
              <Text
                fontSize={StyleConstants.textSize.headerSmall}
                fontWeight={StyleConstants.textWeight.semiBold}
                text="1. Make sure you log in using the ID and OTP provided by your institute."
              />
              <Text
                fontSize={StyleConstants.textSize.headerSmall}
                fontWeight={StyleConstants.textWeight.semiBold}
                text="2. Do not log in with your personal phone number."
              />
            </div>
          </div>
        </Layout>
      );
    }
    if (testList.length === 0 && completedTestList.length === 0) {
      return (
        <Layout hideFooter>
          <div className="MainPage" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: global.innerHeight - 120, alignItems: 'center' }}>
            <img alt="empty_test_icon" height={200} width={200} src={noTest} style={{ marginRight: 5 }} />
            <Text
              color={StyleConstants.textColor.primary}
              fontSize={StyleConstants.textSize.header}
              fontWeight={StyleConstants.textWeight.semiBold}
              text={`No ${isInstituteAssignment ? 'Assignment' : 'Test'} available!`}
            />
          </div>
        </Layout>
      );
    }
    return (
      <Layout hideFooter>
        <div className="MainPage">
          {testList.length > 0 && (
            <div className="flexcontainer">
              {testList.map((test) => this.renderTestList(test))}
            </div>
          )}
          {testList.length > 0 && completedTestList.length > 0
            && <div style={{ marginBottom: 15, borderTop: '1px solid #e6e6e6' }} />}
          {completedTestList.length > 0
            && (
            <div>
              <Text
                color={StyleConstants.textColor.primary}
                fontWeight={StyleConstants.textWeight.bold}
                fontSize={StyleConstants.textSize.headerSmall}
                text="Completed"
              />
              <div className="flexcontainer" style={{ marginTop: 20 }}>
                {completedTestList.map((test) => this.renderCompletedTestList(test))}
              </div>
            </div>
            )}
        </div>
      </Layout>
    );
  }
}

TestPage.propTypes = {
  isIdeaboardStudent: PropTypes.bool,
  isMockTestLocked: PropTypes.bool,
  isTestTetakable: PropTypes.bool,
  isInstituteAssignment: PropTypes.bool,
  pageType: PropTypes.string,
  testList: PropTypes.array,
  completedTestList: PropTypes.array,
};

TestPage.defaultProps = {
  pageType: '',
  isTestTetakable: false,
  isMockTestLocked: false,
  isIdeaboardStudent: false,
  testList: [],
  completedTestList: [],
  isInstituteAssignment: false,
};

const mapStateToProps = ({ home }) => ({
  pageType: home.pageType,
  testList: home.testList,
  isMockTestLocked: home.isMockTestLocked,
  completedTestList: home.completedTestList,
  isTestTetakable: home.isTestTetakable,
  isInstituteAssignment: home.isInstituteAssignment,
});

export default connect(mapStateToProps)(TestPage);
