export const BOOKMARK_TYPES = {
  REVISE: 1,
  DOUBTS: 2,
};

export const SIDEBAR_TYPES = {
  HOME: 'home',
  TRAIN: 'train',
  TEST: 'test',
  IMPROVEMENTS: 'improvements',
  BOOKMARK: 'bookmark',
  CHAPTER_VIDEO_LIST: 'chapter_video_list',
  VIRTUAL_MEETINGS: 'virtual_meeting',
};

export const TOAST_MESSAGE_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const SUBJECT_MAPPING = {
  1: 'Maths',
  2: 'Physics',
  3: 'Chemistry',
  4: 'Biology',
  5: 'Biology',
};

export const OBJECT_TYPES = {
  CHECKPOINT: 0,
  TEST: 1,
  SKILL: 2,
  ROADMAP: 3,
  PERSONALISED_TEST: 4,
  CHAPTER: 5,
  ASSIGNMENT: 6,
};

export const SESSION_TYPES = {
  ADAPTIVE: 0,
  REVISE: 1,
  PREVIOUS_PAPER: 2,
  ROADMAP_TYPE: 3,
  ROADMAP_ASSESSMENT: 4,
};

export const SIDEBAR_TRAIN_SUB_TYPES = {
  PHYSICS: 'Physics',
  CHEMISTRY: 'Chemistry',
  BIOLOGY: 'Biology',
  MATHS: 'Maths',
};

export const SIDEBAR_TEST_SUB_TYPES = {
  MOCK: 'Mock Tests',
  PREVIOUS_PAPER: 'Previous Paper',
  INSTITUTE: 'Institute',
};

export const TEST_MODE_TYPES = {
  0: 'institute',
  1: 'mock',
  2: 'previous_paper',
  3: 'test_report',
};

export const TEST_QUESTION_OVERVIEW_TYPES = {
  CORRECT: 'Correct',
  INCORRECT: 'Incorrect',
  MARKED: 'Marked',
  UNATTEMPTED: 'Unattempted',
  UNVISITED: 'Unvisited',
  ATTEMPTED: 'Attempted',
  ANSWER_REQUESTED: 'Key_Used',
};

export const QUESTION_TYPES = {
  FOUR_OPTIONS: 0,
  NUMERICAL: 1,
  MULTIPLE_OPTION: 2,
  COMPREHENSION: 3,
};