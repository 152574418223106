import React from 'react';
import '../../../shared/styleConstants/staticPages/home.css';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import ReactPlayer from 'react-player';
import {
  questions, goalbaseds, insights, teachers, institutes, ideaboardHighlights
} from './home.json';
import StyleConstants from '../../../shared/styleConstants/styles.json'
import { landingPageData } from '../../../actions/home';
import { numberWithCommas, isMobileDevice, getAccountsDomain } from '../../../helpers/Utils';
import Constants from '../../../shared/constants';
import Layout from '../../layout';
import { sendEvent } from '../../../helpers/Analytics';
import Text from '../../text';

const IS_MOBILE = isMobileDevice();
const carouselLength = Object.keys(teachers).length;
const carouselWidth = (IS_MOBILE) ? window.innerWidth : window.innerWidth / 2;
const customPadding = (IS_MOBILE) ? 0 : '0px 0px 0px 100px';
const customalign = (IS_MOBILE) ? 'center' : 'left';
const MAX_WEB_CONTENT_WIDTH = 1000;

class LandingHome extends React.Component {
  constructor() {
    super();
    this.scrollRef = React.createRef();
    this.state = {
      offset: 0,
      currentCarouselPosition: 0,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.landingPageData(this.props);
    sendEvent('InstitiuteLandingPage - Viewed');
  }

  getInviteModal(align, padding, info, style = {}) {
    return (
      <div style={{ display: 'flex', justifyContent: align, padding }}>
        <div
          onClick={() => {
            window.location.replace(`${getAccountsDomain()}/sign_up?type=INSTITUTE&info=${info}`);
            sendEvent(`InstituteLandingPage - TryNowFrom${info}Clicked`);
          }}
          className="home-button home-primary-button"
          style={{ width: IS_MOBILE ? 100 : 120, ...style }}
          role="presentation"
        >
         Join for Free
        </div>
      </div>
    );
  }

  async handleScroll(e) {
    const { offset, currentCarouselPosition } = this.state;
    const element = e.target;
    const nextElementPosition = (offset + 1) * (carouselWidth - 3);
    const PreviousElementPosition = currentCarouselPosition - (carouselWidth - 10);
    clearInterval(this.carouselAutoScroll);
    if (element.scrollLeft > (nextElementPosition)) {
      await this.setState({ offset: (offset + 1) % carouselLength, currentCarouselPosition: element.scrollLeft });
    }
    if (element.scrollLeft < (PreviousElementPosition)) {
      await this.setState({ offset: ((offset % carouselLength) === 0) ? carouselLength - 1 : ((offset - 1) % carouselLength), currentCarouselPosition: element.scrollLeft });
    }
  }

  displayTeacherComment(teacher, index) {
    const leftMargin = IS_MOBILE ? ((window.innerWidth < 700) ? '18%' : '28%') : '25%';
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', backgroundColor: StyleConstants.color.white, padding: '2% 2%', width: IS_MOBILE ? '90%' : '45%', maxWidth: IS_MOBILE && '700px', margin: IS_MOBILE ? ((index === 0) ? '50px auto 10px auto' : '10px auto 50px auto') : ('50px auto 50px auto'), boxShadow: '0px 3px 10px #4E4E4E1A'
      }}
      >
        <div style={{ display: 'flex', marginTop: 30, height: 100, width: '100%' }}>
          <div style={{ display: 'flex', width: '20%', paddingTop: 18, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', fontFamily: 'Nunito Sans', height: 82, fontSize: 150, color: StyleConstants.landingPage.quotesColor }}>{'“'}</div>
          </div>
          <div style={{ display: 'flex', width: '80%' }}>
            <img style={{ height: 100, marginLeft: leftMargin }} src={teacher.url} alt="NEET_JEE_teacher" />
          </div>
        </div>
        <div style={{ marginTop: 15, color: StyleConstants.landingPage.contentColor }}>{teacher.content}</div>
        <div style={{ margin: '20px 0 5px 0', fontFamily: 'Nunito Sans', fontWeight: 800, color: StyleConstants.landingPage.headerColor }}>{teacher.name}</div>
        <div style={{color: StyleConstants.landingPage.designationColor }}>{teacher.designation}</div>
        <div style={{ marginBottom: 15, color: StyleConstants.landingPage.designationColor }}>{teacher.institute}</div>
      </div>
    );
  }

  displayInstitutions() {
    const isMobileAndNotTab = (IS_MOBILE && window.innerWidth < 700)
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: StyleConstants.color.white, padding: '5%', marginTop: 0 }}>
        <div style={{ display: 'flex', fontFamily: 'Nunito Sans', justifyContent: IS_MOBILE && 'center', flexWrap: 'wrap', fontWeight: 800 }}>
          <div style={{ marginRight: 5, zIndex: 1, fontSize: window.innerWidth < 400 ? '200%' : '275%', width: '100%' }}>Some of Our Clients</div>
          <div style={{ display: 'flex', zIndex: 0, flexDirection: 'column', width: '60%', margin: window.innerWidth < 400 ? '-17px 0 0 42%' : '-23px 0 0 42%' }}>
            <div className="half-background" style={{ backgroundColor: StyleConstants.landingPage.highlightColor }} />
          </div>
        </div>
        <div style={{ display: 'flex', fontSize: 20, justifyContent: IS_MOBILE && 'center', textAlign: IS_MOBILE && 'center', color: StyleConstants.landingPage.secondaryHeaderColor }}>
          Join a growing family of smart institutions
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: !isMobileAndNotTab ? '95%' : '' }}>
          {
            institutes.map((institute) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: isMobileAndNotTab ? '5%' : '40px 0% 0% 0%', textAlign: 'center', width: isMobileAndNotTab ? '40%' : '25%' }}>
                  <img className="logo" style={{ cursor: 'default', width: '100%', height: 'auto' }} src={institute.url} alt="NEET_JEE_institute_Logos" />
                  <div className="home-text" style={{ color: StyleConstants.landingPage.headerColor, marginTop: isMobileAndNotTab ? 5 : 20, fontSize: isMobileAndNotTab ? 12 : 18, fontWeight: 800 }}>{institute.name}</div>
                  <div style={{ color: StyleConstants.landingPage.secondaryHeaderColor, fontWeight: 600, fontSize: isMobileAndNotTab ? 8 : 14 }}>{institute.location}</div>
                </div>
              );
            })
          }
        </div>
        <div style={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: 40, color: StyleConstants.landingPage.footerColor, paddingRight: '10%' }}>and many more…</div>
      </div>
    );
  }

  renderData(count) {
    return (
      <span style={{ textAlign: 'center' }}>
        <span>
          {count.newValue && <CountUp start={count.oldValue} end={count.newValue} duration={count.oldValue > 0 ? 10 : 5} formattingFn={numberWithCommas} />}
        </span>
      </span>
    );
  }

  renderUserMessage() {
    return (
      <div style={{ backgroundColor: StyleConstants.landingPage.backgroundColor }}>
        <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: IS_MOBILE ? 'column' : 'row', width: '90%', margin: 'auto' }}>
          {
          teachers.map(
            (teacher, index) => { return this.displayTeacherComment(teacher, index) }
          )
        }
        </div>
      </div>
    );
  }

  renderHighlight(highlight) {
    return (
      <div key={highlight.img} style={{ margin: IS_MOBILE ? 25 : '40px 20px', display: 'flex', width: IS_MOBILE ? '100%' : 445 }}>
        <div style={{ paddingRight: 15 }}>
          <img src={highlight.url} alt="goalbased" width="70px" />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
           <div className="content-primary"  style={{ marginBottom: '5px', fontWeight: 'bold' }}>
              {highlight.title}
            </div>
           <div className="content-secondary" style={{ lineHeight: 1.5 }}>
            {highlight.content}
            </div>
        </div>
      </div>
    );
  }

  renderFeatures() {
    const screenWidth = window.innerWidth;
    const isSmallScreenAndNotMobile = (screenWidth < MAX_WEB_CONTENT_WIDTH) && !IS_MOBILE;
    const containerWidth = IS_MOBILE ? screenWidth : MAX_WEB_CONTENT_WIDTH;
    return (
      <div 
        style={{ 
          width: containerWidth,
          margin: IS_MOBILE ? '20px 0px' : '30px 0px',
          display: 'flex',
          flexDirection: isSmallScreenAndNotMobile ? 'column' : 'row',
          flexWrap: 'wrap',
          justifyContent: IS_MOBILE ? 'center' : 'space-between',
          alignItems: isSmallScreenAndNotMobile ? 'center' : ''
        }}
      >
        {ideaboardHighlights.map((highlight) => this.renderHighlight(highlight))}
      </div>
    );
  }

  render() {
    const { numberOfQuestions, numberOfTests, numberOfAnswers } = this.props;
    return (
      <Layout showTeacherLink={false}>
        <div className="home-text">
          <div className="carousel" onScroll={this.handleScroll}>
            <div className="combine-views-main " style={{ backgroundColor: '#FFFFFF', display: 'flex', height: 'auto', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h1 className="main-heading" style={{ marginTop: 50 }}>Run your institute online.</h1>
                <h1 className="main-heading" style={{ margin: '0px 0px 10px 0px' }}> Easy. Smart. Efficient. </h1>
              </div>
              {this.renderFeatures()}
            </div>
            <Element id="QP-Generator">
              <div id="mobile" className="combine-views-main " style={{ backgroundColor: '#F8F8F8', height: 'auto' }}>
                <div>
                  <h1 className="main-heading">
                    Create NEET and JEE tests
                  </h1>
                  <h1 className="sub-heading">
                    in 10 Minutes!
                  </h1>
                </div>
                <div className="combine-views-sub">
                  <div className="content-display">
                    <div className="content-display-left">
                      {questions.map((question) => (
                        <div className="feature-combine-tags">
                          <div>
                            <img className="feature-image" src={question.url} width="60px" alt="Online_test_analysis_NEET|JEE" />
                          </div>
                          <div className="feature-description">
                            <div className="content-primary" style={{ marginBottom: '5px', fontWeight: 700 }}>
                              {question.contentPrimary}
                            </div>
                            <div className="content-secondary">
                              {question.contentSecondary}
                            </div>
                          </div>
                        </div>
                      ))}
                      {this.getInviteModal(customalign, customPadding, 'QPGenerator')}
                    </div>
                    <div className="content-display-right" style={{ padding: '5%' }}>
                      <ReactPlayer
                        height="100%"
                        width="100%"
                        url={Constants().videoUrl}
                        controls
                        onStart={() => { sendEvent('InstituteLandingPage - DemoVideoPalyed'); }}
                        onEnded={() => { sendEvent('InstituteLandingPage - DemoVideoWatchedFully'); }}
                        onPause={(response) => { sendEvent('InstituteLandingPage - DemoVideoPaused', { seconds: Math.round(response.seconds) }); }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Element>
            <Element id="Mobile-app">
              <div id="Mobile-app" className="combine-views-main " style={{ backgroundColor: '#FFFFFF', height: 'auto' }}>
                <div>
                  <h1 className="main-heading">
                    Students take the tests on computers or tablets
                  </h1>
                  <h1 className="sub-heading">
                    Even without internet!
                  </h1>
                </div>
                <div className="combine-views-sub">
                  <div className="content-display">
                    <div className="content-display-left">
                      {goalbaseds.map((goalbased) => (
                        <div className="feature-combine-tags">
                          <div>
                            <img className="feature-image" src={goalbased.url} width="60px" alt="NEET|JEE_goalbased" />
                          </div>
                          <div className="feature-description">
                            <div className="content-primary" style={{ marginBottom: '5px', fontWeight: 700 }}>
                              {goalbased.contentPrimary}
                            </div>
                            <div className="content-secondary">
                              {goalbased.contentSecondary}
                            </div>
                          </div>
                        </div>
                      ))}
                      {this.getInviteModal(customalign, customPadding, 'StudentsApp')}
                    </div>
                    <div className="content-display-right">
                      <img style={{ width: '100%' }} src="images/landing_institute/goalbased.svg" alt="goalbased" />
                    </div>
                  </div>
                </div>
              </div>
            </Element>
            <Element id="Dashboard">
              <div className="combine-views-main " style={{ backgroundColor: '#F8F8F8', height: 'auto' }}>
                <div>
                  <h1 className="main-heading">
                    Personalised reports and insights
                  </h1>
                  <h1 className="sub-heading">
                    For everyone - management, lecturers, students and parents
                  </h1>
                </div>
                <div className="combine-views-sub">
                  <div className="content-display">
                    <div className="content-display-left">
                      {insights.map((insight) => (
                        <div className="feature-combine-tags">
                          <div>
                            <img className="feature-image" src={insight.url} width="60px" alt="NEET_JEE_insights" />
                          </div>
                          <div className="feature-description">
                            <div className="content-primary" style={{ marginBottom: '5px', fontWeight: 700 }}>
                              {insight.contentPrimary}
                            </div>
                            <div className="content-secondary">
                              {insight.contentSecondary}
                            </div>
                          </div>
                        </div>
                      ))}
                      {this.getInviteModal(customalign, customPadding, 'Dashboards')}
                    </div>
                    <div className="content-display-right">
                      <img style={{ width: '100%' }} src={IS_MOBILE ? 'images/landing_institute/insightsmobile.svg' : 'images/landing_institute/insights.svg'} alt="goalbased" />
                    </div>
                  </div>
                </div>
              </div>
            </Element>
            <div style={{ backgroundColor: '#FFFFFF' }}>
              <div style={{ padding: '2%' }}>
                <div className="dynamic-view">
                  <div className="dynamic-flex">
                    <img src="images/landing_institute/questions.svg" alt="NEET_JEE_questions" />
                    <div className="dynamic-topic">QUESTIONS</div>
                    <div className="dynamic-val">{this.renderData(numberOfQuestions)}</div>
                  </div>
                  <div className="dynamic-flex">
                    <img src="images/landing_institute/testtaken.svg" alt="NEET_JEE_test_taken" />
                    <div className="dynamic-topic">TEST TAKEN</div>
                    <div className="dynamic-val">{this.renderData(numberOfTests)}</div>
                  </div>
                  <div className="dynamic-flex">
                    <img src="images/landing_institute/chat.svg" alt="NEET_JEE_questions_answered" />
                    <div className="dynamic-topic">QUESTIONS ANSWERED</div>
                    <div className="dynamic-val">{this.renderData(numberOfAnswers)}</div>
                  </div>
                </div>
              </div>
              {this.displayInstitutions()}
              <div>
                { this.renderUserMessage() }
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

LandingHome.propTypes = {
  numberOfQuestions: PropTypes.number,
  numberOfTests: PropTypes.number,
  numberOfAnswers: PropTypes.number,
  landingPageData: PropTypes.func,
};

LandingHome.defaultProps = {
  numberOfQuestions: '1 Lakh +',
  numberOfTests: '2.5 Lakh +',
  numberOfAnswers: '5 Thousand +',
};

const mapStateToProps = ({ home }) => ({
  numberOfQuestions: home.numberOfQuestions,
  numberOfAnswers: home.numberOfAnswers,
  numberOfTests: home.numberOfTests,
});

export default connect(mapStateToProps, { landingPageData })(LandingHome);
