import React from 'react';
import '../../../shared/styleConstants/staticPages/aboutUs.css';
import { data } from './aboutUs';

class AboutUsMobile extends React.Component {
  constructor() {
    super();
    this.state = {
      selected: 0,

    };
  }

  render() {
    return (
      <div className="about-us-mobile-view">
        <div className="problem-mobile-view">
          <div>We are Problem</div>
          <div>Solvers, Literaly!</div>
          <div className="problem-sub-heading-mobile-view">A bunch of creative people working on solutions to make life easier for students , parents and teachers.</div>
        </div>
        <div className="human-mobile-view">
                    Humans Inside
          <div className="human-sub-heading-mobile-view">Our in-house team consists of Software Engineers, User, Experience Designers, Buisness Development Associate, Business Development Manager, Machine Learning Engineer, Data Scientist</div>
        </div>
        <div className="employee-details">
          <div className="overflow" />
          <div className="details-display">
            <div className="menu" style={{ width: (window.screen.width - 48) }}>
              {
                                data.map((teamInfo, i) => {
                                  return (
                                    <div className="menu-items" onClick={() => { this.setState({ selected: i }); }}>
                                      {(data[this.state.selected].title === teamInfo.title) ? (<div className="menu-items1">{teamInfo.title}</div>)
                                        : (<div className="menu-items2">{teamInfo.title}</div>)}

                                    </div>
                                  );
                                })
                            }
            </div>
            <div className="image-container-mobile">
              {
                                data[this.state.selected].names.map((teamInfo, team) => {
                                  return (
                                    <div className="text-over-image-mobile">
                                      <div className="image-web" style={{ background: 'url(/images/employees/Human2.png)' }}>

                                        <div className="layer" />
                                      </div>

                                      <div className="name-mobile">{teamInfo}</div>
                                      <div className="post-mobile">{data[this.state.selected].posts[team]}</div>
                                    </div>
                                  );
                                })
}
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default AboutUsMobile;
