import Cookies from 'universal-cookie';
import { createBrowserHistory } from 'history';
import Api from '../helpers/Api';
import constants from '../shared/constants';
import { updateLoaderState, showToast, updateTrainLoader } from './global';
import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import { sendEvent } from '../helpers/Analytics';

const cookies = new Cookies();
const Constants = constants();
const history = createBrowserHistory({
  forceRefresh: true,
});

export const UPDATE_HOME_DATA = 'Home/UPDATE_HOME_DATA';
export const UPDATE_PAGE_TYPE = 'Home/UPDATE_PAGE_TYPE';
export const UPDATE_CHECKPOINT_DATA = 'Home/UPDATE_CHECKPOINT_DATA';
export const UPDATE_RECENT_CHAPTER_LIST = 'Home/UPDATE_RECENT_CHAPTER_LIST';
export const UPDATE_NOTIFICATION_READ_STATUS = 'Notification/UPDATE_NOTIFICATION_READ_STATUS';
export const UPDATE_LANDING_PAGE_DATA = 'Home/UPDATE_LANDING_PAGE_DATA';
export const GET_NOTIFICATIONS = 'Home/GET_NOTIFICATIONS';
export const UPDATE_QUESTION_DATA = 'Home/UPDATE_QUESTION_DATA';
export const UPDATE_CHEAT_SHEET_LIST = 'Home/UPDATE_CHEAT_SHEET_LIST';
export const UPDATE_CHEAT_SHEET_TAG = 'Home/UPDATE_CHEAT_SHEET_TAG';
export const UPDATE_CHEAT_SHEET_PAGE_TYPE = 'Home/UPDATE_CHEAT_SHEET_PAGE_TYPE';
export const UPDATE_OVERALL_PROGRESS_VIEW = 'Home/UPDATE_OVERALL_PROGRESS_VIEW';
export const UPDATE_VIDEO_SOLUTION_LIST = 'Home/UPDATE_VIDEO_SOLUTION_LIST';
export const UPDATE_VIDEO_SOLUTION_QUESTION_INDEX = 'Home/UPDATE_VIDEO_SOLUTION_QUESTION_INDEX';
export const UPDATE_LIVE_VIDEO_DETAILS = 'Home/UPDATE_LIVE_VIDEO_DETAILS';

export const getLiveVideo = () => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: '/live_classrooms/get_live_video',
  });
  if (response.success) {
    const payload = {
      videoDetails: response.data,
    };
    dispatch({
      type: UPDATE_LIVE_VIDEO_DETAILS,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const getChapterVideoSolutionList = (chapterId) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/student_app/get_chapter_video_solution_list?chapter_id=${chapterId}`,
  });
  if (response.success) {
    const payload = {
      chapter: response.chapter,
      videoQuestionList: response.video_question_list,
      currentVideoQuestion: response.video_question_list[0],
    };
    dispatch({
      type: UPDATE_VIDEO_SOLUTION_LIST,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const updateCurrentVideoQuestionIndex = (videoQuestionIndex) => async (dispatch) => {
  dispatch({
    type: UPDATE_VIDEO_SOLUTION_QUESTION_INDEX,
    payload: { videoQuestionIndex },
  });
};

export const getOverallProgressData = () => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: '/student_app/get_my_progress_data',
  });
  if (response.success) {
    const payload = {
      subjects: response.subjects,
      overallData: response.overall_data,
      previousWeekData: response.previous_week_data,
      currentWeekData: response.current_week_data,
      graphData: response.graph_data,
    };
    dispatch({
      type: UPDATE_OVERALL_PROGRESS_VIEW,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const getCheatSheetData = (chapterId) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/student_app/get_chapter_quickpoint_list?chapter_id=${chapterId}`,
  });
  if (response.success) {
    const payload = {
      chapter: response.chapter,
      allQuickpoints: response.quickpoint_list,
      quickpointList: response.quickpoint_list,
      knownQuickpointIds: response.known_quickpoint_ids,
      unknownQuickpointIds: response.unknown_quickpoint_ids,
    };
    dispatch({
      type: UPDATE_CHEAT_SHEET_LIST,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const updateCheatSheetPageType = (cheatSheetPageType) => async (dispatch) => {
  dispatch({
    type: UPDATE_CHEAT_SHEET_PAGE_TYPE,
    payload: { cheatSheetPageType },
  });
};

export const updateCheatsheetTag = (id, tag) => async (dispatch) => {
  const payload = { id, tag };
  dispatch({
    type: UPDATE_CHEAT_SHEET_TAG,
    payload,
  });
  const response = await Api({
    method: 'post',
    url: '/student_app/update_student_quickpoint_tag',
    data: { quickpoint_id: id, tag },
  });
  if (!response.success) {
    dispatch(showToast('Failed to update. Try again after sometime', TOAST_MESSAGE_TYPES.ERROR));
    payload.tag = '';
    dispatch({
      type: UPDATE_CHEAT_SHEET_TAG,
      payload,
    });
  }
};

export const getLandingPageData = () => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: '/home/get_landing_page_data',
  });
  if (response.success) {
    const homeData = {
      questionCount: response.question_count,
      testCount: response.test_count,
      responseCount: response.response_count,
    };
    dispatch({
      type: UPDATE_LANDING_PAGE_DATA,
      payload: { homeData },
    });
  }
};

export const updateNotificationStatus = (notificationId) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: `/notifications/${notificationId}/mark_as_read`,
  });
  if (response.success) {
    dispatch({
      type: UPDATE_NOTIFICATION_READ_STATUS,
      notificationId,
    });
  }
};
export function landingPageData({ numberOfQuestions, numberOfAnswers, numberOfTests }) {
  return (dispatch) => Api({
    method: 'get',
    url: '/home/get_landing_page_data',
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_QUESTION_DATA,
        numberOfQuestions: { oldValue: numberOfQuestions.newValue, newValue: response.question_count },
        numberOfTests: { oldValue: numberOfTests.newValue, newValue: response.test_count },
        numberOfAnswers: { oldValue: numberOfAnswers.newValue, newValue: response.response_count },
      });
    }
    return response;
  });
}

export const createPersonalisedTest = (chapterId, totalCount, complexity) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  await Api({
    method: 'post',
    url: '/student_app/create_personalised_test',
    data: { chapter_id: chapterId, totalCount, complexity },
  });
  sendEvent('PersonalisedAssessment - Started', { chapterId });
  history.push(`/personalised_assignment/${chapterId}`);
};

export const updateRecentChapters = (chapterId) => async (dispatch) => {
  dispatch({
    type: UPDATE_RECENT_CHAPTER_LIST,
    payload: { chapterId },
  });
};

export const updatePageType = (pageType) => async (dispatch) => {
  dispatch({ type: UPDATE_PAGE_TYPE, pageType });
};

export const getHomeData = (type, subType = '', isInstituteAssignment = false) => async (dispatch) => {
  dispatch(updateTrainLoader(true));
  const response = await Api({
    method: 'post',
    url: '/student_app/get_student_home_data',
    data: { type, sub_type: subType, is_module: isInstituteAssignment },
  });
  if (response.success) {
    const payload = {
      isInstituteAssignment,
      chapters: response.chapters,
      recentChapterList: response.recent_chapters,
      subjects: response.subjects,
      pageType: response.page_type,
      testList: response.test_list,
      isMockTestLocked: response.is_mock_test_locked,
      completedTestList: response.completed_test_list,
      unitChapterData: response.unit_chapter_data,
      notifications: response.notifications,
      isTestTetakable: response.is_test_retakable,
    };
    cookies.set('notifications', response.notifications, { path: '/', secure: Constants.cookie.secure });
    dispatch({
      type: UPDATE_HOME_DATA,
      payload,
    });
  } else {
    dispatch(showToast(response.message ? response.message : 'Try again later.', TOAST_MESSAGE_TYPES.ERROR));
  }
  dispatch(updateTrainLoader(false));
};

export const getNotifications = () => async (dispatch) => {
  dispatch({
    type: GET_NOTIFICATIONS,
    payload: { notifications: cookies.get('notifications') },
  });
};

export const getCheckpointData = (type, id) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'post',
    url: '/student_app/get_student_home_data',
    data: { type, id },
  });
  if (response.success) {
    const payload = {
      chapter: response.chapter_data,
      checkpoints: response.checkpoints,
      hasPremiumAccess: response.has_premium_access,
    };
    dispatch({
      type: UPDATE_CHECKPOINT_DATA,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};
