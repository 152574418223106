import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import ModalView from '../modal';
import Text from '../text';
import StyleConstants from '../../shared/styleConstants/styles';
import { updateTrainCurrentQuestionIndex, finishSession, restartSession, showTrainSessioFinishModal, applyFilter } from '../../actions/index';
import { TEST_QUESTION_OVERVIEW_TYPES, OBJECT_TYPES } from '../../shared/constants/fieldTypes';
import { sendEvent } from '../../helpers/Analytics';

const history = createBrowserHistory({
    forceRefresh: true
});

const LEGENDS = ['CORRECT', 'INCORRECT', "ANSWER_REQUESTED", 'UNATTEMPTED', 'UNVISITED'];

const DIFFICULTY_FILTER = {
  Easy: [1, 2],
  Medium: [3],
  Hard: [4, 5]
};

const FILTERS = ['INCORRECT', 'UNATTEMPTED', 'UNVISITED'];

let fieldTypeBackgroundColor = {};
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.CORRECT] = StyleConstants.color.Correct;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.ATTEMPTED] = StyleConstants.color.testAttempted;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.INCORRECT] = StyleConstants.color.Incorrect;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.UNATTEMPTED] = StyleConstants.color.testUnattempted;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.UNVISITED] = StyleConstants.color.testUnvisited;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.ANSWER_REQUESTED] = StyleConstants.color.keyUsed;

class TrainOverview extends Component {

  state = {
    difficultySelected: [],
    difficultyValues: [],
    selectedTypes: [],
    screenHeight: 0,
    showModal: false,
    showTrainFinishModal: false
  };

  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight })
  }

  renderQuestionIndex() {
    const { selectedTypes } = this.state;
    const { questionIndexMapping, difficultyQuestionIndexes } = this.props;
    let indexes = Object.keys(questionIndexMapping);
    if (this.state.difficultyValues.length > 0) {
      indexes = indexes && difficultyQuestionIndexes
    }
    const hasSelectedQuestionTypes = selectedTypes.length === 0 ? true : indexes.some(qIndex => questionIndexMapping[`${qIndex}`].some(type => selectedTypes.includes(type)))
    return (
      <div>
        <div style={{ marginTop: 10 }} className="flexcontainer">
          {hasSelectedQuestionTypes && indexes && indexes.map(questionIndex => (
            <div key={`QuestionOverviewIndex_${questionIndex}`} style={{ height: 'fit-content'}}>
              {(selectedTypes.length === 0 || parseInt(questionIndex) === parseInt(this.props.questionIndex + 1) || questionIndexMapping[`${questionIndex}`].some(type => selectedTypes.includes(type))) &&
                <div id={`Question-Index-${questionIndex}`} className={`Circle ${questionIndexMapping[`${questionIndex}`][0]}`}  style={{ marginRight: 13, marginBottom: 13, backgroundColor: fieldTypeBackgroundColor[questionIndexMapping[`${questionIndex}`][0]] }} onClick={() => {
                  this.props.updateTrainCurrentQuestionIndex(parseInt(questionIndex) - 1, true);
                }}>
                  <Text text={questionIndex} fontWeight={StyleConstants.textWeight.bold} color={ questionIndexMapping[`${questionIndex}`][0] === TEST_QUESTION_OVERVIEW_TYPES.UNVISITED ? StyleConstants.textColor.primary : "white" } />
                </div>
              }
            </div>
          ))}
          {indexes.length === 0 &&
            <Text text={`You have no ${selectedTypes.join(', ')} questions`} />
          }
        </div>
      </div>
    )
  }

  updateFiltersSelected(fieldName, fieldValue, fieldData=[]) {
    let selectedValues = this.state[fieldName];
    let { difficultyValues } = this.state;
    if (selectedValues.includes(fieldValue)) {
      selectedValues.splice(selectedValues.indexOf(fieldValue), 1);
      fieldData.forEach(val => difficultyValues.splice(difficultyValues.indexOf(val), 1));
    } else {
      selectedValues.push(fieldValue);
      difficultyValues = [...fieldData, ...difficultyValues]
    }
    if (fieldData.length > 0) {
      this.setState({ difficultyValues });
    }
    this.setState({ [fieldName]: selectedValues });
    fieldData ? this.props.applyFilter(this.state.selectedTypes, difficultyValues) : this.props.applyFilter(selectedValues, this.state.difficultyValues);
  }

  renderDifficultyFilter(type) {
    const isSelected = this.state.difficultySelected.includes(type);
    return (
      <div
        id={`Diffculty-Filter-${type}`}
        key={`DiffcultyType-${type}`}
        className={`${isSelected ? 'Primary-Button' : ''} Button`}
        style={{ width: 105, fontSize: 12, fontWeight: "100" }}
        onClick={() => this.updateFiltersSelected('difficultySelected', type, DIFFICULTY_FILTER[type])}
      > {type} </div>
    );
  }

  renderQuestionOverviewType(type) {
    const fieldName = TEST_QUESTION_OVERVIEW_TYPES[type];
    const isSelected = this.state.selectedTypes.includes(fieldName);
    return (
      <div
        id={`Question-Type-Filter-${fieldName}`}
        key={`QuestionType_${fieldName}`}
        className={`${isSelected ? 'Primary-Button' : ''} Button`}
        style={{ width: 105, fontSize: 12, fontWeight: "100" }}
        onClick={() => this.updateFiltersSelected('selectedTypes', fieldName)}
      > {fieldName} </div>
    );
  }

  renderLegends(type) {
    const fieldName = TEST_QUESTION_OVERVIEW_TYPES[type];
    const color = fieldTypeBackgroundColor[fieldName];
    return (
      <div key={`Legend_${fieldName}`} style={{ display: 'flex', alignItems: 'center' }}>
        <div className="Circle" style={{ width: 8, height: 8 , backgroundColor: color, marginRight: 1 }} />
        <Text text={fieldName.split('_').join(" ")} fontWeight={StyleConstants.textWeight.semiBold} color={color} fontSize={StyleConstants.textSize.small} />
      </div>
    )
  }

  onCloseModal(isSessionRestart=false) {
    if (isSessionRestart) {
      const { unVisitedCount, testInfo, objectType, sessionType } = this.props;
      const { showModal } = this.state;
      if (showModal) {
        sendEvent("CheckpointOverview - RestartTapped", { ...testInfo, unVisitedCount, objectType, sessionType });
        this.props.restartSession(testInfo.session_id)
      } else {
        sendEvent("SessionItem - FinishTapped", { ...testInfo, unVisitedCount, objectType, sessionType });
        this.props.finishSession(true, objectType, sessionType);
      }
    }
    this.setState({ showModal: false, showTrainFinishModal: false });
    this.props.showTrainSessioFinishModal(false)
  }

  renderModalButton() {
    return (
      <div className="modalButtonView">
        <div id="Close-Modal" className="Button Secondary-Button mr-2" style={{ width: 130 }} onClick={() => this.onCloseModal()}> No </div>
        <div id="Finish-Session" className="Button Primary-Button" style={{ width: 130 }} onClick={() => this.onCloseModal(true)}> Yes </div>
      </div>
    )
  }

  renderModalContent() {
    const { unVisitedCount, unAttemptedCount } = this.props;
    const { showModal } = this.state;
    if ((unVisitedCount === 0 && unAttemptedCount === 0) || showModal) {
      const message = showModal ? "Your progress will be lost. Do you want to continue?" : "Are you sure you want to finish the session?";
      return (
        <div>
          <Text text={message} />
          {this.renderModalButton()}
        </div>
      );
    }
    return (
      <div>
        <div className="flexcontainer">
          <Text text={"You have"} />
          {unAttemptedCount > 0 && <Text text={` ${unAttemptedCount} unattempted`} fontWeight={StyleConstants.textWeight.bold} />}
          {unAttemptedCount > 0 && unVisitedCount > 0 && <Text text=" and" />}
          {unVisitedCount > 0 && <Text text={` ${unVisitedCount} unvisited`} fontWeight={StyleConstants.textWeight.bold} />}
          <Text text={" questions."} />
        </div>
        {this.renderModalButton()}
      </div>
    );
  }

  renderModal() {
    const { showSessionFinishModal, testInfo } = this.props;
    const { showModal, showTrainFinishModal } = this.state;
    const headerText = showModal ? "Are you sure you want to restart this session?" : "Are you sure you want to finish the session?";
    if (showSessionFinishModal && testInfo.is_session_review) {
      history.push('/');
      return;
    }
    return (
      <ModalView isModalVisible={showModal || showTrainFinishModal || showSessionFinishModal} onClose={this.onCloseModal} center header={headerText} maxWidth={500}>
        {this.renderModalContent()}
      </ModalView>
    );
  }

  showTopicRestartModal() {
    this.setState({ showModal: true });
  }

  showTrainFinishModal() {
    this.setState({ showTrainFinishModal: true });
  }

  render() {
    const { objectType, questionCount, unVisitedCount, unAttemptedCount, testInfo } = this.props;
    const isPersonalisedTest = objectType === OBJECT_TYPES.PERSONALISED_TEST;
    const isRestartActive = questionCount !== (unVisitedCount + unAttemptedCount);
    const showRestartSession = !isPersonalisedTest && !testInfo.show_finish_session && !testInfo.is_revise;
    return (
      <div>
        {this.renderModal()}
        <div style={{ paddingLeft: 15, paddingRight: 15 }}>
          <div style={{ marginBottom: 15 }}>
            <Text text="Difficulty" fontWeight={StyleConstants.textWeight.semiBold} color={StyleConstants.textColor.primary} />
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              {Object.keys(DIFFICULTY_FILTER).map(type => this.renderDifficultyFilter(type))}
            </div>
          </div>
          <div style={{ marginBottom: 15 }}>
            <Text text="Response" fontWeight={StyleConstants.textWeight.semiBold} color={StyleConstants.textColor.primary} />
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              {FILTERS.map(type => this.renderQuestionOverviewType(type))}
            </div>
          </div>
          <div className="flexcontainer" style={{ marginBottom: 15, justifyContent: 'space-between' }}>
            {LEGENDS.map(type => this.renderLegends(type))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 30 }}>
            <Text
              color={StyleConstants.textColor.primary}
              fontSize={StyleConstants.textSize.headerSmall}
              fontWeight={StyleConstants.textWeight.bold}
              text={`${questionCount} Questions`}
            />
            {showRestartSession &&
              <div id='Restart-Session' className="Button" style={{ width: 95, height: StyleConstants.fieldHeight.small, opacity: !isRestartActive && 0.5 }}  onClick={() => isRestartActive && this.showTopicRestartModal()}> Restart </div>
            }
          </div>

          <div className="flexcontainer" style={{
            marginTop: 15,
            position: 'relative',
            height: this.state.screenHeight - 350,
            overflow: 'scroll',
          }}>
            {this.renderQuestionIndex()}
          </div>
        </div>
        {(testInfo.show_finish_session && !testInfo.is_session_review) &&
          <div
            id='Session-Finish-Button'
            className="Button Primary-Button"
            style={{ height: StyleConstants.fieldHeight.big, position: 'absolute', bottom: 0, width: '100%', borderRadius: 0 }}
            onClick={() =>  this.showTrainFinishModal()}
          > Finish </div>
        }
      </div>
    )
  }
}

TrainOverview.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  finishSession:PropTypes.func.isRequired,
  restartSession: PropTypes.func.isRequired,
  showTrainSessioFinishModal: PropTypes.func.isRequired,
  updateTrainCurrentQuestionIndex: PropTypes.func.isRequired,
  testInfo: PropTypes.object,
  objectType: PropTypes.number,
  sessionType: PropTypes.number,
  questionIndex: PropTypes.number,
  questionCount: PropTypes.number,
  unVisitedCount: PropTypes.number,
  unAttemptedCount: PropTypes.number,
  questionIndexMapping: PropTypes.object,
  showSessionFinishModal: PropTypes.bool,
  difficultyQuestionIndexes: PropTypes.array
}

TrainOverview.defaultProps = {
  difficultyQuestionIndexes: [],
  showSessionFinishModal: false,
  objectType: 0,
  sessionType: 0,
  questionIndexMapping: {},
  questionIndex: 1,
  unVisitedCount: 0,
  unAttemptedCount: 0,
  questionCount: 0
}

const mapStateToProps = ({ train }) => ({
  testInfo: train.testInfo,
  unAttemptedCount: train.unAttemptedCount,
  unVisitedCount: train.unVisitedCount,
  questionIndex: train.questionIndex,
  questionIndexMapping: train.questionIndexMapping,
  questionCount: train.questionCount,
  showSessionFinishModal: train.showSessionFinishModal,
  difficultyQuestionIndexes: train.difficultyQuestionIndexes
});

export default connect(mapStateToProps, { updateTrainCurrentQuestionIndex, finishSession, restartSession, showTrainSessioFinishModal, applyFilter })(TrainOverview);
