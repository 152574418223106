import Cookies from 'universal-cookie';
import Api from '../helpers/Api';
import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import { showToast } from './global';

export const GET_VIRTUAL_MEETINGS = '@virtualMeeting/GET_VIRTUAL_MEETINGS';
export const UPDATE_QUESTION_RESPONSE = '@virtualMeeting/UPDATE_QUESTION_RESPONSE';
export const UPDATE_OPTION_PRESS = '@virtualMeeting/UPDATE_OPTION_PRESS';
export const SHOW_QUIZ_REPORT = '@virtuaMeeting/SHOW_QUIZ_REPORT';
export const UPDATE_QUIZ_QUESTIONS = '@virtualMeeting/UPDATE_QUIZ_QUESTIONS';
export const UPDATE_QUESTION_INDEX = '@virtualMeeting/UPDATE_QUESTION_INDEX';
export const TOGGLE_SHOW_QUIZ = '@virtualMeeting/TOGGLE_SHOW_QUIZ';
export const TOGGLE_EXPAND_STATUS = '@virtualMeeting/TOGGLE_EXPAND_STATUS';
export const HIDE_QUIZ_QUESTION_LIST = '@virtualMeeting/HIDE_QUIZ_QUESTION_LIST';
export const UPDATE_QUIZ_SCORES = '@virtualMeeting/UPDATE_QUIZ_SCORES';
export const UPDATE_ATTENDANCE = '@virtualMeeting/UPDATE_ATTENDANCE';

export const MAX_DAYS_GOING_BACK = 180;
export const MAX_DAYS_GOING_FRONT = -30;

const cookies = new Cookies();
const user = cookies.get('user');

export const createMeetingSignature = (meetConfig, id) => async () => {
  const response = await Api({
    method: 'post',
    url: '/virtual_meetings/create_meeting_signature',
    data: {
      meeting_number: meetConfig.meetingNumber,
      role: meetConfig.role ? meetConfig.role : 0,
      id,
    },
  });
  return response;
};

export const updateQuestionOptionSelection = (questionId, optionIndex) => async (dispatch) => {
  dispatch({
    type: UPDATE_OPTION_PRESS,
    payload: {
      questionId,
      optionIndex,
    },
  });
};

export function getTimeTable(weeksAgo = 0) {
  return (dispatch) => Api({
    method: 'get',
    url: `/live_classrooms/get_time_table?weeks_ago=${weeksAgo}`,
  }).then((response) => {
    dispatch({
      type: GET_VIRTUAL_MEETINGS,
      payload: {
        timeTabledata: response.time_table_data,
        startDate: response.start_date,
        endDate: response.end_date,
        zones: response.zones,
        isHigherEducation: response.is_higher_education,
      },
    });
    return response;
  });
}

export function updateVirtualMeetingConcepts(startTime, endTime, id) {
  return () => Api({
    method: 'post',
    url: '/virtual_meetings/update_virtual_meeting_concepts',
    data: {
      start_at: startTime,
      ends_at: endTime,
      id,
    },
  }).then((response) => {
    return response;
  });
}

export function getVideoRecordings(params = {}) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/get_video_recordings',
    data: params,
  }).then((response) => {
    dispatch({
      type: GET_VIRTUAL_MEETINGS,
      payload: {
        meetings: response.virtual_meetings,
      },
    });
    return response;
  });
}


export const onClickVerify = (payload) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/live_quiz/answer',
    data: payload,
  });
  if (response.success) {
    dispatch({
      type: UPDATE_QUESTION_RESPONSE,
      payload: {
        questionId: payload.question_id,
      },
    });
  }
};

export const getQuizScoreData = () => async (dispatch) => {
  const isHigherEducation = user.is_higher_education;
  const response = await Api({
    method: 'get',
    url: '/virtual_meetings/get_quiz_scores',
  });
  if (response.success) {
    const subjectName = isHigherEducation ? response.quiz_scores.length : 'Total';
    const totalQuizScores = { total_attempted: 0, correct: 0, subject_name: subjectName, subject_code: 'Total' };
    response.quiz_scores.map((subject) => {
      totalQuizScores.total_attempted += subject.total_attempted;
      totalQuizScores.correct += subject.correct;
    });
    const payload = {
      quizScores: response.quiz_scores,
      totalQuizScores,
    };
    dispatch({
      type: UPDATE_QUIZ_SCORES,
      payload,
    });
  }
  return response;
};

export const getAttendaceData = () => async (dispatch) => {
  const isHigherEducation = user.is_higher_education;
  const response = await Api({
    method: 'get',
    url: '/virtual_meetings/get_subject_wise_attendance',
  });
  if (response.success) {
    const subjectName = isHigherEducation ? response.attendance.length : '';
    const totalAttendance = { total_classes: 0, attended: 0, subject_name: subjectName, subject_code: '' };
    response.attendance.map((subject) => {
      totalAttendance.total_classes += subject.total_classes;
      totalAttendance.attended += subject.attended;
    });
    const payload = {
      attendance: response.attendance,
      totalAttendance,
    };
    dispatch({
      type: UPDATE_ATTENDANCE,
      payload,
    });
  }
  return response;
};

export const getVirtualMeetings = (daysAgo) => async (dispatch) => {
  const date = new Date();
  date.setDate(date.getDate() - (daysAgo || 0));
  const response = await Api({
    method: 'get',
    url: `/virtual_meetings/get_live_classes?date=${date}`,
  });
  if (response.success) {
    const payload = {
      meetings: response.virtual_meetings,
      userName: response.user_name,
      useZoom: response.live_class_through_zoom_app,
      quizQuestionData: [],
    };
    dispatch({
      type: GET_VIRTUAL_MEETINGS,
      payload,
    });
  }
  return response;
};

export const getQuestionData = (meetingId) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/virtual_meetings/${meetingId}/get_quiz_question_data`,
  });
  if (response.success) {
    const payload = {
      quizData: response.question_data,
      responseData: response.response_data,
      quizId: response.quiz_id,
    };
    dispatch({
      type: GET_VIRTUAL_MEETINGS,
      payload,
    });
  }
  return response;
};

export const setShowQuizQuestionStatus = (quizQuestionData,
  responseData, quizId, isRecorded = false) => async (dispatch) => {
    dispatch({
      type: UPDATE_QUIZ_QUESTIONS,
      payload: {
        quizQuestionData: quizQuestionData || [], responseData: responseData || [], quizId, isRecorded,
      },
    });
  };

export const updateQuestionIndex = (switchIndex) => async (dispatch) => {
  dispatch({
    type: UPDATE_QUESTION_INDEX,
    payload: { switchIndex },
  });
};

export const getClassVideos = (videoMeetingId) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/virtual_meetings/${videoMeetingId}/get_meeting_videos`,
  });
  if (response.success) {
    dispatch({
      type: GET_VIRTUAL_MEETINGS,
      payload: {
        recordedMeeting: response.meeting,
        quizData: response.quiz_data,
        responseData: response.response_data,
      },
    });
  }
  return response;
};

export const showReport = (liveQuizId) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/live_quiz/${liveQuizId}/report`,
  });
  if (response.success) {
    dispatch({
      type: SHOW_QUIZ_REPORT,
      payload: response.live_quiz_report,
    });
  }
};

export const markStudentAttendance = (id) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/virtual_meetings/mark_student_attendance',
    data: {
      id,
    },
  });
  return response;
};

export const changeShowQuizStatus = () => async (dispatch) => {
  dispatch({
    type: TOGGLE_SHOW_QUIZ,
  });
};

export const changeExpandStatus = () => async (dispatch) => {
  dispatch({
    type: TOGGLE_EXPAND_STATUS,
  });
};

export const hideQuizQuestionList = () => async (dispatch) => {
  dispatch({
    type: HIDE_QUIZ_QUESTION_LIST,
  });
};

export const sendMeetingFeedback = (meetingId, rating, feedbackMessage) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/virtual_meetings/feedback',
    data: { meeting_id: meetingId, rating, feedback_message: feedbackMessage },
  });
  let message = 'Thank you for your feedback.';
  let toastType = TOAST_MESSAGE_TYPES.SUCCESS;
  if (!response.success) {
    message = 'Feeback not sent. Network error, try again.';
    toastType = TOAST_MESSAGE_TYPES.ERROR;
  }
  dispatch(showToast(message, toastType));
  return response.success;
};
