import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import { logout, verifyTestUnlockOtp } from '../actions/index';

import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import Text from './text';
import StyleConstants from '../shared/styleConstants/styles';
import { showToast } from '../actions/global';

class EnterTestLockedOtp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',
    };
    this.validateOtp = this.validateOtp.bind(this);
    this.updateOtp = this.updateOtp.bind(this);
  }

  validateOtp() {
    const { otp } = this.state;
    if (otp.length === 6) {
      this.props.verifyTestUnlockOtp(otp, this.props.testId);
    } else {
      this.props.showToast('Otp must be 6 digits.', TOAST_MESSAGE_TYPES.ERROR);
    }
  }

  updateOtp(otp) {
    this.setState({ otp }, () => {
      if (otp.length === 6) {
        this.props.verifyTestUnlockOtp(otp, this.props.testId);
      }
    });
  }

  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
        <div
          className="cardView"
          style={{
            padding: '20px 40px', alignItems: 'center', display: 'flex', flexDirection: 'column',
          }}
        >
          <Text text="Please enter the OTP" fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.headerSmall} />
          <OtpInput
            value={this.state.otp}
            onChange={(otp) => this.updateOtp(otp)}
            numInputs={6}
            separator={<span> &emsp; </span>}
            inputStyle={{ width: 40, height: 40 }}
            shouldAutoFocus
          />
          <div className="Primary-Button Button" style={{ marginTop: 20, width: '100%' }} onClick={() => this.validateOtp()}>Continue</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => ({
  isOtpRequired: test.isOtpRequired,
});

export default connect(mapStateToProps, {
  logout, verifyTestUnlockOtp, showToast,
})(EnterTestLockedOtp);
