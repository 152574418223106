import React, { PureComponent } from 'react';
import Layout from './layout';
import Text from './text';
import Constants from '../shared/constants';
import StyleConstants from '../shared/styleConstants/styles';
import QRCode from '../shared/images/common/ideaBoard_QR.png';

class MobileRedirection extends PureComponent {
  render() {
    return (
      <Layout>
        <div style={{ height: window.innerHeight - 450, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Text
            fontSize={StyleConstants.textSize.header}
            fontWeight={StyleConstants.textWeight.bold}
            text="Please download IdeaBoard from Play Store."
          />
          <img src={QRCode} alt="NEET_JEE_QR Code" width={200} height={200} />
          <a href={`${Constants().playStoreUrl}=utm_source%3DibWebsite%26utm_medium%3Daccounts%26utm_term%3DgetItOnGooglePlay`}><img className="google-play" alt="NEET JEE online app" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" width="140px" /></a>
        </div>
      </Layout>
    );
  }
}

export default MobileRedirection;
