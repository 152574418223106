import { createBrowserHistory } from 'history';
import Api from '../helpers/Api';
import { getTrainQuestionIndexMapping } from '../helpers/Utils';
import { updateLoaderState, showToast } from './global';
import { uploadResponses } from './test';
import {
 OBJECT_TYPES, SESSION_TYPES, QUESTION_TYPES, TOAST_MESSAGE_TYPES 
} from '../shared/constants/fieldTypes';

const history = createBrowserHistory({
  forceRefresh: true,
});

export const GET_TRAIN_QUESTIONS = 'Train/GET_TRAIN_QUESTIONS';
export const UPDATE_TRAIN_NUMERICAL_QUESTION_DATA = 'Train/UPDATE_TRAIN_NUMERICAL_QUESTION_DATA';
export const UPDATE_TRAIN_CURRENT_QUESTION_INDEX = 'Train/UPDATE_CURRENT_QUESTION_INDEX';
export const UPDATE_TRAIN_QUESTION_SELECTED_OPTION = 'Train/UPDATE_QUESTION_SELECTED_OPTION';
export const GET_TRAIN_PROGRESS_DATA = 'Train/GET_TEST_PROGRESS_DATA';
export const UPDATE_TRAIN_QUESTION_TIME_SPENT = 'Train/UPDATE_QUESTION_TIME_SPENT';
export const SHOW_UPLOAD_FAILED_MODAL = 'Train/SHOW_UPLOAD_FAILED_MODAL';
export const CLEAR_UPLOADED_RESULTS = 'Train/CLEAR_UPLOADED_RESULTS';
export const FINISH_SESSION = 'Train/FINISH_SESSION';
export const QUESTION_VERIFIED = 'Train/QUESTION_VERIFIED';
export const UPDATE_SOLUTION_REQUEST_TIME = 'Train/UPDATE_SOLUTION_REQUEST_TIME';
export const SHOW_TRAIN_SESSION_FINISH_MODAL = 'Train/SHOW_TRAIN_SESSION_FINISH_MODAL';
export const UPDATE_QUESTION_LIST = 'Train/UPDATE_QUESTION_LIST';

export const showTrainSessioFinishModal = (showModal = true) => async (dispatch) => {
  dispatch({ type: SHOW_TRAIN_SESSION_FINISH_MODAL, showModal });
};

export const restartSession = (id) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  window.localStorage.removeItem('QuestionResponses');
  const response = await Api({
    method: 'post',
    url: `/student_app/${id}/restart_train_session_item`,
  });
  if (response.success) {
    if (response.is_previous_paper) {
      history.push(`/chapter_previous_paper/${response.object_id}`);
    } else {
      history.push(`/train/${response.object_id}`);
    }
  }
  dispatch(updateLoaderState(false));
};

export const updateTrainQuestionTimeSpent = (questionId) => async (dispatch) => {
  dispatch({
    type: UPDATE_TRAIN_QUESTION_TIME_SPENT,
    payload: { questionId },
  });
};

export const getTrainProgressData = () => async (dispatch) => {
  dispatch({
    type: GET_TRAIN_PROGRESS_DATA,
  });
};

export const updateQuestionVerifiedState = () => async (dispatch) => {
  dispatch({
    type: QUESTION_VERIFIED,
  });
};

export const updateSolutionRequestStatus = () => async (dispatch) => {
  dispatch({ type: UPDATE_SOLUTION_REQUEST_TIME });
  dispatch({ type: QUESTION_VERIFIED });
};

export const applyFilter = (selectedTypes, difficulty) => async (dispatch) => {
  dispatch({ type: UPDATE_QUESTION_LIST, payload: { selectedTypes, difficulty } });
  await dispatch({
    type: UPDATE_TRAIN_CURRENT_QUESTION_INDEX,
    currentIndex: -1,
  });
  dispatch({
    type: UPDATE_TRAIN_CURRENT_QUESTION_INDEX,
    currentIndex: 0,
  });
};

export const updateTrainRichResponse = (richResponse) => async (dispatch) => {
  if ((richResponse.indexOf('-') > -1 && richResponse.indexOf('-') !== 0) || richResponse.split('-').length > 2) {
    dispatch(showToast('Minus cannot be added within a number.', TOAST_MESSAGE_TYPES.WARNING));
    return;
  }
  if (richResponse.split('.').length > 2) {
    dispatch(showToast('There cannot be more than 1 decimal points in a number.', TOAST_MESSAGE_TYPES.WARNING));
    return;
  }
  dispatch({ type: UPDATE_TRAIN_NUMERICAL_QUESTION_DATA, payload: { richResponse } });
};

export const updateTrainCurrentQuestionIndex = (currentIndex, isQuestionReference = false) => async (dispatch) => {
  await dispatch({
    type: UPDATE_TRAIN_CURRENT_QUESTION_INDEX,
    currentIndex: -1,
  });
  dispatch({
    type: UPDATE_TRAIN_CURRENT_QUESTION_INDEX,
    currentIndex,
    isQuestionReference,
  });
};

export const getTrainData = (id, objectType, sessionType) => async (dispatch) => {
  let response = '';
  dispatch(updateLoaderState(true));
  const objectTypeData = objectType === OBJECT_TYPES.PERSONALISED_TEST ? 'get_questions_for_personalised_test' : `get_train_questions?object_type=${objectType}&session_type=${sessionType}`;
  const path = sessionType === SESSION_TYPES.REVISE ? 'get_revise_questions_for_chapters' : objectTypeData;
  response = await Api({
    method: 'get',
    url: `/student_app/${id}/${path}`,
  });
  if (response.success) {
    const questionTimeSpent = {};
    const optionSelectedQuestionList = {};
    let questionsVisited = [];
    const questionSessionItem = {};
    const questionResponseVersion = {};
    const questionsVerifiedAt = {};
    const richResponses = {};
    const questionType = {};
    const questionsVerified = {};
    const solutionRequestTime = {};
    const comprehensionText = {};
    const comprehensionQuestionData = {};
    let currentIndex = 0;
    const resultData = {};
    let sessionTimeSpent = response.test_info.session_time_spent;
    let questionResponseData = response.question_responses;
    const comprehensionQuestions = response.comprehension_questions || [];
    response.question_responses.forEach((question) => {
      questionSessionItem[question.id] = question.session_item_id ? question.session_item_id : response.test_info.session_id;
    });

    comprehensionQuestions.forEach((question) => {
      comprehensionQuestionData[question.question_id] = {
        text: question.comprehension_text,
        supportingPicture: question.supporting_picture ? question.supporting_picture.url : '',
        supportingPictureSize: question.supporting_picture_size,
      };
    });

    const responseData = JSON.parse(window.localStorage.getItem('QuestionResponses'));
    if (responseData) {
      const validSessionId = responseData.sessionId || responseData.is_revise;
      const isNewSession = responseData.sessionId !== response.test_info.session_id;
      const hasTestTimedOut = !responseData.sessionTimeSpent && sessionTimeSpent > 0;
      const hasTestGotUpdated = sessionTimeSpent < responseData.sessionTimeSpent;
      if (validSessionId && (isNewSession || hasTestTimedOut || hasTestGotUpdated)) {
        responseData.responses = JSON.stringify(responseData.responses);
        const responseUploadStatus = await Api({
          method: 'post',
          url: '/student_app/upload_student_responses',
          data: responseData,
        });
        if (!responseUploadStatus.success) {
          dispatch(updateLoaderState(false));
          dispatch({
            type: SHOW_UPLOAD_FAILED_MODAL,
          });
          return;
        }
        window.localStorage.removeItem('QuestionResponses');
        if (!isNewSession) {
          questionResponseData = JSON.parse(responseData.responses);
          currentIndex = response.questions[responseData.currentIndex] ? responseData.currentIndex : 0;
          sessionTimeSpent = responseData.timeRemaining;
        }
      }
    }

    const questionIds = [];
    const questionSubjectMapping = {};
    response.questions.forEach((question) => {
      optionSelectedQuestionList[question.id] = [];
      questionIds.push(question.id);
      questionSubjectMapping[question.id] = question.subject_id;
    });
    
    questionResponseData.forEach((question) => {
      questionType[question.id] = question.question_type;
      const questionResponse = question.responses || [];
      questionsVerifiedAt[question.id] = question.verified_at;
      questionsVerified[question.id] = response.test_info.is_session_review || question.verified;
      questionResponseVersion[question.id] = question.version ? question.version : 0;
      if (questionResponse.length > 0 || parseInt(question.solution_request_time, 10) > 0) {
        optionSelectedQuestionList[question.id] = questionResponse;
        resultData[question.id] = question.result;
      }
      if (question.question_type === QUESTION_TYPES.NUMERICAL) {
        richResponses[question.id] = question.rich_response;
      } else if (question.question_type === QUESTION_TYPES.COMPREHENSION) {
        comprehensionText[question.id] = comprehensionQuestionData[question.id];
      }
      if (question.question_time_spent > 0) {
        questionsVisited = [...questionsVisited, question.id];
      }
      if (question.solution_request_time && question.solution_request_time > 0) {
        solutionRequestTime[question.id] = question.solution_request_time;
      }
      questionTimeSpent[question.id] = question.question_time_spent;
    });
    const currentQuestion = response.questions[currentIndex];
    const bookmarkedQuestionIds = response.bookmarked_question_ids;
    const currentQuestionId = currentQuestion && currentQuestion.id;
    if (questionsVisited.length === 0 && currentQuestionId) {
      questionsVisited.push(currentQuestionId);
    }
    const unVisitedCount = response.questions.length - questionsVisited.length;
    const unAttemptedCount = questionsVisited.length - Object.keys(questionsVerified).length;
    const payload = {
      allQuestions: response.questions,
      questions: response.questions,
      questionIndexMapping: getTrainQuestionIndexMapping(response.questions,
        optionSelectedQuestionList, questionsVisited, questionsVerified,
        solutionRequestTime, richResponses),
      subjectList: Array.from(new Set(response.questions.map((value) => value.subject_id))),
      questionCount: response.questions.length,
      testInfo: response.test_info,
      questionIds,
      questionType,
      richResponses,
      richResponse: currentQuestion && richResponses[currentQuestion.id] ? richResponses[currentQuestion.id] : '',
      resultData,
      unVisitedCount,
      unAttemptedCount,
      sessionTimeSpent,
      questionTimeSpent,
      questionsVisited,
      questionSubjectMapping,
      optionSelectedQuestionList,
      currentQuestion,
      currentIndex,
      questionsVerified,
      solutionRequestTime,
      bookmarkedQuestionIds,
      questionSessionItem,
      questionResponseVersion,
    };
    dispatch({
      type: GET_TRAIN_QUESTIONS,
      payload,
    });
    dispatch({
      type: UPDATE_TRAIN_CURRENT_QUESTION_INDEX,
      currentIndex,
    });
  }
  dispatch(updateLoaderState(false));
};

export const finishSession = (showReport, objectType, sessionType) => async (dispatch) => {
  await dispatch({ type: FINISH_SESSION });
  const responseData = JSON.parse(window.localStorage.getItem('QuestionResponses'));
  responseData.responses = JSON.stringify(responseData.responses);
  const response = await Api({
    method: 'post',
    url: '/student_app/upload_student_responses',
    data: { ...responseData, isFinishTest: true },
  });
  if (response.success) {
    window.localStorage.removeItem('QuestionResponses');
    if (showReport) {
      history.push(`/session_report/${responseData.id}?object_type=${objectType}&session_type=${sessionType}`);
    } else {
      history.push(`/chapter/${responseData.chapter_id}`);
    }
  } else {
    dispatch({
      type: SHOW_UPLOAD_FAILED_MODAL,
    });
  }
};

export const verifyQuestion = () => async (dispatch) => {
  dispatch({
    type: QUESTION_VERIFIED,
  });
};

export const updateTrainQuestionSelectedOptions = (optionIndex, shouldUploadResponses) => async (dispatch) => {
  const payload = {
    optionIndex,
  };
  await dispatch({
    type: UPDATE_TRAIN_QUESTION_SELECTED_OPTION,
    payload,
  });
  if (shouldUploadResponses && navigator.onLine) {
    dispatch(uploadResponses('UploadResult'));
  }
};
