import { TEST_QUESTION_OVERVIEW_TYPES, SUBJECT_MAPPING, QUESTION_TYPES } from '../shared/constants/fieldTypes';
import StyleConstants from '../shared/styleConstants/styles';
import Constants from '../shared/constants';

const constants = Constants();

export const isMobileDevice = () => {
  return (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1);
};

export const asciiForMathJax = () => {
  const asciiMathJax = 'U = 1/(R_(si) + sum_(i=1)^n(s_n/lambda_n) + R_(se))';
  return asciiMathJax;
};

export const getVimeoVideoId = (url) => {
  const videoUrl = url ? url.match(/.*\/*vimeo.com\/(\d+)\/*/) : '';
  return videoUrl ? videoUrl[1] : '';
};

export const getTrainQuestionIndexMapping = (questions, optionSelectedQuestionList = {}, questionsVisited = [], questionsVerified = {}, solutionRequestTime = {}, richResponses = {}) => {
  const questionIndexMapping = {};
  questions.forEach((question, index) => {
    const answer = question.answers || [];
    const fieldTypes = [];
    const selectedOptions = optionSelectedQuestionList[question.id] || [];
    const isNumerical = question.question_type === QUESTION_TYPES.NUMERICAL;
    const isSelected = isNumerical ? richResponses[question.id] : selectedOptions && selectedOptions.length > 0;
    const isVisited = questionsVisited.includes(question.id);
    const isSolutionRequested = Object.keys(solutionRequestTime).includes(`${question.id}`);
    const visitedData = isVisited ? TEST_QUESTION_OVERVIEW_TYPES.UNATTEMPTED : TEST_QUESTION_OVERVIEW_TYPES.UNVISITED;
    const selectedData = isSelected ? TEST_QUESTION_OVERVIEW_TYPES.ATTEMPTED : visitedData;
    let isCorrect = false;
    if (question.question_type === QUESTION_TYPES.NUMERICAL) {
      const richAnswer = answer.map((data) => {
        return parseFloat(data).toFixed(2);
      });
      isCorrect = richAnswer.includes(parseFloat(richResponses[question.id]).toFixed(2));
    } else {
      isCorrect = JSON.stringify(answer.sort()) === JSON.stringify(selectedOptions.sort());
    }
    const visitedSolutionData = isSelected ? TEST_QUESTION_OVERVIEW_TYPES.INCORRECT : visitedData;
    const solutionData = isCorrect ? TEST_QUESTION_OVERVIEW_TYPES.CORRECT : visitedSolutionData;
    const questionsVerifiedData = questionsVerified[question.id] ? solutionData : selectedData;
    const result = isSolutionRequested ? TEST_QUESTION_OVERVIEW_TYPES.ANSWER_REQUESTED : questionsVerifiedData;
    fieldTypes.push(result);
    questionIndexMapping[index + 1] = fieldTypes;
  });
  return questionIndexMapping;
};

export const getQuestionIndexMapping = (questions,
  markedQuestionList = [], optionSelectedQuestionList = {}, questionsVisited = [],
  richResponses = {}, isTest = false, resultData = {}) => {
  const questionIndexMapping = {};
  questions.forEach((question, index) => {
    if (!questionIndexMapping[SUBJECT_MAPPING[question.subject_id]]) {
      questionIndexMapping[SUBJECT_MAPPING[question.subject_id]] = {};
    }
    const fieldTypes = [];
    const selectedOptions = optionSelectedQuestionList[question.id] || [];
    const isNumerical = question.question_type === QUESTION_TYPES.NUMERICAL;
    const isSelected = isNumerical ? richResponses[question.id]
      : selectedOptions && selectedOptions.length > 0;
    const isVisited = questionsVisited.includes(question.id);
    const visitedData = isVisited ? TEST_QUESTION_OVERVIEW_TYPES.UNATTEMPTED
      : TEST_QUESTION_OVERVIEW_TYPES.UNVISITED;
    if (isTest) {
      const isMarked = markedQuestionList.includes(question.id);
      if (isMarked) {
        fieldTypes.push(TEST_QUESTION_OVERVIEW_TYPES.MARKED);
      }
      fieldTypes.push(isSelected ? TEST_QUESTION_OVERVIEW_TYPES.ATTEMPTED : visitedData);
    } else {
      let resultType = typeof resultData[question.id] === 'undefined' ? visitedData
        : TEST_QUESTION_OVERVIEW_TYPES.INCORRECT;
      const result = resultData[question.id] > 1
        ? TEST_QUESTION_OVERVIEW_TYPES.INCORRECT : TEST_QUESTION_OVERVIEW_TYPES.CORRECT;
      resultType = resultData[question.id] > 0 ? result : resultType;
      fieldTypes.push(resultType);
    }
    questionIndexMapping[SUBJECT_MAPPING[question.subject_id]][index + 1] = fieldTypes;
  });
  return questionIndexMapping;
};

export const getAttendedQuestionlist = (question, attendedQuestionList, optionSelectedQuestionList, richResponses) => {
  attendedQuestionList = attendedQuestionList || {}
  attendedQuestionList[question.subject_id] =  attendedQuestionList[question.subject_id] || {}
  attendedQuestionList[question.subject_id][question.question_type] = attendedQuestionList[question.subject_id][question.question_type] || [];
  const attendedposition = attendedQuestionList[question.subject_id][question.question_type].indexOf(question.id);
  if((richResponses[question.id] && richResponses[question.id].length > 0) || (optionSelectedQuestionList[question.id] && optionSelectedQuestionList[question.id].length > 0)){
    if(attendedposition === -1){
      attendedQuestionList[question.subject_id][question.question_type] = [ ...attendedQuestionList[question.subject_id][question.question_type], question.id]
    }
  } else if(attendedposition > -1) {
    attendedQuestionList[question.subject_id][question.question_type].splice(attendedposition, 1);
  }
  return attendedQuestionList;
}

export const getQuestionSideBarData = (questions, availableQuestionIds, markedQuestionList = [], optionSelectedQuestionList = {}, currentQuestionId = '', richResponses = {}) => {
  if (!currentQuestionId && questions.length > 0) {
    currentQuestionId = questions[0].id;
  }
  const questionSideBarData = [];
  questions.forEach((question) => {
    if (availableQuestionIds.includes(question.id)) {
      const isMarkedQuestion = markedQuestionList.includes(question.id);
      const hasSelectedOption = question.question_type === QUESTION_TYPES.NUMERICAL ? richResponses[question.id] : Object.keys(optionSelectedQuestionList).includes(`${question.id}`);
      const isCurrentQuestion = question.id === currentQuestionId;
      const color = isCurrentQuestion ? StyleConstants.textColor.primary : StyleConstants.textColor.secondary;
      const fontWeight = isCurrentQuestion ? StyleConstants.textWeight.bold : '';
      const backgroundColor = isMarkedQuestion ? StyleConstants.backgroundColor.testMarked : (hasSelectedOption ? StyleConstants.backgroundColor.testAttempted : 'white');
      questionSideBarData.push({
        id: question.id,
        question_text: question.question_text,
        isVisible: true,
        color,
        fontWeight,
        backgroundColor,
      });
    } else {
      questionSideBarData.push({
        id: question.id,
        question_text: question.question_text,
        isVisible: false,
      });
    }
  });
  return questionSideBarData;
};
export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getTestProgressData = (questionCount, selectedQuestionCount, visitedQuestionCount, endDate) => {
  const unAttemptedCount = visitedQuestionCount - selectedQuestionCount;
  const unVisitedCount = questionCount - visitedQuestionCount;
  const { hours, minutes, sec } = calculateCountdown(endDate);
  const timeRemaining = hours > 0 ? `${hours}h ${minutes}m` : minutes > 0 ? `${minutes}m ${sec}s` : `${sec}s`;

  return {
    unAttemptedCount,
    unVisitedCount,
    timeRemaining,
  };
};

export const updateQuestionCache = (questionIds, payload, cacheName, updateAll) => {
  const questionResposes = {
    ...payload.testInfo,
    currentIndex: payload.currentIndex,
    sessionId: payload.sessionId,
    timeRemaining: payload.timeRemaining,
    responses: [],
  };
  const visitedQuestions = [...new Set(payload.questionsVisited)].filter((n) => {
    return questionIds.indexOf(n) > -1;
  });

  // Safety Precaution to avoid multiple entires of the same question Id
  const questions = updateAll ? questionIds : visitedQuestions;
  let sessionTimeSpent = 0;
  questions.forEach((questionId) => {
    sessionTimeSpent += payload.questionTimeSpent[questionId];
    const questionVersion = payload.questionResponseVersion[questionId] || 0;
    questionResposes.responses.push({
      id: questionId,
      subject_id: payload.questionSubjectMapping[questionId],
      responses: payload.optionSelectedQuestionList[questionId] || [],
      is_marked: payload.markedQuestionList && payload.markedQuestionList.includes(questionId),
      question_time_spent: payload.questionTimeSpent[questionId],
      solution_request_time: payload.solutionRequestTime && payload.solutionRequestTime[questionId] ? payload.solutionRequestTime[questionId] : '',
      verified_at: payload.questionVerifiedAt[questionId],
      position: questionIds.indexOf(questionId) + 1,
      verified: payload.questionsVerified ? payload.questionsVerified[questionId] : false,
      session_item_id: payload.questionSessionItem[`${questionId}`] ? payload.questionSessionItem[`${questionId}`] : payload.sessionId,
      version: payload.questionsVerified ? questionVersion + 1 : questionVersion,
      rich_response: (payload.richResponses && payload.richResponses[`${questionId}`]) ? payload.richResponses[`${questionId}`] : '',
      question_type: payload.questionType[questionId],
    });
  });
  questionResposes.sessionTimeSpent = sessionTimeSpent;
  window.localStorage.setItem(cacheName, JSON.stringify(questionResposes));
};

export const getAccountsDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.accountsDomain;
};

export const getHomeDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.homeDomain;
};

export const getInsituteDomain = (isClassBoard = false, isTeacher = false) => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  const path = isClassBoard ? (isTeacher ? "/classboard/virtual_meeting" : "" ): "";
  return protocolScheme + constants.cookie.instituteDomain + path;
}

export const getParentDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.parentDomain;
}

export const calculateTime = (timeInSeconds) => {
  const timeLeft = { hours: 0, minutes: 0, sec: 0 };

  if (timeInSeconds >= 3600) {
    timeLeft.hours = Math.floor(timeInSeconds / 3600);
    timeInSeconds -= timeLeft.hours * 3600;
  }

  if (timeInSeconds >= 60) {
    timeLeft.minutes = Math.floor(timeInSeconds / 60);
    timeInSeconds -= timeLeft.minutes * 60;
  }

  timeLeft.sec = timeInSeconds;
  return timeLeft;
};

export const getCalculatedTime = (timeInSeconds, round = false) => {
  const { hours, minutes, sec } = calculateTime(timeInSeconds);
  const second = round ? sec.toFixed(2) : sec;
  const days = parseInt(hours / 24, 0);
  return days > 0 ? (`${days}d : ${hours % 24}h`) : (hours > 0 ? `${hours}h : ${minutes}m` : (minutes > 0 ? `${minutes}m : ${second}s` : `${second}s`));
};

export const calculateCountdown = (endDate, countDownInSeconds = false) => {
  const diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
  // clear countdown when date is reached
  if (diff < 0) return false;

  if (countDownInSeconds) {
    return diff;
  }

  return calculateTime(diff);
};

export const emailValidate = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (emailRegex.test(email));
};

export const validatePhone = (phone) => {
  const onlyNumRegex = '^[0-9]*$';
  return (phone.match(onlyNumRegex));
};


export const checkDevice = () => {
  if (navigator.userAgent.match(/mobile/i)) {
    global.sessionStorage.setItem('device', 'Mobile');
  } else if (navigator.userAgent.match(/iPad|Android|Touch/i)) {
    global.sessionStorage.setItem('device', 'Tablet');
  } else {
    global.sessionStorage.setItem('device', 'Desktop');
  }
};

export const encodeURI = (queryParamas) => {
  let encodedString = '';
  Object.keys(queryParamas).forEach((param) => encodedString += `&${encodedString}=${queryParamas[param]}`);
  return encodedString;
};

export const convertTitleCaseString = (title = '') => {
  return title === '' ? '' : (title.replace(/\w\S*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }));
};

export const TIME_CONVERSION = 60 * 1000;
