import React, { PureComponent } from 'react';

class Index extends PureComponent {
  render() {
    return (
      <h1> Index </h1>
    );
  }
}

export default Index;
