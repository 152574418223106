import { UPDATE_OPENINGS, DISPLAY_OPENINGS, UPDATE_APPLICATION_DATA } from '../actions';

const INITIAL_STATE = {
  openings: {},
  jobDescription: {},
  applicationData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_OPENINGS:
      return { ...state, openings: action.payload };
    case DISPLAY_OPENINGS:
      return { ...state, jobDescription: action.payload };
    case UPDATE_APPLICATION_DATA:
      return { ...state, applicationData: { ...state.applicationData, [action.payload.key]: action.payload.value } };
    default:
      return state;
  }
};
