import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { createBrowserHistory } from 'history';
import { getQuestionData, setShowQuizQuestionStatus, showReport } from '../actions/virtualMeeting';
import { SIDEBAR_TYPES } from '../shared/constants/fieldTypes';
import { ShowQuizQuestion } from './common';
import Loader from './loader';
import constants from '../shared/constants/index';
import Layout from './layout';
import SideBar from './sidebar';

const LIVE_QUIZ_QUESTION_UPDATE_CHANNEL = 'LiveQuizQuestionUpdateChannel';
const cookies = new Cookies();
const USER = cookies.get('user');


class AttendQuiz extends PureComponent {
  constructor(props) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      showLoader: true,
      id,
    };
  }


  componentDidMount() {
    const { id } = this.state;
    this.props.getQuestionData(id).then((data) => {
      if (data && data.question_data && data.question_data.length > 0) {
        this.props.setShowQuizQuestionStatus(data.question_data,
          data.response_data, data.quiz_id, false);
      }
      this.setState({ showLoader: false, quizId: data.quiz_id });
      if (data.quiz_id) {
        const actionCable = require('actioncable');
        const cable = actionCable.createConsumer(constants().api.ws_url);
        const subscription = cable.subscriptions.create({
          channel: LIVE_QUIZ_QUESTION_UPDATE_CHANNEL,
          institute_id: USER.institute_id,
          meeting_id: id,
          quiz_id: data.quiz_id,
        }, {
          received: (result) => {
            if (result.complete && data.quiz_id) {
              this.props.showReport(data.quiz_id);
            } else {
              this.props.setShowQuizQuestionStatus(result.questions,
                [], data.quiz_id, false);
            }
          },
        });
        this.setState({ subscription, cable });
      }
    });
  }

  render() {
    const { id, showLoader } = this.state;
    const { showQuizQuestionsListOutside, dataUpdateCount, quizId } = this.props;
    if (showLoader) {
      return <Loader />;
    }
    return (
      <Layout hideFooter>
        <SideBar pageType={SIDEBAR_TYPES.VIRTUAL_MEETINGS} />
        <div
          className="MainPage"
          style={{
            marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%',
          }}
        >

          {showQuizQuestionsListOutside
            ? <ShowQuizQuestion key={`${dataUpdateCount}-${quizId}`} isRecorded={false} showReport />
            : <div style={{ marginTop: 100, fontSize: 20, fontWeight: 'bold' }}>No Questions, Wait Untill A New Question Is Added</div>}
        </div>
      </Layout>
    );
  }
}

AttendQuiz.propTypes = {
  showQuizQuestionsListOutside: PropTypes.bool,
  dataUpdateCount: PropTypes.number,
  match: PropTypes.object,
};

AttendQuiz.defaultProps = {
  showQuizQuestionsListOutside: false,
  dataUpdateCount: 0,
  match: {},
};

const mapStateToProps = ({ virtualMeeting }) => ({
  showQuizQuestionsListOutside: virtualMeeting.showQuizQuestionsListOutside,
  quizId: virtualMeeting.quizId,
});

export default connect(mapStateToProps, { getQuestionData, setShowQuizQuestionStatus, showReport })(AttendQuiz);
