import { createBrowserHistory } from 'history';
import Cookies from 'universal-cookie';
import Api from '../helpers/Api';
import constants from '../shared/constants';
import { sendEvent } from '../helpers/Analytics';
import { QUESTION_TYPES, TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import { getQuestionIndexMapping, getQuestionSideBarData, calculateTime, getAttendedQuestionlist } from '../helpers/Utils';
import { updateLoaderState, showToast } from './global';


const cookies = new Cookies();
const Constants = constants();
const history = createBrowserHistory({
  forceRefresh: true,
});

export const GET_TEST_INSTRUCTIONS = 'Home/GET_TEST_INSTRUCTIONS';
export const GET_TEST_QUESTIONS = 'Test/GET_TEST_QUESTIONS';
export const UPDATE_CURRENT_QUESTION_INDEX = 'Test/UPDATE_CURRENT_QUESTION_INDEX';
export const UPDATE_QUESTION_MARKED_LIST = 'Test/UPDATE_QUESTION_MARKED_LIST';
export const UPDATE_QUESTION_SELECTED_OPTION = 'Test/UPDATE_QUESTION_SELECTED_OPTION';
export const GET_TEST_PROGRESS_DATA = 'Test/GET_TEST_PROGRESS_DATA';
export const UPDATE_QUESTION_TIME_SPENT = 'Test/UPDATE_QUESTION_TIME_SPENT';
export const SHOW_UPLOAD_FAILED_MODAL = 'Test/SHOW_UPLOAD_FAILED_MODAL';
export const CLEAR_UPLOADED_RESULTS = 'Test/CLEAR_UPLOADED_RESULTS';
export const FINISH_TEST = 'Test/FINISH_TEST';
export const SHOW_TEST_SESSION_FINISH_MODAL = 'Train/SHOW_TEST_SESSION_FINISH_MODAL';
export const UPDATE_TEST_QUESTION_LIST = 'Test/UPDATE_TEST_QUESTION_LIST';
export const UPDATE_TEST_NUMERICAL_QUESTION_DATA = 'Test/UPDATE_TEST_NUMERICAL_QUESTION_DATA';
export const UPDATE_TEST_TIME_REMAINING = 'Test/UPDATE_TEST_TIME_REMAINING';
export const UPDATE_TEST_LOCK_STATE = 'Test/UPDATE_TEST_LOCK_STATE';

export const showTestSessioFinishModal = (showModal = true) => async (dispatch) => {
  dispatch({ type: SHOW_TEST_SESSION_FINISH_MODAL, showModal });
};

export const applyTestFilter = (selectedTypes, subjects) => async (dispatch) => {
  dispatch({ type: UPDATE_TEST_QUESTION_LIST, payload: { selectedTypes, subjects } });
  await dispatch({
    type: UPDATE_CURRENT_QUESTION_INDEX,
    currentIndex: -1,
  });
  dispatch({
    type: UPDATE_CURRENT_QUESTION_INDEX,
    currentIndex: 0,
  });
};

export const updateQuestionTimeSpent = (questionId) => async (dispatch) => {
  dispatch({
    type: UPDATE_QUESTION_TIME_SPENT,
    payload: { questionId },
  });
};

export const getTestProgressData = () => async (dispatch) => {
  dispatch({
    type: GET_TEST_PROGRESS_DATA,
  });
};

export const getTestInstructions = (testId) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/student_app/${testId}/get_test_instructions`,
  });
  if (response.success) {
    const payload = {
      testInfo: response.test_info,
    };
    dispatch({
      type: GET_TEST_INSTRUCTIONS,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const beginTest = (testId, otp = '') => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/student_app/find_or_create_session_item',
    data: { id: testId, otp },
  });
  if (response.success) {
    let routePath = '';
    if (response.is_assignment) {
      routePath = 'assignment';
    } else {
      routePath = 'test';
    }
    dispatch(updateLoaderState(true));
    history.push(`/${routePath}/${response.test_id}`);
  } else {
    dispatch(showToast(response.message ? response.message : 'Unable to begin the test. Try again after some time.', TOAST_MESSAGE_TYPES.ERROR));
  }
};

export const finishTest = (hasTimerExpired = false, shouldRedirect = false) => async (dispatch) => {
  await dispatch({ type: FINISH_TEST });
  dispatch(updateLoaderState(true));
  const responseData = JSON.parse(window.localStorage.getItem('QuestionResponses'));
  responseData.responses = JSON.stringify(responseData.responses);
  const response = await Api({
    method: 'post',
    url: '/student_app/upload_student_responses',
    data: { ...responseData, isFinishTest: true },
  });
  if (response.success) {
    sendEvent('Tests - ResponseSubmittedonFinish', { ...responseData, hasTimerExpired, isFinishTest: true });
    window.localStorage.removeItem('QuestionResponses');
    if (shouldRedirect) {
      cookies.set('initialTestReport', { reportData: response.report_data, subjects: response.subjects, testInfo: response.test_info }, { path: '/', secure: Constants.cookie.secure });
      history.push(`/initial_test_report/${responseData.id}`);
    }
  } else {
    sendEvent('Tests - SubmissionFailed', { testId: responseData.id, sessionId: responseData.sessionId });
    dispatch(showToast('Network error, please try again after sometime.', TOAST_MESSAGE_TYPES.ERROR));
    dispatch({
      type: SHOW_UPLOAD_FAILED_MODAL,
    });
    dispatch(updateLoaderState(false));
  }
};

export const updateTimeRemaining = (timeRemainingInSeconds) => async (dispatch) => {
  dispatch({ type: UPDATE_TEST_TIME_REMAINING, payload: { timeRemainingInSeconds } });
};

export const getTestData = (testId) => async (dispatch) => {
  const responseData = JSON.parse(window.localStorage.getItem('QuestionResponses'));
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/student_app/${testId}/get_test_questions`,
  });
  if (!response.success) {
    dispatch(showToast('This is not an active test.', TOAST_MESSAGE_TYPES.WARNING));
    history.push('/');
    return;
  }

  if (response.success) {
    const questionTimeSpent = {};
    const markedQuestionList = [];
    const optionSelectedQuestionList = {};
    const questionsVisited = [];
    const questionVerifiedAt = {};
    const comprehensionText = {};
    const resultData = {};
    const questionType = {};
    const richResponses = {};
    const comprehensionQuestionData = {};
    let currentIndex = 0;
    const testDuration = response.test_info.test_duration;
    let questionResponseData = response.question_responses;
    const comprehensionQuestions = response.comprehension_questions || [];
    if (testDuration <= 0 && response.test_info.session_status !== 'COMPLETE') {
      dispatch(showToast('Your test is being submitted as you have run out time.', TOAST_MESSAGE_TYPES.WARNING));
      const userResponseData = responseData || { ...response.test_info, sessionId: response.test_info.session_id, isFinishTest: true };
      if (responseData) {
        userResponseData.isFinishTest = true;
        userResponseData.responses = JSON.stringify(responseData.responses);
      }

      await Api({
        method: 'post',
        url: '/student_app/upload_student_responses',
        data: userResponseData,
      });
      history.push(`/initial_test_report/${response.test_info.id}`);
      return;
    }
    if (responseData) {
      const validSessionId = responseData.sessionId || responseData.is_revise;
      const isNewSession = responseData.sessionId !== response.test_info.session_id;
      const hasTestTimedOut = !responseData.timeRemaining && testDuration > 0;
      const hasTestGotUpdated = testDuration > responseData.timeRemaining;

      if (validSessionId && (isNewSession || hasTestTimedOut || hasTestGotUpdated)) {
        if (responseData.timeRemaining === 0) {
          responseData.isFinishTest = true;
        }
        responseData.responses = JSON.stringify(responseData.responses);
        const responseUploadStatus = await Api({
          method: 'post',
          url: '/student_app/upload_student_responses',
          data: responseData,
        });
        if (!responseUploadStatus.success) {
          dispatch(updateLoaderState(false));
          dispatch({
            type: SHOW_UPLOAD_FAILED_MODAL,
          });
          return;
        }
        window.localStorage.removeItem('QuestionResponses');
        if (responseData.sessionId === response.test_info.session_id && testDuration > responseData.timeRemaining) {
          questionResponseData = JSON.parse(responseData.responses);
          currentIndex = responseData.currentIndex;
        }
      }
    }
    comprehensionQuestions.forEach((question) => {
      comprehensionQuestionData[question.question_id] = {
        text: question.comprehension_text,
        supportingPicture: question.supporting_picture ? question.supporting_picture.url : '',
        supportingPictureSize: question.supporting_picture_size,
      };
    });
    let attendedQuestionList = {}
    questionResponseData.forEach((question) => {
      questionType[question.id] = question.question_type;
      if ((question.responses && question.responses.length > 0) || question.rich_response) {
        resultData[question.id] = question.result;
      }
      if (question.question_type === QUESTION_TYPES.NUMERICAL) {
        richResponses[question.id] = question.rich_response;
      } else if (question.question_type === QUESTION_TYPES.COMPREHENSION) {
        comprehensionText[question.id] = comprehensionQuestionData[question.id];
      }
      optionSelectedQuestionList[question.id] = [];
      if (question.responses && question.responses.length > 0) {
        optionSelectedQuestionList[question.id] = question.question_type
          === QUESTION_TYPES.NUMERICAL ? 0 : question.responses;
        questionVerifiedAt[question.id] = question.verified_at;
      }
      if (question.question_time_spent > 0) {
        questionsVisited.push(question.id);
      }
      if (question.is_marked) {
        markedQuestionList.push(question.id);
      }
      questionTimeSpent[question.id] = question.question_time_spent;
    });
    const isTestReview = response.test_info.session_status === 'COMPLETE';
    const bookmarkedQuestionIds = response.bookmarked_question_ids;
    const currentQuestion = response.questions[currentIndex] || {};
    const currentQuestionId = currentQuestion.id;
    const currentSelectedOption = optionSelectedQuestionList[currentQuestionId] || [];
    const currentQuestionTimeSpent = calculateTime(
      Math.ceil(questionTimeSpent[currentQuestionId] / 1000) || 0,
    );
    if (questionsVisited.length === 0 && !response.test_info.is_missed_test) {
      questionsVisited.push(currentQuestionId);
    }
    const questionIds = [];
    const questionSubjectMapping = {};
    response.questions.forEach((question) => {
      questionIds.push(question.id);
      questionSubjectMapping[question.id] = question.subject_id;
      attendedQuestionList = getAttendedQuestionlist(question, attendedQuestionList, optionSelectedQuestionList, richResponses);
    });
    const payload = {
      questions: response.questions,
      allQuestions: response.questions,
      redirectToReport: response.redirect_to_report,
      questionIndexMapping: getQuestionIndexMapping(response.questions,
        markedQuestionList, optionSelectedQuestionList, questionsVisited,
        richResponses, !isTestReview, resultData),
      questionSideBarData: getQuestionSideBarData(response.questions, questionIds,
        markedQuestionList, optionSelectedQuestionList,
        currentQuestionId, richResponses),
      subjectList: Array.from(new Set(response.questions.map((value) => value.subject_id))),
      questionCount: response.questions.length,
      attendingCount: response.attending_count,
      selectedOption: currentSelectedOption,
      testInfo: response.test_info,
      isCurrentQuestionBookmarked: bookmarkedQuestionIds.includes(currentQuestion.id),
      richResponse: richResponses[currentQuestion.id] ? richResponses[currentQuestion.id] : '',
      isOtpRequired: response.is_otp_required,
      canLockTest: response.can_lock_test,
      questionIds,
      isTestReview,
      testDuration,
      attendedQuestionList: { ...attendedQuestionList },
      questionTimeSpent,
      questionsVisited,
      comprehensionText,
      markedQuestionList,
      questionType,
      questionSubjectMapping,
      optionSelectedQuestionList,
      resultData,
      currentQuestionTimeSpent,
      currentQuestion,
      currentIndex,
      richResponses,
      bookmarkedQuestionIds,
    };
    dispatch({
      type: GET_TEST_QUESTIONS,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const updateQuestionMarkedList = () => async (dispatch) => {
  dispatch({ type: UPDATE_QUESTION_MARKED_LIST });
};

export const updateCurrentQuestionIndex = (currentIndex, isQuestionReference = false) => async (dispatch) => {
  await dispatch({
    type: UPDATE_CURRENT_QUESTION_INDEX,
    currentIndex: -1,
  });
  dispatch({
    type: UPDATE_CURRENT_QUESTION_INDEX,
    currentIndex,
    isQuestionReference,
  });
};

export const updateRichResponse = (richResponse) => async (dispatch) => {
  if ((richResponse.indexOf('-') > -1 && richResponse.indexOf('-') !== 0) || richResponse.split('-').length > 2) {
    dispatch(showToast('Minus cannot be added within a number.', TOAST_MESSAGE_TYPES.WARNING));
    return;
  }
  if (richResponse.split('.').length > 2) {
    dispatch(showToast('There cannot be more than 1 decimal points in a number.', TOAST_MESSAGE_TYPES.WARNING));
    return;
  }
  dispatch({ type: UPDATE_TEST_NUMERICAL_QUESTION_DATA, payload: { richResponse } });
};

export const uploadResponses = (cacheName) => async (dispatch) => {
  const responseData = JSON.parse(window.localStorage.getItem(cacheName));
  if (responseData) {
    if (responseData.timeRemaining && responseData.timeRemaining === 0) {
      responseData.isFinishTest = true;
    }
    responseData.responses = JSON.stringify(responseData.responses);
    const response = await Api({
      method: 'post',
      url: '/student_app/upload_student_responses',
      data: responseData,
    });
    if (response.success) {
      window.localStorage.removeItem(cacheName);
      dispatch({
        type: CLEAR_UPLOADED_RESULTS,
      });
    }
  }
};

export const updateQuestionSelectedOptions = (optionIndex, shouldUploadResponses) => async (dispatch) => {
  const payload = {
    optionIndex,
  };
  await dispatch({
    type: UPDATE_QUESTION_SELECTED_OPTION,
    payload,
  });
  if (shouldUploadResponses && navigator.onLine) {
    dispatch(uploadResponses('UploadResult'));
  }
};

export const lockTestByOtp = (testId) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/student_app/lock_test_by_otp',
    data: { id: testId },
  });
  if (response.success) {
    const payload = {
      isOtpRequired: response.is_otp_required,
    };
    dispatch({
      type: UPDATE_TEST_LOCK_STATE,
      payload,
    });
  }
};

export const verifyTestUnlockOtp = (otp, testId) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/student_app/unlock_test_by_otp',
    data: { id: testId, otp },
  });
  if (response.success) {
    const payload = {
      isOtpRequired: response.is_otp_required,
    };
    dispatch({
      type: UPDATE_TEST_LOCK_STATE,
      payload,
    });
    dispatch(showToast(response.message ? response.message : 'Invalid Otp.', TOAST_MESSAGE_TYPES.SUCCESS));
  } else {
    dispatch(showToast(response.message ? response.message : 'Invalid Otp.', TOAST_MESSAGE_TYPES.ERROR));
  }
};
