import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from './layout';
import Loader from './loader';
import SideBar from './sidebar';
import {
  setShowQuizQuestionStatus, showReport,
  getClassVideos, hideQuizQuestionList,
} from '../actions/virtualMeeting';

class ViewClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    const { match, getClassVideos } = this.props;
    const { params } = match;
    const { id } = params;

    getClassVideos(id).then(() => {
      const {
        recordedMeeting, responseData, quizData, setShowQuizQuestionStatus,
      } = this.props;
      if (recordedMeeting && recordedMeeting.live_quiz_id) {
        setShowQuizQuestionStatus(quizData[recordedMeeting.id],
          responseData[recordedMeeting.id], recordedMeeting.live_quiz_id, true);
      }
      this.setState({ showLoader: false });
    });
  }

  componentWillUnmount() {
    this.props.hideQuizQuestionList()
  }

  displayVideo(url) {
    const { recordedMeeting } = this.props;
    return (
      <iframe
        id="embed-recorded-video"
        width="80%"
        height="80%"
        title={recordedMeeting.title}
        src={url}
        frameBorder="0"
        allowFullScreen
      />
    );
  }

  render() {
    const { showLoader } = this.state;
    const { recordedMeeting } = this.props;
    if (showLoader) {
      return (
        <Loader />
      );
    }
    if (!recordedMeeting.video) {
      return (
        <Layout hideFooter>
          <div style={{ display: 'flex' }}>
            <SideBar showBackNavigation navigateTo="/virtual_meeting" width="20%" />
            <div style={{
              height: window.innerHeight - 70, display: 'flex', alignItems: 'center', width: '100%', fontFamily: 'Nunito', fontWeight: 'bold', fontSize: 24, justifyContent: 'center', marginLeft: 300, marginBottom: 30,
            }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span>
                  Your institute hasn't uploaded the video for this class yet
                </span>
                <span>
                  We will notify you once it is uploaded!
                </span>
              </div>
            </div>
          </div>
        </Layout>
      );
    }

    return (
      <Layout hideFooter>
        <div style={{ display: 'flex' }}>
          <SideBar showBackNavigation navigateTo="/virtual_meeting" width="20%" />
          <div style={{
            textAlign: 'center', width: '100%', height: window.innerHeight - 70, marginLeft: 300, display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          >
            {this.displayVideo(recordedMeeting.video)}
          </div>
        </div>
      </Layout>
    );
  }
}

ViewClass.propTypes = {
  recordedMeeting: PropTypes.object,
  quizData: PropTypes.object,
  responseData: PropTypes.object,
  match: PropTypes.object,
};

ViewClass.defaultProps = {
  recordedMeeting: {},
  quizData: {},
  responseData: {},
  match: {},
};

const mapStateToProps = ({ virtualMeeting }) => ({
  recordedMeeting: virtualMeeting.recordedMeeting,
  quizData: virtualMeeting.quizData,
  responseData: virtualMeeting.responseData,
});

export default connect(mapStateToProps,
  {
    getClassVideos, setShowQuizQuestionStatus, hideQuizQuestionList, showReport,
  })(ViewClass);
