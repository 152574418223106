import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from '../../reducers';

export default function configureStore(preloadedState) {
  const persistConfig = {
    key: 'authType',
    storage,
    whitelist: ['home'],
  };
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const storeEnhancers = [middlewareEnhancer];
  const composedEnhancer = process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging' ? compose(...storeEnhancers) : composeWithDevTools(...storeEnhancers);
  const pReducer = persistReducer(persistConfig, reducers);

  const store = createStore(pReducer, preloadedState, composedEnhancer);
  persistStore(store);
  return store;
}
