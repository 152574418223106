import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { staticPages } from '../../../shared/styleConstants/styles';
import { emailValidate, validatePhone } from '../../../helpers/Utils';
import '../../../shared/styleConstants/staticPages/careers.css';
import {
  getPositions,
  updateApplicationData,
  uploadApplicationData,
} from '../../../actions/index';
import StaticText from '../staticText';
import Layout from '../../layout';

const { fontColor, fontWeight } = staticPages;
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

class Openings extends Component {
  constructor(props) {
    super(props);
    this.fileUploaderRef = React.createRef();
    this.formRef = React.createRef();
    this.state = {
      apply: false,
      file: null,
      buttonText: '',
      errors: {
        email: '',
        phone: '',
      },
    };

    this.uploadResume = this.uploadResume.bind(this);
    this.showButton = this.showButton.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams(window.location.search);
    const position = searchParams.get('type');
    this.props.getPositions(position);
  }

  showButton() {
    this.fileUploaderRef.current.click();
  }

  onSubmit() {
    const { errors } = this.state;

    const { email, phone } = this.props.applicationData;
    if (this.formRef.current.reportValidity()) {
      errors.email = email.length >= 6 && emailValidate(email) ? '' : 'Email is not valid!';
      errors.phone = phone.length === 10 && validatePhone(phone) ? '' : 'Invalid Phone!';

      if (validateForm(this.state.errors)) {
        this.props.uploadApplicationData(this.state.file);
      }
    }
  }

  uploadResume(event) {
    const file = event.target.files;
    this.setState({ file, buttonText: file[0].name });
  }

  renderPositionDescription() {
    const { applicationData } = this.props;
    if (!this.state.apply) {
      return (
        <div>
          <div
            className="row bottom-pad"
            style={{ paddingBottom: 20, backgroundColor: 'white' }}
          >
            <button
              type="button"
              className="apply-btn static-pages"
              onClick={() => this.setState({ apply: true })}
            >
              APPLY FOR THIS JOB
            </button>
          </div>
          <div className="container-fluid space">
            <div className="col-sm-2" />
            <div className="static-pages col-sm-8">
              <div>
                <div className="details-content">
                  <StaticText
                    fontWeight={fontWeight.content}
                    color={fontColor.content}
                    text={this.props.jobDescription.aboutTheRole}
                    definedClass="content-size"
                  />
                </div>

                <div className="header header-size-secondary details-sub-header">
                 {this.props.jobDescription.title === 'Machine Learning Engineer' ? 'Key Responsibilities:' : '' }
                </div>
                <div className="details-content">
                  <ul>
                    {(this.props.jobDescription.title === 'Machine Learning Engineer') && this.props.jobDescription.keyResponsibilities && this.props.jobDescription.keyResponsibilities.map(responsibility => 
                      <li>
                        <StaticText
                          fontWeight={fontWeight.content}
                          color={fontColor.content}
                          text={responsibility}
                          definedClass="content-size"
                        />
                      </li>
                    )}
                  </ul>
                </div>

                <div className="header header-size-secondary details-sub-header">
                  Requirements:
                </div>
                <div className="details-content">
                  <ul>
                    {this.props.jobDescription.requirements && this.props.jobDescription.requirements.map(requirement => 
                      <li>
                        <StaticText
                          fontWeight={fontWeight.content}
                          color={fontColor.content}
                          text={requirement}
                          definedClass="content-size"
                        />
                      </li>
                    )}
                  </ul>
                </div>

                <div
                  className="row"
                  style={{ marginTop: 30, marginBottom: 120 }}
                >
                  <button
                    type="button"
                    className="apply-btn"
                    onClick={() => this.setState({ apply: true })}
                  >
                    APPLY FOR THIS JOB
                  </button>
                </div>
              </div>
              <div className="col-sm-2" />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="container-fluid space" style={{ background: '#FFFFFF' }}>
        <div className="col-md-3" />
        <div className="static-pages col-md-6">
          <form ref={this.formRef}>
            <div className="header header-size-secondary details-sub-header">
                Submit your Application:
            </div>
            <div className="header header-size-secondary details-sub-header">
              <div className="name">Resume/CV</div>
              <div>
                <input
                  type="file"
                  ref={this.fileUploaderRef}
                  id="uploadResume"
                  name="cv"
                  accept=".pdf"
                  onChange={this.uploadResume}
                  style={{ height: 0, width: 0, opacity: 0 }}
                  required
                />
                <button
                  type="button"
                  className={`attach-btn ${this.state.buttonText ? 'hide' : ''}`}
                  onClick={this.showButton}
                >
                  <span
                    className="outline-attach"
                    style={{
                        textAlign: 'center',
                        float: 'left',
                      }}
                  />

                  <div
                    onChange={this.updateState}
                    className="contain-file-name"
                  >
                    Attach Resume

                  </div>
                </button>
                <span style={{ fontWeight: 400 }}>{this.state.buttonText}</span>
              </div>
            </div>
            <div className="header header-size-secondary details-sub-header">
              <div className="name">Full name</div>
              <div className="input">
                <input
                  type="text"
                  className="value"
                  value={applicationData.name}
                  onChange={(event) => {
                    this.props.updateApplicationData({
                      key: 'name',
                      value: event.target.value,
                    });
                  }}
                  required
                />
              </div>
            </div>
            <div className="header header-size-secondary details-sub-header">
              <div className="name">Email</div>
              <div className="input">
                <input
                  type="email"
                  className="value"
                  value={applicationData.email}
                  onChange={(event) => {
                    this.props.updateApplicationData({
                      key: 'email',
                      value: event.target.value,
                    });
                  }}
                  required
                />
              </div>
            </div>
            <div className="header header-size-secondary details-sub-header">
              <div className="name">Phone</div>
              <div className="input">
                <input
                  type="text"
                  className="value"
                  value={applicationData.phone}
                  onChange={(event) => {
                    this.props.updateApplicationData({
                      key: 'phone',
                      value: event.target.value,
                    });
                  }}
                  required
                />
              </div>
            </div>
            <div className="header header-size-secondary details-sub-header">
              <div className="name">Current company</div>
              <div className="input">
                <input
                  type="text"
                  className="value"
                  value={applicationData.currentCompany}
                  onChange={(event) => {
                    this.props.updateApplicationData({
                      key: 'current_company',
                      value: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="header header-size-secondary details-sub-header">
              <div className="name">Current position</div>
              <div className="input">
                <input
                  type="text"
                  className="value"
                  value={applicationData.currentPosition}
                  onChange={(event) => {
                    this.props.updateApplicationData({
                      key: 'current_position',
                      value: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="header header-size-secondary details-sub-header">
              <div className="name">Why IdeaBoard?</div>
              <div className="textarea">
                <textarea
                  rows="4"
                  cols="50"
                  className="value"
                  value={applicationData.whyIdeaboard}
                  onChange={(event) => {
                    this.props.updateApplicationData({
                      key: 'why_ideaboard',
                      value: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="form-row row submit"
              style={{ marginTop: 30, marginBottom: 120 }}
            >
              <button
                type="button"
                className="apply-btn static-pages"
                onClick={this.onSubmit}
              >
                  APPLY NOW
              </button>
            </div>
          </form>
        </div>

        <div className="col-md-3" />
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <div>
          <div
            style={{ backgroundColor: 'white' }}
            className="static-pages space"
          >
            <div className="row ">
              <div className="details header">
                {this.props.jobDescription.title}
              </div>
            </div>
            <div
              className="row content-content-size "
              style={{
                marginTop: 20,
                color: '#5D5D5D',
                font: 600,
                paddingBottom: 30,
              }}
            >
              <span style={{ margin: 10}}>
                {this.props.jobDescription.city}
              </span>
              <span className="bullet"/>
              <span style={{ margin: 10}}>
                {this.props.jobDescription.type}
              </span>
              <span className="bullet"/>
              <span style={{ margin: 10}}>
                Full-Time
              </span>
            </div>
          </div>

          {this.renderPositionDescription()}
        </div>
      </Layout>
    );
  }
}

Openings.propTypes = {
  jobDescription: PropTypes.object,
  getPositions: PropTypes.func,
  updateApplicationData: PropTypes.func,
  uploadApplicationData: PropTypes.func,
  applicationData: PropTypes.object,
};

const mapStateToProps = ({ staticPages }) => ({
  jobDescription: staticPages.jobDescription,
  applicationData: staticPages.applicationData,
});

export default connect(
  mapStateToProps,
  { getPositions, updateApplicationData, uploadApplicationData },
)(Openings);
