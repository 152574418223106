import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Text from './text';
import {
  updateUserSubscription, getSubscriptionDetails, verifyCouponCode, removeCoupon, removeReferral, verifyReferralCode, removePaymentTextFieldHighlight,
} from '../actions/index';
import constants from '../shared/constants';
import StyleConstants from '../shared/styleConstants/styles';
import illustration1 from '../shared/images/payment/Illustration1.svg';
import illustration2 from '../shared/images/payment/Illustration2.svg';
import illustration3 from '../shared/images/payment/Illustration3.svg';
import illustration4 from '../shared/images/payment/Illustration4.svg';
import referral from '../shared/images/payment/referral.svg';
import discount from '../shared/images/common/discountIcon.svg';
import Layout from './layout';

import { sendEvent } from '../helpers/Analytics';

const cookies = new Cookies();
const Constants = constants();

class Payment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: '',
      customMonths: 2,
      numberOfMonths: 2,
      selectedPlanType: '',
      referralCode: '',
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    document.head.appendChild(script);
    if (!cookies.get('payment') || Object.keys(cookies.get('payment')).length === 0) {
      this.props.getSubscriptionDetails();
    }
    sendEvent('Payments - PlansDisplayed');
  }

  componentWillUnmount() {
    cookies.remove('payment', { path: '/', secure: Constants.cookie.secure });
  }

  renderPayableContent(text, amount, color, isHighlight = false) {
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 15, paddingRight: 15,
      }}
      >
        <Text
          color={isHighlight ? StyleConstants.textColor.primary : StyleConstants.textColor.secondary}
          fontWeight={isHighlight && StyleConstants.textWeight.semiBold}
          fontSize={isHighlight ? StyleConstants.textSize.headerSmall : StyleConstants.textSize.text}
          text={text}
        />
        <Text
          color={color}
          fontSize={isHighlight ? StyleConstants.textSize.headerSmall : StyleConstants.textSize.text}
          fontWeight={isHighlight ? StyleConstants.textWeight.bold : StyleConstants.textWeight.semiBold}
          text={amount}
        />
      </div>
    );
  }

  illustrationView(url, name, text, isActive = false) {
    return (
      <div className={`item ${isActive && 'active'}`}>
        <img src={url} alt={name} style={{ height: global.innerHeight - 70 }} />
        <div style={{
          top: '25%', right: 30, textAlign: 'left', width: '60%', position: 'absolute', textShadow: 0,
        }}
        >
          <Text text={text} fontSize={StyleConstants.textSize.header} fontWeight={StyleConstants.textWeight.semiBold} color="white" />
        </div>
      </div>
    );
  }

  renderIllustration() {
    const illustrationTexts = [
      'Get detailed reports to improve time management and weaknesses!',
      'Access all 1,00,000+ Questions, 1000+ Topics!',
      'Personalised Challenges to improve your speed!',
      'Test yourself with all the High Quality Mock Test!',
    ];
    return (
      <div>
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          <ol
            className="carousel-indicators"
            style={{
              marginLeft: '25%', display: 'flex', justifyContent: 'space-around', width: 100,
            }}
          >
            <li data-target="#myCarousel" data-slide-to="0" className="active" />
            <li data-target="#myCarousel" data-slide-to="1" />
            <li data-target="#myCarousel" data-slide-to="2" />
            <li data-target="#myCarousel" data-slide-to="3" />
          </ol>
          <div className="carousel-inner">
            {this.illustrationView(illustration1, 'Time Management', illustrationTexts[0], true)}
            {this.illustrationView(illustration2, 'Questions', illustrationTexts[1])}
            {this.illustrationView(illustration3, 'Personalized Challenges', illustrationTexts[2])}
            {this.illustrationView(illustration4, 'Mock Test', illustrationTexts[3])}
          </div>
        </div>
      </div>
    );
  }

  renderDetailedBill(text, value, color) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Text text={text} />
        <Text text={value} color={color} />
      </div>
    );
  }

  renderDiscountCard(value, fieldName, placeholder, icon, shouldHighlight, isValid, functionName, text = '', showSpinner = false) {
    const backgroundColor = shouldHighlight && isValid ? '#D9EDE3' : 'white';
    const textColor = isValid ? '#00AD35' : '#FF4419';
    const borderColor = shouldHighlight ? `${isValid ? '#D9EDE3' : '#FF4419'}` : '';
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', height: StyleConstants.fieldHeight.normal, marginTop: 20,
      }}
      >
        <div
          className="simpleCardView"
          style={{
            display: 'flex', width: 480, backgroundColor, alignItems: 'center', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20, borderColor: borderColor && borderColor,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img alt={fieldName} src={icon} width={20} height={20} />
            <input
              type="text"
              maxLength="6"
              placeholder={placeholder}
              style={{
                borderRadius: 0, borderLeft: 0, borderRight: 0, marginTop: 0, width: 300, backgroundColor, paddingLeft: 10,
              }}
              value={value}
              disabled={isValid}
              onChange={(event) => {
                if (!shouldHighlight || !isValid) {
                  this.setState({ [fieldName]: event.target.value.toUpperCase() });
                  this.props.removePaymentTextFieldHighlight(fieldName === 'couponCode' ? 'highlightCouponCard' : 'highlightReferralCard');
                }
              }}
            />
          </div>
          {shouldHighlight && text && <Text text={text} color={textColor} fontSize="10px" />}
        </div>
        <div
          className={`Button ${!isValid && 'Primary-Button'}`}
          style={{ width: 160, height: StyleConstants.fieldHeight.normal, opacity: value.length === 0 && 0.5 }}
          onClick={() => value.length > 0 && this.props[functionName](value)}
        >
          {showSpinner && <div className="loadingSpinner" style={{ width: 30, height: 30 }} />}
          {!showSpinner && `${isValid ? 'Remove' : 'Apply'}`}
        </div>
      </div>
    );
  }

  render() {
    const user = cookies.get('user');
    const token = cookies.get('token');
    const { couponCode, referralCode } = this.state;
    const {
      highlightCouponCard, highlightReferralCard, couponDiscount, showCouponSpinner, showReferralSpinner, isValidCoupon, isValidReferral,
    } = this.props;
    const payment = cookies.get('payment') && Object.keys(cookies.get('payment')).length > 0 ? cookies.get('payment') : this.props.payment;

    if (Object.keys(payment).length === 0) {
      return (
        <Layout hideFooter>
          <div />
        </Layout>
      );
    }

    const actualPrice = parseInt(payment.price);
    const offerPrice = parseInt(payment.offer_price);
    const couponPrice = parseInt((offerPrice * (100 - couponDiscount)) / 100);
    const referralDiscount = isValidReferral ? payment.referralReward : 0;
    const finalPrice = couponPrice - referralDiscount;

    if (payment.has_valid_subscription) {
      return (
        <Layout hideFooter>
          <div style={{
            display: 'flex', padding: 0, margin: 0, width: '100%',
          }}
          >
            {this.renderIllustration()}
            <div style={{
              height: global.innerHeight - 70, paddingLeft: 20, paddingRight: 20, display: 'flex', justifyContent: 'center', flexGrow: 1, paddingTop: 50,
            }}
            >
              <div className="cardView" style={{ width: 700, height: 'fit-content' }}>
                <Text text={`Valid till ${payment.validity}.`} fontWeight={StyleConstants.textWeight.semiBold} />
                <div style={{ marginTop: 5 }} />
                <Text text={`You have subscribed for ${payment.currency} ${finalPrice} plan.`} fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.header} color={StyleConstants.textColor.primary} />
              </div>
            </div>
          </div>
        </Layout>
      );
    }

    const options = {
      description: payment.headerDescription,
      image: payment.logo,
      amount: finalPrice * 100,
      currency: 'INR',
      key: Constants.razorPayPublicKey,
      name: payment.name,
      theme: {
        color: payment.theme,
      },
      prefill: {
        email: user.email,
        contact: user.phone,
        name: user.name,
      },
      notes: {
        token_1: token.substring(0, token.length / 2),
        token_2: token.substring(token.length / 2),
        number_of_months: this.state.numberOfMonths,
      },
      handler: (response) => {
        sendEvent('Payments - PaymentSuccessfulCallback', { ...response, coupon: highlightCouponCard ? couponCode : '', referral_code: highlightReferralCard ? referralCode : '' });
        this.props.updateUserSubscription({ ...response, coupon: highlightCouponCard ? couponCode : '', referral_code: highlightReferralCard ? referralCode : '' });
      },
    };
    const greenColor = '#00AD35';
    return (
      <Layout hideFooter>
        <div style={{
          display: 'flex', padding: 0, margin: 0, width: '100%',
        }}
        >
          {this.renderIllustration()}
          <div style={{
            height: global.innerHeight - 70, paddingLeft: 20, paddingRight: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
          }}
          >
            <div style={{ width: 700 }}>
              <div className="cardView" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Text text="PREMIUM" color={StyleConstants.textColor.primary} fontSize={StyleConstants.textSize.headerSmall} fontWeight={StyleConstants.textWeight.bold} />
                    <Text text={`Till ${payment.validity}`} />
                  </div>
                  <Text text={`${payment.currency} ${actualPrice.toLocaleString()}`} color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.semiBold} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                  <div>
                    <Text text="Introductory Offer" fontSize={StyleConstants.textSize.headerSmall} fontWeight={StyleConstants.textWeight.bold} />
                    <Text text="Limited Period*" />
                  </div>
                  <Text text={`save ${payment.currency} ${(actualPrice - offerPrice).toLocaleString()}`} fontSize={StyleConstants.textSize.headerSmall} fontWeight={StyleConstants.textWeight.semiBold} color={greenColor} />
                </div>
                {this.renderDiscountCard(couponCode, 'couponCode', 'Enter Coupon Code', discount, highlightCouponCard, isValidCoupon, isValidCoupon ? 'removeCoupon' : 'verifyCouponCode', isValidCoupon ? 'Coupon Applied' : 'Invalid Coupon Code', showCouponSpinner)}
                {this.renderDiscountCard(referralCode, 'referralCode', 'Enter Referral Code', referral, highlightReferralCard, isValidReferral, isValidReferral ? 'removeReferral' : 'verifyReferralCode', isValidReferral ? 'Referral Applied' : 'Invalid Referral Code', showReferralSpinner)}
                <div style={{
                  marginTop: 10, marginBottom: 10, paddingTop: 10, paddingBottom: 10, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd',
                }}
                >
                  <Text text="Detailed Bill" color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.headerSmall} />
                  {this.renderDetailedBill('Premium Subscription', `${payment.currency} ${actualPrice.toLocaleString()}`, StyleConstants.textColor.secondary)}
                  {this.renderDetailedBill(`Introductory Offer (${parseInt(((actualPrice - offerPrice) * 100) / actualPrice)}% off)`, `${payment.currency} ${(actualPrice - offerPrice).toLocaleString()}`, greenColor)}
                  {this.renderDetailedBill('Coupon', `${payment.currency} ${(offerPrice - couponPrice).toLocaleString()}`, offerPrice === couponPrice ? StyleConstants.textColor.inactive : greenColor)}
                  {this.renderDetailedBill('Referral', `${payment.currency} ${referralDiscount.toLocaleString()}`, referralDiscount > 0 ? greenColor : StyleConstants.textColor.inactive)}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Text text="Total" color={StyleConstants.textColor.primary} fontSize={StyleConstants.textSize.headerSmall} fontWeight={StyleConstants.textWeight.bold} />
                    <Text text="(Inclusive of GST)" />
                  </div>
                  <Text text={`${payment.currency} ${finalPrice.toLocaleString()}`} color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.headerSmall} />
                </div>
              </div>
              <div
                className="simpleCardView"
                style={{
                  backgroundColor: '#D9EDE3', borderColor: greenColor, display: 'flex', width: '100%', justifyContent: 'center', padding: 10, marginTop: 20,
                }}
              >
                <Text text={`You have saved ${payment.currency} ${(actualPrice - finalPrice).toLocaleString()} `} color={greenColor} />
              </div>
              <div
                id="Payment-Button"
                className="Primary-Button Button"
                style={{ width: '100%', marginTop: 20, height: StyleConstants.fieldHeight.big }}
                onClick={() => {
                  const rzp1 = new window.Razorpay(options);
                  sendEvent('Payments - PayNowTapped', {
                    offerPrice, actualPrice, couponDiscount, finalPrice,
                  });
                  rzp1.open();
                }}
              >
Pay
                {payment.currency}
                {finalPrice.toLocaleString()}
              </div>
              <Link id="Pay" to="/terms" style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}> Terms & Conditions </Link>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

Payment.propTypes = {
  removePaymentTextFieldHighlight: PropTypes.func.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  removeReferral: PropTypes.func.isRequired,
  verifyCouponCode: PropTypes.func.isRequired,
  verifyReferralCode: PropTypes.func.isRequired,
  getSubscriptionDetails: PropTypes.func.isRequired,
  updateUserSubscription: PropTypes.func.isRequired,
  payment: PropTypes.object,
  couponDiscount: PropTypes.number,
  showCouponSpinner: PropTypes.bool,
  showReferralSpinner: PropTypes.bool,
  highlightCouponCard: PropTypes.bool,
  highlightReferralCard: PropTypes.bool,
  isValidCoupon: PropTypes.bool,
  isValidReferral: PropTypes.bool,
};

Payment.defaultProps = {
  couponDiscount: 0,
  isValidCoupon: false,
  isValidReferral: false,
  highlightCouponCard: false,
  highlightReferralCard: false,
  showCouponSpinner: false,
  showReferralSpinner: false,
};

const mapStateToProps = ({ global }) => ({
  payment: global.payment,
  couponDiscount: global.couponDiscount,
  isValidReferral: global.isValidReferral,
  isValidCoupon: global.isValidCoupon,
  highlightCouponCard: global.highlightCouponCard,
  highlightReferralCard: global.highlightReferralCard,
  showCouponSpinner: global.showCouponSpinner,
  showReferralSpinner: global.showReferralSpinner,
});

export default connect(mapStateToProps, {
  removeCoupon, updateUserSubscription, getSubscriptionDetails, verifyCouponCode, removeReferral, verifyReferralCode, removePaymentTextFieldHighlight,
})(Payment);
