import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Text from '../text';
import StyleConstants from '../../shared/styleConstants/styles';
import { TOAST_MESSAGE_TYPES } from '../../shared/constants/fieldTypes';
import { showToast } from '../../actions/index';

class RatingComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackRatingValue: -1,
      feedbackMessage: '',
    };
  }

  renderRatingElement(index, selected) {
    return (
      <img
        id={`Feedback-Rating-${index}`}
        alt={`feedback_icon_${index}`}
        role="presentation"
        height={30}
        width={30}
        style={{ cursor: 'pointer', marginRight: 10 }}
        onClick={() => {
          this.setState({ feedbackRatingValue: index });
          this.props.onRatingTapped(index + 1);
        }}
        src={require(`../../shared/images/ratingEmojis/${index}${selected ? '_selected' : ''}.svg`)}
      />
    );
  }

  render() {
    const array = [0, 1, 2, 3, 4];
    const { feedbackRatingValue } = this.state;
    const { heading, name, style, isModal } = this.props;
    return (
      <div style={{
        ...style, backgroundColor: StyleConstants.color.white, border: !isModal && `2px solid ${StyleConstants.color.border}`, padding: !isModal && 20, borderRadius: 4,
      }}
      >
        <Text
          color={StyleConstants.textColor.primary}
          fontSize={StyleConstants.textSize.headerBig}
          fontWeight={StyleConstants.textWeight.bold}
          text={heading}
        />
        <div style={{ marginTop: 20 }}>
          <Text
            color={StyleConstants.textColor.secondary}
            fontSize={StyleConstants.textSize.text}
            text={`Date: ${new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }).replace(/ /g, ' ')}`}
          />
        </div>
        <div className="flex" style={{ margin: '20px 0', textAlign: 'center', flex: 1, display: 'flex', justifyContent: 'space-evenly' }}>
          {array.map((index) => this.renderRatingElement(index, index === this.state.feedbackRatingValue))}
        </div>
        <textarea
          cols="44"
          rows="7"
          style={{ width: '100%', margin: '20px 0' }}
          value={this.state.feedbackMessage}
          onChange={(e) => this.setState({ feedbackMessage: e.target.value })}
          placeholder="Please share your feeback..."
        />
        <div className="modalButtonView">
          <div
            id="Send-Feeback-Button"
            role="presentation"
            style={{ width: 100, opacity: feedbackRatingValue < 0 && 0.5 }}
            className="Button Primary-Button"
            onClick={() => (feedbackRatingValue < 0
              ? this.props.showToast('Please select a Rating.', TOAST_MESSAGE_TYPES.ERROR)
              : this.props.onSubmit(this.state.feedbackRatingValue + 1, this.state.feedbackMessage))}
          >
            Send
          </div>
        </div>
      </div>
    );
  }
}

RatingComment.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onRatingTapped: PropTypes.func,
  showToast: PropTypes.func.isRequired,
  style: PropTypes.object,
};

RatingComment.defaultProps = {
  heading: '',
  name: '',
  onRatingTapped: null,
  isModal: false,
  style: {},
};

export default connect(null, { showToast })(RatingComment);
