import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Element, scroller } from 'react-scroll';
import Text from '../text';
import StyleConstants from '../../shared/styleConstants/styles';
import { updateCurrentQuestionIndex } from '../../actions/index';

class TestSideBar extends Component {
  state = {
    screenHeight: 0,
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight })
    scroller.scrollTo(`question_ref_${this.props.currentQuestion.id}`, {
      offset: -15,
      containerId: "questionListView"
    });
  }

  renderQuestions(question, index) {
    if (!question.isVisible) {
      return;
    }
    return (
      <Element name={`question_ref_${question.id}`} key={`question_list_${question.id}`}>
        <div
          id={`Question-SideBar-${index}`}
          className="d-flex Test_Sidebar_Padding"
          style={{ border: "1px solid rgba(221, 221, 221, 0.5)", paddingTop: 10, paddingBottom: 10, backgroundColor: question.backgroundColor, cursor: 'pointer' }}
          onClick={() => this.props.updateCurrentQuestionIndex(index - 1, true)}
        >
          <Text color={question.color} fontWeight={question.fontWeight} fontSize={StyleConstants.textSize.small} text={`${index}.`} />
          <div className="ml-1"> <Text color={question.color} fontWeight={question.fontWeight} fontSize={StyleConstants.textSize.small} text={question.question_text} /> </div>
        </div>
      </Element>
    );
  }

  render() {
    const { questionSideBarData } = this.props;
    return (
      <div>
        <div className="Test_Sidebar_Padding">
          <Text
            fontWeight={StyleConstants.textWeight.bold}
            color={StyleConstants.textColor.primary}
            fontSize={StyleConstants.textSize.headerSmall}
            text={"Questions"} />
        </div>
          <Element id="questionListView" style={{
            paddingTop: 20,
            position: 'relative',
            height: this.state.screenHeight - 140,
            overflowX: 'hidden'
          }}>
            {questionSideBarData.length > 0 && questionSideBarData.map((question, index) => this.renderQuestions(question, index + 1))}
          </Element>
      </div>
    )
  }
}

TestSideBar.propTypes = {
  updateCurrentQuestionIndex: PropTypes.func.isRequired,
  currentQuestion: PropTypes.object.isRequired,
  testInfo: PropTypes.object,
  questionSideBarData: PropTypes.array
}

TestSideBar.defaultProps = {
  testInfo: {},
  questionSideBarData: []
}

const mapStateToProps = ({ test }) => ({
  testInfo: test.testInfo,
  questionSideBarData: test.questionSideBarData,
  currentQuestion: test.currentQuestion
});

export default connect(mapStateToProps, {updateCurrentQuestionIndex})(TestSideBar);
