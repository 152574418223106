import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import Text from '../text';
import ModalView from '../modal';
import { sendEvent } from '../../helpers/Analytics';
import { isMobileDevice, getAccountsDomain } from '../../helpers/Utils';
import {
  logout, showToast, sendUserFeedback, showTestSessioFinishModal, showTrainSessioFinishModal, updateNotificationStatus, getNotifications,
} from '../../actions/index';
import { TOAST_MESSAGE_TYPES } from '../../shared/constants/fieldTypes';
import StyleConstants from '../../shared/styleConstants/styles';
import downArrow from '../../shared/images/common/down.svg';
import upArrow from '../../shared/images/common/up.svg';
import profile from '../../shared/images/header/profile.svg';
import notificationSelected from '../../shared/images/header/notificationSelected.svg';
import notification from '../../shared/images/header/notification.svg';
import feedbackSelected from '../../shared/images/header/feedbackStartSelectedIcon.svg';
import feedback from '../../shared/images/header/feedbackStarIcon.svg';
import Constants from '../../shared/constants';

const history = createBrowserHistory({
  forceRefresh: true,
});
const supportContact = Constants().contact;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDropdown: false,
      showFeedbackModal: false,
      feedbackRatingValue: -1,
      feedbackMessage: '',
    };
    this.onCloseModal = this.onCloseModal.bind(this);
    this.showComponents = this.showComponents.bind(this);
    this.closeComponents = this.closeComponents.bind(this);
    this.renderSolutionBoardHeader = this.renderSolutionBoardHeader.bind(this);
  }

  renderComponent(component, showBottomBorder) {
    return (
      <div role="presentation" id={`Header-components-${component.name}`} style={{ cursor: 'pointer', padding: 20, borderBottom: showBottomBorder && `1px solid ${StyleConstants.color.lightBorder}` }} onClick={() => (component.propFunction ? this.props[component.propFunction]() : component.stateName && this.setState({ [component.stateName]: component.stateValue }))} key={`Component_${component.name}`}>
        <Text text={component.name} color={StyleConstants.textColor.primary} />
      </div>
    );
  }

  renderHeaderComponents(component, showBottomBorder = false) {
    if (component.replace) {
      return (
        <div role="presentation" id={component.name} style={{ cursor: 'pointer', padding: 20, borderBottom: showBottomBorder && `1px solid ${StyleConstants.color.lightBorder}` }} onClick={() => { window.location.replace(component.replace); }}>
          <Text text={component.name} color={StyleConstants.textColor.primary} />
        </div>
      );
    }
    if (!component.link) {
      return this.renderComponent(component, showBottomBorder);
    }
    return (
      <Link to={component.link} key={`headerComponent${component.name}`} id={`Header-Drop-Down-${component.name}`}>
        {this.renderComponent(component, showBottomBorder)}
      </Link>
    );
  }

  showComponents(event, eventName) {
    event.preventDefault();
    this.setState({ displayDropdown: eventName }, () => {
      document.addEventListener('click', this.closeComponents);
    });
  }

  closeComponents(event) {
    if (event && (event.target.className !== 'profileDropdown' || event.target.className !== 'notificationDropdown')) {
      this.setState({ displayDropdown: false }, () => {
        document.removeEventListener('click', this.closeComponents);
      });
    }
  }

  onCloseModal(submitFeedback = false) {
    if (submitFeedback) {
      this.props.sendUserFeedback(this.state.feedbackRatingValue + 1, this.state.feedbackMessage);
    }
    this.setState({ showFeedbackModal: false, feedbackRatingValue: -1, feedbackMessage: '' });
  }

  renderRatingElement(index, selected) {
    return (
      <img
        id={`Feedback-Rating-Index-${index}`}
        role="presentation"
        alt="NEET_JEE_feedback_icon"
        height={25}
        width={25}
        style={{ paddingRight: 5, cursor: 'pointer' }}
        onClick={() => {
          this.setState({ feedbackRatingValue: index });
          sendEvent('Profile - FeedbackRatingTapped', { feedbackRatingValue: index + 1 });
        }}
        src={selected ? feedbackSelected : feedback}
      />
    );
  }

  renderModal() {
    const array = [0, 1, 2, 3, 4];
    const { feedbackRatingValue } = this.state;
    sendEvent('Profile - FeedbackTapped');
    return (
      <div>
        <Text text="How is your experience with IdeaBoard?" />
        <div className="flex" style={{ paddingTop: 8 }}>
          {array.map((index) => this.renderRatingElement(index, index <= this.state.feedbackRatingValue))}
        </div>
        <textarea
          cols="44"
          rows="4"
          style={{ width: '100%', marginTop: 10 }}
          value={this.state.feedbackMessage}
          onChange={(e) => this.setState({ feedbackMessage: e.target.value })}
          placeholder="Please share your feeback..."
        />
        <div className="modalButtonView">
          <div
            id="Send-Feeback-Button"
            role="presentation"
            style={{ width: 100, opacity: feedbackRatingValue < 0 && 0.5 }}
            className="Button Primary-Button"
            onClick={() => (feedbackRatingValue < 0 ? this.props.showToast('Please select a Rating.', TOAST_MESSAGE_TYPES.ERROR) : this.onCloseModal(true))}
          >
          Send
          </div>
        </div>
      </div>
    );
  }

  renderHeaderLogo() {
    const whiteListedPaths = {
      personalised_assignment: 'showTrainSessioFinishModal',
      test: 'showTestSessioFinishModal',
    };
    const { isTestReview } = this.props;
    let isWhiteListedPath = false;
    let functionName = '';
    Object.keys(whiteListedPaths).forEach((path) => {
      if (window.location.pathname.includes(`/${path}/`)) {
        isWhiteListedPath = true;
        functionName = whiteListedPaths[path];
      }
    });
    return (
      <div
        id="Header-Logo"
        role="presentation"
        style={{
          height: 60, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer',
        }}
        onClick={() => (isWhiteListedPath && !isTestReview && functionName && functionName !== '' ? this.props[functionName]() : history.push(Constants().landingUrl))}
      >
        <div style={{ fontWeight: 800 }}>
          <span style={{ fontSize: 24 }}>
            <span style={{ color: '#FF5F5F' }}>IDEA</span>
            <span style={{ color: '#454545' }}>BOARD</span>
          </span>
        </div>
      </div>
    );
  }

  notificationView(notification, showBorder) {
    const backgroundColor = notification.is_read ? 'white' : '#F0F5FF';
    return (
      <div
        id={`Notification-Item-${notification.id}`}
        role="presentation"
        onClick={() => {
          this.props.updateNotificationStatus(notification.id);
          if (notification.url) {
            const urlPath = notification.url.split('/');
            if (notification.type === 'DetailedTestReportNotification') {
              history.push(`/${urlPath[urlPath.length - 2]}/${urlPath[urlPath.length - 1]}`);
            } else {
              history.push(`/${urlPath[urlPath.length - 1]}`);
            }
          }
        }}
        style={{
          padding: 20, borderBottom: showBorder && `1px solid ${StyleConstants.color.lightBorder}`, borderRadius: !showBorder && '0px 0px 4px 4px', backgroundColor,
        }}
      >
        <div style={{ display: 'flex' }}>
          <Text text={notification.title} color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.bold} />
          <div style={{ paddingLeft: 20 }} />
          <Text text={`${notification.time_left} ago`} fontSize={StyleConstants.textSize.small} />
        </div>
        <Text text={notification.message} fontSize={StyleConstants.textSize.small} />
      </div>
    );
  }

  renderNotificationList(newNotificationCount) {
    const { notifications } = this.props;
    const notificationStyle = {
      position: 'fixed', zIndex: 0, borderRadius: '0px 0px 4px 4px', width: '30%', right: 100, maxHeight: 400, padding: 0, borderTop: 0,
    };
    if (notifications.length === 0) {
      return (
        <div
          className="cardView HorizontalScroll"
          style={{
            ...notificationStyle, display: 'flex', justifyContent: 'center', padding: 20,
          }}
        >
          No Notifications
        </div>
      );
    }
    return (
      <div className="cardView HorizontalScroll" style={notificationStyle}>
        {newNotificationCount > 0 && (
        <div style={{ padding: '5px 5px 5px 20px', borderBottom: `1px solid ${StyleConstants.color.lightBorder}` }}>
          <Text text={`${newNotificationCount} new notifications`} fontWeight={StyleConstants.textWeight.semiBold} color={StyleConstants.textColor.primary} />
        </div>
        )}
        {notifications.map((notification, index) => this.notificationView(notification, notifications.length !== index + 1))}
      </div>
    );
  }

  displaySolutionBoardHeaderItems(userProfile) {
    if (Object.keys(userProfile).length === 0) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a href={`${getAccountsDomain()}/sign_in`}><div className="Tertiary-Button mr-4" style={{ width: 140, color: '#01778a' }}> Log In </div></a>
        </div>
      );
    }
    return (
      <div
        id="Show-DropDown-Header"
        role="presentation"
        style={{
          display: 'flex', cursor: 'pointer', alignItems: 'center', padding: '0% 5%',
        }}
        onClick={(event) => this.showComponents(event, 'showProfile')}
      >
        <div className="Circle" style={{ border: `2px solid ${StyleConstants.color.white}` }}>
          <Text
            text={userProfile.name ? userProfile.name.charAt(0) : ''}
            fontWeight={StyleConstants.textWeight.bold}
            color={StyleConstants.color.white}
          />
        </div>
        <img alt="NEET_JEE_arrow_icon" src="/images/common/double-arrow.svg" height={20} width={20} style={{ marginLeft: 10 }} />
      </div>
    );
  }

  displayHeaderItems(userProfile, displayDropdown) {
    if (Object.keys(userProfile).length === 0) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a style={{ marginRight: 50 }} onClick={() => { sendEvent('Header - SupportContactClicked'); }} href={`tel:${supportContact}`}><div style={{ color: '#454545', fontSize: 18 }}>{`${supportContact}`}</div></a>
          <a href={`${getAccountsDomain()}/sign_in`} onClick={() => { sendEvent('Header - LoginButtonClicked'); }}><div className="Tertiary-Button mr-4" style={{ width: 140, color: '#01778a' }}> Log In </div></a>
          <a href={`${getAccountsDomain()}/sign_up?info=HeaderSignUp`} onClick={() => { sendEvent('Header - SignUpButtonClicked'); }}><div className="home-button home-primary-button" style={{ width: 140, marginRight: 20 }}>Join for Free</div></a>
        </div>
      );
    }
    return (
      <div className="flexcontainer" style={{ width: '100%', justifyContent: 'flex-start' }}>
        {!userProfile.has_only_test_board_access && this.renderNotification()}
        <div id="Show-DropDown-Header" style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} onClick={(event) => this.showComponents(event, 'showProfile')} role="presentation">
          <img alt="NEET_JEE_Profile_Icon" src={profile} height={30} width={30} style={{ marginRight: 10, marginLeft: 15 }} />
          <Text text={userProfile.name || 'User'} />
          <img alt="NEET_JEE_Arrow_Icon" src={displayDropdown === 'showProfile' ? upArrow : downArrow} height={20} width={20} style={{ marginLeft: 10 }} />
        </div>
      </div>
    );
  }

  renderNotification() {
    const { notifications } = this.props;
    const { displayDropdown } = this.state;
    let newNotificationCount = 0;
    notifications.forEach((notification) => {
      if (!notification.is_read) {
        newNotificationCount += 1;
      }
    });
    return (
      <div
        role="presentation"
        id="Show-Notification-Button"
        className="pl-3 pr-3"
        style={{
          padding: 5, borderRight: '1px solid #eee', cursor: 'pointer',
        }}
        onClick={(event) => {
          this.showComponents(event, 'showNotifications');
          this.props.getNotifications();
        }}
      >
        <img alt="NEET_JEE_Notification_Icon" src={newNotificationCount > 0 ? notificationSelected : notification} height={20} width={20} />
        {displayDropdown === 'showNotifications' && (
        <div
          style={{
            width: global.innerWidth, height: global.innerHeight, position: 'fixed', right: 0, top: 70, zIndex: 1,
          }}
          className="hideBackground"
        >
          {this.renderNotificationList(newNotificationCount)}
        </div>
        )}
      </div>
    );
  }

  renderMobileHeader() {
    return (
      <div
        className="Header"
        style={{
          position: 'fixed', top: 0, display: 'flex', justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img style={{ marginLeft: 20, height: 40, width: 40 }} src="/images/static_pages/ib_logo.png" alt="NEET JEE Ideaboard" />
          <a style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { sendEvent('Header - SupportContactTapped'); }} href={`tel:${supportContact}`}>
            <img
              style={{
                width: 30, marginLeft: 15
              }}
              alt="NEET JEE phone icon"
              src="/images/landing_institute/phone.svg"
            />

          </a>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }}>
          <a href={`${getAccountsDomain()}/sign_in`} onClick={() => { sendEvent('Header - LoginButtonClicked'); }}><div className="Tertiary-Button mr-4" style={{ width: 60, color: '#01778a' }}> Log In </div></a>
          <a href={`${getAccountsDomain()}/sign_up?info=HeaderSignUp`} onClick={() => { sendEvent('Header - SignUpButtonClicked'); }}><div className="home-button home-primary-button mr-4" style={{ width: 100 }}>Join for Free</div></a>
        </div>
      </div>
    );
  }

  renderDropDownList() {
    const headerComponents = [
      { name: 'My Account', link: '/profile' },
      { name: 'Feedback', stateName: 'showFeedbackModal', stateValue: true },
      { name: 'Refer and Earn', link: '/referral_code' },
      { name: 'Logout', link: '/', propFunction: 'logout' },
    ];
    const { auth } = this.props;
    if (auth.userTypes.length > 1) {
      headerComponents.push({ name: 'Switch Membership', replace: `${Constants().accountsUrl}/switch_membership` });
    }
    return (
      <div
        style={{
          width: global.innerWidth, height: global.innerHeight, position: 'fixed', right: 0, top: 70, zIndex: 1,
        }}
        className="hideBackground"
      >
        <div
          className="profileDropdown"
          style={{
            position: 'fixed', zIndex: 1, backgroundColor: 'white', borderRadius: '0px 0px 4px 4px', width: 260, right: '5%',
          }}
        >
          {headerComponents.map((component, index) => this.renderHeaderComponents(component, ((index + 1) < headerComponents.length)))}
        </div>
      </div>
    );
  }

  renderSolutionBoardHeader() {
    const { userProfile } = this.props;
    const { displayDropdown } = this.state;
    return (
      <div className="Header" style={{ backgroundColor: StyleConstants.solutionBoard.primary, display: 'flex', justifyContent: 'space-between' }}>
        <div style={{
          display: 'flex', alignItems: 'center', backgroundColor: StyleConstants.solutionBoard.headerSecondaryColor, height: '100%', padding: '0% 5%',
        }}
        >
          <div style={{ cursor: 'default' }}>
            <Text
              text="Solutions"
              color={StyleConstants.solutionBoard.headerPrimaryColor}
              fontSize="16px"
              fontWeight={StyleConstants.textWeight.bold}
            />
            <Text
              text="Board"
              color={StyleConstants.color.white}
              fontSize="12px"
            />
          </div>
        </div>
        {this.displaySolutionBoardHeaderItems(userProfile, displayDropdown)}
        {displayDropdown === 'showProfile' && this.renderDropDownList()}
      </div>
    );
  }

  render() {
    const { userProfile, isSolutionBoard } = this.props;
    const { displayDropdown, showFeedbackModal } = this.state;
    const header = 'Feedback';
    const isMobile = isMobileDevice();
    if (isSolutionBoard) {
      return this.renderSolutionBoardHeader();
    }
    if (isMobile) {
      return this.renderMobileHeader();
    }
    return (
      <div>
        <ModalView isModalVisible={showFeedbackModal} onClose={this.onCloseModal} center header={header} maxWidth={500}>
          {showFeedbackModal && this.renderModal()}
        </ModalView>
        <div className="Header" style={{ display: 'flex' }}>
          <div className="headerLeft" style={{ display: 'flex', alignItems: 'center', paddingLeft: '5%' }}>
            {this.renderHeaderLogo()}
          </div>
          <div style={{ border: Object.keys(userProfile).length === 0 && 0, paddingRight: '5%' }}>
            {this.displayHeaderItems(userProfile, displayDropdown)}
          </div>
        </div>
        {displayDropdown === 'showProfile' && this.renderDropDownList()}
      </div>
    );
  }
}

Header.propTypes = {
  updateNotificationStatus: PropTypes.func.isRequired,
  sendUserFeedback: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  showTestSessioFinishModal: PropTypes.func,
  showTrainSessioFinishModal: PropTypes.func,
  auth: PropTypes.object.isRequired,
  getNotifications: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  notifications: PropTypes.array,
  isTestReview: PropTypes.bool,
  isSolutionBoard: PropTypes.bool,
};

Header.defaultProps = {
  userProfile: {},
  notifications: [],
  isTestReview: false,
  isSolutionBoard: false,
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps, {
  logout, showToast, sendUserFeedback, showTrainSessioFinishModal, showTestSessioFinishModal, updateNotificationStatus, getNotifications,
})(Header);
