import Cookies from 'universal-cookie';
import { createBrowserHistory } from 'history';
import Api from '../helpers/Api';
import constants from '../shared/constants';
import { encrypt } from '../helpers/CryptoHelper';
import { sendEvent } from '../helpers/Analytics';

import { showToast } from './global';
import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';

const history = createBrowserHistory({
  forceRefresh: true,
});
const cookies = new Cookies();
const Constants = constants();

export const CHECK_LOGIN = 'Auth/CHECK_LOGIN';
export const USER_PAYMENT = 'Auth/USER_PAYMENT';
export const USER_REGISTRY = 'Auth/USER_REGISTRY';
export const UPDATE_AUTH = 'Auth/UPDATE_AUTH';

export const updateUserInfo = (name, course) => async (dispatch) => {
  const payload = { name, course };
  const response = await Api({
    method: 'post',
    url: '/users/update_profile',
    data: { secret: encrypt(payload), is_onboarding: true },
  });

  if (response.success) {
    const { user } = response.auth;
    sendEvent('Onboarding - Completed', { user });
    await cookies.set('user', user, { secure: Constants.cookie.secure, domain: Constants.cookie.domainRegex });
    history.push('/');
  } else {
    dispatch(showToast(response.message, TOAST_MESSAGE_TYPES.ERROR));
  }
};

export const getUserTypes = () => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: '/authentications/get_user_types',
  });
  if (response.success) {
    if (response.user_types.length) {
      dispatch({
        type: UPDATE_AUTH,
        payload: { userTypes: response.user_types },
      });
    }
  }
};


export const login = (phone = '', otp = '', googleResponse = '') => async (dispatch) => {
  return;
  const payload = {};
  if (googleResponse !== '') {
    payload.type = 'gmail';
    payload.payload = googleResponse;
  } else {
    payload.phone_number = phone;
    if (otp !== '') {
      payload.otp = otp.join('');
    }
  }
  const response = await Api({
    method: 'post',
    url: '/mobile_login',
    data: { secret: encrypt(payload) },
  });
  if (response.isLoggedIn) {
    await cookies.set('token', response.token, { path: '/', secure: Constants.cookie.secure });
    await cookies.set('user', response.user, { path: '/', secure: Constants.cookie.secure });
    dispatch({
      type: CHECK_LOGIN,
      user: response.user,
    });
    if (response.is_new_user) {
      sendEvent('Authentication - AccountCreated', { user: response.user });
    } else {
      sendEvent('Authentication - LoggedIn', { user: response.user });
    }
    if (!response.user.name || response.user.name === '') {
      history.push('/user_registration');
    } else {
      history.push('/');
    }
  } else {
    if (!response.success) {
      sendEvent('Authentication - InvalidOTPEntered', { message: response.message });
    } else if (otp === '') {
      sendEvent('Authentication - SmsOTPReceived', { phone });
    } else {
      sendEvent('Authentication - ValidOTPEntered', { phone });
    }
    dispatch(showToast(response.message, response.success ? TOAST_MESSAGE_TYPES.SUCCESS : TOAST_MESSAGE_TYPES.ERROR));
  }
};

export const updateUserSubscription = (data) => async () => {
  const response = await Api({
    method: 'post',
    url: '/subscriptions/create_mobile_subscription',
    data,
  });
  if (response.success) {
    sendEvent('Payments - PaymentSuccessfulCallback', { isServerCallback: true });
    cookies.set('paymentSuccessful', response.success, { path: '/', secure: Constants.cookie.secure });
    cookies.remove('payment', { path: '/', secure: Constants.cookie.secure });
    history.push('/');
  }
};

export const logout = () => async () => {
  sendEvent('Authentication - LoggedOut');
  Object.keys(cookies.getAll()).forEach((cookieName) => {
    cookies.remove(cookieName, { secure: Constants.cookie.secure, domain: Constants.cookie.domainRegex });
    cookies.remove(cookieName, { secure: Constants.cookie.secure, path: '/' });
  });
  history.push('/');
};
