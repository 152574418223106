module.exports = {
  sentryConnectionUri: 'https://c9bcdb97068e4549a8242beb1355020c@sentry.io/283114',
  amplitudeApiKey: '94abffb9b66817fea16d58681bb4f459',
  google_analytics_key: 'UA-108501037-7',
  googleClientId: '5511654609-12jin0hsdch3uona6c3orp7qs59b0g0n.apps.googleusercontent.com',
  razorPayPublicKey: 'rzp_live_kPnZZLgPnRiKz3',
  publicKey: '-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA5pS2+FYurgcAn9XIbS6ziGmHRdqW9SSiJFjsYTnWVGdWj382yoKMaWJ+p7jAyDd/xE6q7bkgYQmhxHWQnwRe99VohK5w2jkKOoa+x2Gxlpa4zWZGvoQcbJMZ3yoO5YKlVM2HYOTGRG1WIVUuMIGbd9suvr6ND2s60pwTZgVn22egYwt1xotZygiTinfK0iu/XGjjVfzpsnquHdehzuaqAvOxitjYIxE7FuqwjpSl7MEkGLToACLRV85MRhcu1qb5VJO4fduah9jCs9deC3IUGu9y8m2+2Jo4BiaCxmgkIVS+Xng3rJmfMewXV12bQHoFN1fu682KWrd0vAv85DRKCy3aa/rYyYxA0DmYKq2W7AgSn7sQ+8fhAxT4TA03xudL4mwD7onr9ldT5tamvBJqUkQl6wzlaRnwre5wutpcAceKSpBz7TEvVkcK3SL5yHZDAYyXkFYC1DZfFfIxn+W4RTcASyhhWVL3CMW3zzTU46or+4WrTTg//xVJQIfcPtdCAT43xi3FHRIvMMQXyd3S6nFuLPVcQzsW6hLFxObxMmTH/jQdO2bheR45TZBTz28ygzhHdVKxT7B2TCCLb9R0MhjAGPBPSPcGneL15yoIwPYE7XjN68fJY64H9kFPaDLa+ubSZvgcdHxRNGsVJJuBe6SxSxw9nc9qoukAKENALBUCAwEAAQ==-----END PUBLIC KEY-----',
  appId: 'com.ideaboard',
  defaultReferrer: 'referrer=utm_source%3DTrafficAds%26utm_medium%3DWebsiteRedirect%26utm_campaign%3DAppInstall',
  appDeepLink: 'ideaboard://ideaboard',
};
