import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import Text from './text';
import ProgressBar from './progressBar';
import SideBar from './sidebar';
import { sendEvent } from '../helpers/Analytics';
import { getCheckpointData, createPersonalisedTest } from '../actions/index';
import StyleConstants from '../shared/styleConstants/styles';
import ModalView from './modal';
import arrow from '../shared/images/common/up.svg';
import lock from '../shared/images/common/lock.svg';
import accuracy from '../shared/images/topics/accuracy.svg';
import incorrect from '../shared/images/topics/incorrect.svg';
import speed from '../shared/images/topics/speed.svg';
import premiumPersonalizedImage from '../shared/images/premiumAccessIcons/personalised.svg';
import premiumChapterImage from '../shared/images/premiumAccessIcons/topics.svg';
import Layout from './layout';

const history = createBrowserHistory({
  forceRefresh: true,
});

class TrainChapter extends PureComponent {
  constructor(props) {
    super(props);
    this.updatePremiumModalState = this.updatePremiumModalState.bind(this);
    this.updateModalState = this.updateModalState.bind(this);
    this.state = {
      showPremiumModal: false,
      showModal: false,
      totalCount: '',
      complexity: '',
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { checkpoints, chapter } = this.props;
    if (checkpoints.length === 0 || chapter.id !== parseInt(id)) {
      this.props.getCheckpointData('checkpoint', id);
    }
  }

  renderTopic(checkpoint) {
    sendEvent('Train - CheckpointBeginClicked', checkpoint);
    history.push(`/train/${checkpoint.id}`);
  }

  renderCheckpoints(checkpoint) {
    if (checkpoint.has_access) {
      return (
        <div id={`Topic-Item-${checkpoint.id}`} key={`CheckpointCardView${checkpoint.id}`} className="cardView cardViewHover" style={{ marginTop: 10 }} onClick={() => this.renderTopic(checkpoint)}>
          <Text
            color={StyleConstants.textColor.primary}
            fontSize={StyleConstants.textSize.small}
            fontWeight={StyleConstants.textWeight.bold}
            text={checkpoint.name}
          />
          <div style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10,
          }}
          >
            <ProgressBar width={440} height={5} fillPercentage={checkpoint.progress} />
            <Text
              color={StyleConstants.textColor.primary}
              fontSize={StyleConstants.textSize.small}
              fontWeight={StyleConstants.textWeight.semiBold}
              text={checkpoint.progress}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        id={`Topic-Item-${checkpoint.id}`}
        key={`CheckpointCardView${checkpoint.id}`}
        className="cardView cardViewHover"
        style={{
          marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        }}
        onClick={() => this.updatePremiumModalState('chapter')}
      >
        <Text
          color={StyleConstants.textColor.inactive}
          fontWeight={StyleConstants.textWeight.semiBold}
          text={checkpoint.name}
        />
        <img alt="NEET_JEE_lock" src={lock} width={15} height={15} />
      </div>
    );
  }

  renderProgressData(icon, text, value) {
    return (
      <div
        className="cardView"
        style={{
          height: 40, width: 165, display: 'flex', alignItems: 'center',
        }}
      >
        <img alt="NEET_JEE_Progress" src={icon} width={19} height={19} style={{ marginRight: 5 }} />
        <Text text={text} />
        <Text
          color={StyleConstants.textColor.primary}
          fontWeight={StyleConstants.textWeight.semiBold}
          text={value}
        />
      </div>
    );
  }

  updateModalState(modalState = false) {
    if (modalState) {
      sendEvent('Train - PersonalisedAssessmentTapped', this.props.chapter);
    }
    this.setState({ showModal: modalState, totalCount: '', complexity: '' });
  }

  updatePremiumModalState(modalState = false) {
    this.setState({ showPremiumModal: modalState });
  }

  renderAssignmentConfigButtons(name, type, value, selected) {
    return (
      <div
        id={`Select-Config-${type}-${name}`}
        className={`Button ${selected && 'Primary-Button'}`}
        style={{ width: '100%', marginRight: 5 }}
        onClick={() => {
          this.setState({ [type]: value });
          sendEvent(`${type === 'complexity' ? 'PersonalisedAssessment - ComplexityTapped' : 'PersonalisedAssessment - QuestionCountTapped'}`, { type: name });
        }}
      >
        {name}
      </div>
    );
  }

  showPremiumModal() {
    const { showPremiumModal } = this.state;
    const context = showPremiumModal === 'personalised' ? 'Solve assignments created specially for you!' : 'Get complete access to all topics of all chapters!';
    const imageUrl = showPremiumModal === 'personalised' ? premiumPersonalizedImage : premiumChapterImage;
    sendEvent('Payments - GoPremiumTapped', { path: 'TrainChapter', type: showPremiumModal });
    return (
      <ModalView isModalVisible={showPremiumModal !== false} onClose={this.updatePremiumModalState} center maxWidth={500}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img alt="NEET_JEE_premium" src={imageUrl} width={220} height={170} style={{ marginBottom: 20 }} />
          <Text
            text={context}
            color={StyleConstants.textColor.primary}
            fontSize={StyleConstants.textSize.headerSmall}
          />
        </div>
        <div className="modalButtonView">
          <div id="Close-Premium-Modal" className="Button Secondary-Button" style={{ width: '49%', marginRight: 5 }} onClick={() => this.updatePremiumModalState()}> Later </div>
          <Link id="Get-Premium-Payment" to="/payment" style={{ width: '49%' }}>
            <div className="Button Primary-Button" style={{ width: '100%' }}> Get Premium </div>
          </Link>
        </div>
      </ModalView>
    );
  }

  personalizedAssignmentModal() {
    const { id } = this.props.match.params;
    const { showModal, complexity, totalCount } = this.state;
    const createAssignment = complexity !== '' && totalCount !== '';
    return (
      <ModalView isModalVisible={showModal} onClose={this.updateModalState} center header="Personalised Assignment" maxWidth={500}>
        <div>
          <Text
            color={StyleConstants.textColor.primary}
            fontWeight={StyleConstants.textWeight.semiBold}
            text="Difficulty"
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
            {this.renderAssignmentConfigButtons('Easy', 'complexity', 0, complexity === 0)}
            {this.renderAssignmentConfigButtons('Medium', 'complexity', 1, complexity === 1)}
            {this.renderAssignmentConfigButtons('Hard', 'complexity', 2, complexity === 2)}
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Text
            color={StyleConstants.textColor.primary}
            fontWeight={StyleConstants.textWeight.semiBold}
            text="Question Count"
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
            {this.renderAssignmentConfigButtons(15, 'totalCount', 15, totalCount === 15)}
            {this.renderAssignmentConfigButtons(30, 'totalCount', 30, totalCount === 30)}
          </div>
        </div>
        <div className="modalButtonView">
          <div id="Close-Modal" className="Button Secondary-Button" style={{ width: 120, marginRight: 5 }} onClick={() => this.updateModalState()}> Close </div>
          <div
            id="Start-Personalised-Assignment"
            className="Button Primary-Button"
            style={{ width: 120, opacity: !createAssignment && 0.5 }}
            onClick={() => {
              if (createAssignment) {
                this.props.createPersonalisedTest(id, this.state.totalCount, this.state.complexity);
                this.updateModalState();
              }
            }}
          >
Start
          </div>
        </div>
      </ModalView>
    );
  }

  render() {
    const { chapter, checkpoints, hasPremiumAccess } = this.props;
    return (
      <Layout hideFooter>
        <div>
          {this.state.showPremiumModal && this.showPremiumModal()}
          {this.state.showModal && this.personalizedAssignmentModal()}
          <SideBar showBackNavigation navigateTo={chapter.subject_name ? `/${chapter.subject_name}` : '/'} />
          <div className="MainPage" style={{ marginTop: 0, padding: 0, marginBottom: 40 }}>
            <div style={{
              height: 200,
              width: window.innerWidth - 300,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundImage: 'linear-gradient(260deg, rgb(74, 159, 245) -15%, rgb(42, 245, 152) 130%)',
            }}
            >
              <div style={{ width: 520 }}>
                <div className="cardView">
                  <Text
                    color={StyleConstants.textColor.primary}
                    fontSize={StyleConstants.textSize.small}
                    fontWeight={StyleConstants.textWeight.bold}
                    text={chapter.name}
                  />
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ProgressBar width={440} height={5} fillPercentage={chapter.progress} />
                    <Text
                      color={StyleConstants.textColor.primary}
                      fontSize={StyleConstants.textSize.small}
                      fontWeight={StyleConstants.textWeight.semiBold}
                      text={chapter.progress}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                  {this.renderProgressData(accuracy, 'Accuracy', chapter.accuracy)}
                  {this.renderProgressData(incorrect, 'Incorrect', chapter.incorrect)}
                  {this.renderProgressData(speed, 'Speed', chapter.avg_speed)}
                </div>
              </div>
            </div>
            <div style={{
              marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', marginBottom: 20,
            }}
            >
              <div style={{ width: 520 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                  <div
                    id={hasPremiumAccess ? 'Create-Personalised-Assignment' : 'Get-Premium-Payment'}
                    className="cardView cardViewHover"
                    onClick={() => (hasPremiumAccess ? this.updateModalState(true) : this.updatePremiumModalState('personalised'))}
                    style={{
                      display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: chapter.has_previous_paper ? 250 : 520,
                    }}
                    role="presentation"
                  >
                    <Text
                      color={hasPremiumAccess ? StyleConstants.textColor.primary : StyleConstants.textColor.inactive}
                      fontWeight={StyleConstants.textWeight.semiBold}
                      text="Personalised Assignment"
                    />
                    {hasPremiumAccess && <img alt="NEET_JEE_Arrow" src={arrow} width={20} height={20} style={{ transform: 'rotate(90deg)' }} />}
                    {!hasPremiumAccess && <img alt="NEET_JEE_lock" src={lock} width={14} height={14} />}
                  </div>
                  {chapter.has_previous_paper &&
                    <div
                      id="Begin-Chapter-Previous-Paper"
                      className="cardView cardViewHover"
                      onClick={() => {
                        history.push(`/chapter_previous_paper/${chapter.id}`);
                        sendEvent('Train - ChapterPreviousPaperQsTapped', chapter);
                      }}
                      style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 250
                      }}
                    >
                      <Text
                        color={StyleConstants.textColor.primary}
                        fontWeight={StyleConstants.textWeight.semiBold}
                        text="Previous Paper"
                      />
                      <img alt="NEET_JEE_Arrow" src={arrow} width={20} height={20} style={{ transform: 'rotate(90deg)' }} />
                    </div>
                  }
                </div>
                {(chapter.has_quickpoints || chapter.has_video_solutions) && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                    {chapter.has_quickpoints && (
                      <div
                        role="presentation"
                        id="Cheat-Sheet-View"
                        className="cardView cardViewHover"
                        onClick={() => {
                          hasPremiumAccess ? history.push(`/cheat_sheet/${chapter.id}`) : this.updatePremiumModalState('chapter');
                          sendEvent('Train - CheatSheetTapped', chapter);
                        }}
                        style={{
                          display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: chapter.has_video_solutions ? 250 : 520,
                        }}
                      >
                        <Text
                          color={hasPremiumAccess ? StyleConstants.textColor.primary : StyleConstants.textColor.inactive}
                          fontWeight={StyleConstants.textWeight.semiBold}
                          text="Cheat Sheet"
                        />
                        {hasPremiumAccess && <img alt="NEET_JEE_Arrow" src={arrow} width={20} height={20} style={{ transform: 'rotate(90deg)' }} />}
                        {!hasPremiumAccess && <img alt="NEET_JEE_lock" src={lock} width={14} height={14} />}
                      </div>
                    )}
                    {chapter.has_video_solutions && (
                      <div
                        role="presentation"
                        id="Video-List"
                        className="cardView cardViewHover"
                        onClick={() => {
                          hasPremiumAccess ? history.push(`/chapter_video_list/${chapter.id}`) : this.updatePremiumModalState('chapter');
                          sendEvent('Train - VideoListTapped', chapter);
                        }}
                        style={{
                          display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: chapter.has_quickpoints ? 250 : 520,
                        }}
                      >
                        <Text
                          color={hasPremiumAccess ? StyleConstants.textColor.primary : StyleConstants.textColor.inactive}
                          fontWeight={StyleConstants.textWeight.semiBold}
                          text="Video"
                        />
                        {hasPremiumAccess && <img alt="NEET_JEE_Arrow" src={arrow} width={20} height={20} style={{ transform: 'rotate(90deg)' }} />}
                        {!hasPremiumAccess && <img alt="NEET_JEE_lock" src={lock} width={14} height={14} />}
                      </div>
                    )}
                  </div>
                )}

                <Text
                  color={StyleConstants.textColor.primary}
                  fontSize={StyleConstants.textSize.small}
                  fontWeight={StyleConstants.textWeight.bold}
                  text="Topics"
                />
                {checkpoints.map((checkpoint, index) => this.renderCheckpoints(checkpoint, index))}
                {chapter.show_revise_misses
                  && (
                  <div className="cardView" style={{ marginTop: 20 }}>
                    <Text
                      color={StyleConstants.textColor.primary}
                      fontWeight={StyleConstants.textWeight.semiBold}
                      text="Revising the past misses improves your mastery over the topic and also increases your hit rate."
                    />
                    <div id="Chapter-Revise-Misses" className="Button Secondary-Button" style={{ marginTop: 20 }} onClick={() => history.push(`/chapter_revise/${chapter.id}`)}> Revise Misses </div>
                  </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

TrainChapter.propTypes = {
  createPersonalisedTest: PropTypes.func.isRequired,
  getCheckpointData: PropTypes.func.isRequired,
  hasPremiumAccess: PropTypes.bool,
  checkpoints: PropTypes.array,
  chapter: PropTypes.object,
  match: PropTypes.object,
};

TrainChapter.defaultProps = {
  hasPremiumAccess: false,
  checkpoints: [],
  chapter: {},
};

const mapStateToProps = ({ home }) => ({
  chapter: home.chapter,
  checkpoints: home.checkpoints,
  hasPremiumAccess: home.hasPremiumAccess,
});

export default connect(mapStateToProps, { getCheckpointData, createPersonalisedTest })(TrainChapter);
