import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { staticPages } from '../../shared/styleConstants/styles';

const { fontColor } = staticPages;
class StaticText extends PureComponent {
  render() {
    const {
      fontWeight, color, text, definedClass,
    } = this.props;
    return (
      <p
        className={definedClass && definedClass}
        style={{
          color,
          fontWeight: fontWeight && fontWeight,
          display: 'inline',
          margin: 0,
        }}
      >

        {text}
      </p>
    );
  }
}

StaticText.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  fontWeight: PropTypes.string.isRequired,
  definedClass: PropTypes.string,
};

StaticText.defaultProps = {
  color: fontColor.content,
  text: '',
};

export default StaticText;
