import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { sendEvent } from '../../helpers/Analytics';
import ModalView from '../modal';
import Text from '../text';
import StyleConstants from '../../shared/styleConstants/styles';
import { calculateCountdown } from '../../helpers/Utils';
import { updateCurrentQuestionIndex, getTestProgressData, finishTest, showTestSessioFinishModal, applyTestFilter } from '../../actions/index';
import { SUBJECT_MAPPING, TEST_QUESTION_OVERVIEW_TYPES } from '../../shared/constants/fieldTypes';

const history = createBrowserHistory({
    forceRefresh: true
});

const LEGENDS = {
  test: ['ATTEMPTED', 'MARKED', 'UNATTEMPTED', 'UNVISITED'],
  review: ['CORRECT', 'INCORRECT', 'UNATTEMPTED', 'UNVISITED']
}

const FILTERS = {
  test: ['MARKED', 'UNATTEMPTED', 'UNVISITED'],
  review: ['INCORRECT', 'UNATTEMPTED', 'UNVISITED']
}

let fieldTypeBackgroundColor = {};
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.CORRECT] = StyleConstants.color.Correct;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.INCORRECT] = StyleConstants.color.Incorrect;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.MARKED] = StyleConstants.color.testMarked;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.ATTEMPTED] = StyleConstants.color.testAttempted;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.UNATTEMPTED] = StyleConstants.color.testUnattempted;
fieldTypeBackgroundColor[TEST_QUESTION_OVERVIEW_TYPES.UNVISITED] = StyleConstants.color.testUnvisited;

class TestOverview extends Component {
  state = {
    selectedSubjects: [],
    selectedSubjectIds: [],
    selectedTypes: [],
    screenHeight: 0,
    showFinishTestModal: false
  };

  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
  }

  renderSubjectQuestions(subjectName) {
    const { selectedTypes } = this.state;
    const { questionIndexMapping } = this.props;
    const hasSelectedQuestionTypes = selectedTypes.length === 0 ? true : Object.keys(questionIndexMapping[subjectName]).some(qIndex => questionIndexMapping[subjectName][qIndex].some(type => selectedTypes.includes(type)))
    return (
      <div className="mb-2" key={`OverviewSubjects_${subjectName}`}>
        <Text text={subjectName} fontWeight={StyleConstants.textWeight.bold} color={StyleConstants.textColor.primary} />
        <div style={{ marginTop: 10 }} className="flexcontainer">
          {hasSelectedQuestionTypes && questionIndexMapping[subjectName] && Object.keys(questionIndexMapping[subjectName]).map(questionIndex => (
            <div key={`QuestionOverviewIndex_${questionIndex}`}>
              {(selectedTypes.length === 0 || parseInt(questionIndex) === parseInt(this.props.questionIndex + 1) || questionIndexMapping[subjectName][questionIndex].some(type => selectedTypes.includes(type))) &&
                <div id={`Question-Index-${questionIndex}`} className={`Circle ${questionIndexMapping[subjectName][questionIndex][0]}`}  style={{ marginRight: 13, marginBottom: 13, backgroundColor: fieldTypeBackgroundColor[questionIndexMapping[subjectName][questionIndex][0]] }} onClick={() => {
                  this.props.updateCurrentQuestionIndex(parseInt(questionIndex) - 1, true);
                }}>
                  <Text text={questionIndex} fontWeight={StyleConstants.textWeight.bold} color={ questionIndexMapping[subjectName][questionIndex][0] === TEST_QUESTION_OVERVIEW_TYPES.UNVISITED ? StyleConstants.textColor.primary : "white" } />
                </div>
              }
            </div>
          ))}
          {!hasSelectedQuestionTypes &&
            <Text text={`You have no ${selectedTypes.join(', ')} questions in ${subjectName}`} />
          }
        </div>
      </div>
    )
  }

  renderQuestionIndex(subjects) {
    const { selectedSubjects } = this.state;
    const subjectList = selectedSubjects.length > 0 ? [...new Set(subjects)].filter(x => new Set(selectedSubjects).has(x)) : subjects;
    return (
      <div>
        {subjectList.map(subjectName => this.renderSubjectQuestions(subjectName))}
      </div>
    );
  }

  updateFiltersSelected(fieldName, fieldValue, fieldData=[]) {
    let selectedValues = this.state[fieldName];
    let { selectedSubjectIds } = this.state;
    if (selectedValues.includes(fieldValue)) {
      selectedValues.splice(selectedValues.indexOf(fieldValue), 1);
      fieldData.forEach(val => selectedSubjectIds.splice(selectedSubjectIds.indexOf(val), 1));
    } else {
      selectedValues.push(fieldValue);
      selectedSubjectIds = [...fieldData, ...selectedSubjectIds]
    }
    if (fieldData.length > 0) {
      this.setState({ selectedSubjectIds });
    }
    this.setState({ [fieldName]: selectedValues });
    fieldData ? this.props.applyTestFilter(this.state.selectedTypes, selectedSubjectIds) : this.props.applyTestFilter(selectedValues, selectedSubjectIds);
  }

  renderSubjects(subjectName, subjectCount) {
    const isSelected = this.state.selectedSubjects.includes(subjectName);
    let subjectIds = []
    Object.keys(SUBJECT_MAPPING).forEach(subjectId => {
      if (SUBJECT_MAPPING[subjectId] === subjectName) {
        subjectIds.push(subjectId)
      }
    })
    return (
      <div
        id={`Subject-Filter-${subjectName}`}
        key={`Overview_${subjectName}`}
        className={`${isSelected ? 'Primary-Button' : ''} Button`}
        style={{ width: 105, fontSize: 12, fontWeight: "100" }}
        onClick={() => this.updateFiltersSelected('selectedSubjects', subjectName, subjectIds)}
      > {subjectName} </div>
    );
  }

  renderQuestionOverviewType(type) {
    const fieldName = TEST_QUESTION_OVERVIEW_TYPES[type];
    const isSelected = this.state.selectedTypes.includes(fieldName);
    return (
      <div
        id={`Question-Type-Filter-${fieldName}`}
        key={`QuestionType_${fieldName}`}
        className={`${isSelected ? 'Primary-Button' : ''} Button`}
        style={{ width: 105, fontSize: 12, fontWeight: "100" }}
        onClick={() => this.updateFiltersSelected('selectedTypes', fieldName)}
      > {fieldName} </div>
    );
  }

  renderLegends(type) {
    const fieldName = TEST_QUESTION_OVERVIEW_TYPES[type];
    const color = fieldTypeBackgroundColor[fieldName];
    return (
      <div key={`Legend_${fieldName}`} style={{ display: 'flex', alignItems: 'center' }}>
        <div className="Circle" style={{ width: 8, height: 8 , backgroundColor: color, marginRight: 1 }} />
        <Text text={fieldName} fontWeight={StyleConstants.textWeight.semiBold} color={color} fontSize={StyleConstants.textSize.small} />
      </div>
    )
  }

  onCloseModal(finishClicked=false, hasTimerExpired) {
    const { testInfo } = this.props;
    if (hasTimerExpired) {
      sendEvent('Tests - TestFinishedByTimeOut', { ...testInfo, hasTimerExpired, finishClicked });
      history.push(`/initial_test_report/${this.props.testInfo.id}`);
    }
    else if (finishClicked) {
      sendEvent('Tests - FinishClicked', { ...testInfo, hasTimerExpired, finishClicked });
      this.props.finishTest(false, true);
    }
    this.setState({ showFinishTestModal: false });
    this.props.showTestSessioFinishModal(false)
  }

  renderModalButton(hasTimerExpired=false) {
    return (
      <div className="modalButtonView">
        {!hasTimerExpired && <div id='Close-Modal' className="Button Secondary-Button mr-2" style={{ width: 130 }} onClick={() => this.onCloseModal()}> Cancel </div>}
        <div id={hasTimerExpired ? 'Close-Modal' : 'Finish-Test'} className="Button Primary-Button" style={{ width: 130 }} onClick={() => this.onCloseModal(true, hasTimerExpired)}> {hasTimerExpired ? 'Close' : 'Finish'} </div>
      </div>
    )
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = `0${value}`;
    }
    return value;
  }

  getTimeRemaining() {
    const { testDuration } = this.props;
    const { hours, minutes, sec} = calculateCountdown(new Date(new Date().setSeconds(new Date().getSeconds() + testDuration)).toString());
    let timeRemaining = '';
    if (hours > 0) {
      timeRemaining += `${hours} h `;
    }
    if (hours > 0 || minutes > 0) {
      timeRemaining += `${minutes} min `;
    }
    timeRemaining += `${sec} s`;
    return timeRemaining;
  }

  renderModalContent() {
    const { hasTimerExpired, unAttemptedCount, unVisitedCount } = this.props;
    const timeRemaining = this.getTimeRemaining();
    if (hasTimerExpired || (unAttemptedCount === 0 && unVisitedCount === 0)) {
      const message = hasTimerExpired ? "Your test has been submitted. You can see your test report now." : `You have ${timeRemaining} remaining.`;
      return (
        <div>
          <Text text={message} />
          {this.renderModalButton(hasTimerExpired)}
        </div>
      );
    }
    return (
      <div>
        <div className="flexcontainer">
          <Text text={"You have "} />
          {unVisitedCount > 0 &&
            <div className="flexcontainer">
               <Text text={`${unVisitedCount} unvisited`} fontWeight={StyleConstants.textWeight.bold} />
               {unAttemptedCount > 0 && <Text text={" and "} />}
            </div>
           }
           {unAttemptedCount > 0 && <Text text={`${unAttemptedCount} unattempted`} fontWeight={StyleConstants.textWeight.bold} />}
           <Text text={" questions with "} />
           <Text text={`${timeRemaining} remaining.`} fontWeight={StyleConstants.textWeight.bold} />
        </div>
        {this.renderModalButton()}
      </div>
    );
  }

  renderModal() {
    const { hasTimerExpired, isTestReview, showSessionFinishModal } = this.props;
    const headerText = hasTimerExpired ? "Oops! you have run out of time." : "Are you sure you want to finish test?";
    if (showSessionFinishModal) {
      this.props.getTestProgressData();
    }
    if (isTestReview && showSessionFinishModal) {
      history.push('/');
    }
    return (
      <ModalView isModalVisible={hasTimerExpired && !isTestReview ? true : this.state.showFinishTestModal || showSessionFinishModal} onClose={this.onCloseModal} center header={headerText} maxWidth={500}>
        {this.renderModalContent()}
      </ModalView>
    );
  }

  showTestFinishModal() {
    this.props.getTestProgressData();
    this.setState({ showFinishTestModal: true });
  }

  render() {
    const { subjectList, isTestReview, hasTimerExpired } = this.props;
    let subjectItems = []
    subjectList.forEach(subjectId => {
      subjectItems.push(SUBJECT_MAPPING[subjectId])
    });
    subjectItems = [...new Set(subjectItems)];
    if (hasTimerExpired) {
      this.props.finishTest(hasTimerExpired, false);
    }

    return (
      <div>
        {this.renderModal()}
        <div style={{ paddingLeft: 15, paddingRight: 15 }}>
          <div style={{ marginBottom: 10 }}>
            <Text text={"Subject"} fontWeight={StyleConstants.textWeight.bold} color={StyleConstants.textColor.primary} />
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              {subjectItems.map(subjectName => this.renderSubjects(subjectName, subjectItems.length))}
            </div>
          </div>
          <div style={{ marginBottom: 15 }}>
            <Text text={"Response"} fontWeight={StyleConstants.textWeight.bold} color={StyleConstants.textColor.primary} />
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              {FILTERS[isTestReview ? 'review' : 'test'].map(type => this.renderQuestionOverviewType(type))}
            </div>
          </div>
          <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'space-between' }}>
            {LEGENDS[isTestReview ? 'review' : 'test'].map(type => this.renderLegends(type))}
          </div>
          <div className="flexcontainer" style={{
            position: 'relative',
            height: this.state.screenHeight - 350,
            overflow: 'scroll',
          }}>
            {this.renderQuestionIndex(subjectItems)}
          </div>
        </div>
        {!isTestReview && <div
          id='Test-Finish-Button'
          className="Button Primary-Button"
          style={{ height: StyleConstants.fieldHeight.big, position: 'absolute', bottom: 0, width: '100%', borderRadius: 0, backgroundColor: StyleConstants.questionView.IncorrectBorder, borderColor: StyleConstants.questionView.IncorrectBorder }}
          onClick={() => this.showTestFinishModal()}
        > End Test </div>}
      </div>
    )
  }
}

TestOverview.propTypes = {
  applyTestFilter: PropTypes.func.isRequired,
  getTestProgressData: PropTypes.func.isRequired,
  updateCurrentQuestionIndex: PropTypes.func.isRequired,
  showTestSessioFinishModal: PropTypes.func.isRequired,
  finishTest:PropTypes.func.isRequired,
  testInfo: PropTypes.object,
  subjectList: PropTypes.array,
  hasTimerExpired: PropTypes.bool,
  questionIndexMapping: PropTypes.object,
  unAttemptedCount: PropTypes.number,
  unVisitedCount: PropTypes.number,
  questionIndex: PropTypes.number,
  isTestReview: PropTypes.bool,
  testDuration: PropTypes.number,
  showSessionFinishModal: PropTypes.bool
}

TestOverview.defaultProps = {
  questionIndex: 1,
  isTestReview: false,
  subjectList: [],
  hasTimerExpired: false,
  questionIndexMapping: {},
  showSessionFinishModal: false,
  unAttemptedCount: 0,
  unVisitedCount: 0,
  testDuration: ''
}

const mapStateToProps = ({ test, global }) => ({
  testInfo: test.testInfo,
  questionIndex: test.questionIndex,
  isTestReview: test.isTestReview,
  unAttemptedCount: test.unAttemptedCount,
  unVisitedCount: test.unVisitedCount,
  testDuration: test.testDuration,
  subjectList: test.subjectList,
  hasTimerExpired: global.hasTimerExpired,
  questionIndexMapping: test.questionIndexMapping,
  showSessionFinishModal: test.showSessionFinishModal
});

export default connect(mapStateToProps, { updateCurrentQuestionIndex, getTestProgressData, finishTest, showTestSessioFinishModal, applyTestFilter })(TestOverview);
