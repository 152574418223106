import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { createBrowserHistory } from 'history';
import Text from './text';
import DropDown from './dropDown';
import { Link } from 'react-router-dom';
import StyleConstants from '../shared/styleConstants/styles';
import { sendEvent } from '../helpers/Analytics';
import { logout } from '../actions/index';
import Constants from '../shared/constants';
import Cookies from 'universal-cookie';
import { isMobileDevice, getAccountsDomain } from '../helpers/Utils';
const IS_MOBILE = isMobileDevice();
const constants = Constants();
const cookies = new Cookies();
const history = createBrowserHistory({
  forceRefresh: true,
});

class SolutionBoardMenubar extends PureComponent {
    constructor(props){
      super(props);
      this.state = {
        hoverMenuIndex: false,
        showMoreOptions: false,
      };
      this.renderContent = this.renderContent.bind(this);
    }

    showComponents = (event) => {
      event.preventDefault();
      this.setState({ showMoreOptions: true }, () => {
        document.addEventListener('click', this.closeComponents);
      });
    }
  
    closeComponents = (event) => {
      if (event && (event.target.className !== 'show-more-options')) {
        this.setState({ showMoreOptions: false }, () =>
          document.removeEventListener('click', this.closeComponents));
        }
    }

    renderDropDowns(text, list, type) {
      let selectedItem = this.props.appliedFilters[type];
      if(this.props.appliedFilters[type] && !this.props.appliedFilters[type].value) {
        list.map((item) => {
          if(item.id === parseInt(this.props.appliedFilters[type].key)) {
            selectedItem = {
              key: item.id,
              value: item.name,
            };
          }
        })
      }
      return (
        <div key={`${text}-${list.length}`}>
          <Text
            text={text}
            color={StyleConstants.color.white}
            fontSize={StyleConstants.textSize.headerSmall}
          />
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            {<DropDown selectedItem={selectedItem} key={text} optionsList={list} optionType={text} width={210} color={StyleConstants.color.white} onValueChange={(value, text) => this.props.updateFilters(type, value, text)} />}
          </div>
        </div>
      );
    }

    renderContent() {
      const { subjectsList, chaptersList, topics, courses } = this.props;
      return (
        <div style={{ width: '100%' }}>
          {this.renderDropDowns("Subject", subjectsList, "selectedSubject")}
          {this.renderDropDowns("Chapter", chaptersList, "selectedChapter")}
          {this.renderDropDowns("Topic", topics, "selectedTopic")}
        </div>
      );
   }

    render() {
      const { width } = this.props;
      const { showMoreOptions } = this.state;
      const user = cookies.get('user');
      return(
        <div style={{ backgroundColor: StyleConstants.solutionBoard.menuBarBackgroundLight, minHeight: '100vh', position: 'fixed', width }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: StyleConstants.solutionBoard.menuBarBackgroundDark, height: 70 }}>
            <Text
              text="IDEA"
              color={StyleConstants.solutionBoard.headerTextPrimaryColor}
              fontSize={StyleConstants.textSize.header}
              style={{ paddingRight: 0, fontSize: 24 }}
              fontWeight={StyleConstants.textWeight.bold}
              />
            <Text
              text="BOARD"
              color={StyleConstants.color.white}
              style={{ paddingLeft: 0, fontSize: 24 }}
              fontSize={StyleConstants.textSize.header}
            />
          </div>
          <div style={{ padding: 20 }}>
          {false && this.renderContent()}
        </div>
          <div style={{ height: 70, position: 'fixed', bottom: 0, padding: '10px 20px', backgroundColor: StyleConstants.solutionBoard.menuBarBackgroundDark, width, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
           { user &&
            <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 40, height: 40, padding: 5, borderRadius: 30, background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: 34, height: 34, background: '#4F5F6F', position: 'absolute', borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF' }}>
                      { user ? user.name.charAt(0): 
                        <div className="Button" style={{ backgroundColor: '#F2C94C', width: 150 }}> Sign In </div>
                      }
                    </div>
                  </div>
                </div>
                <div style={{ color: '#8D9FB4', paddingLeft: 20, fontWeight: '400', fontSize: 16, cursor: 'pointer', fontFamily: 'Nunito', width: 100, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  { user &&  user.name}
                </div>
                <div className="show-more-options" style={{ cursor: 'pointer' }} onClick={(event) => this.showComponents(event)}>
                  <img src="/images/common/drop-down-arrow.svg" style={{ marginLeft: 50 }} alt="NEET JEE"/>
                </div>
                {showMoreOptions && 
                  <div style={{ position: 'fixed', width: 100, height: 50, bottom: 70, left: 110, backgroundColor: StyleConstants.color.white, borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => this.props.logout()}>
                   Log out
                  </div>
                }
              </>
            }
            {!user && <a href={`${getAccountsDomain()}/sign_in`} onClick={() => { sendEvent('Header - LoginButtonClicked'); }}><div className="Primary-Button Button" style={{ width: 140, height: 35 }}> Log In </div></a>}
          </div>
        </div>
      );
    }
  }

  const mapStateToProps = ({ solutionBoard }) => ({
    selectedSubject: solutionBoard.appliedFilters.selectedSubject,
    selectedChapter: solutionBoard.appliedFilters.selectedChapter,
    selectedTopic: solutionBoard.appliedFilters.selectedTopic,
    appliedFilters: solutionBoard.appliedFilters,
  });

  export default connect (mapStateToProps, { logout })(SolutionBoardMenubar);
