import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SideBar from './sidebar';
import Text from './text';
import ModalView from './modal';
import { calculateCountdown } from '../helpers/Utils';
import StyleConstants from '../shared/styleConstants/styles';
import { getTestInstructions, beginTest, showToast } from '../actions/index';
import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import { sendEvent } from '../helpers/Analytics';
import { TEST_MODE_TYPES } from '../shared/constants/fieldTypes';
import Layout from './layout'
class TestInstruction extends Component {

  state = {
    otp: [...Array(6)],
    showOtpModal: false,
    focusOtpIndex: ''
  }

  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { testInfo } = this.props;
    if (testInfo.id !== parseInt(id)) {
      this.props.getTestInstructions(id);
    }
  }

  renderInstructionList(text) {
    return (
      <li>
        <Text
          color={StyleConstants.textColor.primary}
          fontWeight={StyleConstants.textWeight.semiBold}
          text={text}
        />
      </li>
    );
  }

  updateOtp(index, value) {
    const { otp } = this.state;
    otp[index] = value;
    if(index !== 5 && value !== null) {
      this.refs[`OTP_${index+1}`].focus();
      this.setState({ focusOtpIndex: index+1 });
    } else if (index !== 0 && value === null) {
      this.refs[`OTP_${index-1}`].focus();
      this.setState({ focusOtpIndex: index-1 });
    }
    this.setState({ otp });
  }

  renderOtp() {
    const { testInfo } = this.props;
    const { otp, focusOtpIndex } = this.state;
    if (this.refs['OTP_0'] && !focusOtpIndex) {
      this.refs['OTP_0'].focus();
    }
    return (
      <div>
        <div style={{ display: 'flex' }}>
          {otp.map((_, index) => (
            <input id={`otp-${index}`} type="text" key={`OTP_${index}`} maxLength="1"
              style={{
                width: 50,
                textAlign: 'center',
                height: StyleConstants.fieldHeight.big,
                margin: 5
              }}
              value={otp[index]}
              ref={`OTP_${index}`}
              onKeyPress={(event) => event.key === 'Enter' && otp.join('').length === 6 && this.props.beginTest(testInfo.id, otp.join(''))}
              onChange={(event) => this.updateOtp(index, event.target.value.match(/[0-9]/g))}
            />
          ))}
        </div>
        <div className="modalButtonView">
          <div className="Button Secondary-Button" style={{ padding: 20 }} onClick={() => this.onCloseModal()}> Cancel </div>
          <div
            id="Start-Test"
            style={{ opacity: otp.join('').length < 6 && 0.5, padding: 20, marginLeft: 10 }}
            className="Button Primary-Button"
            onClick={() => otp.join('').length === 6 && this.props.beginTest(testInfo.id, otp.join(''))}
          >
            Continue
          </div>
        </div>
      </div>
    );
  }

  getBackUrl() {
    const { testInfo } = this.props;
    let backUrl = `/${TEST_MODE_TYPES[testInfo.test_mode_type + '']}`;
    if (testInfo.test_mode_type === 0) {
      backUrl = `${backUrl}?type=${testInfo.test_type}`;
    }
    return backUrl;
  }

  onCloseModal() {
    this.setState({ showOtpModal: false, otp: [...Array(6)] })
  }

  beginTest() {
    const { testInfo } = this.props;
    if (testInfo.should_request_otp) {
      this.setState({ showOtpModal: testInfo.should_request_otp });
    } else {
      this.props.beginTest(testInfo.id);
    }
  }

  render() {
    const { testInfo } = this.props;
    const { hours, minutes } = calculateCountdown(new Date(new Date().setSeconds(new Date().getSeconds() + testInfo.test_duration)).toString());
    const time = hours > 0 && minutes === 0 ? `${hours} hours` : `${hours} hours ${minutes} minutes`;
    const backUrl = this.getBackUrl();
    return (
      <Layout hideFooter>
        <div>
          <ModalView isModalVisible={this.state.showOtpModal} onClose={this.onCloseModal} center header={"Enter OTP"} maxWidth={500}>
            {this.renderOtp()}
          </ModalView>
          <SideBar showBackNavigation={true} navigateTo={backUrl} />
          <div className="MainPage">
            <Text
              color={StyleConstants.textColor.primary}
              fontWeight={StyleConstants.textWeight.bold}
              fontSize={StyleConstants.textSize.headerSmall}
              text={testInfo.name}
            />
            <div className="cardView" style={{ padding: 30, marginTop: 20 }}>
              <Text
                fontWeight={StyleConstants.textWeight.semiBold}
                text={`${testInfo.question_count} Questions | ${time}`}
              />
              <div style={{ marginTop: 10 }}>
                {this.renderInstructionList("Exam will start once you click begin.")}
                {this.renderInstructionList("When the countdown timer at the top reaches zero, the examination will end by itself.")}
                {this.renderInstructionList(`The examination will end exactly in ${time}, from the time when you click begin.`)}
                {this.renderInstructionList("Once you “Finish” the test, you cannot resume the test again.")}
                {testInfo.is_new_neet_pattern && (
                  <>
                  {this.renderInstructionList('Attempt all quesitons in SECTION A.')}
                  {this.renderInstructionList('SECTION B will have 15 questions. Candidate can choose to attempt any 10 questions out of these 15 questions. In case the candidate attempts more than 10 questions, first 10 attempted questions will be considered for marking.')}
                  </>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                id="Begin-Test"
                className="Button Primary-Button pl-5 pr-5 mt-4"
                style={{ height: StyleConstants.fieldHeight.normal, width: 300, opacity: testInfo.disable_begin && 0.5 }}
                onClick={() => {
                  if (!testInfo.disable_begin) {
                    sendEvent('Tests - BeginTestTapped', testInfo);
                    this.beginTest();
                  } else {
                    this.props.showToast(`Test cannot start before ${testInfo.test_date} ${testInfo.start_time}`, TOAST_MESSAGE_TYPES.WARNING);
                  }
                }}
              > Begin </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

TestInstruction.propTypes = {
  beginTest: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getTestInstructions: PropTypes.func.isRequired,
  match: PropTypes.object,
  testInfo: PropTypes.object
}

TestInstruction.defaultProps = {
  testInfo: {}
}

const mapStateToProps = ({ test }) => ({
  testInfo: test.testInfo
});

export default connect(mapStateToProps, { getTestInstructions, beginTest, showToast })(TestInstruction);
