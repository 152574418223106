import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { sendEvent } from '../helpers/Analytics';
import Countdown from './countDown';
import ModalView from './modal';
import Text from './text';
import constants from '../shared/constants';
import StyleConstants from '../shared/styleConstants/styles';
import { login, updateUserInfo, updateTimerExpirey } from '../actions/index';
import Layout from './layout';
import phone from '../shared/images/sign_in/phone_icon.svg';
import phoneSelected from '../shared/images/sign_in/phone_icon_selected.svg';
import user from '../shared/images/sign_in/user.svg';
import userSelected from '../shared/images/sign_in/userSelected.svg';

const Constants = constants();

const PAGE_TYPES = {
  PHONE: 'phone_number',
  OTP: 'otp',
  REGISTER: 'register',
};

const PAGE_TYPE_DATA = {
  phone_number: {
    topic: 'Sign In',
    info: 'Please enter your 10 digit mobile number to proceed',
    placeholder: 'Mobile Number',
    buttonText: 'Next',
  },
  otp: {
    topic: 'Enter Your One Time Passcode',
    buttonText: 'Verify',
  },
  register: {
    topic: 'Register',
    info: 'Please enter your personal details to continue.',
    placeholder: 'Name',
    buttonText: 'Save',
  },
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      courseType: '',
      showErrorModal: false,
      pageType: props.pageType ? props.pageType : PAGE_TYPES.PHONE,
      phone_number: '',
      otp: [...Array(6)],
      showCountDown: false,
      focusOtpIndex: '',
      otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + 30)).toString(),
    };
    this.onCloseModal = this.onCloseModal.bind(this);
    this.updateNextState = this.updateNextState.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
  }

  updateNextState(isResendOTP = false) {
    if (this.state.pageType === PAGE_TYPES.OTP && this.state.otp.join('').length === 6 && !isResendOTP) {
      this.props.login(this.state.phone_number, this.state.otp);
    } else if (this.state.pageType === PAGE_TYPES.REGISTER) {
      this.props.updateUserInfo(this.state[this.state.pageType], this.state.courseType);
    } else {
      if (isResendOTP) {
        sendEvent('Authentication - ResendOTPTapped', { phone: this.state.phone_number });
      } else {
        sendEvent('Authentication - OTPRequested', { phone: this.state.phone_number });
      }
      this.props.updateTimerExpirey(false);
      this.props.login(this.state.phone_number);
      this.setState({ pageType: PAGE_TYPES.OTP, otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + 30)).toString() });
    }
  }

  updateOtp(index, value) {
    const { otp } = this.state;
    otp[index] = value;
    if (index !== 5 && value !== null) {
      this.refs[`OTP_${index + 1}`].focus();
      this.setState({ focusOtpIndex: index + 1 });
    } else if (index !== 0 && value === null) {
      this.refs[`OTP_${index - 1}`].focus();
      this.setState({ focusOtpIndex: index - 1 });
    }
    this.setState({ otp });
  }

  renderInputField(pageType) {
    if (pageType === PAGE_TYPES.PHONE) {
      const { placeholder } = PAGE_TYPE_DATA[pageType];
      const imageUrl = this.state[PAGE_TYPES.PHONE] && this.state[PAGE_TYPES.PHONE] !== '' ? phoneSelected : phone;
      return (
        <input
          id="phone-number"
          type="text"
          maxLength="10"
          style={{
            width: 400,
            height: StyleConstants.fieldHeight.big,
            background: `url(${imageUrl}) no-repeat 4px 12px`,
            paddingLeft: 50,
            marginTop: 10,
          }}
          pattern="[0-9. -]*"
          onKeyPress={(event) => event.key === 'Enter' && this.state[pageType].length === 10 && this.updateNextState()}
          placeholder={placeholder}
          onChange={(event) => this.setState({ [pageType]: event.target.value ? event.target.value.match(/[0-9]/g).join('') : event.target.value })}
          value={this.state[pageType]}
          autoFocus="autofocus"
        />
      );
    }
    if (pageType === PAGE_TYPES.REGISTER) {
      const { placeholder } = PAGE_TYPE_DATA[pageType];
      const { courseType } = this.state;
      const imageUrl = this.state[PAGE_TYPES.REGISTER] && this.state[PAGE_TYPES.REGISTER] !== '' ? userSelected : user;
      const courseTypes = {
        MED: 'Medical',
        ENG: 'Engineering',
        MED_ENG: 'Both',
      };
      return (
        <div>
          <input
            id="name"
            type="text"
            style={{
              width: '100%',
              height: StyleConstants.fieldHeight.big,
              background: `url(${imageUrl}) no-repeat 4px 12px`,
              paddingLeft: 50,
              marginTop: 10,
            }}
            placeholder={placeholder}
            onKeyPress={(event) => event.key === 'Enter' && !(this.state[pageType] === '' || this.state.courseType === '') && this.updateNextState()}
            onChange={(event) => this.setState({ [pageType]: event.target.value })}
            value={this.state[pageType]}
            autoFocus="autofocus"
          />
          <div style={{ marginTop: 20 }}>
            <Text text="Course" textWeight={StyleConstants.textWeight.semiBold} color={StyleConstants.textColor.primary} />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
              {Object.keys(courseTypes).map((type) => (
                <div id={type} className={`Button ${courseType === type ? 'Primary-Button' : 'Secondary-Button'}`} style={{ width: '30%', height: StyleConstants.fieldHeight.big }} onClick={() => this.setState({ courseType: type })}>
                  {courseTypes[type]}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    const { otp, focusOtpIndex } = this.state;
    if (this.refs.OTP_0 && !focusOtpIndex) {
      this.refs.OTP_0.focus();
    }
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          {otp.map((_, index) => (
            <input
              id={`otp-${index}`}
              type="text"
              key={`OTP_${index}`}
              min={0}
              max={9}
              maxLength="1"
              style={{
                textAlign: 'center',
                height: StyleConstants.fieldHeight.big,
                width: 50,
              }}
              value={otp[index]}
              ref={`OTP_${index}`}
              onKeyPress={(event) => event.key === 'Enter' && this.state[PAGE_TYPES.OTP].join('').length === 6 && this.updateNextState()}
              onChange={(event) => this.updateOtp(index, event.target.value.match(/[0-9]/g))}
            />
          ))}
        </div>
      </div>
    );
  }

  responseGoogle(response) {
    console.log('response', response);
    if (!response || response.error) {
      sendEvent('Authentication - GoogleSignInFailed');
      this.setState({ showErrorModal: true });
    } else {
      sendEvent('Authentication - GoogleSignInSuccess', { ...response.profileObj, id_token: response.Zi.id_token });
      this.props.login('', '', { ...response.profileObj, id_token: response.Zi.id_token });
    }
  }

  onCloseModal() {
    this.setState({ showErrorModal: false });
  }

  showModal() {
    return (
      <ModalView isModalVisible={this.state.showErrorModal} onClose={this.onCloseModal} center header="Oops login failed" maxWidth={500}>
        <Text text="Try Again or enter your mobile number" />
        <div className="modalButtonView">
          <div id="modal-close-button" className="Button Primary-Button" style={{ width: 130 }} onClick={() => this.onCloseModal()}> Close </div>
        </div>
      </ModalView>
    );
  }

  render() {
    const { hasTimerExpired } = this.props;
    const { pageType, showErrorModal, otpTime } = this.state;
    const { topic, info, buttonText } = PAGE_TYPE_DATA[pageType];

    let inactive = true;
    if (pageType === PAGE_TYPES.PHONE) {
      inactive = this.state[PAGE_TYPES.PHONE].length !== 10;
    } else if (pageType === PAGE_TYPES.REGISTER) {
      inactive = this.state[pageType] === '' || this.state.courseType === '';
    } else {
      inactive = this.state[PAGE_TYPES.OTP].join('').length !== 6;
    }

    return (
      <Layout hideFooter>
        <div className="d-flex flex-column" style={{ alignItems: 'center', height: global.innerHeight * 0.7, justifyContent: 'center' }}>
          {showErrorModal && this.showModal()}
          <div className="p-5 m-5 simpleCardView" style={{ width: 500 }}>
            <Text
              color={StyleConstants.textColor.primary}
              fontWeight={StyleConstants.textWeight.bold}
              fontSize={StyleConstants.textSize.headerBig}
              text={topic}
            />
            <div className="d-flex flex-row">
              <Text text={pageType === PAGE_TYPES.OTP ? this.state[PAGE_TYPES.PHONE] : info} />
              {pageType === PAGE_TYPES.OTP
                && (
                <div style={{ cursor: 'pointer' }} onClick={() => this.setState({ pageType: PAGE_TYPES.PHONE })}>
                  <Text color={StyleConstants.color.primary} text="change" />
                </div>
                )}
            </div>
            {this.renderInputField(pageType)}
            {pageType === PAGE_TYPES.OTP
              && (
              <div style={{ marginTop: 10 }}>
                {!hasTimerExpired
                  && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text
                      fontWeight={StyleConstants.textWeight.semiBold}
                      text="Resend OTP"
                    />
                    <div style={{ marginLeft: 10 }} />
                    <Countdown date={otpTime} />
                  </div>
                  )}
                {hasTimerExpired
                  && (
                  <div id="Resend-OTP-Button" style={{ cursor: 'pointer' }} onClick={() => this.updateNextState(true)} role="presentation">
                    <Text
                      fontWeight={StyleConstants.textWeight.semiBold}
                      color={StyleConstants.color.primary}
                      text="Resend OTP"
                    />
                  </div>
                  )}
              </div>
              )}
            <div
              id={buttonText}
              className="Button Primary-Button mt-4"
              style={{ width: '100%', height: StyleConstants.fieldHeight.big, opacity: inactive && 0.5 }}
              onClick={() => !inactive && this.updateNextState()}
              role="presentation"
            >
              {buttonText}
            </div>
            {false && pageType === PAGE_TYPES.PHONE
              && (
              <div>
                <div className="mt-3 mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Text
                    fontWeight={StyleConstants.textColor.semiBold}
                    text="--------------- or ---------------"
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <GoogleLogin
                    theme="dark"
                    clientId={Constants.googleClientId}
                    buttonText="Sign in with Google"
                    onSuccess={() => this.responseGoogle()}
                    onFailure={() => this.responseGoogle()}
                  />
                </div>
              </div>
              )}
          </div>
        </div>
      </Layout>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  updateTimerExpirey: PropTypes.func.isRequired,
  hasTimerExpired: PropTypes.bool,
  pageType: PropTypes.string,
};

Login.defaultProps = {
  pageType: '',
};

const mapStateToProps = ({ global }) => ({
  hasTimerExpired: global.hasTimerExpired,
});

export default connect(mapStateToProps, { login, updateUserInfo, updateTimerExpirey })(Login);
