import React, { PureComponent } from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SideBar from './sidebar';
import Layout from './layout';
import { getAttendaceData } from '../actions/index';
import { SIDEBAR_TYPES } from '../shared/constants/fieldTypes';
import noClassTaken from '../shared/images/common/noData.svg';
import Loader from "./loader";

const targetPercentage = 0.75;
const cookies = new Cookies();
const user = cookies.get('user');

class Attendance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    this.props.getAttendaceData().then(() => this.setState({ showLoader: false }));
  }

  renderSubjectQuizScore(subject, highlight = false) {
    const isHigherEducation = user.is_higher_education;
    const style = { padding: 20, whiteSpace: 'nowrap', fontWeight: highlight ? 'bold' : '' };
    const score = subject && subject.attended ? (Math.ceil(((subject.attended || 0) * 100) / (subject.total_classes))) : 0;
    const totalClasses = subject && subject.total_classes ? subject.total_classes : 0;
    const totalClassesAttended = subject && subject.attended ? subject.attended : 0;
    const minClassesToAttend = (score < (targetPercentage * 100)) ? Math.floor(((targetPercentage * totalClasses) - totalClassesAttended) / (1 - targetPercentage)) : '--';
    return (
      <tr>
        {isHigherEducation && <td style={style}>{subject && subject.subject_code}</td>}
        <td style={style}>{subject && subject.subject_name}</td>
        <td style={style}>{totalClasses}</td>
        <td style={style}>{totalClassesAttended}</td>
        <td style={{ ...style, backgroundColor: (score < (targetPercentage * 100)) ? '#ef9a9a' : '#a5d6a7' }}>{`${score} %`}</td>
        <td style={style}>{minClassesToAttend}</td>
      </tr>
    );
  }

  renderTable() {
    const { attendance, totalAttendance } = this.props;
    const isHigherEducation = user.is_higher_education;
    const style = { padding: 20, whiteSpace: 'nowrap', borderRight: '1px solid #DFE3EA', verticalAlign: 'middle' };
    if (attendance.length === 0) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column', height: global.innerHeight - 80, alignItems: 'center', justifyContent: 'center',
        }}
        >
          <img alt="Previous icon" height={200} width={200} src={noClassTaken} style={{ marginRight: 5 }} />
          <div>No Classes taken</div>
        </div>
      );
    }
    return (
      <>
        <div style={{ marginLeft: '5%', marginTop: 30, fontSize: 26 }}>
          Attendance
        </div>
        <table
          className="table table-hover"
          style={{
            boxShadow: '1px 2px 6px #8B9DAF33', width: '90%', marginTop: 20, marginLeft: '5%', marginRight: '5%', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
          }}
        >
          <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
            <tr>
              {isHigherEducation && <th style={style}>Code</th>}
              <th style={style}>Subject</th>
              <th style={style}> Total Classes </th>
              <th style={style}>Attended</th>
              <th style={style}>Percentage</th>
              <th style={{ ...style, whiteSpace: 'normal', width: 200 }}> Consecutive classes to get 75% attendance</th>
            </tr>
          </thead>
          <tbody>
            {attendance.map((subject) => {
              return this.renderSubjectQuizScore(subject);
            })}
            {this.renderSubjectQuizScore(totalAttendance, true)}
          </tbody>
        </table>
      </>
    );
  }

  renderQuizScores() {
    const { showLoader } = this.state;
    return (
      <div className="MainPage" style={{ marginTop: 0, padding: 0 }}>
        {showLoader ? (
          <Loader />
        )
          : this.renderTable()}
      </div>
    );
  }

  render() {
    const {
      pageContentHeight,
    } = this.state;

    return (
      <Layout hideFooter>
        <SideBar pageType={SIDEBAR_TYPES.VIRTUAL_MEETINGS} />
        <div style={{ height: pageContentHeight }}>
          {this.renderQuizScores()}
        </div>
      </Layout>
    );
  }
}

Attendance.propTypes = {
  attendance: PropTypes.array.isRequired,
};

Attendance.defaultProps = {
};

const mapStateToProps = ({ virtualMeeting }) => ({
  attendance: virtualMeeting.attendance,
  totalAttendance: virtualMeeting.totalAttendance,
});

export default connect(mapStateToProps, { getAttendaceData })(Attendance);
