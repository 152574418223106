import { createBrowserHistory } from 'history';
import Api from '../helpers/Api';
import { updateLoaderState, showToast } from './global';

import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import constants from '../shared/constants';

export const UPDATE_OPENINGS = 'staticPages/UPDATE_OPENINGS';
export const DISPLAY_OPENINGS = 'staticPages/DISPLAY_OPENINGS';
export const UPDATE_APPLICATION_DATA = 'staticPages/UPDATE_APPLICATION_DATA';

const history = createBrowserHistory({
  forceRefresh: true,
});
const Constants = constants();

export const uploadApplicationData = (attachment) => async (dispatch, getState) => {
  dispatch(updateLoaderState(true));
  const { staticPages: { applicationData } } = getState();
  const sendmail = await Api({
    method: 'post',
    url: '/static_pages/upload_application',
    attachment,
    data: applicationData,

  });

  if (sendmail.success) {
    dispatch(updateLoaderState(false));
    history.push('/jobs');
    dispatch(showToast('Your application has been successfully submited.', TOAST_MESSAGE_TYPES.SUCCESS));
  }
};

export const displayDepartments = () => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: '/static_pages/get_openings',
  });

  if (response.success) {
    dispatch({
      type: UPDATE_OPENINGS,
      payload: response.data,
    });
  } else {
    history.push('/login');
  }

  dispatch(updateLoaderState(false));
};

export const getPositions = (position) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const positionData = await Api({
    method: 'get',
    url: `/static_pages/get_position?type=${position}`,
  });

  if (positionData.success) {
    dispatch({
      type: DISPLAY_OPENINGS,
      payload: positionData.data,
    });
  } else {
    history.push('/jobs');
  }
  dispatch(updateLoaderState(false));
};


export const updateApplicationData = (payload) => {
  return {
    type: UPDATE_APPLICATION_DATA,
    payload,
  };
};

export const newLeadData = (name, email, phone, product, info) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/leads',
    data: {
      phone, product, name, info, email,
    },
  });
  if (response.success) {
    dispatch(showToast(Constants.toastMessages.thankYouResponse, TOAST_MESSAGE_TYPES.SUCCESS));
  } else {
    dispatch(showToast(Constants.toastMessages.networkError, TOAST_MESSAGE_TYPES.WARNING));
  }
};
