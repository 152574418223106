import {
  GET_BOOKMARK_QUESTIONS, ADD_BOOKMARK_QUESTIONS, BOOKMARK_UPDATE_QUESTION_BOOKMARK, UPDATE_BOOKMARK_QUESTION_LOADER,
} from '../actions';

const INITIAL_STATE = {
  questions: [],
  bookmarkedQuestionIds: [],
  selectedSubject: '',
  page: 1,
  renderMoreQuestions: false,
  showLoader: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BOOKMARK_QUESTIONS:
      return {
        ...state, ...action.payload, page: 1, renderMoreQuestions: true,
      };
    case ADD_BOOKMARK_QUESTIONS:
      return {
        ...state,
        page: state.page + 1,
        questions: [...new Set([...state.questions, ...action.payload.questions])],
        bookmarkedQuestionIds: [...new Set([...state.bookmarkedQuestionIds, ...action.payload.bookmarkedQuestionIds])],
        renderMoreQuestions: action.payload.renderMoreQuestions,
      };
    case BOOKMARK_UPDATE_QUESTION_BOOKMARK:
      const { bookmarkedQuestionIds } = state;
      const { questionId, isBookmarked } = action.payload;
      if (isBookmarked) {
        bookmarkedQuestionIds.push(questionId);
      } else {
        bookmarkedQuestionIds.splice(bookmarkedQuestionIds.indexOf(questionId), 1);
      }
      return { ...state, bookmarkedQuestionIds };
    case UPDATE_BOOKMARK_QUESTION_LOADER:
      return { ...state, showLoader: action.showLoader };
    default:
      return state;
  }
};
