import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import Layout from './layout';
import { showToast } from '../actions/global';
import { sendMeetingFeedback } from '../actions/virtualMeeting';
import RatingComment from './common/feedback';

const history = createBrowserHistory({
  forceRefresh: true,
});

class VirtualMeetingFeedback extends Component {
  componentDidMount() {
    const { isModal, id, title } = this.props;
    if (isModal) {
      this.meetingId = id;
      this.name = title;
    } else {
      this.meetingId = this.props.match.params.id;
      this.name = new URLSearchParams(window.location.search).get('title');
    }
  }

  render() {
    const { isModal } = this.props;
    if (isModal) {
      return (
        <RatingComment
          heading="Live class feedback"
          name={this.name}
          onRatingTapped={() => {
            // sendEvent('VirtualMeeting - FeedbackRatingTapped', { feedbackRatingValue });
          }}
          isModal
          onSubmit={this.props.onSubmit}
          style={{ maxWidth: 500 }}
        />
      );
    }
    return (
      <Layout hideFooter>
        <div style={{
          flex: 1, justifyContent: 'center', display: 'flex', marginTop: 50,
        }}
        >
          <RatingComment
            heading="Live class feedback"
            name={this.name}
            onRatingTapped={() => {
              // sendEvent('VirtualMeeting - FeedbackRatingTapped', { feedbackRatingValue });
            }}
            onSubmit={async (rating, feedbackMessage) => {
              const isSuccess = await this.props.sendMeetingFeedback(this.meetingId, rating, feedbackMessage);
              if (isSuccess) {
                history.goBack();
              }
            }}
            style={{ maxWidth: 500, width: '80%' }}
          />
        </div>
      </Layout>
    );
  }
}

export default connect(null, { showToast, sendMeetingFeedback })(VirtualMeetingFeedback);
