import { createBrowserHistory } from 'history';
import axios from 'axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { showToast, serverError } from '../actions/index';
import { store } from '../index';
import constants from '../shared/constants';
import { getHomeDomain } from './Utils';


const history = createBrowserHistory({
  forceRefresh: true,
});

const cookies = new Cookies();
const Constants = constants();

const getToken = () => {
  const token = cookies.get('token');
  if (token) {
    return `bearer ${cookies.get('token')}`;
  }
  return '';
};

export const getUser = () => {
  if (typeof global.user !== 'undefined' && global.user && global.user !== '') {
    return global.user;
  }
  const user = cookies.get('user');
  return user || {};
};

export const expiry = () => {
  history.push('/payment');
};

const redirectToInstituteTest = (testId, message) => {
  store.dispatch(showToast(message));
  history.push(`/test/${testId}`);
};

const searchApi = (options) => {
  const onSuccess = (response) => {
    if (process.env.REACT_APP_ENV === 'development') {
      console.log('Request Successful!', response);
    }
    return response.data;
  };

  const onError = (error) => {
    if (error.response && error.response.status === 307) {
      redirectToInstituteTest(error.response.data.test_id, error.response.data.message);
      return;
    }
    if (error.response && error.response.status === 406) {
      if (error.response.data && error.response.data.redirect_domain) {
        window.location.href = error.response.data.redirect_domain;
      } else {
        // window.location.href = Constants.searchEndpoint;
      }
      return;
    }
    if (error.response && error.response.status === 410) {
      expiry();
      return;
    }

    if (process.env.REACT_APP_ENV !== 'production') {
      console.log('Request Failed:', error);

      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.log('Status:', error.response.status);
        console.log('Data:', error.response.data);
        console.log('Headers:', error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.log('Error Message:', error.message);
      }
    }
    store.dispatch(serverError());
    return error.response || error.message;
  };

  let url = `${Constants.searchEndpoint}${options.url}`;
  if (options.url.includes('?')) {
    url += `&token=${getToken()}&webAppVersionCode=${Constants.webAppVersionCode}`;
  } else {
    url += `?token=${getToken()}&webAppVersionCode=${Constants.webAppVersionCode}`;
  }
  return axios({
    method: options.method,
    url,
  })
  .then(onSuccess)
  .catch(onError);
};

export default searchApi;
