import Api from '../helpers/Api';
import Cookies from 'universal-cookie';
import { updateLoaderState } from './global';
import { calculateTime } from '../helpers/Utils';

export const GET_REPORT = 'Report/GET_REPORT';
export const CLEAR_REPORT = 'Report/CLEAR_REPORT';
export const GET_PERSONALISED_REPORT = 'Report/GET_PERSONALISED_REPORT';
export const GET_IMPROVEMENT_DATA = 'Report/GET_IMPROVEMENT_DATA';
export const REMOVE_DATA = 'Report/REMOVE_DATA';
export const UPDATE_TEST_REPORTS = 'Report/UPDATE_TEST_REPORTS';
export const UPDATE_IMPROVEMENT_TYPE = 'Report/UPDATE_IMPROVEMENT_TYPE';

const cookies = new Cookies();

export const getAssignnmentReport = (id, objectType, sessionType) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/student_app/${id}/get_assignment_report?object_type=${objectType}&session_type=${sessionType}`,
  });
  if (response.success) {
    const payload = {
      subjects: response.subjects,
      reportData: response.report_data,
      topicProgress: response.topic_progress,
    };
    dispatch({
      type: GET_PERSONALISED_REPORT,
      payload,
    });
  }
};

export const getTestReports = (id) => async (dispatch) => {
  removeAllData({
    type: REMOVE_DATA,
  });
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/tests/${id}/get_student_test_reports`,
  });
  if (response.success) {
    const payload = {
      data: response.data,
      testInfo: response.test_info,
      isAccessibleStudentData: response.is_accessible_student_data,
      isDataNotPresent: response.is_data_not_present,
      hideQuestionRevise: response.hide_test_question_revise_experience,
      hideLeaderBoard: response.hide_leaderboard,
      testReportInsights: response.test_report_insights,
    };
    dispatch({
      type: UPDATE_TEST_REPORTS,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export function removeAllData() {
  return (dispatch) => dispatch({
    type: REMOVE_DATA,
  });
}


export const getReport = (testId, isTest = true) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  let payload = cookies.get('initialTestReport');
  if (!payload || !payload.testInfo || payload.testInfo.id !== parseInt(testId, 10)) {
    const response = await Api({
      method: 'get',
      url: `/student_app/${testId}/get_report?is_test=${isTest}`,
    });
    if (response.success) {
      dispatch(updateLoaderState(false));
      payload = {
        subjects: response.subjects,
        testInfo: response.test_info,
        reportData: response.report_data,
      };
    }
  }
  dispatch({
    type: GET_REPORT,
    payload,
  });
  dispatch(updateLoaderState(false));
};

export const clearReport = () => async (dispatch) => {
  dispatch({ type: CLEAR_REPORT });
};

export const updateSelectedImprovementType = (improvementType) => async (dispatch) => {
  dispatch({
    type: UPDATE_IMPROVEMENT_TYPE,
    improvementType,
  });
};

export const getImprovementsData = (testId, type) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/student_app/${testId}/get_improvement_data`,
  });
  if (response.success) {
    const optionSelectedQuestionList = {};
    const questionsVisited = [];
    const questionTimeSpent = {};
    response.question_responses.forEach((question) => {
      if (question.response !== 0) {
        optionSelectedQuestionList[question.id] = question.response;
      }
      questionTimeSpent[question.id] = calculateTime(Math.ceil(question.question_time_spent / 1000) || 0);
      if (question.question_time_spent > 0) {
        questionsVisited.push(question.id);
      }
    });
    const payload = {
      selectedImprovementType: type,
      improvements: response.improvements,
      questions: response.question_data,
      questionsVisited,
      questionTimeSpent,
      optionSelectedQuestionList,
    };
    dispatch({
      type: GET_IMPROVEMENT_DATA,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};
