import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import Text from './text';
import StyleConstants from '../shared/styleConstants/styles';
import '../shared/styleConstants/loader.css';
import bookLoader from '../lottie/book'; 

class Loader extends PureComponent {
  state = {
    screenHeight: 0,
    isStopped: false,
    isPaused: false,
  };

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
  }
  render() {
    const { height, customLoader } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: customLoader || bookLoader,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <div className="loader" style={{ height: height ? height : this.state.screenHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Lottie 
          options={defaultOptions}
          height={300}
          width={300}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}/>
      </div>
    )
  }
}

Loader.propTypes = {
  height: PropTypes.number,
  compact: PropTypes.bool,
}

export default Loader;
