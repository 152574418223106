import React, { Component } from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';
import { isMobileDevice } from '../../helpers/Utils';
import Constants from '../../shared/constants';
import { sendEvent } from '../../helpers/Analytics';

const isMobile = isMobileDevice();
const supportContact = Constants().contact;
export default class Footer extends Component {
  renderWebFooter() {
    const { showTeacherLink, showStudentLink } = this.props;
    const enginneringCoarses = ['JEE Main & Advance', 'KCET', 'MHCET', 'APEAMCET'];
    const medicalCoarses = ['NEET', 'AIIMS', 'JIPMER'];
    return (
      <div className="footer-container flex-column-container">
        <div style={{ display: 'flex', marginBottom: 45, justifyContent: 'space-between' }}>
          <div className="flex-column-container" style={{ justifyContent: 'space-between' }}>
            <div>
              {showTeacherLink
                && (
                <Link to="/">
                  <div className="main-topic" style={{ color: '#3C3C3C' }}>Are you a Teacher?</div>
                </Link>
                )}
              {showStudentLink
                && (
                <Link to="/">
                  <div className="main-topic" style={{ color: '#3C3C3C' }}>Are you a Student?</div>
                </Link>
                )}
              <Link to="/careers">
                <div className="main-topic" style={{ margin: '0px 10px 25px 0px', display: 'flex', alignItems: 'center' }}>Careers</div>
              </Link>
              <div className="flex-row-container" style={{ marginLeft: -10 }}>
                <div><a onClick={() => { sendEvent('Footer - YouTubeIconClicked'); }} href="https://www.youtube.com/channel/UCVzzTmIWLd5uKUlU7LuakSQ"><img src="/images/landing_institute/youtube.svg" alt="NEET_JEE_analysis_you_tube" /></a></div>
                <div><a onClick={() => { sendEvent('Footer - LinkedInIconClicked'); }} href="https://www.linkedin.com/company/ideaboard/about/"><img src="/images/landing_institute/linkedin.svg" alt="NEET_JEE_analysis_linked_in" /></a></div>
                <div><a onClick={() => { sendEvent('Footer - FacebookIconClicked'); }} href="https://www.facebook.com/ideaboardtech/"><img src="/images/landing_institute/fb.svg" alt="NEET_JEE_analysis_facebook" /></a></div>
              </div>
            </div>
          </div>
          <div className="flex-column-container">
            <div className="main-topic list-topic">PRODUCTS</div>
            <ScrollLink offset={-60} to="Question Board" spy smooth duration={500}><div className="list-topic">Question Board</div></ScrollLink>
            <ScrollLink offset={-60} to="Mentor Board" spy smooth duration={500}><div className="list-topic">Mentor Board</div></ScrollLink>
            <ScrollLink offset={-60} to="Insight Board" spy smooth duration={500}><div className="list-topic">Insight Board</div></ScrollLink>
          </div>

          <div className="flex-column-container" style={{ justifyContent: 'space-between' }}>
            <div className="flex-row-container">
              <div className="flex-column-container" style={{ paddingRight: 80 }}>
                <div className="main-topic">ENGINEERING</div>
                {enginneringCoarses.map((coarse) => {
                  return (<div className="list-topic">{coarse}</div>);
                })}
              </div>
              <div className="flex-column-container">
                <div className="main-topic">MEDICAL</div>
                {medicalCoarses.map((coarse) => {
                  return (<div className="list-topic">{coarse}</div>);
                })}
              </div>
            </div>
          </div>


          <div className="flex-column-container" style={{ justifyContent: 'space-between' }}>
            <div style={{ textAlign: 'right', marginRight: -10 }}>
              <a href={`${Constants().playStoreUrl}=utm_source%3DibWebsite%26utm_medium%3Daccounts%26utm_term%3DgetItOnGooglePlay`}><img className="google-play" alt="NEET_JEE_analysis_app" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" width="140px" /></a>
            </div>
            <div className="flex-column-container" style={{ textAlign: 'right' }}>
              <a href="https://www.google.com/maps/place/IdeaBoard+Technologies/@13.0105415,77.6492807,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae16d491ffcbdd:0xb72fd2219e491927!8m2!3d13.0105415!4d77.6514694" target="_blank">
                <div className="main-topic" style={{ marginBottom: 10, color: '#3C3C3C' }}>OFFICE ADDRESS</div>
                <div style={{ color: '#5D5D5D' }}>802, 9th B Main Rd, </div>
                <div style={{ color: '#5D5D5D' }}>1st Block, HRBR Layout, Banaswadi, </div>
                <div style={{ color: '#5D5D5D' }}>Bengaluru, Karnataka 560043</div>
              </a>
            </div>
          </div>


        </div>
        <div className="flex-row-container" style={{ justifyContent: 'space-around', padding: '0px 160px' }}>
          <Link to="/terms"><div className="list-topic" style={{ cursor: 'pointer' }}>Terms Of Services</div></Link>
          <a href="mailto:support@ideaboard.xyz"><div className="list-topic" style={{ cursor: 'pointer' }}>Contact Us</div></a>
          <Link to="/privacy">
            <div className="list-topic" style={{ cursor: 'pointer' }}>Privacy Policies</div>
          </Link>
        </div>
      </div>
    );
  }

  renderMobileFooter() {
    const { showTeacherLink, showStudentLink } = this.props;
    return (
      <div style={{ backgroundColor: '#F2F2F2', padding: '20px 0px 20px 0px' }}>
        <div className="footer1-mobile">
          <div className="connect-with-us-mobile">
            {showTeacherLink && (
              <Link to="/">
                <div className="main-topic" style={{ color: '#3C3C3C' }}>Are you a Teacher?</div>
              </Link>
            )}
            {showStudentLink && (
              <Link to="/">
                <div className="main-topic" style={{ color: '#3C3C3C' }}>Are you a Student?</div>
              </Link>
            )}
            <Link to="/careers">
              <div className="main-topic" style={{ margin: '0px 10px 25px 0px', display: 'flex', alignItems: 'center' }}>Careers</div>
            </Link>

          </div>
          <div className="connect-mobile">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <a href="https://www.youtube.com/channel/UCVzzTmIWLd5uKUlU7LuakSQ">
                  <img src="/images/landing_institute/youtube.svg" alt="NEET_JEE_you_tube" />
                </a>
              </div>
              <div>
                <a href="https://www.linkedin.com/company/ideaboard/about/">
                  <img src="/images/landing_institute/linkedin.svg" alt="NEET_JEE_linked_in" />
                </a>
              </div>
              <div>
                <a href="https://www.facebook.com/ideaboardtech/">
                  <img src="/images/landing_institute/fb.svg" alt="NEET_JEE_facebook" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer2-mobile">
          <div className="with-us">
            <div className="address" style={{ textAlign: 'center' }}>
              <a href="https://www.google.com/maps/place/IdeaBoard+Technologies/@13.0105415,77.6492807,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae16d491ffcbdd:0xb72fd2219e491927!8m2!3d13.0105415!4d77.6514694" target="_blank">
                <div className="connect-with-us-mobile" style={{ padding: '0px 0px 10px 0px', color: '#3C3C3C' }}>OFFICE ADDRESS</div>
                <div style={{ color: '#5D5D5D' }}>802, 9th B Main Rd, </div>
                <div style={{ color: '#5D5D5D' }}>1st Block HRBR Layout, Banasawadi, </div>
                <div style={{ color: '#5D5D5D' }}>Bengaluru, Karnataka 560043</div>
              </a>
            </div>
          </div>
        </div>
        <div className="footer3-mobile" style={{ paddingTop: '10px', fontSize: '12px' }}>
          <Link to="/terms">
            <div style={{ color: '#5D5D5D', padding: '0px 20px 0px 0px' }}>Terms Of Service</div>
          </Link>
          <a href={`tel:${supportContact}`}><div style={{ color: '#5D5D5D', padding: '0px 20px 0px 0px' }}>Contact Us</div></a>
          <Link to="/privacy">
            <div style={{ color: '#5D5D5D', padding: '0px 20px 0px 0px' }}>PrivacyPolicies</div>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {isMobile ? this.renderMobileFooter() : this.renderWebFooter()}
      </div>
    );
  }
}

Footer.propTypes = {
  showTeacherLink: PropTypes.bool,
  showStudentLink: PropTypes.bool,
};


Footer.defaultProps = {
  showTeacherLink: true,
  showStudentLink: true,
};
