import React, { PureComponent } from 'react';
import queryString from 'query-string';
import constants from '../shared/constants';
import { sendEvent } from '../helpers/Analytics';
import { encodeURI } from '../helpers/Utils';

const Constants = constants();

export default class Playstore extends PureComponent {
  constructor(props) {
    super(props);
    this.queryParams = queryString.parse(props.location.search);
    this.navigate = this.navigate.bind(this);
  }

  navigate() {
    sendEvent('Redirect - Playstore');
    window.location.replace(Constants.appDeepLink);
    let utmParam = '';
    if (Object.keys(this.queryParams).length) {
      if (this.queryParams.referrer) {
        utmParam = `referrer=${encodeURIComponent(this.queryParams.referrer)}`;
      } else {
        const hasUtmRegex = Object.keys(this.queryParams).some((key) => {
          return /utm_/.test(key);
        });
        if (hasUtmRegex) {
          utmParam = `referrer=${encodeURIComponent(encodeURI(this.queryParams))}`;
        } else {
          utmParam = Constants.defaultReferrer;
        }
      }
    } else {
      utmParam = Constants.defaultReferrer;
    }
    window.location.replace(`https://play.google.com/store/apps/details?id=${Constants.appId}&${utmParam}`);
  }

  render() {
    return (
      <div
        ref={(ref) => {
          ref.click();
        }}
        role="presentation"
        onKeyDown={this.navigate}
        onClick={this.navigate}
      />
    );
  }
}
