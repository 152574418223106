import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { Header, Footer } from '../common';
import Constants from '../../shared/constants';

const constants = Constants();

const cookies = new Cookies();

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenHeight: '',
    };
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
  }

  render() {
    const { screenHeight } = this.state;
    const {
      showTeacherLink, showStudentLink, children, notifications, isTestReview, isSolutionBoard, hideHeader,
    } = this.props;
    let { hideFooter } = this.props;
    const searchParams = new URLSearchParams(window.location.search);
    const forceRemoveHeader = searchParams.get('force_hide_header') === 'true';
    const userProfile = cookies.get('user', { secure: constants.cookie.secure, domain: constants.cookie.domainRegex });
    if (searchParams.get('force_hide_header') === 'true') {
      hideFooter = true;
    }
    if (searchParams.get('utm_source') != null) {
      const utmParams = window.location.search.spice(1).split('&');
      const utmDetails = {};
      utmParams.forEach((param) => {
        const params = param.split('=');
        utmDetails[params[0]] = decodeURIComponent(params[1] || '');
      });
      cookies.set('utmDetails', JSON.stringify(utmDetails), { domain: Constants.cookie.domainRegex, secure: Constants.cookie.secure });
    }

    return (
      <div style={{
        backgroundColor: '#FCFCFC', minHeight: screenHeight, maxWidth: '100%', overflowX: 'hidden', paddingTop: forceRemoveHeader ? 15 : isSolutionBoard ? 0 : 70,
      }}
      >
        {!forceRemoveHeader && !isSolutionBoard && !hideHeader && <Header userProfile={userProfile} notifications={notifications} isTestReview={isTestReview} isSolutionBoard={isSolutionBoard} />}
        {children}
        {!hideFooter && <Footer showTeacherLink={showTeacherLink} showStudentLink={showStudentLink} />}
      </div>
    );
  }
}

Layout.propTypes = {
  showTeacherLink: PropTypes.bool,
  hideHeader: PropTypes.bool,
  showStudentLink: PropTypes.bool,
  notifications: PropTypes.array,
  hideFooter: PropTypes.bool,
  isTestReview: PropTypes.bool,
};


Layout.defaultProps = {
  showTeacherLink: true,
  showStudentLink: true,
  hideHeader: false,
  hideFooter: false,
  notifications: [],
  isTestReview: false,
};

const mapStateToProps = ({ home, test }) => ({
  notifications: home.notifications,
  isTestReview: test.isTestReview,
});

export default connect(mapStateToProps)(Layout);
