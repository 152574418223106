import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import SideBar from './sidebar';
import Text from './text';
import HomeLanding from './staticpages/home';
import TestPage from './homePage/testPage';
import TrainPage from './homePage/trainPage';
import ModalView from './modal';
import constants from '../shared/constants';
import { isMobileDevice } from '../helpers/Utils';
import { SIDEBAR_TYPES, SIDEBAR_TEST_SUB_TYPES } from '../shared/constants/fieldTypes';
import { getHomeData } from '../actions/index';
import { sendEvent } from '../helpers/Analytics';
import successIcon from '../shared/images/common/check.svg';

const cookies = new Cookies();
const Constants = constants();

const ASSIGNMENT = 'assignment';

class Home extends PureComponent {
  constructor(props) {
    super(props);
    const paymentSuccessful = cookies.get('paymentSuccessful') && JSON.parse(cookies.get('paymentSuccessful'));
    const showModal = (isMobileDevice() || paymentSuccessful);
    this.state = {
      showModal,
      paymentSuccessful,
    };
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    const { type } = this.props.match.params;
    const { subjects, chapters } = this.props;
    const isInstituteAssignment = queryString.parse(this.props.location.search).type === ASSIGNMENT;
    const user = cookies.get('user');
    if (user && !user.is_higher_education) {
      const isTest = type && Object.keys(SIDEBAR_TEST_SUB_TYPES).includes(type.toUpperCase());
      if (subjects && subjects.length === 0 && chapters.length === 0) {
        if (type) {
          this.props.getHomeData(isTest ? 'tests' : 'chapter', type, isInstituteAssignment);
        } else {
          this.props.getHomeData('chapter');
        }
      }
    }
  }

  onCloseModal() {
    this.setState({ showModal: false });
    if (this.state.paymentSuccessful) {
      cookies.remove('paymentSuccessful', { path: '/', secure: Constants.cookie.secure });
    }
  }

  modalMessage(paymentSuccessful) {
    if (paymentSuccessful) {
      sendEvent('Payments - PaymentSuccessfulDisplayed');
      return <Text text="Happy learning! Let’s achieve something awesome together!" />;
    }
    return <Text text="Please download app from the Play Store or login on your desktop/laptop." />;
  }

  renderHomePageContent(chapterLength) {
    if (chapterLength > 0) {
      return <TrainPage />;
    }
    const user = cookies.get('user');
    return <TestPage isIdeaboardStudent={user.isIdeaboardStudent} />;
  }

  render() {
    if (Object.keys(this.props.userProfile).length === 0) {
      return <HomeLanding />;
    }
    const { chapters } = this.props;
    const { showModal, paymentSuccessful } = this.state;
    const header = paymentSuccessful ? 'Payment Successful' : showModal ? 'Download App' : '';
    const headerImage = paymentSuccessful ? successIcon : '';

    return (
      <div>
        {showModal
          && (
          <ModalView isModalVisible={showModal} onClose={this.onCloseModal} center header={header} maxWidth={500} image={headerImage}>
            {this.modalMessage(paymentSuccessful)}
            <div className="modalButtonView">
              <div id="Home-Close-Button" className={`${paymentSuccessful ? 'Primary-Button' : 'Secondary-Button'} Button`} style={{ width: 100 }} onClick={this.onCloseModal}> Close </div>
              {!paymentSuccessful
                && (
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.ideaboard&utm_source=Website&utm_campaign=Header&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  className="Primary-Button Button ml-3"
                  style={{ width: 100 }}
                >
                  Get App
                </a>
                )}
            </div>
          </ModalView>
          )}
        <SideBar pageType={SIDEBAR_TYPES.HOME} />
        {this.renderHomePageContent(chapters.length)}
      </div>
    );
  }
}

Home.propTypes = {
  getHomeData: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  subjects: PropTypes.array,
  chapters: PropTypes.array,
};

Home.defaultProps = {
  userProfile: {},
  subjects: [],
  chapters: [],
};

const mapStateToProps = ({ home }) => ({
  chapters: home.chapters,
  subjects: home.subjects,
  testList: home.testList,
});

export default connect(mapStateToProps, { getHomeData })(Home);
