import { scroller } from 'react-scroll';
import {
  GET_TRAIN_QUESTIONS, UPDATE_TRAIN_CURRENT_QUESTION_INDEX, UPDATE_TRAIN_QUESTION_SELECTED_OPTION,
  GET_TRAIN_PROGRESS_DATA, UPDATE_TRAIN_QUESTION_TIME_SPENT, CLEAR_UPLOADED_RESULTS, FINISH_SESSION,
  QUESTION_VERIFIED, UPDATE_SOLUTION_REQUEST_TIME, TRAIN_UPDATE_QUESTION_BOOKMARK,
  SHOW_TRAIN_SESSION_FINISH_MODAL, UPDATE_QUESTION_LIST, UPDATE_TRAIN_NUMERICAL_QUESTION_DATA,
} from '../actions';
import { QUESTION_TYPES } from '../shared/constants/fieldTypes';
import {
  getTrainQuestionIndexMapping, getTestProgressData, updateQuestionCache, calculateTime,
} from '../helpers/Utils';

const INITIAL_STATE = {
  testInfo: {},
  questions: [],
  allQuestions: [],
  questionTimeSpent: {},
  questionVerifiedAt: {},
  questionsVerified: {},
  resultData: {},
  questionCount: 0,
  optionSelectedQuestionList: {},
  currentIndex: 0,
  currentQuestion: {},
  selectedOption: [],
  questionIds: [],
  questionSubjectMapping: {},
  questionType: {},
  questionResponseList: [],
  questionSideBarData: [],
  questionsVisited: [],
  shouldUploadResponses: false,
  unAttemptedCount: 0,
  unVisitedCount: 0,
  startTime: new Date(),
  currentQuestionTimeSpent: {},
  currentquestionVerified: false,
  solutionRequestTime: {},
  bookmarkedQuestionIds: [],
  richResponses: {},
  isCurrentQuestionBookmarked: false,
  showSessionFinishModal: false,
  questionSessionItem: {},
  questionResponseVersion: {},
  questionIndexMapping: {},
  difficultyQuestionIndexes: [],
};

const updateCache = (state, updateFields = {}, cacheName = 'QuestionResponses') => {
  const payload = {
    testInfo: state.testInfo,
    sessionId: state.testInfo.session_id,
    richResponses: state.richResponses,
    currentIndex: state.currentIndex,
    questionsVisited: state.questionsVisited,
    questionSubjectMapping: state.questionSubjectMapping,
    optionSelectedQuestionList: state.optionSelectedQuestionList,
    questionTimeSpent: state.questionTimeSpent,
    questionType: state.questionType,
    questionVerifiedAt: state.questionVerifiedAt,
    questionsVerified: state.questionsVerified,
    solutionRequestTime: state.solutionRequestTime,
    questionSessionItem: state.questionSessionItem,
    questionResponseVersion: state.questionResponseVersion,
  };
  Object.keys(updateFields).forEach((type) => {
    payload[type] = updateFields[type];
  });
  updateQuestionCache(state.questionIds, payload, cacheName, false);
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRAIN_QUESTIONS:
      return { ...state, ...action.payload, startTime: new Date() };
    case UPDATE_TRAIN_CURRENT_QUESTION_INDEX:
      if (action.currentIndex < 0) {
        return { ...state, currentIndex: action.currentIndex };
      }
      let question = state.questions[action.currentIndex];
      let { currentIndex } = action;
      if (action.isQuestionReference) {
        question = state.allQuestions[currentIndex];
        currentIndex = state.questions.indexOf(question);
      }
      if (!question) {
        return state;
      }
      const questionsVisited = state.testInfo.is_session_review ? state.questionsVisited : [...new Set([...state.questionsVisited, question.id])];
      updateCache(state, {
        currentIndex,
        questionsVisited,
      });
      scroller.scrollTo(`question_ref_${question.id}`, {
        offset: -15,
        containerId: 'questionListView',
      });
      const currentquestionVerified = state.questionsVerified[question.id];
      const { questionTimeSpent } = state;
      const currentQuestionTimeSpent = calculateTime(Math.ceil(questionTimeSpent[question.id] / 1000) || 0);
      if (!state.testInfo.is_session_review) {
        questionTimeSpent[state.currentQuestion.id] = (questionTimeSpent[state.currentQuestion.id] || 0) + (new Date() - state.startTime);
      }
      return {
        ...state,
        currentQuestionTimeSpent,
        currentquestionVerified,
        questionsVisited,
        questionTimeSpent,
        currentIndex,
        startTime: new Date(),
        unVisitedCount: state.questionCount - questionsVisited.length,
        unAttemptedCount: questionsVisited.length - Object.keys(state.questionsVerified).length,
        questionIndex: state.allQuestions.indexOf(question),
        currentQuestion: question,
        selectedOption: state.optionSelectedQuestionList[question.id] || [],
        isCurrentQuestionBookmarked: state.bookmarkedQuestionIds.includes(question.id),
        questionSolutionRequested: parseInt(state.solutionRequestTime[question.id], 10) > 0,
        questionIndexMapping: getTrainQuestionIndexMapping(state.allQuestions, state.optionSelectedQuestionList, questionsVisited, state.questionsVerified, state.solutionRequestTime, state.richResponses),
      };
    case UPDATE_TRAIN_NUMERICAL_QUESTION_DATA:
      const { richResponses } = state;
      const responseList = state.optionSelectedQuestionList;
      const verifiedAt = state.questionVerifiedAt;
      richResponses[state.currentQuestion.id] = action.payload.richResponse;
      if (action.payload.richResponse.length > 0) {
        responseList[state.currentQuestion.id] = 0;
        verifiedAt[state.currentQuestion.id] = new Date().toJSON();
      } else {
        delete responseList[state.currentQuestion.id];
      }
      updateCache(state, { optionSelectedQuestionList: responseList, richResponses });
      const newQuestionResponseList = [...new Set([...state.questionResponseList, state.currentQuestion.id])];
      const shouldUploadQuestionResponses = newQuestionResponseList.length >= 5;
      if (shouldUploadQuestionResponses) {
        updateCache(state, { questionsVisited: newQuestionResponseList, questionVerifiedAt: verifiedAt }, 'UploadResult');
      }
      return {
        ...state,
        richResponses,
        questionVerifiedAt: verifiedAt,
        richResponse: action.payload.richResponse,
        shouldUploadResponses: shouldUploadQuestionResponses,
        questionIndexMapping: getTrainQuestionIndexMapping(state.allQuestions,
          state.optionSelectedQuestionList, state.questionsVisited, state.questionsVerified, state.solutionRequestTime, state.richResponses),
        optionSelectedQuestionList: responseList,
      };

    case UPDATE_TRAIN_QUESTION_SELECTED_OPTION:
      const { currentQuestion } = state;
      const selectedList = state.optionSelectedQuestionList;
      if (selectedList[currentQuestion.id].includes(action.payload.optionIndex)) {
        const index = selectedList[currentQuestion.id].indexOf(action.payload.optionIndex);
        selectedList[currentQuestion.id].splice(index, 1);
      } else if (currentQuestion.question_type === QUESTION_TYPES.MULTIPLE_OPTION) {
        selectedList[currentQuestion.id].push(action.payload.optionIndex);
      } else {
        selectedList[currentQuestion.id] = [action.payload.optionIndex];
      }
      updateCache(state, { optionSelectedQuestionList: { ...selectedList } });
      let shouldUploadResponses = false;
      const questionResponseList = [...new Set([...state.questionResponseList, currentQuestion.id])];
      if (questionResponseList.length >= 5) {
        shouldUploadResponses = true;
        updateCache(state, { questionsVisited: questionResponseList }, 'UploadResult');
      }
      return {
        ...state,
        shouldUploadResponses,
        questionResponseList: [...new Set([...state.questionResponseList, currentQuestion.id])],
        optionSelectedQuestionList: { ...selectedList },
        questionIndexMapping: getTrainQuestionIndexMapping(state.allQuestions, state.optionSelectedQuestionList, state.questionsVisited, state.questionsVerified, state.solutionRequestTime, state.richResponses),
        selectedOption: [...(selectedList[currentQuestion.id] || [])],
      };
    case GET_TRAIN_PROGRESS_DATA:
      updateCache(state);
      const progressData = getTestProgressData(state.questionCount, Object.keys(state.optionSelectedQuestionList).length, state.questionsVisited.length, state.testDuration);
      return { ...state, ...progressData };
    case QUESTION_VERIFIED:
      const verifedQuestions = state.questionsVerified;
      const verificationTime = state.questionVerifiedAt;
      verificationTime[state.currentQuestion.id] = new Date().toJSON();
      verifedQuestions[state.currentQuestion.id] = true;
      updateCache(state, {
        questionsVerified: verifedQuestions,
        questionVerifiedAt: verificationTime,
      });
      return {
        ...state,
        currentquestionVerified: true,
        questionsVerified: verifedQuestions,
        questionsVerifiedAt: verificationTime,
        unAttemptedCount: state.questionCount - Object.keys(verifedQuestions).length - state.unVisitedCount,
        questionIndexMapping: getTrainQuestionIndexMapping(state.allQuestions, state.optionSelectedQuestionList, state.questionsVisited, verifedQuestions, state.solutionRequestTime, state.richResponses),
      };
    case UPDATE_SOLUTION_REQUEST_TIME:
      const { solutionRequestTime } = state;
      const questionList = state.optionSelectedQuestionList;
      questionList[state.currentQuestion.id] = 0;
      solutionRequestTime[state.currentQuestion.id] = (state.questionTimeSpent[state.currentQuestion.id] || 0) + (new Date() - state.startTime);
      return {
        ...state,
        solutionRequestTime,
        optionSelectedQuestionList: questionList,
        questionSolutionRequested: true,
      };
    case UPDATE_TRAIN_QUESTION_TIME_SPENT:
      const timeSpent = state.questionTimeSpent;
      timeSpent[state.currentQuestion.id] = (timeSpent[state.currentQuestion.id] || 0) + (new Date() - state.startTime);
      updateCache(state, { questionTimeSpent: timeSpent });
      return {
        ...state,
        questionTimeSpent: timeSpent,
        startTime: new Date(),
      };
    case CLEAR_UPLOADED_RESULTS:
      return { ...state, questionResponseList: [] };
    case FINISH_SESSION:
      updateCache(state);
      return state;
    case TRAIN_UPDATE_QUESTION_BOOKMARK:
      const { bookmarkedQuestionIds } = state;
      const { questionId, isBookmarked } = action.payload;
      if (isBookmarked) {
        bookmarkedQuestionIds.push(questionId);
      } else {
        bookmarkedQuestionIds.splice(bookmarkedQuestionIds.indexOf(questionId), 1);
      }
      return { ...state, bookmarkedQuestionIds, isCurrentQuestionBookmarked: isBookmarked };
    case SHOW_TRAIN_SESSION_FINISH_MODAL:
      return { ...state, showSessionFinishModal: action.showModal };
    case UPDATE_QUESTION_LIST:
      const { difficulty, selectedTypes } = action.payload;
      const availableQuestions = [];
      state.allQuestions.forEach((questionData, index) => {
        let shouldFilterQuestion = false;
        if (difficulty.length > 0) {
          shouldFilterQuestion = !difficulty.includes(questionData.complexity);
        }
        if (!shouldFilterQuestion && selectedTypes.length > 0) {
          shouldFilterQuestion = !state.questionIndexMapping[index + 1].some((type) => selectedTypes.includes(type));
        }
        if (!shouldFilterQuestion) {
          availableQuestions.push(questionData);
        }
      });
      const questionIndexes = [];
      availableQuestions.forEach((questionData) => {
        questionIndexes.push(state.allQuestions.indexOf(questionData) + 1);
      });
      return {
        ...state, questions: availableQuestions, questionCount: availableQuestions.length, difficultyQuestionIndexes: questionIndexes,
      };
    default:
      return state;
  }
};
