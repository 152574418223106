import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StyleConstants from '../../shared/styleConstants/styles';
import Text from '../text';
import { getTestReports } from '../../actions/index';

class TestSummaryHeader extends PureComponent {
  renderReportStats(selectedSubject, data, value) {
    return (
      <div style={{
        display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-around',
      }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginLeft: (value === 'unattempted' ? 0 : '4%'), marginRight: '2%' }}>
            <Text
              text={((selectedSubject !== '') && (typeof data.overview[selectedSubject] !== 'undefined')) ? `${data.overview[selectedSubject][value]}` : `${data.overview.overall[value]}`}
              style={{
                color: StyleConstants.color.dark,
                fontFamily: StyleConstants.textFont.header,
                fontWeight: StyleConstants.textWeight.bold,
              }}
            />
          </div>
          <div style={{ marginRight: '4%' }}>
            <Text text={`${value.replace(/\b(\w)/g, (s) => s.toUpperCase())}`} style={{ color: StyleConstants.color.mediumLight, fontSize: StyleConstants.textSize.text }} />
          </div>
        </div>
        {value !== 'unattempted'
        && <div style={{ color: '#CBCBCB', fontSize: StyleConstants.textSize.header }}>|</div>}
      </div>
    );
  }

  renderRank(data, type) {
    const { hideLeaderBoard } = this.props;
    if (hideLeaderBoard) {
      return ((typeof data.overview[type] !== 'undefined') && (typeof data.overview[type].question_count !== 'undefined') && (((data.overview[type].question_count) - (data.overview[type].unattempted)) > 0) ? (`${Math.round((data.overview[type].correct * 100) / (data.overview[type].question_count - data.overview[type].unattempted))}%`) : '0%');
    }
    return ((typeof data.score_rank_hash[type] !== 'undefined') && (typeof data.overview[type] !== 'undefined') && (typeof data.overview[type].total !== 'undefined') && (data.overview[type].total !== null)) ? `${data.score_rank_hash[type][data.overview[type].total] || '-'}/${data.rank_data_length || 1}` : `${data.rank_data_length}/${data.rank_data_length || 1}`;
  }

  renderScore(data, type) {
    return ((typeof data.overview[type] !== 'undefined') && (typeof data.overview[type].total !== 'undefined') && (data.overview[type].total !== null)) ? `${data.overview[type].total}/${data.total_scores[type]}` : `${data.total_scores[type]}/${data.total_scores[type]}`;
  }

  renderBasicTestDetails(selectedSubject, data, value) {
    const { hideLeaderBoard } = this.props;
    return (
      <div
        className="Simple-Card-View"
        style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 'calc(50% - 0.5em)', padding: '30px 0px 15px 0px',
        }}
      >
        <Text
          text={(value === 'rank' ? (selectedSubject !== '' ? this.renderRank(data, selectedSubject) : this.renderRank(data, 'overall')) : selectedSubject !== '' ? this.renderScore(data, selectedSubject) : this.renderScore(data, 'overall'))}
          style={{
            color: StyleConstants.color.white, fontFamily: StyleConstants.textFont.header, fontSize: StyleConstants.textSize.headerBig, fontWeight: StyleConstants.textWeight.bold, lineHeight: '25px',
          }}
        />
        <Text text={value === 'rank' ? hideLeaderBoard ? 'Accuracy' : 'Rank' : 'Score'} style={{ color: StyleConstants.color.white, fontSize: StyleConstants.textSize.text }} />
      </div>
    );
  }

  render() {
    const { selectedSubject, data, leaderBoard } = this.props;
    const topList = selectedSubject !== '' && data[selectedSubject] ? data[selectedSubject].top_list : (data.overall ? data.overall.top_list : []);
    const reportStats = ['correct', 'incorrect', 'unattempted'];
    const rankDetails = ['total', 'rank'];
    if (data.overview) {
      return (
        <div style={{
          backgroundImage: 'linear-gradient(230deg, rgb(74, 159, 245) 5%, rgb(42, 245, 152) 150%)',
          paddingTop: 60,
          paddingBottom: 15,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 44,
        }}
        >
          { leaderBoard
            ? (
              <div>
                <div className="Header-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  {
                  topList && topList.length > 0
                  && topList.map((details) => (details.student_id !== data.student.id ? (
                    this.props.renderStudentDetail(details, false)
                  ) : (
                    this.props.renderStudentDetail(details, true)
                  )))
                  }
                </div>
              </div>
            )
            : (
              <div className="Header-content" style={{ display: 'flex', flexDirection: 'column', width: 500 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                  {rankDetails.map((rankDetail) => {
                    return this.renderBasicTestDetails(selectedSubject, data, rankDetail);
                  }) }
                </div>
                <div style={{
                  display: 'flex', flexDirection: 'column', width: '100%', marginTop: '25px', boxShadow: '0px 0px 12px  rgba(65, 61, 74, 0.1)',
                }}
                >
                  <div
                    className="Simple-Card-View"
                    style={{
                      height: 35, padding: 0, borderRadius: 4, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: StyleConstants.color.white,
                    }}
                  >
                    {reportStats.map((report) => {
                      return this.renderReportStats(selectedSubject, data, report);
                    })}
                  </div>
                </div>
              </div>
            )}
        </div>
      );
    }
    return <div />;
  }
}

TestSummaryHeader.propTypes = {
  data: PropTypes.object,
  leaderBoard: PropTypes.bool,
  renderStudentDetail: PropTypes.func.isRequired,
  hideLeaderBoard: PropTypes.bool,
};

TestSummaryHeader.defaultProps = {
  data: {},
  leaderBoard: false,
  hideLeaderBoard: true,
};

const mapStateToProps = ({ report }) => ({
  data: report.data,
  hideLeaderBoard: report.hideLeaderBoard,
});

export default connect(mapStateToProps, { getTestReports })(TestSummaryHeader);
