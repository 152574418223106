import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import SideBar from './sidebar';
import Text from './text';
import StyleConstants from '../shared/styleConstants/styles';
import { OBJECT_TYPES } from '../shared/constants/fieldTypes';
import { getAssignnmentReport } from '../actions/index';
import { calculateCountdown } from '../helpers/Utils';
import { sendEvent } from '../helpers/Analytics';
import Layout from './layout';

class PersonalizedAssignmentReport extends PureComponent {
  componentDidMount() {
    const { id } = this.props.match.params;
    const queryParams = queryString.parse(this.props.location.search);
    this.props.getAssignnmentReport(id, queryParams.object_type, queryParams.session_type);
  }

  renderSummaryHeader(text) {
    return (
      <div style={{
        backgroundColor: StyleConstants.backgroundColor.header, alignItems: 'center', height: 40, justifyContent: 'center', display: 'flex',
      }}
      >
        <Text text={text} />
      </div>
    );
  }

  renderStats(type, value) {
    return (
      <div className="d-flex" style={{ alignItems: 'center' }}>
        <div className="Circle ml-2 mr-2" style={{ width: 6, height: 6, backgroundColor: StyleConstants.color[type] }} />
        <Text text={`${value} ${type}`} fontWeight={StyleConstants.textWeight.semiBold} color={StyleConstants.textColor.primary} fontSize={StyleConstants.textSize.small} />
      </div>
    );
  }

  renderSummaryComponents() {
    const { reportData } = this.props;
    const cardStyle = {
      height: 150, width: 320, padding: 0, marginRight: 20,
    };
    const subCardStyle = {
      display: 'flex', justifyContent: 'space-between', height: 110, alignItems: 'center', paddingLeft: 30, paddingRight: 30,
    };
    const { minutes, sec } = calculateCountdown(new Date(new Date().setSeconds(new Date().getSeconds() + reportData.avg_speed)).toString());
    return (
      <div className="d-flex flexcontainer" style={{ justifyContent: 'center' }}>
        <div className="cardView" style={cardStyle}>
          {this.renderSummaryHeader('Accuracy')}
          <div style={subCardStyle}>
            <Text text={`${reportData.accuracy}%`} fontWeight={StyleConstants.textWeight.bold} color={StyleConstants.textColor.primary} fontSize="52px" />
            <div>
              {this.renderStats('Correct', reportData.correct)}
              {this.renderStats('Incorrect', reportData.incorrect)}
              {this.renderStats('Unattempted', reportData.unattempted)}
            </div>
          </div>
        </div>
        <div className="cardView" style={{ ...cardStyle, marginRight: 0 }}>
          {this.renderSummaryHeader('Speed')}
          <div style={{ ...subCardStyle, justifyContent: 'center' }}>
            <Text text={minutes > 0 ? `${minutes}min ${sec}s/Q` : `${sec}s/Q`} fontWeight={StyleConstants.textWeight.bold} color={StyleConstants.textColor.primary} fontSize={minutes > 0 ? '40px' : '52px'} />
          </div>
        </div>
      </div>
    );
  }

  renderTopics(topic, index) {
    const { topicProgress } = this.props;
    const accuracy = `${parseInt((topic.correct * 100) / topic.total)}%`;
    return (
      <div
        key={`TopicInfo_${topic.id}`}
        className={Object.keys(topicProgress).length !== index + 1 ? 'bottomBorderView' : ''}
        style={{ padding: 15, display: 'flex', flexDirection: 'column' }}
      >
        <Text
          color={StyleConstants.textColor.primary}
          fontWeight={StyleConstants.textWeight.bold}
          fontSize={StyleConstants.textSize.headerSmall}
          text={topic.name}
        />
        <div style={{ display: 'flex' }}>
          <div style={{ width: 150 }}>
            <Text
              text={`Accuracy: ${accuracy}`}
            />
          </div>
          <Text
            text={`Score: ${topic.correct}/${topic.total}`}
          />
        </div>

      </div>
    );
  }

  render() {
    const { id } = this.props.match.params;
    const queryParams = queryString.parse(this.props.location.search);
    const objectType = parseInt(queryParams.object_type);
    const sessionType = parseInt(queryParams.session_type);
    const { topicProgress, reportData } = this.props;
    const backUrl = objectType === OBJECT_TYPES.ASSIGNMENT ? '/institute?type=assignment' : `/chapter/${id}`;
    if (Object.keys(topicProgress).length === 0 || Object.keys(reportData).length === 0) {
      return (
        <Layout hideFooter>
          <SideBar showBackNavigation navigateTo={backUrl} />
          <div
            className="MainPage"
            style={{
              fontWeight: StyleConstants.staticPages.fontWeight.header,
              textAlign: 'center',
            }}
          >
            <Text text="Test report will be available shortly..!" fontSize={StyleConstants.textSize.headerBig} />
          </div>
        </Layout>
      );
    }
    return (
      <Layout hideFooter>
        <div>
          <SideBar showBackNavigation navigateTo={backUrl} />
          <div className="MainPage" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: 660 }}>
              {this.renderSummaryComponents()}
              <div className="simpleCardView" style={{ marginTop: 30 }}>
                {Object.keys(topicProgress).map((topicId, index) => this.renderTopics(topicProgress[topicId], index))}
              </div>
              <div style={{ marginTop: 30, marginBottom: 30 }}>
                <Text
                  color={StyleConstants.textColor.primary}
                  fontSize={StyleConstants.textSize.headerSmall}
                  fontWeight={StyleConstants.textWeight.bold}
                  text="Practice makes perfect."
                />
                <div className="simpleCardView" style={{ padding: 15, marginTop: 10 }}>
                  <Text color={StyleConstants.textColor.primary} text="Revisit the errors you made, to understand where you went wrong. Hone yourself to perfection." />
                  <Link id="Personalised-Assignment-Revise-Button" to={objectType === OBJECT_TYPES.ASSIGNMENT ? `/assignment/${id}` : `/personalised_assignment/${id}`} onClick={() => sendEvent('TestReport - ReviseTapped', { testId: id, objectType, sessionType })}>
                    <div className="Button Secondary-Button mt-3"> Revise </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

PersonalizedAssignmentReport.propTypes = {
  getAssignnmentReport: PropTypes.func.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  reportData: PropTypes.object,
  topicProgress: PropTypes.object,
};

PersonalizedAssignmentReport.defaultProps = {
  reportData: {},
  topicProgress: {},
};

const mapStateToProps = ({ report }) => ({
  reportData: report.reportData,
  topicProgress: report.topicProgress,
});

export default connect(mapStateToProps, { getAssignnmentReport })(PersonalizedAssignmentReport);
