import { combineReducers } from 'redux';

import AuthReducer from './authReducer';
import HomeReducer from './homeReducer';
import TestReducer from './testReducer';
import ReportReducer from './reportReducer';
import GlobalReducer from './globalReducer';
import TrainReducer from './trainReducer';
import BookmarkReducer from './bookmarkReducer';
import StaticPagesReducer from './staticPagesReducer';
import SolutionBoardReducer from './solutionBoardReducer';
import VirtualMeetingReducer from './virtualMeetingReducer'

export default combineReducers({
  auth: AuthReducer,
  home: HomeReducer,
  test: TestReducer,
  report: ReportReducer,
  global: GlobalReducer,
  train: TrainReducer,
  bookmark: BookmarkReducer,
  staticPages: StaticPagesReducer,
  solutionBoard: SolutionBoardReducer,
  virtualMeeting: VirtualMeetingReducer
});
