import {
  GET_REPORT, GET_PERSONALISED_REPORT, UPDATE_TEST_REPORTS, REMOVE_DATA, GET_IMPROVEMENT_DATA, UPDATE_IMPROVEMENT_TYPE, CLEAR_REPORT,
} from '../actions';

const INITIAL_STATE = {
  testInfo: {},
  subjects: [],
  reportData: {},
  improvements: {},
  questions: [],
  selectedImprovementType: '',
  questionTimeSpent: {},
  optionSelectedQuestionList: {},
  graphData: {},
  topicProgress: {},
  hasPremiumAccess: false,
  hideQuestionRevise: false,
  hideLeaderBoard: true,
  data: {},
  isAccessibleStudentData: false,
  isDataNotPresent: false,
  testReportInsights: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORT:
      return { ...state, ...action.payload };
    case CLEAR_REPORT:
      return INITIAL_STATE;
    case GET_PERSONALISED_REPORT:
      return { ...state, ...action.payload };
    case GET_IMPROVEMENT_DATA:
      return { ...state, ...action.payload };
    case UPDATE_TEST_REPORTS:
      return { ...state, ...action.payload };
    case REMOVE_DATA:
      return { data: {}}
    case UPDATE_IMPROVEMENT_TYPE:
      return { ...state, selectedImprovementType: action.improvementType };
    default:
      return state;
  }
};
