import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import SideBar from './sidebar';
import Loader from './loader';
import Layout from './layout';
import Modal from './modal';
import ProgressBar from './progressBar';
import Folder from '../shared/images/file_types/folder.svg';
import { TOAST_MESSAGE_TYPES, SIDEBAR_TYPES } from '../shared/constants/fieldTypes';
import {
  getOfflineTestSubjects, getOfflineTest, updateOfflineTestStudentResponse, getSignedUrl, showToast, fileUploadPercentage, uploadFileTo3, updateAnswerSheet,
} from '../actions/index';

import noOfflineTest from '../shared/images/common/noData.svg';
import Download from '../shared/images/common/download.png';
import Upload from '../shared/images/common/upload.png';
import LeftArrow from '../shared/images/common/left-arrow.svg';
import TestPaper from '../shared/images/common/test-paper.gif';

const BYTES_TO_MEGA_BYTE = 1048576;
export const FILE_IMAGES = {
  png: 'image', jpeg: 'image', svg: 'image', bmp: 'image', doc: 'word', docx: 'word', txt: 'word', mp4: 'video', avi: 'video', mpg: 'video', mpeg: 'video', mkv: 'video', ppt: 'powerpoint', pptx: 'powerpoint', pps: 'powerpoint', xlsx: 'excel', xlsm: 'excel', xls: 'excel', pdf: 'pdf', default: 'file',
};

const query = new URLSearchParams(window.location.search);

const studentTestStatus = {
  NOT_STARTED: {
    name: 'Not started',
    status: 'NOT_STARTED',
    color: '#BA1B1B',
  },
  QP_DOWNLOADED: {
    name: 'QP Downloaded',
    status: 'QP_DOWNLOADED',
    color: '#DBAF1A',
  },
  SUBMITTED: {
    name: 'Submitted',
    status: 'SUBMITTED',
    color: '#14B536',
  },
  MISSED: {
    name: 'Missed',
    status: 'MISSED',
    color: '#F73A3A',
  },
};

const offLineTestPageTypes = {
  subject: 'OFFLINE_TEST_SUBJECT',
  test: 'OFFLINE_TEST',
};

class InstituteFile extends PureComponent {
  constructor(props) {
    super(props);
    let pageType = null;
    if (query.get('subject_id')) {
      pageType = offLineTestPageTypes.test;
    } else {
      pageType = offLineTestPageTypes.subject;
    }
    this.state = {
      showLoader: true,
      pageType,
      maxFileSize: 100,
      fileTypes: 'image/*,.pdf,.doc,.docx',
      answerSheet: null,
      answerSheetUploading: false,
      answerSheetTestDetails: null,
      showAnswerUpdateModal: false,
    };
  }

  componentDidMount() {
    const { pageType } = this.state;
    if (pageType === offLineTestPageTypes.subject) {
      this.props.getOfflineTestSubjects().then(() => this.setState({ showLoader: false }));
    } else if (pageType === offLineTestPageTypes.test) {
      this.props.getOfflineTest(query.get('subject_id')).then(() => this.setState({ showLoader: false }));
    }
  }

  subjectFolderView(subject) {
    return (
      <div
        role="presentation"
        style={{
          padding: 20,
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '0px 1px 4px #0000003d',
          cursor: 'pointer',
          width: '100%',
          background: '#FFFFFF',
        }}
        onClick={() => { window.location.replace(`/offline_tests?subject_id=${subject.id}`); }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="" src={Folder} height="40px" width="40px" />
          <div style={{ marginRight: 30, marginLeft: 30 }}>{subject.name}</div>
        </div>
        <div style={{ whiteSpace: 'nowrap' }}>
          {subject.test_count > 1 ? `${subject.test_count} tests` : `${subject.test_count} test`}
        </div>
      </div>
    );
  }

  OfflineTestsSubjects() {
    const { pageType } = this.state;
    if (pageType === offLineTestPageTypes.test) {
      return (
        this.renderTableData()
      );
    }
    return (
      <div style={{ margin: '5%', marginTop: 30 }}>
        <div style={{ fontSize: 26 }}>
          Offline Tests
        </div>
        <div
          style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20, marginRight: 20,
          }}
        >
          {this.renderPageContent()}
        </div>
      </div>
    );
  }

  OfflineTests() {
    const { showLoader } = this.state;
    return (
      <div className="MainPage" style={{ marginTop: 0, padding: 0 }}>
        {showLoader ? (
          <Loader />
        ) : this.OfflineTestsSubjects()}
      </div>
    );
  }

  renderTableHeader() {
    const style = { padding: 20, whiteSpace: 'nowrap', borderRight: '1px solid #DFE3EA' };
    return (
      <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
        <tr>
          <th style={{ width: '15%', ...style }}>Test Name</th>
          <th style={{ width: '25%', ...style }}>Starts at</th>
          <th style={{ width: '10%', ...style }}>Duration</th>
          <th style={{ width: '10%', ...style }}>Your Marks</th>
          <th style={{ width: '25%', ...style }}>Your status</th>
          <th style={{ width: '10%', ...style }}>Question Paper</th>
          <th style={{ width: '10%', ...style }}>Your Answer</th>
        </tr>
      </thead>
    );
  }

  renderTests(test) {
    const style = { padding: 20, verticalAlign: 'middle' };
    const studentMark = (test.response_summary && test.response_summary.marks) || '-';
    return (
      <tr>
        <td style={style}>{test.name}</td>
        <td style={style}>{moment(test.start_date).format('MMM Do, h:mm a')}</td>
        <td style={style}>{test.duration}</td>
        <td style={style}>{`${studentMark} / ${test.summary.total_mark}`}</td>
        <td style={{ ...style, color: studentTestStatus[test.status] && studentTestStatus[test.status].color }}>
          {studentTestStatus[test.status] && studentTestStatus[test.status].name}
        </td>
        <td style={{ textAlign: 'center', ...style }}>
          <img
            alt="download"
            role="presentation"
            src={Download}
            height="35px"
            width="35px"
            style={{ marginRight: 20, cursor: 'pointer', alignSelf: 'center' }}
            onClick={() => {
              window.open(test.question_file_url);
              if (test.status === 'NOT_STARTED') {
                this.props.updateOfflineTestStudentResponse(test.offline_test_student_response_id, studentTestStatus.QP_DOWNLOADED.status, query.get('subject_id'));
              }
            }}
          />
        </td>
        <td style={{ textAlign: 'center', ...style }}>
          {
            test.file_url ? (
              <img
                alt="download"
                role="presentation"
                src={Download}
                height="35px"
                width="35px"
                style={{ marginRight: 20, cursor: 'pointer', alignSelf: 'center' }}
                onClick={() => {
                  window.open(test.file_url);
                }}
              />
            )
              : (
                <img
                  alt="upload"
                  role="presentation"
                  src={Upload}
                  height="35px"
                  width="35px"
                  style={{ marginRight: 20, cursor: 'pointer', alignSelf: 'center' }}
                  onClick={() => { this.setState({ answerSheetTestDetails: test, showAnswerUpdateModal: true }); }}
                />
              )
          }
        </td>
      </tr>
    );
  }

  renderTableData() {
    const { offlineTestSubject, offlineTestLists } = this.props;
    const { updateTestStatusId } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <div style={{
          margin: '5%', marginTop: 30, display: 'flex', verticalAlign: 'middle',
        }}
        >
          <img alt="backIcon" role="presentation" src={LeftArrow} height="20px" width="20px" style={{ marginRight: 20, cursor: 'pointer', alignSelf: 'center' }} onClick={() => { window.location.replace('/offline_tests'); }} />
          <div style={{ fontSize: 26 }}>{offlineTestSubject}</div>
        </div>
        <div>
          <table
            className="table table-hover"
            style={{
              boxShadow: '1px 2px 6px #8B9DAF33', marginLeft: '5%', width: '90%', marginTop: 30, borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
            }}
          >
            {this.renderTableHeader()}
            <tbody key={updateTestStatusId}>
              {offlineTestLists.length
                ? offlineTestLists.map((test) => {
                  return this.renderTests(test);
                })
                : <div>No Files Are Uploaded</div> }
            </tbody>
          </table>
        </div>
      </div>

    );
  }

  renderPageContent() {
    const { offlineTestListSubjectList } = this.props;
    if (!offlineTestListSubjectList.length) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column', height: global.innerHeight - 220, alignItems: 'center', justifyContent: 'center',
        }}
        >
          <img alt="Previous icon" height={200} width={200} src={noOfflineTest} style={{ marginRight: 5 }} />
          <div>No Offline test created yet!</div>
        </div>
      );
    }
    return (
      offlineTestListSubjectList.map((subject) => {
        return this.subjectFolderView(subject);
      }));
  }

  uploadAnswerSheet(event) {
    const { maxFileSize } = this.state;
    const file = event.target.files[0];
    if (file.size > (maxFileSize * BYTES_TO_MEGA_BYTE)) {
      this.props.showToast(`File should be less than ${maxFileSize} MB.`, TOAST_MESSAGE_TYPES.ERROR);
    } else {
      const fileExtension = file.name.split('.')[(file.name.split('.').length) - 1];
      const fileimage = FILE_IMAGES[fileExtension];
      this.setState({
        answerSheet: file, fileExtension: fileimage || FILE_IMAGES.default,
      });
    }
  }

  uploadAndSubmitAnswerSheet() {
    const { answerSheet, answerSheetTestDetails } = this.state;
    this.setState({ answerSheetUploading: true });
    const fileExtension = answerSheet.name.split('.').slice(-1).pop();
    this.props.getSignedUrl(fileExtension).then((response) => {
      if (response.success) {
        this.props.uploadFileTo3(response, answerSheet).then((res) => {
          const answerSheetS3Url = `${res.config.url}/${response.key}`;
          this.props.updateAnswerSheet(answerSheetS3Url, answerSheetTestDetails.id).then((result) => {
            this.props.fileUploadPercentage(0);
            this.props.updateOfflineTestStudentResponse(answerSheetTestDetails.id, studentTestStatus.SUBMITTED.status, query.get('subject_id'));
            if (result) {
              this.setState({ answerSheetUploading: false, answerSheet: null, showAnswerUpdateModal: false });
            } else {
              this.setState({ answerSheetUploading: false });
            }
          });
        });
      } else {
        this.props.showToast('Something went wrong please contact us!', TOAST_MESSAGE_TYPES.ERROR);
      }
    });
  }

  renderTestDetails() {
    const { answerSheetTestDetails } = this.state;
    if (!answerSheetTestDetails) {
      return (<div />);
    }
    return (
      <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
          <div style={{ fontWeight: 'bold' }}>
            Name:
          </div>
          <div style={{ marginLeft: 10 }}>
            {answerSheetTestDetails.name}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ fontWeight: 'bold' }}>
            Ends at:
          </div>
          <div style={{ marginLeft: 10 }}>
            {moment(answerSheetTestDetails.start_date).add(answerSheetTestDetails, 'minutes').format('MMM Do, h:mm a')}
          </div>
        </div>
      </div>
    );
  }

  uploadTestFile() {
    const { fileTypes, answerSheet } = this.state;
    const fileFormat = answerSheet && answerSheet.name.split('.').slice(-1).pop();
    const isPdf = answerSheet && answerSheet.type.includes('pdf');
    const isDoc = answerSheet && ((fileFormat === 'doc') || (fileFormat === 'docx'));
    if (answerSheet) {
      return (
        <div style={{
          borderRadius: 4, width: 400, textAlign: 'center', display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer', marginTop: 20, height: 275,
        }}
        >
          <div style={{ height: '10px', overflow: 'hidden' }}>
            <input id="uploadFile" type="file" name="file" accept={fileTypes} onChange={(event) => this.uploadAnswerSheet(event)} />
          </div>
          <div style={{ cursor: 'pointer', height: '99%' }}>
            <div style={{
              borderRadius: '4px', border: 'solid 1px #cdcdce', height: '100%', display: 'flex', justifyContent: 'center', padding: 5, position: 'relative',
            }}
            >
              {isPdf && <embed style={{ maxWidth: '60%', width: 'fit-content' }} height="fit-content" name="plugin" src={URL.createObjectURL(answerSheet)} scrolling="no" />}
              {(isDoc) && (
                <div style={{
                  alignSelf: 'center', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center',
                }}
                >
                  {' '}
                  {answerSheet.name}
                  {' '}
                </div>
              )}
              <div
                role="presentation"
                style={{
                  height: 35, backgroundColor: '#E4E7EE', width: '100%', position: 'absolute', bottom: 0, left: 0, color: '#40A7FF', display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
                onClick={(event) => {
                  event.stopPropagation(); event.preventDefault(); document.getElementById('uploadFile').value = null; this.setState({ answerSheet: null });
                }}
              >
                Change
              </div>
              <div
                role="presentation"
                style={{
                  position: 'absolute', top: 5, right: 10, cursor: 'pointer',
                }}
                onClick={(event) => {
                  event.stopPropagation(); event.preventDefault(); document.getElementById('uploadFile').value = null; this.setState({ answerSheet: null });
                }}
              >
                <img src="/images/landing_institute/x-mark.svg" alt="x-mark" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <div style={{
          borderRadius: 4, width: 400, textAlign: 'center', border: '1px solid #343737', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer', marginTop: 30,
        }}
        >
          <div style={{
            zIndex: 10, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: ' center', justifyContent: 'center', border: 'none',
          }}
          >
            <input
              style={{
                opacity: '0', height: 250, textAlign: 'center', zIndex: 100, width: 400, cursor: 'pointer',
              }}
              type="file"
              accept={fileTypes}
              onChange={(event) => this.uploadAnswerSheet(event)}
            />
            <div style={{ display: 'flex', position: 'absolute', flexDirection: 'column' }}>
              <img alt="upload" role="presentation" src={Upload} height="35px" width="35px" style={{ cursor: 'pointer', alignSelf: 'center' }} />
              <div style={{
                fontSize: '12', color: '#112F56', padding: '8px',
              }}
              >
                Upload answer sheet
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  uploadAnswerSheetButton() {
    const { fileUploadedPercentage } = this.props;
    const { answerSheet, answerSheetUploading } = this.state;
    if (answerSheetUploading) {
      return (
        <div style={{
          width: '100%', borderRadius: 4, marginBottom: 0, maxWidth: 300, display: 'flex', flexDirection: 'row', margin: 'auto',
        }}
        >
          <div><img alt="fileTransfer" src={TestPaper} height="90px" width="90px" /></div>
          <div style={{ disply: 'flex', width: '100%', alignSelf: 'center' }}>
            <div style={{ marginBottom: 10, textAlign: 'center', fontSize: 16 }}>Submitting your test</div>
            <ProgressBar
              height={4}
              width="100%"
              fillColor="#6c5ce7"
              fillPercentage={`${fileUploadedPercentage}%`}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        id="Send-Feeback-Button"
        role="presentation"
        style={{ width: '100%', opacity: answerSheet ? 1 : 0.5, marginTop: 30 }}
        className="Button Primary-Button"
        onClick={() => {
          if (!answerSheet) {
            this.props.showToast('Please upload the file and click on submit', TOAST_MESSAGE_TYPES.ERROR);
          } else {
            this.setState({ answerSheetUploading: true }, () => {
              this.uploadAndSubmitAnswerSheet();
            });
          }
        }}
      >
        Submit
      </div>
    );
  }

  render() {
    const {
      pageContentHeight, showAnswerUpdateModal,
    } = this.state;

    return (
      <Layout hideFooter>
        <Modal isModalVisible={showAnswerUpdateModal} onClose={() => { this.setState({ showAnswerUpdateModal: false, answerSheetTestDeatails: null }); }} maxWidth={500}>
          {this.renderTestDetails()}
          {this.uploadTestFile()}
          {this.uploadAnswerSheetButton()}
        </Modal>
        <SideBar pageType={SIDEBAR_TYPES.VIRTUAL_MEETINGS} />
        <div style={{ height: pageContentHeight }}>
          {this.OfflineTests()}
        </div>
      </Layout>
    );
  }
}

InstituteFile.propTypes = {
  getOfflineTestSubjects: PropTypes.func.isRequired,
  offlineTestListSubjectList: PropTypes.array.isRequired,
  offlineTestSubject: PropTypes.string.isRequired,
  offlineTestLists: PropTypes.array.isRequired,
  fileUploadedPercentage: PropTypes.number.isRequired,
};

InstituteFile.defaultProps = {
};

const mapStateToProps = ({ global }) => ({
  filesData: global.filesData,
  updatedCount: global.updatedCount,
  subjects: global.subjects,
  offlineTestSubject: global.offlineTestSubject,
  offlineTestLists: global.offlineTestLists,
  virtualFileData: global.virtualFileData,
  offlineTestListSubjectList: global.offlineTestListSubjectList,
  fileUploadedPercentage: global.fileUploadedPercentage,
});

export default connect(mapStateToProps, {
  getSignedUrl, getOfflineTestSubjects, getOfflineTest, updateOfflineTestStudentResponse, showToast, fileUploadPercentage, uploadFileTo3, updateAnswerSheet,
})(InstituteFile);
