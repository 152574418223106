import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { reportError, showToast, updateQuestionBookmarkState } from '../actions';
import Text from './text';
import ModalView from './modal';
import { getVimeoVideoId } from '../helpers/Utils';
import StyleConstants from '../shared/styleConstants/styles';
import markQuestion from '../shared/images/test/markQuestion.svg';
import markQuestionSelected from '../shared/images/test/markQuestionSelected.svg';
import solution from '../shared/images/test/solution.svg';
import solutionSelected from '../shared/images/test/solutionSelected.svg';
import bookmark from '../shared/images/test/bookmark.svg';
import bookmarkSelected from '../shared/images/test/bookmarkSelected.svg';
import check from '../shared/images/test/check.png';
import wrong from '../shared/images/test/wrong.png';
import videoSolution from '../shared/images/test/videoSolution.svg';
import reportErrorImage from '../shared/images/test/reportError.svg';
import { sendEvent } from '../helpers/Analytics';
import {
  TOAST_MESSAGE_TYPES, SIDEBAR_TYPES, BOOKMARK_TYPES, QUESTION_TYPES,
} from '../shared/constants/fieldTypes';

const IMAGE_WIDTH = {
  0: { width: '50%', maxWidth: 310 },
  1: { width: '25%', maxWidth: 160 },
  2: { width: '50%', maxWidth: 310 },
  3: { width: '70%', maxWidth: 430 },
};

const OPTION_IMAGE_WIDTH = {
  0: { width: '40%', maxWidth: 250 },
  1: { width: '25%', maxWidth: 160 },
  2: { width: '40%', maxWidth: 250 },
  3: { width: '50%', maxWidth: 310 },
};

const RenderKeyPadElement = (props) => (
  <div
    role="presentation"
    id={`Option-Tap-${props.currentQuestionIndex + 1}-${props.text}-Numerical`}
    key={`KeyPad_${props.text}`}
    className="key-pad-press"
    onClick={props.onClick}
    style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, width: 50, backgroundColor: '#ddd', borderRadius: 4, marginTop: 10, marginRight: 10, cursor: 'pointer',
    }}
  >
    {props.children}
  </div>
);

const PAGE_WIDTH = 700;

class QuestionView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBookmarkTypeModal: false,
      showVideoSolutionModal: false,
      showReportErrorModal: false,
      selectedErrorType: 0,
      reportText: '',
    };
    this.onCloseModal = this.onCloseModal.bind(this);
    this.renderView = this.renderView.bind(this);
  }

  componentDidMount() {
    const { showResult, isBookmarkView, currentQuestion } = this.props;
    if (!showResult && !isBookmarkView) {
      const { id } = currentQuestion;
      this.interval = setInterval(() => {
        this.props.updateQuestionTimeSpent(id);
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onCloseModal() {
    this.setState({
      showReportErrorModal: false, selectedErrorType: 0, reportText: '', showVideoSolutionModal: false, showBookmarkTypeModal: false,
    });
  }

  getImageWidth(isQuestionText, widthType) {
    switch (widthType) {
      case '1':
        return isQuestionText ? IMAGE_WIDTH[widthType] : OPTION_IMAGE_WIDTH[widthType];
      case '2':
        return isQuestionText ? IMAGE_WIDTH[widthType] : OPTION_IMAGE_WIDTH[widthType];
      case '3':
        return isQuestionText ? IMAGE_WIDTH[widthType] : OPTION_IMAGE_WIDTH[widthType];
      default:
        return isQuestionText ? IMAGE_WIDTH['0'] : OPTION_IMAGE_WIDTH['0'];
    }
  }

  getColor(showResult, option, type = '') {
    const correctAnswerData = option.isSelected ? StyleConstants.questionView[`selectedCorrect${type}`] : StyleConstants.questionView[`Correct${type}`];
    const result = option.isCorrectAnswer ? correctAnswerData : option.isSelected && StyleConstants.questionView[`Incorrect${type}`];
    const backgroundColor = showResult ? result : option.isSelected && StyleConstants.questionView[`Selected${type}`];
    return backgroundColor;
  }

  updateBookmarkState(bookmarkTag = BOOKMARK_TYPES.REVISE) {
    const {
      currentQuestion, showSolutionIcon, isCurrentQuestionBookmarked, isBookmarkView, currentIndex,
    } = this.props;
    const showIcon = isBookmarkView ? SIDEBAR_TYPES.BOOKMARK : SIDEBAR_TYPES.TRAIN;
    this.props.updateQuestionBookmarkState(
      showSolutionIcon ? showIcon : SIDEBAR_TYPES.TEST, currentQuestion.id,
      !isCurrentQuestionBookmarked, currentIndex + 1,
      bookmarkTag, currentQuestion.question_type, this,
    );
    if (isBookmarkView) {
      this.props.updateBookmarkQuestionList(currentQuestion.id, !isCurrentQuestionBookmarked);
    }
  }

  showNumericalNotes() {
    const { currentQuestion } = this.props;
    if (currentQuestion.question_type === QUESTION_TYPES.NUMERICAL
      && currentQuestion.numerical_notes && currentQuestion.numerical_notes.length > 0) {
      return (
        <Text
          color="#9EA0A5"
          text={`(Note : The answer should be ${currentQuestion.numerical_notes})`}
        />
      );
    }
    return <div />;
  }

  showSolutionData(text, hasImage, option) {
    const { currentQuestion } = this.props;
    return (
      <div style={{ marginTop: text !== '' && 20 }}>

        {(currentQuestion.solution_text && currentQuestion.solution_text !== '')
          && this.renderText(currentQuestion.solution_text, {
            color: StyleConstants.textColor.primary,
            fontWeight: StyleConstants.textWeight.semiBold,
          })}
        <div style={{ marginBottom: hasImage && option.isQuestionText && 10 }} />
        {currentQuestion.solution_supporting_picture
          && (
            this.renderSolutionSupportingPicture()
          )}
      </div>
    );
  }

  requestSolution(questionId) {
    const { solutionRequested, showResult, isBookmarkView } = this.props;
    if (!solutionRequested && !showResult && !isBookmarkView) {
      this.props.updateSolutionRequestStatus();
    } else if (isBookmarkView && !showResult) {
      this.props.toggleQuestionView(questionId, true);
    }
  }

  selectOption(index, questionId, option) {
    const { showResult, isBookmarkView, shouldUploadResponses } = this.props;
    if (!showResult && !(option.isQuestionText
      || option.comprehensionQuestion) && !isBookmarkView) {
      this.props.updateQuestionSelectedOptions(index, shouldUploadResponses);
    } else if (option.isQuestionText && isBookmarkView) {
      this.props.toggleQuestionView(questionId);
    }
  }

  renderBookMark() {
    const { isCurrentQuestionBookmarked } = this.props;
    return (
      <img
        id="Bookmark-Question"
        role="presentation"
        alt="NEET_JEE_Question_Bookmark"
        height={24}
        width={24}
        src={isCurrentQuestionBookmarked ? bookmarkSelected : bookmark}
        style={{ marginLeft: 20, cursor: 'pointer' }}
        onClick={() => {
          if (isCurrentQuestionBookmarked) {
            this.updateBookmarkState();
          } else {
            this.setState({ showBookmarkTypeModal: !isCurrentQuestionBookmarked });
          }
        }}
      />
    );
  }

  renderText(text, style = {}) {
    return (
      <Text
        style={style}
        text={text}
      />
    );
  }

  renderReportError() {
    return (
      <img
        role="presentation"
        id="Report-Error"
        alt="NEET_JEE_Report Error"
        height={24}
        width={24}
        src={reportErrorImage}
        style={{ marginLeft: 20, cursor: 'pointer' }}
        onClick={() => this.setState({ showReportErrorModal: true })}
      />
    );
  }

  renderVideoSolution() {
    const { solutionRequested, showResult } = this.props;
    return (
      <img
        id="Request-Video-Solution"
        alt="NEET_JEE_Request_Video_Solution"
        role="presentation"
        height={24}
        width={24}
        src={videoSolution}
        style={{ marginLeft: 20, cursor: 'pointer' }}
        onClick={() => {
          if (!solutionRequested && !showResult) {
            this.props.updateSolutionRequestStatus();
          }
          this.setState({ showVideoSolutionModal: true });
        }}
      />
    );
  }

  renderSolution(questionId) {
    const { showResult } = this.props;
    return (
      <img
        id="Request-Solution"
        alt="Request Solution"
        role="presentation"
        height={24}
        width={24}
        src={showResult ? solutionSelected : solution}
        style={{ marginLeft: 20, cursor: 'pointer' }}
        onClick={() => this.requestSolution(questionId)}
      />
    );
  }

  renderMarkQuestion(questionId, index) {
    const { sessionInfo, selectedOption, isCurrentQuestionMarked } = this.props;
    return (
      <img
        id={`Mark-Question-${index}`}
        alt="NEET_JEE_Mark_Question"
        role="presentation"
        height={24}
        width={24}
        src={isCurrentQuestionMarked ? markQuestionSelected : markQuestion}
        style={{ marginLeft: 20, cursor: 'pointer' }}
        onClick={() => {
          this.props.updateQuestionMarkedList();
          if (isCurrentQuestionMarked) {
            sendEvent('Questions - Pinned', { ...sessionInfo, questionId, option: selectedOption });
          }
        }}
      />
    );
  }

  renderHeader(questionId, index) {
    const {
      showResult, showVideoSolutionIcon, currentQuestion,
      showMarkIcon, currentQuestionTimeSpent, isBookmarkView,
      showSolutionIcon,
    } = this.props;
    const showBookmark = !new Cookies().get('user').has_only_test_board_access;
    const questionTimeSpent = currentQuestionTimeSpent.minutes > 0 ? `${currentQuestionTimeSpent.minutes}m ${currentQuestionTimeSpent.sec}s` : `${currentQuestionTimeSpent.sec}s`;
    return (
      <div
        className="cardView d-flex"
        style={{
          backgroundColor: StyleConstants.backgroundColor.header,
          padding: '20px 16px',
          borderRadius: '4px 4px 0px 0px',
          justifyContent: 'space-between',
        }}
      >
        {currentQuestion.section !== null && this.renderText(`Section ${currentQuestion.section === 0 ? 'A' : 'B'}`, { color: StyleConstants.textColor.primary, fontWeight: StyleConstants.textWeight.bold })}
        {this.renderText(`Question ${index}`, { color: StyleConstants.textColor.primary, fontWeight: StyleConstants.textWeight.bold })}
        <div style={{ display: 'flex' }}>
          {showResult && !showSolutionIcon && this.renderText(questionTimeSpent, { fontWeight: StyleConstants.textWeight.semiBold })}
          {this.renderReportError()}
          {showBookmark && this.renderBookMark()}
          {showVideoSolutionIcon && currentQuestion.video_solution_uri && !isBookmarkView && this.renderVideoSolution()}
          {showSolutionIcon && this.renderSolution(questionId)}
          {!showResult && showMarkIcon && this.renderMarkQuestion(questionId, index)}
        </div>
      </div>
    );
  }

  renderOptionStatus() {
    const { questionsVisited, currentQuestion, solutionRequested } = this.props;
    const isCurrentQuestionVisited = questionsVisited.includes(
      currentQuestion && currentQuestion.id,
    ) || solutionRequested;
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
        {this.renderText(isCurrentQuestionVisited ? 'UNATTEMPTED' : 'UNVISITED',
          {
            color: StyleConstants.textColor.primary,
            fontWeight: StyleConstants.textWeight.bold,
            fontSize: StyleConstants.textSize.text,
          })}
      </div>
    );
  }

  renderSolutionSupportingPicture() {
    const { currentQuestion } = this.props;
    return (
      <img
        alt="NEET JEE"
        height="100%"
        width={
          OPTION_IMAGE_WIDTH[
            currentQuestion.image_widths.solution_supporting_picture_size
          ].width
        }
        src={currentQuestion.solution_supporting_picture}
        style={{
          maxWidth: OPTION_IMAGE_WIDTH[
            currentQuestion.image_widths.solution_supporting_picture_size
          ].maxWidth,
        }}
      />
    );
  }

  renderImage(option, srcImage, widthType) {
    const { width, maxWidth } = this.getImageWidth(option.isQuestionText, widthType);
    return (
      <img
        alt="NEET JEE"
        width={width}
        height="100%"
        src={srcImage}
        style={{
          maxWidth, display: 'flex', alignSelf: (option.isQuestionText || option.comprehensionQuestion) && 'center',
        }}
      />
    );
  }

  renderMathjax() {
    return window.MathJax && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }

  renderView(text, srcImage, widthType, index, questionId, option = {}, questionIndex = 0) {
    const {
      showResult, currentQuestion, selectedOption,
      isBookmarkView, showOptions, richResponse,
    } = this.props;
    const showQuestionType = ![QUESTION_TYPES.FOUR_OPTIONS, QUESTION_TYPES.COMPREHENSION].includes(
      currentQuestion.question_type,
    );
    const questionType = Object.keys(QUESTION_TYPES)[parseInt(currentQuestion.question_type, 10)];
    const backgroundColor = this.getColor(showResult, option);
    const borderColor = this.getColor(showResult, option, 'Border');
    const hasImage = srcImage && srcImage !== '';
    const CorrectBorderImage = option.isSelected ? check : null;
    const wrongImage = option.isSelected ? wrong : null;
    const showImage = option.isCorrectAnswer ? CorrectBorderImage : wrongImage;
    const borderImage = showResult ? showImage : null;
    return (
      <div style={{ userSelect: 'none' }}>
        {option.isQuestionText && this.renderHeader(questionId, index)}
        <div
          className={`cardView ${!isBookmarkView && !option.isQuestionText && !showResult && 'cardViewHover'}`}
          style={{
            backgroundColor,
            padding: 0,
            border: borderColor && `1px solid ${borderColor}`,
            borderBottom: isBookmarkView && showOptions && index !== 4 ? '1px solid #efefef' : borderColor && `1px solid ${borderColor}`,
            cursor: (isBookmarkView && option.isQuestionText) && 'pointer',
            boxShadow: isBookmarkView && '0px 0px',
            marginTop: !(isBookmarkView || option.isQuestionText) && 10,
            marginBottom: !isBookmarkView
              && (!showResult && (option.isQuestionText || option.comprehensionQuestion)) && 25,
            borderRadius: ((option.isQuestionText && !showOptions) || (isBookmarkView && index === 4)) ? '0px 0px 4px 4px' : (isBookmarkView && index !== 4) && 0,
            zIndex: 1,
          }}
        >
          {borderImage && (
            <img src={borderImage} alt="" height="18px" width="18px" style={{ position: 'absolute', marginTop: -5, right: 425 }} />
          )}
          <div
            id={`Option-Tap-${questionIndex}-${index}`}
            role="presentation"
            style={{
              backgroundColor,
              padding: 20,
              borderRadius: 4,
            }}
            onClick={() => this.selectOption(index, questionId, option)}
          >
            <div className="d-flex" style={{ flexDirection: 'column' }}>
              {(option.comprehensionQuestion || (option.isQuestionText && showQuestionType))
                && this.renderText(questionType,
                  {
                    padding: '5px 20px',
                    color: StyleConstants.color.testUnattempted,
                    fontWeight: StyleConstants.textWeight.bold,
                    border: `1px solid ${StyleConstants.color.testUnattempted}`,
                    borderRadius: 4,
                    width: 'fit-content',
                    marginBottom: 20,
                  })}
              {option.showSolution && (
                <div style={{ display: 'flex', marginBottom: 5 }}>
                  {this.renderText('Solution:', {
                    color: StyleConstants.textColor.primary,
                    fontWeight: StyleConstants.textWeight.bold,
                  })}
                </div>
              )}
              {(!hasImage || option.isQuestionText || option.comprehensionQuestion || option.showSolution)
                && this.renderText(text, { color: StyleConstants.textColor.primary })}
              {option.isQuestionText && this.showNumericalNotes()}
              <div style={{ marginBottom: hasImage && option.isQuestionText && 10 }} />
              {hasImage && (
                this.renderImage(option, srcImage, widthType)
              )}
              {isBookmarkView && option.isQuestionText && currentQuestion.chapter_name
                && this.renderText(currentQuestion.chapter_name, { color: StyleConstants.color.primary, fontWeight: StyleConstants.textWeight.semiBold })}
            </div>
            {option.showSolution && (this.showSolutionData(text, hasImage, option))}
          </div>
        </div>
        {showResult && (selectedOption.length === 0 && richResponse === '') && !isBookmarkView && option.isQuestionText && this.renderOptionStatus()}
      </div>
    );
  }

  renderReportErrorModal() {
    const { selectedErrorType, reportText } = this.state;
    const { currentQuestion } = this.props;
    const { id } = currentQuestion;
    const modalError = {
      10: 'Need a better solution',
      7: 'Issue in question or options',
      2: 'Issue in diagram',
      6: 'Incorrect answer',
      4: 'Other issue',
    };
    const keyOrder = [10, 7, 2, 6, 4];
    return (
      <div>
        {keyOrder.map((errorKey) => (
          <div
            id={`Report-Error-Field-${errorKey}`}
            role="presentation"
            key={`ReportError_${errorKey}`}
            className={`${selectedErrorType === errorKey && 'Primary-Button'} Button mb-3 pl-3`}
            style={{ justifyContent: 'left' }}
            onClick={() => this.setState({ selectedErrorType: errorKey })}
          >
            {modalError[errorKey]}
          </div>
        ))}
        <div className="mb-3" style={{ display: 'flex' }}>
          <textarea rows="4" cols="50" value={reportText} onChange={(event) => this.setState({ reportText: event.target.value })} style={{ width: '100%', height: 100 }} />
        </div>
        <div>
          <Text text={`Question Id: ${id}`} color={StyleConstants.textColor.inactive} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div
            role="presentation"
            id="Report-Error-Cancel"
            className="Button mr-2"
            style={{ width: 130 }}
            onClick={this.onCloseModal}
          >
            Cancel
          </div>
          <div
            id="Report-Error-Submit"
            role="presentation"
            className="Button Primary-Button"
            style={{ width: 130 }}
            onClick={() => {
              if (selectedErrorType !== 0) {
                if (selectedErrorType === 4 && reportText === '') {
                  this.props.showToast('Please specify the issue.', TOAST_MESSAGE_TYPES.ERROR);
                } else {
                  this.props.reportError(id, selectedErrorType, reportText);
                  this.onCloseModal();
                }
              } else {
                this.props.showToast('You have to select atleast one field.', TOAST_MESSAGE_TYPES.WARNING);
              }
            }}
          >
            Submit
          </div>
        </div>
      </div>
    );
  }

  renderNumericalAnswerInput() {
    const {
      richResponse, showResult, currentQuestion, isBookmarkView, updateRichResponse, isCurrentQuestionBlocked,
    } = this.props;
    const { currentIndex: currentQuestionIndex } = this.props;
    const isCorrectAnswer = parseFloat(currentQuestion.answers) === parseFloat(richResponse);
    if (showResult) {
      return (
        <>
          {!isCorrectAnswer && this.renderView(currentQuestion.answers, '', '', 1, currentQuestion.id, { isCorrectAnswer: true, showNumericalAnswer: true })}
          {richResponse !== '' && this.renderView(richResponse, '', '', 1, currentQuestion.id, {
            isSelected: richResponse !== '',
            isCorrectAnswer: showResult && (isBookmarkView || (isCorrectAnswer)),
            showNumericalAnswer: true,
          })}
        </>
      );
    }
    if (isBookmarkView) {
      return false;
    }
    const keyPadElements = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', '-'];
    return (
      <div style={{cursor: isCurrentQuestionBlocked && 'not-allowed'}}>
        <input id="Text-Input" value={richResponse} disabled style={{ backgroundColor: 'inherit', cursor: isCurrentQuestionBlocked && 'not-allowed' }} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="flexcontainer" style={{ width: 180, pointerEvents: isCurrentQuestionBlocked && 'none' }}>
            {keyPadElements.map((text) => (
              <RenderKeyPadElement currentQuestionIndex={currentQuestionIndex} text={text !== '.' ? text : '10'} onClick={() => updateRichResponse(richResponse + text)}>
                <Text
                  color={StyleConstants.textColor.primary}
                  fontWeight={StyleConstants.textWeight.semiBold}
                  fontSize={StyleConstants.textSize.headerSmall}
                  text={text}
                />
              </RenderKeyPadElement>
            ))}
            <div style={{ display: 'flex', marginTop: 10 }}>
              <div
                className="Button"
                role="presentation"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => updateRichResponse(richResponse.slice(0, -1))}
              >
                Delete
              </div>
              <div
                className="Button"
                role="presentation"
                style={{ width: 80 }}
                onClick={() => updateRichResponse('')}
              >
                Clear
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderbookMarkModal(showBookmarkTypeModal) {
    return (
      <ModalView isModalVisible={showBookmarkTypeModal} onClose={this.onCloseModal} center header="Choose Bookmark Type" maxWidth={400}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: 320 }}>
          <div
            className="Button"
            role="presentation"
            style={{ width: 150 }}
            onClick={() => {
              this.updateBookmarkState(BOOKMARK_TYPES.REVISE);
              this.onCloseModal();
            }}
          >
            Revise
          </div>
          <div
            className="Button"
            role="presentation"
            style={{ width: 150 }}
            onClick={() => {
              this.updateBookmarkState(BOOKMARK_TYPES.DOUBTS);
              this.onCloseModal();
            }}
          >
            Doubts
          </div>
        </div>
      </ModalView>
    );
  }

  render() {
    this.renderMathjax();
    const {
      currentQuestion, comprehensionText, selectedOption, currentIndex,
      showResult, isBookmarkView, showOptions, hideQuestion,
    } = this.props;
    const { showReportErrorModal, showVideoSolutionModal, showBookmarkTypeModal } = this.state;
    const questionImageWidth = currentQuestion.image_widths;
    const modalHeaderText = 'Let us know your concern with this question';
    if (!currentQuestion.id) {
      return <div />;
    }
    const videoHeight = window.innerHeight - 200 > PAGE_WIDTH
      ? PAGE_WIDTH : window.innerHeight - 200;
    const videoWidth = window.innerWidth * 0.9 > PAGE_WIDTH ? PAGE_WIDTH : window.innerWidth * 0.9;
    const videoId = getVimeoVideoId(currentQuestion.video_solution_uri);
    return (
      <div style={{ boxShadow: isBookmarkView && '0px 3px 15px rgba(65, 61, 74, 0.1)', borderRadius: isBookmarkView && !showOptions && 4, display: hideQuestion && 'none' }}>
        {showReportErrorModal
          && (
            <ModalView
              isModalVisible={showReportErrorModal}
              onClose={this.onCloseModal}
              center
              header={modalHeaderText}
              maxWidth={500}
            >
              {this.renderReportErrorModal()}
            </ModalView>
          )}
        {showVideoSolutionModal
          && (
            <ModalView
              isModalVisible={showVideoSolutionModal}
              onClose={this.onCloseModal}
              center
              maxWidth={1000}
            >
              <iframe title="Question Video Solution" src={`https://player.vimeo.com/video/${videoId}`} frameBorder="0" allow="autoplay; fullscreen" style={{ height: videoHeight, width: videoWidth }} allowFullScreen />
            </ModalView>
          )}
        {showBookmarkTypeModal && this.renderbookMarkModal(showBookmarkTypeModal)}
        {currentQuestion.question_type === QUESTION_TYPES.COMPREHENSION
          && comprehensionText[currentQuestion.id] && (comprehensionText[currentQuestion.id].text
            || comprehensionText[currentQuestion.id].supportingPicture)
          && this.renderView(comprehensionText[currentQuestion.id].text,
            comprehensionText[currentQuestion.id].supportingPicture, comprehensionText[currentQuestion.id].supportingPictureSize, '', currentQuestion.id, { comprehensionQuestion: true })}
        {this.renderView(currentQuestion.question_text,
          currentQuestion.supporting_picture,
          questionImageWidth && questionImageWidth.supporting_picture_size,
          currentIndex + 1, currentQuestion.id, { isQuestionText: true })}
        {(currentQuestion.question_type !== QUESTION_TYPES.NUMERICAL && (!isBookmarkView || (isBookmarkView && showOptions))) && [...Array(4)].map((_, i) => this.renderView(currentQuestion[`option_${i + 1}`], currentQuestion[`image_option_${i + 1}`], questionImageWidth && questionImageWidth[`image_option_${i + 1}_size`], i + 1, currentQuestion.id,
          {
            isSelected: selectedOption.includes(i + 1),
            isCorrectAnswer: showResult && currentQuestion.answers.includes(i + 1),
          }, currentIndex + 1))}
        {(currentQuestion.question_type === QUESTION_TYPES.NUMERICAL)
          && (!isBookmarkView || (isBookmarkView && showOptions))
          && this.renderNumericalAnswerInput()}
        {(showResult && ((currentQuestion.solution_text && currentQuestion.solution_text !== '') || currentQuestion.solution_supporting_picture))
          && showResult && this.renderView(currentQuestion.solution_text, currentQuestion.solution_supporting_picture, questionImageWidth && questionImageWidth.solution_supporting_picture_size, '', currentQuestion.id, { showSolution: true })}
      </div>
    );
  }
}

QuestionView.propTypes = {
  richResponse: PropTypes.string,
  hideQuestion: PropTypes.bool,
  isBookmarkView: PropTypes.bool,
  showOptions: PropTypes.bool,
  showMarkIcon: PropTypes.bool,
  sessionInfo: PropTypes.object,
  showResult: PropTypes.bool,
  currentQuestion: PropTypes.object,
  currentIndex: PropTypes.number,
  selectedOption: PropTypes.array,
  isCurrentQuestionMarked: PropTypes.bool,
  showSolutionIcon: PropTypes.bool,
  shouldUploadResponses: PropTypes.bool,
  solutionRequested: PropTypes.bool,
  isCurrentQuestionBookmarked: PropTypes.bool,
  currentQuestionTimeSpent: PropTypes.object,
  toggleQuestionView: PropTypes.func,
  updateQuestionMarkedList: PropTypes.func,
  updateSolutionRequestStatus: PropTypes.func,
  updateQuestionSelectedOptions: PropTypes.func.isRequired,
  updateQuestionTimeSpent: PropTypes.func.isRequired,
  updateBookmarkQuestionList: PropTypes.func,
  updateRichResponse: PropTypes.func,
  updateQuestionBookmarkState: PropTypes.func.isRequired,
  reportError: PropTypes.func.isRequired,
  isCurrentQuestionBlocked: PropTypes.bool,
  showToast: PropTypes.func.isRequired,
  questionsVisited: PropTypes.array,
  comprehensionText: PropTypes.object,
  showVideoSolutionIcon: PropTypes.bool,
};

QuestionView.defaultProps = {
  toggleQuestionView: () => {},
  updateRichResponse: () => {},
  updateBookmarkQuestionList: () => {},
  updateQuestionMarkedList: () => {},
  updateSolutionRequestStatus: () => {},
  hideQuestion: false,
  showOptions: false,
  showMarkIcon: false,
  sessionInfo: {},
  isCurrentQuestionBlocked: false,
  showResult: false,
  currentQuestion: {},
  questionsVisited: [],
  currentIndex: 0,
  selectedOption: [],
  isCurrentQuestionMarked: false,
  shouldUploadResponses: false,
  currentQuestionTimeSpent: {},
  showSolutionIcon: false,
  solutionRequested: false,
  isCurrentQuestionBookmarked: false,
  comprehensionText: {},
  isBookmarkView: false,
  showVideoSolutionIcon: false,
  richResponse: '',
};

export default connect(null,
  { reportError, showToast, updateQuestionBookmarkState })(QuestionView);
