import React, { PureComponent } from 'react';
import Cookies from 'universal-cookie';
import Text from './text';
import StyleConstants from '../shared/styleConstants/styles';
import referral from '../shared/images/common/referral.svg';
import Layout from './layout';

const cookies = new Cookies();

class Referral extends PureComponent {
  state = {
    isReferralCodeCopied: false
  }

  render() {
    const referralTexts = [
      "Invite your friends.",
      "When they download and make the payment.",
      "You and your friend get rewarded."
    ];
    const referralCode = cookies.get('user').referral_code;
    const { isReferralCodeCopied } = this.state;
    return (
      <Layout hideFooter>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 60 }}>
          <div style={{ width: 350 }}>
            <img alt="NEET_JEE_Premium" src={referral} width={350} height={280} style={{ marginBottom: 30 }} />
            <Text
              text="How it works"
              color={StyleConstants.textColor.primary}
              fontSize={StyleConstants.textSize.headerSmall}
              fontWeight={StyleConstants.textWeight.bold}
            />
            {referralTexts.map((text, index) => <div key={`ReferralText_${index}`} style={{ marginTop: 10 }}><Text text={`${index + 1}. ${text}`} /> </div>)}
            <div style={{ marginTop: 30 }}>
              <Text
                text="Your Referral Code"
                color={StyleConstants.textColor.primary}
                fontSize={StyleConstants.textSize.headerSmall}
                fontWeight={StyleConstants.textWeight.bold}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                <input type="text" value={referralCode} readOnly style={{ height: StyleConstants.fieldHeight.normal, marginTop: 0, width: 220, fontWeight: StyleConstants.textWeight.bold }} />
                <div
                  id='Copy-Referral-Code'
                  className="Button Primary-Button"
                  style={{ width: 120, height: StyleConstants.fieldHeight.normal, backgroundColor: isReferralCodeCopied && StyleConstants.color.Correct, borderColor: isReferralCodeCopied && StyleConstants.color.Correct }}
                  onClick={() => {
                    window.navigator.clipboard.writeText(referralCode);
                    this.setState({ isReferralCodeCopied: true });
                  }}
                > {isReferralCodeCopied ? 'Copied' : 'Copy' } </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Referral;
