import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import Cookies from 'universal-cookie';
import { createBrowserHistory } from 'history';
import Playstore from './playstore';
import Loader from './loader';
import InstituteFile from './instituteFiles';
import OfflineTest from './offlineTest';
import QuizScores from './quizScores';
import Attendance from './virtualMeetingattendance';
import VirtualMeeting from './virtualMeeting';
import VirtualMeetingFeedback from './virtualMeetingFeedback';
import Profile from './profile';
import Home from './home';
import TrainChapter from './trainChapter';
import VideoLibrary from './videoLibrary'
import Bookmark from './bookmark';
import Train from './train';
import Careers from './staticPage/careers';
import Openings from './staticPage/careers/getOpenings';
import MyProgress from './myProgress';
import Index from './index';
import Login from './login';
import AboutUs from './staticpages/aboutUs';
import HomeLanding from './staticpages/home';
import Terms from './terms';
import SolutionBoard from './solutionBoard';
import Privacy from './privacy';
import Test from './test';
import TestInstruction from './testInstruction';
import TestReport from './testReport';
import InitialTestReport from './initialTestReport';
import ChapterPerformance from './chapters';
import Improvements from './improvements';
import PersonalizedReport from './personalizedAssignmentReport';
import Leaderboard from './leaderBoard';
import ViewClass from './viewClass';
import Payment from './payment';
import LiveVideo from './liveVideo';
import Referral from './referral';
import TimeTable from './timeTable';
import AttendQuiz from './attendQuiz';
import Text from './text';
import CheatSheet from './cheatSheet';
import VideoPage from './videoPage';
import ChapterVideoList from './chapterVideoList';
import MobileRedirection from './mobileRedirection';
import { closeToastMessage } from '../actions';
import { TOAST_MESSAGE_TYPES, SESSION_TYPES, OBJECT_TYPES } from '../shared/constants/fieldTypes';
import constants from '../shared/constants';
import success from '../shared/images/common/success.svg';
import error from '../shared/images/common/error.svg';
import warning from '../shared/images/common/warning.svg';
import close from '../shared/images/common/close.svg';
import {
  isMobileDevice, convertTitleCaseString, getInsituteDomain, getParentDomain,
} from '../helpers/Utils';
import Layout from './layout';
import ShowQuizQuestion from './common/showQuizQuestion';

const IS_MOBILE = isMobileDevice();
const Constants = constants();
const cookies = new Cookies();
const toastBackgroundColor = {};
toastBackgroundColor[TOAST_MESSAGE_TYPES.SUCCESS] = '#4AD991';
toastBackgroundColor[TOAST_MESSAGE_TYPES.WARNING] = '#FF9519';
toastBackgroundColor[TOAST_MESSAGE_TYPES.ERROR] = '#F16F6F';
const MEMBERSHIP_TYPES = {
  ADMIN: 'Admin',
  TEACHER: 'Teacher',
  GUARDIAN: 'Guardian',
  COUNSELLOR: 'Counsellor',
  STUDENT: 'Student',
};

const history = createBrowserHistory({
  forceRefresh: true,
});

class RouteNavigation extends PureComponent {
  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this);
    this.showErrorPage = this.showErrorPage.bind(this);
    this.notifyMaintenance = this.notifyMaintenance.bind(this);
    this.state = {
      screenHeight: '',
    };
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize() {
    this.forceUpdate();
  }

  showErrorPage() {
    if (IS_MOBILE) {
      return (
        <div>
          <div style={{
            display: 'flex', flexDirection: 'column', padding: 20, backgroundColor: 'white',
          }}
          >
            <div style={{ fontSize: 20 }}>
              Something went wrong
            </div>
            <div style={{ marginTop: 20, fontSize: 16, color: 'gray' }}>
              please try again after sometime, if problem persists contact us at
              <div>
                <a href={`tel:${constants().contact}`}>
                  <span>
                    <img style={{ height: 25, margin: '0px 4px' }} alt="NEET_JEE_phone" src="/images/common/phone.png" />
                    <span>{constants().contact}</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div style={{
            height: '100% !important', display: 'flex', justifyContent: 'center', position: 'fixed', right: 0,
          }}
          >
            <img style={{ width: '100%', height: '100%' }} alt="NEET_JEE_something_went_wrong_mobile" src="/images/common/something-went-wrong-mobile.png" />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div style={{
          display: 'flex', flexDirection: 'column', position: 'fixed', top: 135, left: '5%', zIndex: 1000,
        }}
        >
          <div style={{ fontSize: 30 }}>
            Something went wrong
          </div>
          <div style={{ marginTop: 20, fontSize: 20, color: 'gray' }}>
            please try again after sometime, if problem persists contact us at
            <img style={{ height: 25, margin: '0px 4px' }} alt="NEET_JEE_phone" src="/images/common/phone.png" />
            {constants().contact}
          </div>
        </div>
        <div style={{
          height: '100% !important', width: '50%', display: 'flex', justifyContent: 'center', position: 'fixed', right: 30, bottom: 30,
        }}
        >
          <img style={{ height: '100%', width: '100%' }} alt="NEET_JEE_something_went_wrong_web" src="/images/common/something-went-wrong.svg" />
        </div>
      </div>
    );
  }

  notifyMaintenance() {
    const { screenHeight } = this.state;
    if (IS_MOBILE) {
      return (
        <div>
          <div style={{
            display: 'flex', flexDirection: 'column', padding: 20, backgroundColor: 'white',
          }}
          >
            <div style={{ fontSize: 20 }}>
              Server under maintenance
            </div>
            <div style={{ marginTop: 20, fontSize: 16, color: 'gray' }}>
              please try again after sometime
            </div>
          </div>
          <div style={{
            height: '100% !important', display: 'flex', justifyContent: 'center', position: 'fixed', right: 0,
          }}
          >
            <img style={{ width: '100%', height: '100%' }} alt="server_under_maintenance_mobile" src="/images/common/something-went-wrong-mobile.png" />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div style={{
          display: 'flex', flexDirection: 'column', position: 'fixed', top: 135, left: '5%', zIndex: 1000,
        }}
        >
          <div style={{ fontSize: 36 }}>
            Server under maintenance
          </div>
          <div style={{ marginTop: 20, fontSize: 26, color: 'gray' }}>
            please try again after sometime
          </div>
        </div>
        <div style={{
          height: '100% !important', display: 'flex', justifyContent: 'center', position: 'fixed', right: 0,
        }}
        >
          <img style={{ height: screenHeight - 70 }} alt="server_under_maintenance" src="/images/common/server-maintainance.svg" />
        </div>
      </div>
    );
  }

  renderToast() {
    const { toastMessage, toastMessageType } = this.props;
    const imageUrl = {
      success,
      warning,
      error,
    };
    return (
      <div style={{
        top: 90, right: IS_MOBILE ? 0 : 40, position: 'fixed', display: 'flex', justifyContent: IS_MOBILE ? 'center' : 'flex-end', width: window.innerWidth, zIndex: 1001, padding: '0px 20px',
      }}
      >
        <div style={{
          borderRadius: 4, boxShadow: '0px 3px 15px rgba(65, 61, 74, 0.1)', backgroundColor: '#454545', display: 'flex', width: '375px',
        }}
        >
          <div style={{ width: 5, backgroundColor: toastBackgroundColor[toastMessageType], borderRadius: '4px 0px 0px 4px' }} />
          <div style={{
            padding: 20, textAlign: 'left', justifyContent: 'space-between', width: '100%',
          }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  className="Circle"
                  style={{
                    backgroundColor: toastBackgroundColor[toastMessageType],
                    width: 30,
                    height: 30,
                    marginRight: 15,
                  }}
                >
                  <img alt="NEET_JEE_ToastIcon" src={imageUrl[toastMessageType]} width={15} height={15} />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: 18, color: '#FFFFFF' }}>{convertTitleCaseString(toastMessageType)}</div>
                  <div>
                    <img role="presentation" id="Close-Toast" alt="NEET_JEE_Close_icon" src={close} width={20} height={20} style={{ padding: 5, cursor: 'pointer' }} onClick={() => this.props.closeToastMessage()} />
                  </div>
                </div>
                <div style={{ width: '90%' }}><Text text={toastMessage} color="#FFFFFF" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLandingPageBasedOnStudentInstituteType(props) {
    const user = cookies.get('user');
    if (user) {
      if (user.type === MEMBERSHIP_TYPES.STUDENT) {
        if (user.is_higher_education) {
          window.location.href = `${window.location.href}virtual_meeting`;
        } else if (user.has_only_test_board_access) {
          window.location.href = `${window.location.href}institute?type=test`;
        } else {
          return <Home {...props} userProfile={this.props.userProfile} />;
        }
      } else if (user.type === MEMBERSHIP_TYPES.ADMIN || user.type === MEMBERSHIP_TYPES.TEACHER || user.type == MEMBERSHIP_TYPES.COUNSELLOR) {
        window.location.replace(getInsituteDomain(user.is_higher_education, user.type === MEMBERSHIP_TYPES.TEACHER));
      } else if (user.type === MEMBERSHIP_TYPES.GUARDIAN) {
        window.location.replace(getParentDomain());
      } else {
        return <Home {...props} userProfile={this.props.userProfile} />;
      }
      return null;
    }
    return <Home {...props} />;
  }

  render() {
    const { screenHeight } = this.state;
    const {
      userProfile, showQuizQuestionsList, dataUpdateCount,
      showToast, isServerError, isServerUnderMaintenance, loaderState,
      isRecorded,
    } = this.props;
    if (isServerUnderMaintenance) {
      return (
        <Router>
          <Layout hideFooter isServerUnderMaintenance>
            {this.notifyMaintenance()}
          </Layout>
        </Router>
      );
    }
    if (isServerError) {
      return (
        <Router>
          <Layout hideFooter isServerUnderMaintenance>
            {this.showErrorPage()}
          </Layout>
        </Router>
      );
    }
    return (
      <Router>
        <div className="App" style={{ minHeight: screenHeight, height: '100% !important' }}>
          {loaderState && <Loader />}
          {showToast && this.renderToast()}
          {showQuizQuestionsList && <ShowQuizQuestion integrated key={dataUpdateCount} isRecorded={isRecorded} showReport />}
          <div className={`App ${loaderState ? 'hide' : ''}`}>
            <Switch>
              <Route exact path="/" render={(props) => this.renderLandingPageBasedOnStudentInstituteType(props)} />
              <Route path="/mobile_redirection" component={MobileRedirection} />
              <Route path="/index" component={Index} />
              <Route path="/about" component={AboutUs} />
              <Route
                path="/talent"
                render={() => {
                  window.location.href = Constants.talentUrl;
                  return null;
                }}
              />
              <Route path="/playstore_redirect" component={Playstore} />
              <Route path="/home" component={HomeLanding} />
              <Route path="/test_instruction/:id" component={TestInstruction} />
              <Route path="/test/:id" component={Test} />
              <Route path="/attend_quiz/:id" component={AttendQuiz} />
              <Route path="/test_report/:id/chapter_performance" component={ChapterPerformance} />
              <Route path="/test_report/:id" component={TestReport} />
              <Route path="/initial_test_report/:id" component={InitialTestReport} />
              <Route path="/leaderboard/:id" component={Leaderboard} />
              <Route path="/view_class/:id" component={ViewClass} />
              <Route path="/attendance" component={Attendance} />
              <Route path="/video_library" component={VideoLibrary} />
              <Route path="/offline_tests" component={OfflineTest} />
              <Route path="/time_table" component={TimeTable} />
              <Route path="/institute_files/:type/:id" component={InstituteFile} />
              <Route path="/institute_files/:type" component={InstituteFile} />
              <Route path="/quiz_scores" component={QuizScores} />
              <Route path="/improvements/:id/:type" component={Improvements} />
              <Route path="/payment" component={Payment} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              {/* Removed Solutions Board since many students are misusing it during exams */}
              {/* <Route path="/solutions" component={SolutionBoard} /> */}
              <Route path="/chapter/:id" component={(TrainChapter)} />
              <Route path="/bookmark/:type" component={Bookmark} />
              <Route path="/bookmark" component={Bookmark} />
              <Route path="/careers" component={Careers} />
              <Route path="/jobs" component={Openings} />
              <Route path="/my_progress" component={MyProgress} />
              <Route path="/profile" render={(props) => <Profile {...props} userProfile={(userProfile)} />} />
              <Route path="/user_registration" render={(props) => <Login {...props} pageType="register" />} />
              <Route path="/train/:id" render={(props) => <Train {...props} objectType={OBJECT_TYPES.CHECKPOINT} sessionType={SESSION_TYPES.ADAPTIVE} />} />
              <Route path="/chapter_revise/:id" render={(props) => <Train {...props} objectType={OBJECT_TYPES.CHECKPOINT} sessionType={SESSION_TYPES.REVISE} />} />
              <Route path="/assignment/:id" render={(props) => <Train {...props} objectType={OBJECT_TYPES.ASSIGNMENT} sessionType={SESSION_TYPES.ADAPTIVE} />} />
              <Route path="/chapter_previous_paper/:id" render={(props) => <Train {...props} objectType={OBJECT_TYPES.CHAPTER} sessionType={SESSION_TYPES.PREVIOUS_PAPER} />} />
              <Route path="/personalised_assignment/:id" render={(props) => <Train {...props} objectType={OBJECT_TYPES.PERSONALISED_TEST} sessionType={SESSION_TYPES.ADAPTIVE} />} />
              <Route path="/session_report/:id" component={PersonalizedReport} />
              <Route path="/referral_code" component={Referral} />
              <Route path="/videos" component={VideoPage} />
              <Route path="/live_classroom" component={LiveVideo} />
              <Route path="/cheat_sheet/:id" component={CheatSheet} />
              <Route path="/chapter_video_list/:id" component={ChapterVideoList} />
              <Route exact path="/virtual_meeting/:id/feedback" component={VirtualMeetingFeedback} />
              <Route exact path="/virtual_meeting" component={VirtualMeeting} />
              <Route exact path="/:type" render={(props) => <Home {...props} userProfile={userProfile} />} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

RouteNavigation.propTypes = {
  closeToastMessage: PropTypes.func.isRequired,
  isServerError: PropTypes.bool,
  loaderState: PropTypes.bool,
  showToast: PropTypes.bool,
  toastMessage: PropTypes.string,
  toastMessageType: PropTypes.string,
  userProfile: PropTypes.object,
  notifications: PropTypes.array,
  isServerUnderMaintenance: PropTypes.bool,
  showQuizQuestionsList: PropTypes.bool,
  dataUpdateCount: PropTypes.number,
  virtualMeeting: PropTypes.bool,
};

RouteNavigation.defaultProps = {
  isServerError: false,
  loaderState: false,
  showToast: false,
  toastMessage: '',
  toastMessageType: '',
  virtualMeeting: false,
  showQuizQuestionsList: false,
  dataUpdateCount: 0,
  userProfile: {},
  notifications: [],
  isServerUnderMaintenance: false,
};

const mapStateToProps = ({ global, home, virtualMeeting }) => ({
  isServerError: global.isServerError,
  loaderState: global.loaderState,
  toastMessage: global.toastMessage,
  showToast: global.showToast,
  showQuizQuestionsList: virtualMeeting.showQuizQuestionsList,
  isRecorded: virtualMeeting.isRecorded,
  dataUpdateCount: virtualMeeting.dataUpdateCount,
  toastMessageType: global.toastMessageType,
  notifications: home.notifications,
  isServerUnderMaintenance: global.isServerUnderMaintenance,
});

export default connect(mapStateToProps, { closeToastMessage })(RouteNavigation);
