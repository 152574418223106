import { createBrowserHistory } from 'history';
import axios from 'axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { showToast, serverError, serverUnderMaintenance, logout } from '../actions/index';
import { store } from '../index';
import constants from '../shared/constants';
import { getHomeDomain } from './Utils';


const history = createBrowserHistory({
  forceRefresh: true,
});

const cookies = new Cookies();
const Constants = constants();

const getToken = () => {
  const token = cookies.get('token');
  if (token) {
    return `bearer ${cookies.get('token')}`;
  }
  return '';
};

export const getUser = () => {
  if (typeof global.user !== 'undefined' && global.user && global.user !== '') {
    return global.user;
  }
  const user = cookies.get('user');
  return user || {};
};

export const expiry = () => {
  history.push('/payment');
};

const notifyServerMaintenance = () => {
  store.dispatch(serverUnderMaintenance());
}

const redirectToInstituteTest = (testId, message) => {
  store.dispatch(showToast(message));
  history.push(`/test/${testId}`);
};

const redirectToMobileRedirectionPage = () => {
  history.push('/mobile_redirection');
};

const Api = (options) => {
  const onSuccess = (response) => {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.log('Request Successful!', 'response');
    }
    if (response.status === 307) {
      if (response.has_only_mobile_access) {
        redirectToMobileRedirectionPage();
      } else if (response.test_id) {
        redirectToInstituteTest(response.test_id, response.message);
      }
      return;
    }
    if (response.status === 410) {
      expiry();
    }
    if (response.status === 406) {
      if (response.data.redirect_domain) {
        window.location.href = response.data.redirect_domain;
      } else {
        window.location.href = getHomeDomain();
      }
    }
    return response.data;
  };

  const onError = (error) => {
    if (error.response && error.response.status === 307) {
      if (error.response.data.institutes_with_mobile_test_board_access_only) {
        redirectToMobileRedirectionPage();
        history.push('/mobile_redirection');
      } else if (error.response.data.test_id) {
        redirectToInstituteTest(error.response.data.test_id, error.response.data.message);
      }
      return;
    }
    if (error.response && error.response.status === 503) {
      notifyServerMaintenance();
    }
    if (error.response && error.response.status === 406) {
      if (error.response.data && error.response.data.redirect_domain) {
        window.location.href = error.response.data.redirect_domain;
      } else {
        window.location.href = getHomeDomain();
      }
      return;
    }

    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
      return;
    }

    if (error.response && error.response.status === 410) {
      expiry();
      return;
    }

    if (process.env.REACT_APP_ENV !== 'production') {
      console.log('Request Failed:', error);

      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.log('Status:', error.response.status);
        console.log('Data:', error.response.data);
        console.log('Headers:', error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.log('Error Message:', error.message);
      }
    }
    if (options.url !== '/student_app/upload_student_responses') {
      store.dispatch(serverError());
    }
    return error.response || error.message;
  };

  if (options.method === 'post') {
    if (options.attachment) {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const formData = new FormData();
      if (options.attachment.length === 1) {
        formData.append('file', options.attachment[0]);
      } else {
        options.attachment.forEach((file, index) => {
          formData.append(`file_${index + 1}`, file);
        });
      }

      if (options.data) {
        Object.keys(options.data).forEach((key) => {
          formData.set(key, options.data[key]);
        });
      }
      formData.set('token', getToken());
      formData.set('webAppVersionCode', Constants.webAppVersionCode);
      return axios.post(`${Constants.api.url}${options.url}`,
        formData,
        config).then(onSuccess)
        .catch(onError);
    }

    return axios({
      method: options.method,
      url: `${Constants.api.url}${options.url}`,
      data: options.data,
      transformRequest: [function (data) {
        data = data || {};
        data.token = getToken();
        data.webAppVersionCode = Constants.webAppVersionCode;
        return queryString.stringify(data);
      }],
    })
      .then(onSuccess)
      .catch(onError);
  }
  let url = `${Constants.api.url}${options.url}`;
  if (options.url.includes('?')) {
    url += `&token=${getToken()}&webAppVersionCode=${Constants.webAppVersionCode}`;
  } else {
    url += `?token=${getToken()}&webAppVersionCode=${Constants.webAppVersionCode}`;
  }
  return axios({
    method: options.method,
    url,
  })
    .then(onSuccess)
    .catch(onError);
};

export default Api;
