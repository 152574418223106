import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';

import HomeSideBar from './homeSideBar';
import TrainSideBar from './trainSideBar';
import TestSideBar from './testSideBar';
import VideoListSideBar from './videoListSideBar';
import ImprovementSideBar from './improvementSideBar';
import Text from '../text';
import backIcon from '../../shared/images/common/back.svg';
import UserIcon from '../../shared/images/common/user-new.svg';
import StyleConstants from '../../shared/styleConstants/styles';
import { SIDEBAR_TYPES } from '../../shared/constants/fieldTypes';

const cookies = new Cookies();

const history = createBrowserHistory({
  forceRefresh: true,
});

class Sidebar extends PureComponent {
  renderSideBar() {
    const { pageType } = this.props;
    if (pageType === SIDEBAR_TYPES.TRAIN) {
      return <TrainSideBar />;
    }
    if (pageType === SIDEBAR_TYPES.TEST) {
      return <TestSideBar />;
    }
    if (pageType === SIDEBAR_TYPES.IMPROVEMENTS) {
      return <ImprovementSideBar />;
    }
    if ((pageType === (SIDEBAR_TYPES.HOME)) || (pageType === SIDEBAR_TYPES.VIRTUAL_MEETINGS)) {
      return <HomeSideBar />;
    }
    if (pageType === SIDEBAR_TYPES.CHAPTER_VIDEO_LIST) {
      return <VideoListSideBar />;
    }
    return <div />;
  }

  renderBackButton() {
    return (
      <div
        id="Sidebar-Back-Button-Press"
        role="presentation"
        className="flexcontainer Sidebar_Padding"
        onClick={() => this.props.functionCall && this.props.functionCall()}
        style={{
          backgroundColor: StyleConstants.backgroundColor.highlight, marginBottom: 10, alignItems: 'center', cursor: 'pointer',
        }}
      >
        <img alt="NEET_JEE_Previous_icon" height={19} width={19} src={backIcon} style={{ marginRight: 5 }} />
        <Text
          color={StyleConstants.textColor.primary}
          fontWeight={StyleConstants.textWeight.semiBold}
          text="Back"
        />
      </div>
    );
  }

  renderBackNavigation() {
    const { navigateTo, functionCall } = this.props;
    if (functionCall) {
      return this.renderBackButton();
    }
    return (
      <div role="presentation" style={{ cursor: 'pointer' }} onClick={() => history.push(navigateTo)}>
        {this.renderBackButton()}
      </div>
    );
  }

  render() {
    const { showBackNavigation } = this.props;
    const user = cookies.get('user');
    return (
      <div className="SideBar">
        {showBackNavigation && this.renderBackNavigation()}
        <div style={{ marginTop: !showBackNavigation && 15 }}>
          {!showBackNavigation
          && (
          <div
            style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#8D9FB4',
            }}
          >
            <img src={UserIcon} height="80px" width="80px" alt="" />
            <div style={{ marginTop: 10, color: '#8D9FB4' }}>
              {user.name ? user.name : ''}
            </div>
            <div style={{
              marginTop: 10, display: 'flex', alignItems: 'center',
            }}
            >
              {user.badge_id && <div style={{ marginRight: 6, fontSize: 14 }}>{user.badge_id}</div>}
              {(user.badge_id) && <div style={{ marginRight: 6, fontSize: 18 }}>|</div>}
              <div style={{ marginRight: 6, fontSize: 14 }}>Student</div>
            </div>
          </div>
          )}
          {this.renderSideBar()}
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  showBackNavigation: PropTypes.bool,
  functionCall: PropTypes.func.isRequired,
  navigateTo: PropTypes.string,
  pageType: PropTypes.string,
};

Sidebar.defaultProps = {
  showBackNavigation: false,
  navigateTo: '/',
  pageType: '',
};

const mapStateToProps = ({ auth }) => ({
  userData: auth.user,
});

export default connect(mapStateToProps, { })(Sidebar);
