import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import '../../../shared/styleConstants/staticPages/careers.css';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { staticPages, textSize } from '../../../shared/styleConstants/styles';
import { cultures, perks } from './careersData.json';
import StaticText from '../staticText';
import { displayDepartments } from '../../../actions/index';
import Layout from '../../layout';

const { fontColor, fontWeight } = staticPages;
const history = createBrowserHistory({
  forceRefresh: true,
});

class Careers extends PureComponent {
  componentDidMount() {
    this.props.displayDepartments();
  }

  directTo(val) {
    const { title: idopn } = val;
    history.push(`/jobs/get_openings?type=${idopn}`);
  }

  render() {
    return (
      <Layout>
        <div>
          <div className="start">
            <div className="static-pages flex-container">
              <div
                className="row header header-size"
                style={{
                  width: '100%', marginTop: '45px',
                }}
              >
                <div
                  style={{
                    textAlign: 'center', display: 'flex', flexDirection: 'column'
                  }}
                >
                  <StaticText
                    fontWeight={fontWeight.header}
                    color={fontColor.header}
                    text="Join us in inventing"
                    definedClass="header"
                  />
                <StaticText
                    fontWeight={fontWeight.header}
                    color={fontColor.header}
                    text="the future of education."
                    definedClass="header"
                  />
                </div>
              </div>

              <div
                className="row"
                style={{
                  textalign: 'center',
                  marginTop: 40,
                }}
              >
                <a href="#viewOpenings">
                  <button
                    type="button"
                    style={{
                      color: '#FFFFFF',
                    }}
                    className="button"
                  >
                    VIEW OPENINGS
                  </button>
                </a>
              </div>
              <div
                style={{
                  marginTop: 70
                }}
                className="row header header-size-secondary"
              >
                <StaticText
                  color={fontColor.header}
                  fontWeight={fontWeight.header}
                  text="The IdeaBoard Culture"
                />
              </div>
              <div className="row flex-container-content wrap">
                {cultures.map((culture) => (
                  <div key={culture.url} className="flex-item culture-flex" style={{padding: 0}}>
                    <img src={culture.url} alt={culture.url} />
                    <br />
                    <div
                      className="row header content-header-size"
                      style={{ marginTop: 0, fontSize: textSize.headerBig
                    }}
                    >
                      <StaticText
                        color={fontColor.header}
                        fontWeight={fontWeight.header}
                        text={culture.contentPrimary}
                      />
                    </div>
                    <div className="row content-content-size">
                      <StaticText
                        fontWeight={fontWeight.content}
                        color={fontColor.content}
                        text={culture.contentSecondary}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  textalign: 'center',
                  marginTop: 70,
                  marginBottom: 10,
                }}
                className="row header header-size-secondary"
              >
                <StaticText
                  color={fontColor.header}
                  fontWeight={fontWeight.header}
                  text="Perks & Benefits"
                />
              </div>

              <div className="row flex-container-content wrap">
                {perks.map((perk) => (
                  <div
                    key={perk.url}
                    textalign="center"
                    className="flex-item perks-flex"
                  >
                    <img src={perk.url} style={{ float: 'left' }} alt={perk.url} />
                    <div style={{ marginLeft: 70, marginTop: 15 }}>
                      <div className="header content-header-size">
                        <StaticText
                          style={{ marginTop: 40, textalign: 'left' }}
                          color={fontColor.header}
                          fontWeight={fontWeight.header}
                          text={perk.contentPrimary}
                        />
                      </div>


                    </div>
                  </div>
                ))}
              </div>

              <div
                style={{
                  textalign: 'center',
                  marginTop: 70,
                }}
                className="row header header-size-secondary"
              >
                <StaticText
                  color={fontColor.header}
                  fontWeight={fontWeight.header}
                  text="Come Work With Us!"
                />
              </div>
            </div>

            <div id="viewOpenings" className="flex-bottom-container static-pages">
              {Object.keys(this.props.openings).map((key) => (
                <div
                  key={key}
                  className="opening button-heading-size left-pad"
                  style={{ marginTop: 40 }}
                >
                  <span style={{ marginBottom: 20 }} className="header">
                    {key}
                  </span>
                  <div className="link-to-page ">
                    {this.props.openings[key].map((val) => (
                      <span className="individual-button" key={val.title}>
                        <button
                          type="button"
                          className="openings-button row"
                          style={{
                            textAlign: 'left',
                            marginLeft: '2%',
                          }}
                          onClick={() => this.directTo(val)}
                        >
                          <div
                            style={{
                              width: '80%',
                              float: 'left',
                            }}
                          >
                            <span>{val.value}</span>
                          </div>
                          <div
                            style={{
                              width: '20%',
                              float: 'right',
                            }}
                          >
                            <span
                              className="arrow-img"
                              style={{
                                textAlign: 'center',
                                float: 'right',
                              }}
                            />
                          </div>
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div style={{ paddingBottom: 50 }} />
          </div>
        </div>
      </Layout>
    );
  }
}

Careers.propTypes = {
  openings: PropTypes.object,
  displayDepartments: PropTypes.func,
};

const mapStateToProps = ({ staticPages }) => ({
  openings: staticPages.openings,
});

export default connect(
  mapStateToProps,
  { displayDepartments },
)(Careers);
