import axios from 'axios';

import Api from '../helpers/Api';
import { sendEvent } from '../helpers/Analytics';
import { finishTest } from './test';
import { TOAST_MESSAGE_TYPES, SIDEBAR_TYPES } from '../shared/constants/fieldTypes';

export const SEND_FEEDBACK = 'Global/SEND_FEEDBACK';
export const UPDATE_LOADER_STATE = 'Global/UPDATE_LOADER_STATE';
export const UPDATE_TIMER_EXPIREY = 'Global/UPDATE_TIMER_EXPIREY';
export const SHOW_TOAST = 'Global/SHOW_TOAST_MESSAGE';
export const HIDE_TOAST = 'Global/HIDE_TOAST_MESSAGE';
export const UPDATE_SUBSCRIPTION_DETAILS = 'Global/UPDATE_SUBSCRIPTION_DETAILS';
export const TRAIN_UPDATE_QUESTION_BOOKMARK = 'Train/TRAIN_UPDATE_QUESTION_BOOKMARK';
export const TEST_UPDATE_QUESTION_BOOKMARK = 'Test/TEST_UPDATE_QUESTION_BOOKMARK';
export const BOOKMARK_UPDATE_QUESTION_BOOKMARK = 'bookmark/BOOKMARK_UPDATE_QUESTION_BOOKMARK';
export const VERIFY_COUPON_CODE = 'Payment/VERIFY_COUPON_CODE';
export const REMOVE_COUPON_CODE = 'Payment/REMOVE_COUPON_CODE';
export const VERIFY_REFERRAL_CODE = 'Payment/VERIFY_REFERRAL_CODE';
export const REMOVE_REFERRAL = 'Payment/REMOVE_REFERRAL';
export const REMOVE_PAYMENT_HIGHLIGHT = 'Payment/REMOVE_PAYMENT_HIGHLIGHT';
export const UPDATE_COUPON_SPINNER_STATE = 'Payment/UPDATE_COUPON_SPINNER_STATE';
export const UPDATE_REFERRAL_SPINNER_STATE = 'Payment/UPDATE_REFERRAL_SPINNER_STATE';
export const GET_INSTITUTE_FILES = 'Global/GET_INSTITUTE_FILES';
export const GET_OFFLINE_TEST_SUBJECTS = 'Global/GET_OFFLINE_TEST_SUBJECTS';
export const GET_OFFLINE_TEST = 'Global/GET_OFFLINE_TEST';
export const SET_STATUS_OFFLINE_TEST = 'Global/SET_STATUS_OFFLINE_TEST';
export const SERVER_ERROR = 'Global/SERVER_ERROR';
export const FILE_UPLOAD_PERCENTAGE = 'Global/FILE_UPLOAD_PERCENTAGE';
export const SERVER_UNDER_MAINTENANCE = 'Global/SERVER_UNDER_MAINTENANCE';

export const showToast = (message, messageType = TOAST_MESSAGE_TYPES.SUCCESS, duration = 10000) => async (dispatch, getState) => {
  dispatch({ type: SHOW_TOAST, message, messageType });
  setTimeout(() => {
    dispatch({ type: HIDE_TOAST });
  }, duration);
};

export const getFileDetails = () => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: '/institutes/get_files_shared_with_student',
  });
  if (response.success) {
    const payload = { filesData: response.subject_files_mapping, subjects: response.subjects, virtualFileData: response.virtual_meeting_files_mapping };
    dispatch({ type: GET_INSTITUTE_FILES, payload });
  }
  dispatch(updateLoaderState(false));
};

export function fileUploadPercentage(percentageCompleted) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({
      type: FILE_UPLOAD_PERCENTAGE,
      payload: percentageCompleted,
    });
    resolve();
  });
}

export const getOfflineTestSubjects = () => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: '/institutes/get_offline_test_subjects',
  });
  if (response.success) {
    const payload = {
      offlineTestListSubjectList: response.offline_tests,
    };
    dispatch({
      type: GET_OFFLINE_TEST_SUBJECTS,
      payload,
    });
  } else {
    dispatch(showToast(response.message || 'Something went wrong, please contact us!'));
  }
  dispatch(updateLoaderState(false));
};

export const getOfflineTest = (subjectId) => async (dispatch) => {
  dispatch(updateLoaderState(true));
  const response = await Api({
    method: 'get',
    url: `/institutes/get_subjects_offline_test?subject_id=${subjectId}`,
  });
  if (response.success) {
    const payload = {
      offlineTestLists: response.subject_offline_test_list,
      offlineTestSubject: response.subject_name,
    };
    dispatch({
      type: GET_OFFLINE_TEST,
      payload,
    });
  } else {
    dispatch(showToast(response.message || 'Something went wrong, please contact us!'));
  }
  dispatch(updateLoaderState(false));
};

export const updateOfflineTestStudentResponse = (offlineTestStudentResponseId, status, subjectId) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/institutes/update_offline_test_student_response_status',
    data: { offline_test_student_response_id: offlineTestStudentResponseId, status, subject_id: subjectId },
  });
  if (response.success) {
    dispatch({
      type: SET_STATUS_OFFLINE_TEST,
      payload: {
        offlineTestLists: response.subject_offline_test_list,
      },
    });
  } else {
    dispatch(showToast(`Something went wrong, your ${status} is not updated, please try again or contact us!`, TOAST_MESSAGE_TYPES.ERROR));
  }
};

export const getSignedUrl = (fileExtension) => async () => {
  const response = await Api({
    method: 'get',
    url: `/institutes/get_signed_url?file_extension=${fileExtension}`,
  });
  return response;
};

export const uploadFileTo3 = (signedUrlResponse, file) => async (dispatch) => {
  const formData = new FormData();
  Object.keys(signedUrlResponse.presigned_aws_data.url_fields).forEach((key) => {
    formData.set(key, signedUrlResponse.presigned_aws_data.url_fields[key]);
  });
  formData.append('file', file);
  formData.set('Content-Type', file.type);
  const response = await axios.post(signedUrlResponse.presigned_aws_data.url, formData, {
    headers: { 'Content-Type': file.type },
    onUploadProgress: (ProgressEvent) => {
      const percentageCompleted = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);
      dispatch(fileUploadPercentage(percentageCompleted));
    },
  });
  return response;
};

export const updateAnswerSheet = (fileUrl, responseId) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/institutes/update_answer_sheet_url',
    data: { file_url: fileUrl, offline_test_student_response_id: responseId },
  });
  if (response.success) {
    dispatch(showToast('Your answer sheet is submitted successfully!', TOAST_MESSAGE_TYPES.SUCCESS));
  } else {
    dispatch(showToast('Something went wrong please try again, if issue persists please contact us!', TOAST_MESSAGE_TYPES.ERROR));
  }
  return response.success;
};

export const serverError = () => async (dispatch) => {
  dispatch({ type: SERVER_ERROR });
};

export const serverUnderMaintenance = () => async (dispatch) => {
  dispatch({ type: SERVER_UNDER_MAINTENANCE });
};

export const removeReferral = (value) => async (dispatch) => {
  sendEvent('Payments - RemoveReferralCodeTapped');
  dispatch({
    type: REMOVE_REFERRAL,
    payload: {
      highlightReferralCard: false,
      isValidReferral: false,
    },
  });
};

export const verifyReferralCode = (referralCode) => async (dispatch) => {
  sendEvent('Payments - ApplyReferralCodeTapped', { referralCode });
  dispatch({ type: UPDATE_REFERRAL_SPINNER_STATE, showReferralSpinner: true });
  const response = await Api({
    method: 'post',
    url: '/subscriptions/validate_referral',
    data: { referral_code: referralCode },
  });
  if (response.success) {
    dispatch({
      type: VERIFY_REFERRAL_CODE,
      payload: {
        highlightReferralCard: true,
        isValidReferral: response.validReferral,
      },
    });
    if (response.validReferral) {
      sendEvent('Payments - ValidReferralCodeApplied', { referralCode });
    } else {
      sendEvent('Payments - InvalidReferralCodeApplied', { referralCode });
    }
  } else {
    dispatch(showToast('Network error, try again after sometime.', TOAST_MESSAGE_TYPES.ERROR));
  }
  dispatch({ type: UPDATE_REFERRAL_SPINNER_STATE, showReferralSpinner: false });
};

export const removeCoupon = (value) => async (dispatch) => {
  sendEvent('Payments - RemoveCouponTapped');
  dispatch({
    type: REMOVE_COUPON_CODE,
    payload: {
      couponDiscount: 0,
      highlightCouponCard: false,
      isValidCoupon: false,
    },
  });
};

export const removePaymentTextFieldHighlight = (type) => async (dispatch) => {
  dispatch({
    type: REMOVE_PAYMENT_HIGHLIGHT,
    payload: { type },
  });
};

export const verifyCouponCode = (coupon) => async (dispatch) => {
  sendEvent('Payments - ApplyCouponTapped', { coupon });
  dispatch({ type: UPDATE_COUPON_SPINNER_STATE, showCouponSpinner: true });
  const response = await Api({
    method: 'post',
    url: '/subscriptions/validate_coupon',
    data: { coupon },
  });
  if (response.success) {
    if (response.is_coupon_valid) {
      sendEvent('Payments - ValidCouponApplied', { coupon });
    } else {
      sendEvent('Payments - InvalidCouponApplied', { coupon });
    }
    dispatch({
      type: VERIFY_COUPON_CODE,
      payload: {
        couponDiscount: response.is_coupon_valid ? response.offer_percentage : 0,
        highlightCouponCard: true,
        isValidCoupon: response.is_coupon_valid,
      },
    });
  } else {
    dispatch(showToast('Network error, try again after sometime.', TOAST_MESSAGE_TYPES.ERROR));
  }
  dispatch({ type: UPDATE_COUPON_SPINNER_STATE, showCouponSpinner: false });
};

export const sendUserFeedback = (rating, feedbackMessage) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/feedbacks',
    data: { rating, feedbackMessage },
  });
  sendEvent('Profile - FeedbackSubmitted', { submissionStatus: response.success, rating, feedbackMessage });
  dispatch(showToast(response.success ? 'Thank you for your feeback.' : 'Feeback not sent. Network error, try again after sometime.', response.success ? TOAST_MESSAGE_TYPES.SUCCESS : TOAST_MESSAGE_TYPES.ERROR));
};

export const updateQuestionBookmarkState = (type, questionId, isBookmarked, questionPosition, bookmarkTag, questionType, scope) => async (dispatch) => {
  if (!navigator.onLine) {
    dispatch(showToast('You seem to be offline. Try connecting to the internet and try again later.', TOAST_MESSAGE_TYPES.ERROR));
    return;
  }
  dispatch({
    type: type === SIDEBAR_TYPES.TRAIN ? TRAIN_UPDATE_QUESTION_BOOKMARK : type === SIDEBAR_TYPES.TEST ? TEST_UPDATE_QUESTION_BOOKMARK : BOOKMARK_UPDATE_QUESTION_BOOKMARK,
    payload: { questionId, isBookmarked },
  });
  const bookmarkStatus = await Api({
    method: 'post',
    url: '/student_app/update_question_bookmark_status',
    data: {
      question_id: questionId, is_bookmarked: isBookmarked, tag: bookmarkTag, question_type: questionType,
    },
  });
  if (!bookmarkStatus) {
    dispatch({
      type: type === SIDEBAR_TYPES.TRAIN ? TRAIN_UPDATE_QUESTION_BOOKMARK : type === SIDEBAR_TYPES.TEST ? TEST_UPDATE_QUESTION_BOOKMARK : BOOKMARK_UPDATE_QUESTION_BOOKMARK,
      payload: { questionId, bookmarkState: !isBookmarked },
    });
  }
  if (type === SIDEBAR_TYPES.BOOKMARK) {
    scope.forceUpdate();
  }
};

export const updateLoaderState = (state) => {
  const payload = { loaderState: state };
  return {
    type: UPDATE_LOADER_STATE,
    payload,
  };
};

export const updateTrainLoader = (state) => {
  const payload = { trainLoader: state };
  return {
    type: UPDATE_LOADER_STATE,
    payload,
  };
};

export const updateTimerExpirey = (hasTimerExpired, isOtpPage) => async (dispatch) => {
  dispatch({
    type: UPDATE_TIMER_EXPIREY,
    payload: { hasTimerExpired },
  });
  if (isOtpPage) {
    dispatch(showToast('Your test is being submitted as you have run out time.', TOAST_MESSAGE_TYPES.WARNING));
    dispatch(finishTest(true, true));
  }
};

export const reportError = (questionId, errorType, errorText) => async (dispatch) => {
  if (!navigator.onLine) {
    dispatch(showToast('You seem to be offline. Try connecting to the internet and try again later.', TOAST_MESSAGE_TYPES.ERROR));
    return;
  }
  const payload = {
    question_id: questionId,
    problem_type: errorType,
    message: errorText,
  };

  const response = await Api({
    method: 'post',
    url: '/report_issue/create',
    data: payload,
  });

  if (response.success) {
    dispatch(showToast('Your issue has been successfully submited.', TOAST_MESSAGE_TYPES.SUCCESS));
  }
};

export const getSubscriptionDetails = () => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: '/subscriptions/get_subscription_details',
  });
  if (response.success) {
    dispatch({
      type: UPDATE_SUBSCRIPTION_DETAILS,
      payload: { ...response.details },
    });
  }
};

export const closeToastMessage = () => async (dispatch) => {
  dispatch({ type: HIDE_TOAST });
};
