import React from 'react';
import AboutUsWeb from './aboutUsWeb';
import AboutUsMobile from './aboutUsMobile';
import { isMobileDevice } from '../../../helpers/Utils';

const isMobile = isMobileDevice();
class AboutUs extends React.Component {
  render() {
    return (
      isMobile ? (<AboutUsMobile />) : (<AboutUsWeb />)

    );
  }
}
export default AboutUs;
