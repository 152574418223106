import React, { PureComponent } from 'react';
import HttpsRedirect from 'react-https-redirect';
import * as Sentry from '@sentry/browser';
import { createBrowserHistory } from 'history';
import Cookies from 'universal-cookie';
import RouteNavigation from './components/routeNavigation';
import constants from './shared/constants';
import { initAnalytics } from './helpers/Analytics';
import './App.css';
import '../node_modules/react-vis/dist/style.css';

const Constants = constants();
const cookies = new Cookies();
const history = createBrowserHistory({
  forceRefresh: true,
});

class App extends PureComponent {
  render() {
    const userProfile = cookies.get('user');
    Sentry.init({ dsn: Constants.sentryConnectionUri });
    const publicPaths = ['/', '/login', '/terms', '/privacy', '/videos', '/jobs', '/jobs/get_openings', '/about', '/talent', '/playstore_redirect', '/solutions', '/careers'];
    initAnalytics();
    if (!userProfile && !(publicPaths.indexOf(window.location.pathname) > -1)) {
      history.push('/login');
    }
    if (Constants.cookie.secure) {
      return (
        <HttpsRedirect>
          <RouteNavigation userProfile={userProfile} />
        </HttpsRedirect>
      );
    }
    return (
      <RouteNavigation userProfile={userProfile} />
    );
  }
}

export default App;
