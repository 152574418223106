import React, { PureComponent } from 'react';
import Layout from './layout';
import StyleConstants from '../shared/styleConstants/styles';
import Text from './text';
import SideBar from './sidebar';
import { TEST_MODE_TYPES } from '../shared/constants/fieldTypes';

class ChapterPerformance extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderChapters(chapter) {
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', backgroundColor: 'white', borderRadius: '4px', justifyContent: 'space-between', marginTop: 10, width: '100%', boxShadow: '0px 0px 12px  rgba(65, 61, 74, 0.1)',
      }}
      >
        <div
          className="Simple-Card-View"
          style={{
            marginBottom: 5, padding: 0, height: 45, display: 'flex', backgroundColor: 'white', alignItems: 'center', justifyContent: 'space-between',
          }}
        >
          <Text
            text={chapter.chapter_name}
            style={{
              marginLeft: 20,
              color: StyleConstants.color.dark,
              fontSize: StyleConstants.textSize.text,
            }}
          />
          <Text text={`${chapter.correct}/${chapter.total}`} style={{ color: StyleConstants.color.dark, marginRight: 16, fontSize: StyleConstants.textSize.text }} />
        </div>
      </div>
    );
  }

  render() {
    const { id } = this.props.match.params;
    const { chapterList } = this.props.location.state !== undefined
      ? this.props.location.state : [];
    const { selectedSubject } = this.props.location.state !== undefined ? this.props.location.state : '';
    if (typeof chapterList !== 'undefined') {
      return (
        <Layout hideFooter>
          <SideBar showBackNavigation navigateTo={`/${TEST_MODE_TYPES[3]}/${id}`} />
          <div style={{
            height: 44,
            position: 'fixed',
            background: StyleConstants.color.testReportHeader,
            marginLeft: 300,
            width: '100%',
            color: StyleConstants.color.white,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 60,
            fontSize: 18,
          }}
          >
            Chapter Performance
          </div>
          <div
            className="MainPage"
            style={{ marginTop: 70 }}
          >
            {
                chapterList[selectedSubject].map((chapter) => {
                  return (
                    this.renderChapters(chapter)
                  );
                })
            }
          </div>
        </Layout>
      );
    }
    if (chapterList.length === 0) {
      return (
        <Layout hideFooter>
          <div
            className="Header-content"
            style={{
              marginTop: 35, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
            }}
          >
            <Text text="Nothing to display!" style={{ margin: 20, color: StyleConstants.color.dark, fontSize: StyleConstants.textSize.text }} />
          </div>
        </Layout>
      );
    }

    return (
      <Layout hideFooter>
        <div
          className="Header-content"
          style={{
            marginTop: 35, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
          }}
        >
          <Text text="Please try accessing correct page!" style={{ margin: 20, color: StyleConstants.color.dark, fontSize: StyleConstants.textSize.text }} />
        </div>
      </Layout>
    );
  }
}

export default ChapterPerformance;
