import ua from 'universal-analytics';
import constants from '../shared/constants';
import { getUser } from './Api';
import { isMobileDevice } from './Utils';

const IS_MOBILE = isMobileDevice();
const Constants = constants();
const user = getUser();
const visitor = ua(constants().google_analytics_key, user.id, { strictCidFormat: false });


export const googleAnalyticsPage = (path, hostname, title) => {
  visitor.pageview(path, hostname, title).send();
};

const heapTrack = (eventType, screenName, eventAction, properties) => {
  let heapProperties = properties;
  heapProperties['eventAction'] = eventAction;
  heapProperties['screenName'] = screenName;
  window.heap.track(eventType, heapProperties);
}

const googleAnalyticsEvent = (eventType, screenName, eventAction, properties) => {
  const params = {
    ec: eventType,
    ea: eventAction,
    el: JSON.stringify(properties),
    dp: screenName,
  };
  visitor.event(params).send();
};

export const initAnalytics = () => {
  window.amplitude.getInstance().init(Constants.amplitudeApiKey);
  if (user && user.id) {
    window.amplitude.getInstance().setUserId(user.id);
    window.amplitude.getInstance().setUserProperties(user);
  }
};

const amplitudeEvent = (eventType, eventProperties) => {
  window.amplitude.getInstance().logEvent(eventType, eventProperties);
};

export const sendEvent = (eventType, property = {}, heapEvent = false) => {
  const splitEventType = eventType.split('-');
  const deviceType = IS_MOBILE ? 'Mobile' : 'Desktop';
  const properties = { ...property, deviceType };
  if (typeof amplitude !== 'undefined' && !heapEvent) {
    amplitudeEvent(eventType, properties);
  }
  if (heapEvent && !window.heap.identity && user.id) {
    window.heap.identify(user.id);
    window.heap.addUserProperties(user);
  }
  delete properties.token;
  if (heapEvent) {
    heapTrack(splitEventType[1].trim(), splitEventType[0].trim(), window.location.pathname, { ...properties, user });
  } else {
    googleAnalyticsEvent(splitEventType[1].trim(), splitEventType[0].trim(), window.location.pathname, { ...properties, user });
  }
  
};
