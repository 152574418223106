import React, { PureComponent } from 'react';

class TrainSideBar extends PureComponent {
  render() {
    return (
      <h1> Train </h1>
    );
  }
}

export default TrainSideBar;
