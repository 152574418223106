import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineMarkSeries,
  LineSeries,
  DiscreteColorLegend,
} from 'react-vis';
import Text from './text';
import Layout from './layout';
import SideBar from './sidebar';
import { SIDEBAR_TYPES } from '../shared/constants/fieldTypes';
import { getOverallProgressData } from '../actions/index';
import { getCalculatedTime } from '../helpers/Utils';
import StyleConstants from '../shared/styleConstants/styles';

const cookies = new Cookies();
const PAGE_WIDTH = 700;

const ITEMS = [
  {title: 'Total Attempted', color: StyleConstants.color.border, strokeStyle: "dashed", strokeWidth: 3 },
  {title: 'Total Correct', color: StyleConstants.color.primary, strokeWidth: 150 },
];


class MyProgress extends PureComponent {
  state = {
    selectedSubject: 'overall',
  }

  componentDidMount() {
    const user = cookies.get('user');
    if (user && !user.is_higher_education) {
      this.props.getOverallProgressData();
    }
  }

  renderHeaderElement(text, color, subText, showBorder = true) {
    const componentWidth = (PAGE_WIDTH / 4);
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ width: componentWidth - 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Text text={text} color={color} fontSize={StyleConstants.textSize.header} fontWeight={StyleConstants.textWeight.semiBold} />
          <Text text={subText} fontWeight={StyleConstants.textWeight.semiBold} />
        </div>
        {showBorder && <div style={{ border: `1px solid ${StyleConstants.color.lightBorder}`, height: 30 }} />}
      </div>
    );
  }

  renderPageHeader() {
    const { overallData } = this.props;
    const timeSpend = getCalculatedTime(overallData.total_time_spent);
    return (
      <div style={{
        height: 200,
        width: window.innerWidth - 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'linear-gradient(260deg, rgb(74, 159, 245) -15%, rgb(42, 245, 152) 130%)',
      }}
      >
        <Text text="My Progress" color="white" fontWeight={StyleConstants.textWeight.bold} fontSize={StyleConstants.textSize.headerBig} />
        <div className="cardView" style={{ width: PAGE_WIDTH, display: 'flex', marginTop: 10 }}>
          {this.renderHeaderElement(overallData.total_attempted, StyleConstants.textColor.primary, 'Total Attempted')}
          {this.renderHeaderElement(overallData.correct, StyleConstants.color.Correct, 'Correct')}
          {this.renderHeaderElement(overallData.incorrect, StyleConstants.color.Incorrect, 'Incorrect')}
          {this.renderHeaderElement(timeSpend, StyleConstants.color.primary, 'Total Time Spent', false)}
        </div>
      </div>
    );
  }

  renderSubjectComponent(text, stateName) {
    const componentWidth = (PAGE_WIDTH / (this.props.subjects.length + 1)) - 10;
    const isSelected = this.state.selectedSubject === stateName;
    return (
      <div
        className="Button"
        onClick={() => this.setState({ selectedSubject: stateName })}
        style={{ width: componentWidth, color: isSelected && StyleConstants.color.primary, borderColor: isSelected && StyleConstants.color.primary }}>
        {text}
      </div>
    )
  }

  renderSubjectFilter() {
    const { subjects } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        {this.renderSubjectComponent('All Subjects', 'overall')}
        {subjects.map(subject => this.renderSubjectComponent(subject.name, subject.name))}
      </div>
    );
  }

  renderChart() {
    const { graphData } = this.props;
    const { selectedSubject } = this.state;
    const keys = Object.keys(graphData);
    const correctData = [];
    const attemptedData = [];
    Object.keys(graphData).forEach((day, index) => {
      correctData.push({ x: index + 1, y: graphData[day][selectedSubject].correct });
      attemptedData.push({ x: index + 1, y: graphData[day][selectedSubject].total_attempted });
    })
    return (
      <div style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <XYPlot width={PAGE_WIDTH - 40} height={200}>
          <HorizontalGridLines style={{ opacity: 0.5 }} />
          <VerticalGridLines tickValues={[1,2,3,4,5,6,7]} style={{ opacity: 0.5 }} />
          <XAxis tickFormat={(index) => keys[index-1]} />
          <YAxis />
          <LineMarkSeries
            lineStyle={{stroke: StyleConstants.color.primary}}
            markStyle={{stroke: StyleConstants.color.primary, fill: 'white', strokeWidth: 3 }}
            data={correctData}
          />
          <LineSeries
            color={StyleConstants.color.border}
            style={{
              strokeDasharray: '4 5'
            }}
            data={attemptedData}
          />
        </XYPlot>
        <DiscreteColorLegend orientation="horizontal" width={200} items={ITEMS} />
      </div>
    );
  }

  renderProgressData(headerText, text1, text2, text3, alignItems='center') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems, justifyContent: 'space-between', height: 120 }}>
        {<Text text={headerText ? headerText : '.'} color={headerText ? StyleConstants.color.primary : StyleConstants.color.lightBorder} fontWeight={StyleConstants.textWeight.semiBold} />}
        <Text text={text1} fontWeight={StyleConstants.textWeight.semiBold} />
        <Text text={text2} fontWeight={StyleConstants.textWeight.semiBold} />
        <Text text={text3} fontWeight={StyleConstants.textWeight.semiBold} />
      </div>
    )
  }

  render() {
    const { overallData, subjects } = this.props;
    const { selectedSubject } = this.state;
    if (selectedSubject.length === 0) {
      return <div />
    }
    if (!overallData && subjects.length > 0) {
      return (
        <Layout hideFooter>
          <SideBar pageType={SIDEBAR_TYPES.HOME} />
          <div className="MainPage" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 0, padding: 0, height: window.innerHeight - 70 }}>
            <Text text={"Start Praticing and check your daily progress."} color={StyleConstants.textColor.primary} fontWeight={StyleConstants.textWeight.semiBold} />
            <Link id={'Redirect-To-Train'} to={`/${subjects[0].name}`}>
              <div className="Button Primary-Button" style={{ width: 100, marginTop: 20 }} onClick={this.redirectToTrain}> Start Now </div>
            </Link>
          </div>
        </Layout>
      );
    }
    const currentWeekData = this.props.currentWeekData[selectedSubject];
    const previousWeekData = this.props.previousWeekData[selectedSubject];
    return (
      <Layout hideFooter>
        <SideBar pageType={SIDEBAR_TYPES.HOME} />
        <div className="MainPage" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0, padding: 0, marginBottom: 20 }}>
          {this.renderPageHeader()}
          <div style={{ display: 'flex', flexDirection: 'column', width: PAGE_WIDTH, marginTop: 10 }}>
            {this.renderSubjectFilter()}
            <div className="simpleCardView" style={{ width: '100%', marginTop: 20, border: 0, boxShadow: "0px 3px 15px rgba(65, 61, 74, 0.1)", display: 'flex', flexDirection: 'column' }}>
              {this.renderChart()}
              <div style={{ display: 'flex', justifyContent: 'center', padding: 20, backgroundColor: StyleConstants.color.lightBorder }}>
                <div style={{ width: '40%' }}>
                  {this.renderProgressData('', 'Total Attempted', 'Accuracy', 'Time/Questions', 'flex-start')}
                </div>
                <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between' }}>
                  {previousWeekData && this.renderProgressData('Last Week', previousWeekData.total_attempted, previousWeekData.accuracy, getCalculatedTime(previousWeekData.avg_speed))}
                  {currentWeekData && this.renderProgressData('Current Week', currentWeekData.total_attempted, currentWeekData.accuracy, getCalculatedTime(currentWeekData.avg_speed))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

MyProgress.propTypes = {
  getOverallProgressData: PropTypes.func.isRequired,
  subjects: PropTypes.array,
  graphData: PropTypes.object,
  overallData: PropTypes.object,
  currentWeekData: PropTypes.object,
  previousWeekData: PropTypes.object,
};

MyProgress.defaultProps = {
  subjects: [],
  graphData: {},
  overallData: {},
  currentWeekData: {},
  previousWeekData: {},
};

const mapStateToProps = ({ home }) => ({
  subjects: home.subjects,
  graphData: home.graphData,
  overallData: home.overallData,
  currentWeekData: home.currentWeekData,
  previousWeekData: home.previousWeekData,
});

export default connect(mapStateToProps, { getOverallProgressData })(MyProgress);