import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Link } from 'react-router-dom';
import { TIME_CONVERSION, getCalculatedTime } from '../helpers/Utils';
import SideBar from './sidebar';
import { sendEvent } from '../helpers/Analytics';
import { TEST_MODE_TYPES, TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import { TestSummaryHeader } from './common/index';
import Text from './text';
import StyleConstants from '../shared/styleConstants/styles';
import award from '../shared/images/common/award.svg';
import {
  getTestReports, removeAllData,
} from '../actions/index';
import Layout from './layout';

const maxWidth = 500;

class TestReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubject: (typeof this.props.location.state !== 'undefined') ? this.props.location.state.selectedSubject : '',
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { data, testInfo } = this.props;
    if ((data && Object.keys(data).length === 0) || parseInt(testInfo.id, 10) !== parseInt(id, 10)) {
      this.props.getTestReports(id);
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    const { id } = this.props.match.params;
    const { isDataNotPresent } = this.props;
    if (isDataNotPresent) {
      this.props.history.push({ pathname: `/initial_test_report/${id}` });
    }
    window.scrollTo(0, 0);
  }

  convertDateFormat(testDate) {
    return new Date(testDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' }).replace(/ /g, ' ');
  }

  returnOptions(graphData, xAxisTitle, yAxisTitle) {
    return ({
      title: { text: '' },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      chart: {
        backgroundColor: 'white',
        style: {
          width: '100%',
          height: 430,
          fontFamily: StyleConstants.textFont.normal,
          fontSize: StyleConstants.textSize.text,
          fontWeight: StyleConstants.textWeight.semiBold,
        },
      },
      xAxis: {
        title: {
          text: xAxisTitle,
          align: 'middle',
        },
        categories: Object.keys(graphData),
      },
      yAxis: {
        title: { text: yAxisTitle },
      },
      tooltip: {
        formatter() { return `<b>${this.y}</b>`; },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      type: 'column',
      series: [{
        color: yAxisTitle === 'Questions' ? '#FFA46C' : '#3BCFC6',
        type: 'column',
        data: Object.values(graphData),
      }],
    });
  }

  renderSubject(subjectName, key, stateValue, width, showBorder = true) {
    const isSelected = stateValue === this.state.selectedSubject;
    return (
      <div
        role="presentation"
        key={key}
        style={{
          display: 'flex', alignItems: 'center', flexDirection: 'column', width, justifyContent: 'space-between', cursor: 'pointer', height: '100%', paddingTop: 10,
        }}
        onClick={() => this.setState({ selectedSubject: 'none' }, () => this.setState({ selectedSubject: stateValue }))}
      >
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', cursor: 'pointer', borderRight: showBorder && '1px solid #CBCBCB',
        }}
        >
          <Text
            text={subjectName.replace(/\b(\w)/g, (s) => s.toUpperCase())}
            style={{
              fontSize: isSelected ? StyleConstants.textSize.headerSmall : StyleConstants.textSize.text, display: 'flex', justifyContent: 'center', color: isSelected ? StyleConstants.color.selected : StyleConstants.color.inactive,
            }}
          />
        </div>
        <div style={{
          marginTop: 5, borderTop: isSelected && `3px solid ${StyleConstants.color.selected}`, width: '50%', borderRadius: 4, borderTopLeftRadius: 15, borderTopRightRadius: 15,
        }}
        />
      </div>
    );
  }


  renderSubjectHeader() {
    const { data } = this.props;
    if (data.subject_ids) {
      const width = `calc(100%/${data.subject_ids.length})`;
      return (
        <div style={{
          position: 'fixed',
          top: 70,
          width: '80%',
          background: StyleConstants.color.testReportHeader,
          height: 44,
          zIndex: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', zIndex: 10, width: maxWidth, minWidth: maxWidth,
            }}
            >
              {this.renderSubject('overall', 'Overall_Total', '', width)}
              {data.subject_ids.map((subjectId, index) => this.renderSubject(data.subject_mapping[subjectId], `${data.subject_mapping[subjectId]}_Total`, data.subject_mapping[subjectId], width, (index + 1) !== data.subject_ids.length))}
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }

  renderStats(statsArray) {
    const { data } = this.props;
    if (data.is_online) {
      return (
        <div>
          <Text
            text="Improvement Areas"
            style={{
              color: StyleConstants.color.dark, fontFamily: StyleConstants.textFont.header, fontSize: StyleConstants.textSize.subHeader, fontWeight: StyleConstants.textWeight.bold,
            }}
          />
          <div style={{
            display: 'flex', backgroundColor: '#F7F9FA', justifyContent: 'space-between', marginTop: 5, maxWidth: '100%',
          }}
          >
            {statsArray.map((key) => { return this.renderEachStat(key); })}
          </div>
        </div>
      );
    }
    return <div />;
  }

  renderEachStat(type) {
    const { data } = this.props ? this.props : this.props.location.state.data;
    const { selectedSubject } = this.state;
    if (data) {
      return (
        <div
          className="Simple-Card-View"
          style={{
            display: 'flex', backgroundColor: 'white', flexDirection: 'column', width: '48%', padding: '15px 0px 15px 0px', boxShadow: '0px 0px 12px  rgba(65, 61, 74, 0.1)',
          }}
        >
          <Text
            text={data[type] && Object.keys(data[type]).length !== 0 ? (selectedSubject && selectedSubject !== '' ? `${data[type][selectedSubject].length}` : `${data[type].length}`) : '0'}
            style={{
              paddingLeft: '10%', color: StyleConstants.color.dark, fontFamily: StyleConstants.textFont.header, fontSize: StyleConstants.textSize.tertiaryHeader, fontWeight: StyleConstants.textWeight.bold,
            }}
          />
          <Text text={type === 'late_skips' ? 'Late Skips' : 'Rushed Answers'} style={{ paddingLeft: '10%', color: StyleConstants.color.mediumLight, fontSize: StyleConstants.textSize.smallText }} />
        </div>
      );
    }
    return <div />;
  }

  renderGraphContent(options, actualSubjectDuration, selectedSubject, testDurationPerSubject) {
    const { data } = this.props ? this.props : this.props.location.state.data;
    if ((data.is_online === true) && (data.time_per_question !== null)
      && (data.time_per_question[selectedSubject].length > 0)) {
      return (
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
            <Text
              text="Time Management"
              style={{
                color: StyleConstants.color.dark,
                fontFamily: StyleConstants.textFont.header,
                fontSize: StyleConstants.textSize.subHeader,
                fontWeight: StyleConstants.textWeight.bold,
              }}
            />
            <Text
              text={`You spent ${actualSubjectDuration[selectedSubject] > testDurationPerSubject
                ? 'more' : (actualSubjectDuration[selectedSubject] < testDurationPerSubject ? 'less' : 'exact')} time`}
              style={{ paddingBottom: 10, color: StyleConstants.color.mediumDark, fontSize: StyleConstants.textSize.smallText }}
            />
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            style={{ zIndex: 0 }}
          />
        </div>
      );
    }
    return <div />;
  }

  renderGraphs(options) {
    const { data } = this.props ? this.props : this.props.location.state.data;
    if (data.is_online === true) {
      return (
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            style={{ zIndex: 0 }}
          />
        </div>
      );
    }
    return <div />;
  }

  renderChart(options, firstHourQuestions, index) {
    if (firstHourQuestions > 0) {
      return (
        <div
          className="Simple-Card-View"
          style={{
            padding: 0, width: '100%', maxWidth: 'inherit', backgroundColor: StyleConstants.color.white, marginTop: 5, marginBottom: (index === 0 ? 0 : 30), display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: '0px 0px 12px  rgba(65, 61, 74, 0.1)',
          }}
        >
          <div style={{ padding: '10px 0px 0px 15px', display: 'flex', flexDirection: 'column' }}>
            <Text
              text={index === 0 ? 'Time Management' : 'Accuracy'}
              style={{
                color: StyleConstants.color.dark, fontFamily: StyleConstants.textFont.header, fontSize: StyleConstants.textSize.subHeader, fontWeight: StyleConstants.textWeight.bold,
              }}
            />
            <Text text={index === 0 ? `You attempted only ${firstHourQuestions} questions in first 60 mins.` : 'Your accuracy over time'} style={{ color: StyleConstants.color.mediumDark, fontSize: StyleConstants.textSize.smallText }} />
          </div>
          {this.renderGraphs(options[index])}
        </div>
      );
    }
    return <div />;
  }

  renderChapterData(chapter) {
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', backgroundColor: 'white', borderRadius: '4px', justifyContent: 'space-between', marginTop: 7, width: '100%', boxShadow: '0px 0px 12px  rgba(65, 61, 74, 0.1)',
      }}
      >
        <div
          className="Simple-Card-View"
          style={{
            marginBottom: 5, padding: 0, height: 45, display: 'flex', backgroundColor: 'white', alignItems: 'center', justifyContent: 'space-between',
          }}
        >
          <Text
            text={chapter.chapter_name}
            style={{
              marginLeft: 20,
              color: StyleConstants.color.dark,
              fontSize: StyleConstants.textSize.text,
            }}
          />
          <Text text={`${chapter.correct}/${chapter.total}`} style={{ color: StyleConstants.color.dark, marginRight: 16, fontSize: StyleConstants.textSize.text }} />
        </div>
      </div>
    );
  }

  renderViewChapter(id, selectedSubject, chapterList) {
    return (
      <div>
        <Text
          text="Chapter Performance"
          style={{
            color: StyleConstants.color.dark,
            fontFamily: StyleConstants.textFont.header,
            fontSize: StyleConstants.textSize.subHeader,
            fontWeight: StyleConstants.textWeight.bold,
          }}
        />
        {chapterList[selectedSubject].map((chapter, index) => {
          if (index < 3) {
            this.renderChapterData(chapter);
          }
        })}
        <div
          style={{
            display: 'flex', width: '100%', justifyContent: 'center', marginTop: 10, marginBottom: 50,
          }}
          role="presentation"
          onClick={() => { this.props.history.push({ pathname: `/test_report/${id}/chapter_performance`, state: { chapterList, selectedSubject } }); }}
        >
          <Text
            text="View All Chapters"
            style={{
              color: StyleConstants.color.testReportHeader, cursor: 'pointer', fontFamily: StyleConstants.textFont.normal, fontSize: StyleConstants.textSize.text, fontWeight: StyleConstants.textWeight.bold,
            }}
          />
        </div>
      </div>
    );
  }

  renderSubjectSpecificView(id, selectedSubject, actualSubjectDuration,
    testDurationPerSubject, options3, chapterList) {
    const statsArray = ['rushed_answers', 'late_skips'];
    const { testInfo } = this.props;
    return (
      <Layout hideFooter>
        <div>
          <SideBar showBackNavigation navigateTo={`/${TEST_MODE_TYPES[testInfo.test_mode_type]}`} />
          <div style={{ marginLeft: 300 }}>
            {this.renderSubjectHeader()}
            <TestSummaryHeader selectedTestId={id} selectedSubject={selectedSubject} />
          </div>
          <div
            className="MainPage"
            style={{
              display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 0,
            }}
          >
            <div
              className="Header-content"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: maxWidth,
                minWidth: maxWidth,
              }}
            >
              <div style={{
                display: 'flex', width: '100%', maxWidth: 'inherit', backgroundColor: '#F7F9FA', marginTop: 15, flexDirection: 'column', justifyContent: 'left',
              }}
              >
                {this.renderStats(statsArray)}
              </div>
              <div
                className="Simple-Card-View"
                style={{
                  width: '100%',
                  maxWidth: 'inherit',
                  marginTop: 10,
                  padding: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {this.renderGraphContent(options3, actualSubjectDuration, selectedSubject,
                  testDurationPerSubject)}
              </div>
              <div style={{
                display: 'flex', flexDirection: 'column', width: '100%', marginTop: 15,
              }}
              >
                {chapterList[selectedSubject].length > 0 && this.renderViewChapter(id, selectedSubject, chapterList)}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  renderTestReportInsights(testReportInsights) {
    return (
      <div className="flexcontainer" style={{ justifyContent: 'center', margin: '30px 0px' }}>
        <div style={{ width: 485 }}>
          <Text
            color={StyleConstants.textColor.primary}
            fontSize={StyleConstants.textSize.headerSmall}
            fontWeight={StyleConstants.textWeight.bold}
            text="Insights"
          />
          <div className="simpleCardView" style={{ padding: 15, marginTop: 10, cursor: 'default' }}>
            <ol style={{ paddingLeft: 20 }}>
              {testReportInsights.map((insight) => (
                <li style={{ margin: '5px 0px' }}>
                  <Text
                    text={insight}
                    color={StyleConstants.textColor.primary}
                    fontSize={StyleConstants.textSize.smallText}
                  />
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { id } = this.props.match.params;
    const { selectedSubject } = this.state;
    const {
      data, global, testInfo, hideQuestionRevise, hideLeaderBoard, testReportInsights,
    } = this.props;
    const graphData1 = {};
    const graphData2 = {};
    const graphData3 = [];
    let optionsArray = [];
    let options1 = {};
    let options2 = {};
    let options3 = [0];
    let firstHourQuestions = 0;
    let testDuration = 0;
    let testDurationPerSubject = 0;
    const actualSubjectDuration = {};
    const chapterList = [];
    optionsArray = [1, 2];
    if (selectedSubject === 'none' || global.loaderState) {
      return <div />;
    }
    if (typeof data.subject_ids !== 'undefined') {
      for (let i = 0; i < data.subject_ids.length; i += 1) {
        actualSubjectDuration[data.subject_mapping[data.subject_ids[i]]] = 0;
        if (typeof data.chapter_performance[data.subject_mapping[data.subject_ids[i]]] !== 'undefined') {
          chapterList[data.subject_mapping[data.subject_ids[i]]] = [];
          chapterList[data.subject_mapping[data.subject_ids[i]]].push(
            ...data.chapter_performance[data.subject_mapping[data.subject_ids[i]]].weak_chapters,
          );
          chapterList[data.subject_mapping[data.subject_ids[i]]].push(
            ...data.chapter_performance[data.subject_mapping[data.subject_ids[i]]].strong_chapters,
          );
        }
      }
    }
    if ((typeof data.overall_time_chart !== 'undefined') && (data.overall_time_chart !== null)) {
      Object.keys(data.overall_time_chart.questions_per_time).map(
        (key, index) => {
          graphData1[key] = parseInt(data.overall_time_chart.questions_per_time[key]);
          if (parseInt(data.overall_time_chart.questions_per_time[key]) !== 0) {
            testDuration = parseInt(key.substring(4));
          }
          if (index < 2) {
            firstHourQuestions += data.overall_time_chart.questions_per_time[key];
          }
        },
      );
      testDuration *= TIME_CONVERSION;
      if (data.subject_ids) {
        testDurationPerSubject = testDuration / data.subject_ids.length;
      }
      Object.keys(data.overall_time_chart.accuracy_per_time).map(
        (key) => { graphData2[key] = parseInt(data.overall_time_chart.accuracy_per_time[key]); },
      );
      if ((selectedSubject !== '') && (typeof selectedSubject !== 'undefined') && (typeof data.time_per_question !== 'undefined') && (typeof data.time_per_question[selectedSubject] !== 'undefined')) {
        data.time_per_question[selectedSubject].map(
          (key) => {
            graphData3.push({ duration: key.duration, result: key.result });
            actualSubjectDuration[selectedSubject] += key.duration;
          },
        );
      }
      options1 = this.returnOptions(graphData1, 'Time(mins)', 'Questions');
      options2 = this.returnOptions(graphData2, 'Time(mins)', 'Accuracy(%)');
      optionsArray = [options1, options2];
      if (selectedSubject !== '') {
        options3 = {
          legend: {
            enabled: true,
            align: 'left',
            useHTML: true,
            itemHoverStyle: 'none',
            labelFormatter() {
              return `<div style="display: flex; align-items: center; justify-content: space-between;"><div>${this.name}</div></div>`;
            },
          },
          credits: {
            enabled: false,
          },
          chart: {
            backgroundColor: '#FAFAFA',
            style: {
              width: '100%',
              fontFamily: StyleConstants.textFont.normal,
              fontSize: StyleConstants.textSize.text,
              fontWeight: StyleConstants.textWeight.semiBold,
            },
            scrollablePlotArea: {
              minWidth: 800,
              scrollPositionX: 0,
              scrollPositionY: 1,
              opacity: 1,
            },
          },
          title: {
            text: '',
          },
          xAxis: [{
            title: {
              align: 'middle',
              text: 'Question Number',
            },
            offset: 0,
            categories: graphData3.map((key, index) => index + 1),
          }],
          yAxis: {
            title: { text: 'Time' },
            labels: {
              formatter() {
                return getCalculatedTime(parseInt(this.value, 10) / 1000, true);
              },
            },
            lineWidth: 1,
            gridLineWidth: 1,
            offset: 0,
          },
          tooltip: {
            formatter() { return `<b>${getCalculatedTime(parseInt(this.y, 10) / 1000, true)}</b>`; },
          },
          plotOptions: {
            column: {
              pointWidth: 6,
              borderRadiusTopLeft: 4,
              borderRadiusTopRight: 4,
              borderWidth: 0,
            },
          },
          type: 'column',
          width: '100%',
          series: [{
            color: '#56C900',
            type: 'column',
            data: graphData3.map((key) => (key.result === 1 ? (key.duration) : 0)),
            name: 'Correct',
          }, {
            color: '#FF967E',
            type: 'column',
            data: graphData3.map((key) => (key.result === -1 ? (key.duration) : 0)),
            name: 'Incorrect',
          }, {
            color: '#4D4D4D',
            type: 'column',
            data: graphData3.map((key) => (key.result === 0 ? (key.duration) : 0)),
            name: 'Unattempted',
          }],
        };
      }
    }
    if ((selectedSubject !== '') && (typeof chapterList !== 'undefined') && (typeof chapterList[selectedSubject] !== 'undefined')) {
      return (
        <>
          {this.renderSubjectSpecificView(id, selectedSubject, actualSubjectDuration,
            testDurationPerSubject, options3, chapterList)}
        </>
      );
    }
    return (
      <Layout hideFooter>
        <div>
          <SideBar showBackNavigation navigateTo={`/${TEST_MODE_TYPES[testInfo.test_mode_type]}`} width="20%" />
          <div style={{ marginLeft: 300 }}>
            {this.renderSubjectHeader()}
            <TestSummaryHeader
              selectedTestId={id}
              selectedSubject={selectedSubject}
              data={data}
            />
          </div>
          <div
            className="MainPage"
            style={{
              display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 0,
            }}
          >
            <div
              className="Header-content"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: maxWidth,
                minWidth: maxWidth,
              }}
            >
              {(testReportInsights && testReportInsights.length > 0) && this.renderTestReportInsights(testReportInsights)}
              <div className="flexcontainer" style={{ justifyContent: 'center' }}>
                <div style={{
                  marginTop: 30, marginBottom: 30, width: 1000, display: 'flex', justifyContent: 'center',
                }}
                >
                  <div style={{ width: 485 }}>
                    <Text
                      color={StyleConstants.textColor.testReportHeader}
                      fontSize={StyleConstants.textSize.headerSmall}
                      fontWeight={StyleConstants.textWeight.bold}
                      text="Practice makes perfect."
                    />
                    <div className="simpleCardView" style={{ padding: 15, marginTop: 10, cursor: hideQuestionRevise ? 'not-allowed' : 'pointer' }}>
                      <Text color={StyleConstants.textColor.testReportHeader} text="Revisit the errors you made, to understand where you went wrong. Hone yourself to perfection." />
                      <div
                        role="presentation"
                        style={{ pointerEvents: hideQuestionRevise && 'none' }}
                        onClick={() => {
                          sendEvent('TestReport - ReviewTapped', { testId: id, hideQuestionRevise });
                          this.props.history.push(!hideQuestionRevise && `/test/${id}`);
                          if (hideQuestionRevise) {
                            this.props.showToast('To revise, your institute needs to publish the question paper. Please contact your admin.', TOAST_MESSAGE_TYPES.ERROR);
                          }
                        }}
                      >
                        <div className="Button Secondary-Button mt-3" style={{ opacity: hideQuestionRevise && 0.5, pointerEvents: hideQuestionRevise && 'none', cursor: hideQuestionRevise && 'not-allowed' }}> Revise </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!hideLeaderBoard
                && (
                  <div
                    className="Leader-Button"
                    role="presentation"
                    style={{
                      justifyContent: 'left', width: '100%', maxWidth: 'inherit', height: 45, borderColor: StyleConstants.color.white, marginTop: 15, marginBottom: 10, display: 'flex', color: StyleConstants.color.testReportHeader, boxShadow: '0px 0px 12px  rgba(65, 61, 74, 0.1)',
                    }}
                    onClick={() => { this.props.history.push({ pathname: `/leaderboard/${id}`, state: { selectedSubject } }); }}
                  >
                    <img alt="NEET_JEE_Leaderboard" src={award} style={{ paddingRight: 7, paddingLeft: 10 }} />
                    <Text
                      style={{
                        color: '#4D4D4D', fontFamily: 'Lato, serif', fontSize: 14, fontWeight: StyleConstants.textWeight.bold,
                      }}
                      text="Leader Board"
                    />
                  </div>
                )}
              {optionsArray.map((options, index) => {
                return (data.overall_time_chart !== null)
                  ? this.renderChart(optionsArray, firstHourQuestions, index) : <div />;
              })}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
TestReport.propTypes = {
  data: PropTypes.object,
  testInfo: PropTypes.object,
  isDataNotPresent: PropTypes.bool,
  hideQuestionRevise: PropTypes.bool,
  hideLeaderBoard: PropTypes.bool,
  testReportInsights: PropTypes.array,
};

TestReport.defaultProps = {
  data: {},
  testInfo: {},
  isDataNotPresent: false,
  hideQuestionRevise: true,
  hideLeaderBoard: true,
  testReportInsights: [],
};

const mapStateToProps = ({ report, global }) => ({
  data: report.data,
  testInfo: report.testInfo,
  isDataNotPresent: report.isDataNotPresent,
  hideQuestionRevise: report.hideQuestionRevise,
  hideLeaderBoard: report.hideLeaderBoard,
  testReportInsights: report.testReportInsights,
  global,
});
export default connect(mapStateToProps, { getTestReports, removeAllData })(TestReport);
