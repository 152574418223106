import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import Highcharts from 'highcharts';
import PieChart from 'highcharts-react-official';
import { Link } from 'react-router-dom';
import { sendEvent } from '../helpers/Analytics';
import SideBar from './sidebar';
import Text from './text';
import StyleConstants from '../shared/styleConstants/styles';
import { getReport, clearReport, showToast } from '../actions/index';
import { TEST_MODE_TYPES, TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import Layout from './layout';

const subCardStyle = {
  display: 'flex', justifyContent: 'space-between', height: 110, alignItems: 'center', paddingLeft: 40, paddingRight: 25,
};

class TestReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubject: { value: 'total', label: 'Overall' },
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getReport(id);
  }

  componentWillUnmount() {
    this.props.clearReport();
  }

  updateSubject(subject) {
    this.setState({ selectedSubject: subject });
  }

  renderSummaryHeader(text) {
    return (
      <div style={{
        backgroundColor: StyleConstants.backgroundColor.header, alignItems: 'center', height: 40, justifyContent: 'center', display: 'flex',
      }}
      >
        <Text text={text} />
      </div>
    );
  }

  renderStats(type, value = '', size) {
    return (
      <div className="d-flex" style={{ alignItems: 'center' }}>
        <div className="Circle ml-2 mr-2" style={{ width: 6, height: 6, backgroundColor: StyleConstants.color[type] }} />
        <Text text={`${value} ${type}`} fontWeight={StyleConstants.textWeight.semiBold} color={StyleConstants.textColor.primary} fontSize={StyleConstants.textSize[size]} style={{ whiteSpace: 'nowrap', marginRight: 15 }} />
      </div>
    );
  }

  renderChartData(type) {
    const chartData = {
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      chart: {
        height: 200,
        width: 200,
        type: 'pie',
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<div>{point.name}: {point.y}</div>',
      },
      series: [
        {
          colorByPoint: true,
          data: [
            {
              name: 'Correct',
              y: type.correct,
              color: StyleConstants.color.Correct,
            },
            {
              name: 'Incorrect',
              y: type.incorrect,
              color: StyleConstants.color.Incorrect,
            },
            {
              name: 'Unattempted',
              y: type.unattempted,
              color: StyleConstants.color.Unattempted,
            },
          ],
        },
      ],
    };

    return chartData;
  }

  renderSummaryComponents() {
    const { reportData, testInfo } = this.props;
    const cardStyle = {
      height: 150, width: 280, padding: 0, marginRight: 20,
    };
    return (
      <div className="d-flex flexcontainer" style={{ justifyContent: 'center' }}>
        {!testInfo.is_new_neet_pattern && (
          <div className="cardView" style={cardStyle}>
            {this.renderSummaryHeader('Score')}
            <div style={{ ...subCardStyle, justifyContent: 'center' }}>
              <Text text={`${reportData.total.score.toString()}/${reportData.total.max_score.toString()}`} fontWeight={StyleConstants.textWeight.bold} color={reportData.total.score < 0 ? StyleConstants.color.Incorrect : StyleConstants.textColor.primary} fontSize="43px" />
            </div>
          </div>
        )}
        <div className="cardView" style={cardStyle}>
          {this.renderSummaryHeader('Accuracy')}
          <div style={{ ...subCardStyle, justifyContent: 'center' }}>
            <Text text={`${reportData.total.accuracy}%`} fontWeight={StyleConstants.textWeight.bold} color={StyleConstants.textColor.primary} fontSize="52px" />
          </div>
        </div>
        <div className="cardView" style={cardStyle}>
          {this.renderSummaryHeader('Question Responses')}
          <div style={{ ...subCardStyle, justifyContent: 'center', paddingLeft: 25 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {this.renderStats('Correct', reportData.total.correct, 'text')}
              {this.renderStats('Incorrect', reportData.total.incorrect, 'text')}
              {this.renderStats('Unattempted', reportData.total.unattempted, 'text')}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSubjectScoredDistribution(subject, subjectValues, isfirstSubject) {
    const { testInfo } = this.props;
    return (
      <div
        key={`${subject}_ScoreDistribution`}
        className="simpleCardView"
        style={{
          padding: 0, width: 320, height: 170, marginTop: 10,
        }}
      >
        <div
          className="d-flex bottomBorderView"
          style={{
            height: 60, padding: 20, alignItems: 'center', justifyContent: 'space-between',
          }}
        >
          <Text
            text={subject}
            fontWeight={StyleConstants.textWeight.bold}
            color={StyleConstants.textColor.primary}
            fontSize={StyleConstants.textSize.headerSmall}
          />
          {!testInfo.is_new_neet_pattern && (
            <Text
              text={`${subjectValues.score}/${subjectValues.max_score}`}
              fontWeight={StyleConstants.textWeight.bold}
              color={subjectValues.score < 0
                ? StyleConstants.color.Incorrect
                : StyleConstants.textColor.primary}
              fontSize={StyleConstants.textSize.headerSmall}
            />
          )}
        </div>
        <div>
          <div style={{
            alignItems: 'center', justifyContent: 'center', height: 110, width: '100%', display: 'flex',
          }}
          >
            <div style={{
              display: 'flex', justifyContent: 'inherit', alignItems: 'center', flexFlow: 'wrap',
            }}
            >
              {this.renderStats('Correct', subjectValues.correct, 'headerSmall')}
              {this.renderStats('Incorrect', subjectValues.incorrect, 'headerSmall')}
              {this.renderStats('Unattempted', subjectValues.unattempted, 'headerSmall')}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDropDowns(dataSet, selectedItem, selectedFunction, item, showFilter = true) {
    const length = (selectedItem.value.length) + 110;
    const selectBoxStyle = {
      control: (base) => ({
        ...base,
        backgroundColor: '#F2F2F2',
        border: 'none',
        width: length,
        fontSize: StyleConstants.textSize.text,
        boxShadow: '0 !important',
        background: 'transparent',
        cursor: 'pointer',
      }),
      option: (base) => ({
        ...base,
        border: '1px solid #F8F8F8',
        color: '#343B40',
      }),
      indicatorSeparator: (provided) => (
        {
          ...provided,
          width: 0,
        }
      ),
      singleValue: (provided) => ({
        ...provided,
        color: '#122E48',
        fontSize: StyleConstants.textSize.text,
        fontWeight: 'bold',
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#122E48',
          fontSize: StyleConstants.textSize.text,
        };
      },
    };
    if (!showFilter) {
      return (
        <div />
      );
    }
    const filterItems = [];
    filterItems.push({ label: 'Overall', value: 'total' });
    for (let i = 0; i < dataSet.length; i += 1) {
      filterItems.push({ label: dataSet[i], value: dataSet[i] });
    }
    const boxWidth = { width: 'fit-content' };

    return (
      <div style={boxWidth}>
        <Select
          value={selectedItem || filterItems[0]}
          onChange={selectedFunction}
          options={filterItems}
          placeholder="Overall"
          styles={selectBoxStyle}
          isSearchable={false}
        />
      </div>
    );
  }

  render() {
    const {
      subjects, reportData, testInfo,
    } = this.props;
    const dataPresent = Object.keys(reportData).length > 0;
    const { selectedSubject } = this.state;
    return (
      <Layout hideFooter>
        <div>
          <SideBar showBackNavigation navigateTo={`/${TEST_MODE_TYPES[testInfo.test_mode_type]}`} />
          <div className="MainPage">
            <div style={{
              paddingBottom: 25,
              marginLeft: 10,
              fontWeight: StyleConstants.staticPages.fontWeight.header,
              textAlign: !dataPresent && 'center',
            }}
            >
              <Text text={dataPresent ? 'Detailed test report will be available shortly..!' : 'Test report will be available in few minutes..!'} fontSize={dataPresent ? StyleConstants.textSize.text : StyleConstants.textSize.headerBig} />
            </div>
            { dataPresent
            && (
            <div>
              {this.renderSummaryComponents()}
              <div style={{
                display: 'flex', justifyContent: 'space-evenly', marginTop: 10, alignItems: 'center',
              }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text text="Question Analysis - " fontWeight={StyleConstants.textWeight.semiBold} color={StyleConstants.textColor.primary} fontSize={StyleConstants.textSize.text} style={{ whiteSpace: 'nowrap' }} />
                  {this.renderDropDowns(
                    subjects, selectedSubject,
                    (selectedListItem) => this.updateSubject(selectedListItem),
                  )}
                </div>
                <PieChart
                  highcharts={Highcharts}
                  options={this.renderChartData(reportData[selectedSubject.value])}
                />
                <div style={{ ...subCardStyle, justifyContent: 'center', paddingLeft: 25 }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {this.renderStats('Correct', '', 'text')}
                    {this.renderStats('Incorrect', '', 'text')}
                    {this.renderStats('Unattempted', '', 'text')}
                  </div>
                </div>
              </div>
              <div className="flexcontainer" style={{ marginTop: 40, justifyContent: 'space-around' }}>
                {subjects.map((subject, index) => this.renderSubjectScoredDistribution(
                  subject, reportData[subject], (index === 0),
                ))}
              </div>
            </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

TestReport.propTypes = {
  getReport: PropTypes.func.isRequired,
  clearReport: PropTypes.func.isRequired,
  match: PropTypes.object,
  testInfo: PropTypes.object,
  reportData: PropTypes.object,
  subjects: PropTypes.array,
};

TestReport.defaultProps = {
  match: {},
  subjects: [],
  testInfo: {},
  reportData: {},
};

const mapStateToProps = ({ report }) => ({
  testInfo: report.testInfo,
  subjects: report.subjects,
  reportData: report.reportData,
});

export default connect(mapStateToProps, { getReport, clearReport, showToast })(TestReport);
