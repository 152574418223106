import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text from './text';
import StyleConstants from '../shared/styleConstants/styles';

class DropDown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        selectedOptionId: null,
        showOptions: false,
        searchString: '',
        width: props.width,
        defaultText: props.defaultText || 'All',
        optionsList: props.optionsList,
    }
    this.renderContent = this.renderContent.bind(this);
  }

  showComponents = (event) => {
    event.preventDefault();
    this.setState({ showOptions: true }, () => {
      document.addEventListener('click', this.closeComponents);
    });
  }

  closeComponents = (event) => {
    if (event && (event.target.className !== 'show-options')) {
      this.setState({ showOptions: false }, () =>
        document.removeEventListener('click', this.closeComponents));
      }
  }

  renderOptions(option) {
    const { width } = this.state;
    const { selectedItem } = this.props;
    return (
      <div style={{ padding: '10px 30px 10px 30px', background: StyleConstants.color.white, width, alignItems: 'center', cursor: 'pointer', borderBottom: '1px solid #E2E2E2', display: 'flex', justifyContent: 'space-between' }} onClick={()=> this.setState({ showOptions: 'false', optionsList: this.props.optionsList, selectedOptionId: option.id }, () => this.props.onValueChange(option.id, option.name))}>
        <Text
          key={option.id}
          text={option.name}
          color={selectedItem && (option.id === selectedItem.key) ? StyleConstants.solutionBoard.answerColor : '' }
        />
        {(option.id === selectedItem.key) &&
          <div onClick={(event) => {event.stopPropagation();this.setState({ optionsList: this.props.optionsList, selectedOptionId: null }, () => this.props.onValueChange(null))}}>
            <img src="/images/landing_institute/x-mark.svg" alt="NEET JEE close" style={{ marginLeft: 10 }} />
          </div>
        }
      </div>
    );
  }

  renderOptionsList(optionsList) {
    const { width } = this.state;
    return (
      <div style={{ maxHeight: 130, minHeight: 35, overflow: 'scroll', position: 'fixed', zIndex: 1, backgroundColor: 'white', borderRadius: '0px 0px 4px 4px', width }}>
        {optionsList.map((option) => this.renderOptions(option))}
      </div>
    );
  }

  getSearchResults() {
    const { optionsList } = this.props;
    let searchResultList = [];
    optionsList.forEach(option => {
      if (option.name.toUpperCase().indexOf(this.state.searchString.toUpperCase()) >= 0) {
        searchResultList.push(option);
      }
    })
    this.setState({ optionsList: searchResultList})
  }

  renderContent() {
      const { showOptions, width, optionsList, defaultText } = this.state;
      const { color, optionType, style, selectedItem } = this.props;
      const dropDownStyle = { width, border: optionsList.length > 0 ?  '1px solid #D0D0D0' : '1px solid #7C94BA', borderRadius: 4, pointerEvents: optionsList.length > 0 ? '' : 'none', opacity: optionsList.length > 0 ? 1 : .5 }
      return (
        <div className="show-options" style={{ ...dropDownStyle, ...style }} onClick={(event) => this.showComponents(event)}>
          {!showOptions && 
            <div style={{ height: 40, alignItems: 'center', marginLeft: 20, display: 'flex', justifyContent: 'space-between' }}>
              <Text
                text={selectedItem && selectedItem.value ? selectedItem.value: defaultText }
                color={color}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              />
               <img src="/images/common/drop-down-arrow.svg" alt="NEERT JEE drop_down" style={{ marginRight: 10 }} />
            </div>
          }
          {showOptions &&
          <div style={{ display: 'flex', padding: 10, border: '1px solid #7C94BA', borderRadius: 4 }}>
            <img src="/images/common/search.svg" alt="NEET JEE search" style={{ marginRight: 10 }} />
            <input
              id="searchString"
              type="text"
              autoComplete="off"
              placeholder={`Search ${optionType}`}
              autoFocus
              style={{ border: 0, marginBottom: 0, height: '100%', width: '100%', backgroundColor: "inherit", margin: 0, outline: 'none', color }}
              onChange={(e) => {
                this.setState({ searchString: e.target.value }, () => this.getSearchResults())
              }}
            />
          </div>}
          {showOptions && this.renderOptionsList(optionsList)}
        </div>
      );
  }

  render() {
    const { optionsList } = this.state;
    return (
    <div style={{ cursor: optionsList.length > 0 ? 'pointer' : 'not-allowed' }}>
      {this.renderContent()}
    </div>
    );
  }
}

DropDown.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  DropDown: PropTypes.array,
};


DropDown.defaultProps = {
  optionsList: []
};

export default DropDown;
