import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Text from './text';
import { updateTimerExpirey, updateTimeRemaining } from '../actions/index';
import StyleConstants from '../shared/styleConstants/styles';
import { calculateCountdown } from '../helpers/Utils';

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hours: 0,
      minutes: 0,
      sec: 0,
      timeRemainingInSeconds: props.timeRemainingInSeconds,
    };
  }

  componentDidMount() {
    // update every second
    if (!this.props.timeRemainingInSeconds) {
      return;
    }
    this.interval = setInterval(() => {
      const { timeRemainingInSeconds } = this.state;
      const date = calculateCountdown(new Date(new Date().setSeconds(new Date().getSeconds() + timeRemainingInSeconds)).toString());
      this.props.updateTimeRemaining(timeRemainingInSeconds - 1);
      date ? this.setState({ timeRemainingInSeconds: timeRemainingInSeconds - 1, hours: date.hours, minutes: date.minutes, sec: date.sec }) : this.stop(true);
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  stop(hasTimerExpired = false) {
    if (hasTimerExpired) {
      this.props.updateTimerExpirey(hasTimerExpired, this.props.isOtpPage);
    }
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = `0${value}`;
    }
    return value;
  }

  render() {
    const { hours, minutes, sec } = this.state;
    const color = minutes < 5 && hours === 0 ? minutes === 0 && hours === 0 && sec === 0 ? StyleConstants.color.border : StyleConstants.color.error : StyleConstants.color.testUnattempted;
    return (
      <div className="Timer">
        <Text
          color={color}
          fontWeight={StyleConstants.textWeight.bold}
          fontSize={StyleConstants.textSize.headerSmall}
          text={`${this.addLeadingZeros(hours)} : ${this.addLeadingZeros(minutes)} : ${this.addLeadingZeros(sec)}`}
        />
      </div>
    );
  }
}

Countdown.propTypes = {
  updateTimeRemaining: PropTypes.func.isRequired,
  updateTimerExpirey: PropTypes.func.isRequired,
  isOtpPage: PropTypes.bool,
};

Countdown.defaultProps = {
  isOtpPage: false,
};

export default connect(null, { updateTimerExpirey, updateTimeRemaining })(Countdown);
