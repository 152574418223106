import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Text from '../text';
import { updatePageType } from '../../actions/index';
import StyleConstants from '../../shared/styleConstants/styles';
import Maths from '../../shared/images/subjectIcons/Maths.svg';
import Physics from '../../shared/images/subjectIcons/Physics.svg';
import Chemistry from '../../shared/images/subjectIcons/Chemistry.svg';
import Botany from '../../shared/images/subjectIcons/Botany.svg';
import Zoology from '../../shared/images/subjectIcons/Zoology.svg';
import MathsSelected from '../../shared/images/subjectIcons/MathsSelected.svg';
import PhysicsSelected from '../../shared/images/subjectIcons/PhysicsSelected.svg';
import ChemistrySelected from '../../shared/images/subjectIcons/ChemistrySelected.svg';
import BotanySelected from '../../shared/images/subjectIcons/BotanySelected.svg';
import ZoologySelected from '../../shared/images/subjectIcons/ZoologySelected.svg';

const cookies = new Cookies();
const user = cookies.get('user');

const SUBJECT_IMAGES = {
  Maths,
  Physics,
  Chemistry,
  Botany,
  Zoology,
  MathsSelected,
  PhysicsSelected,
  ChemistrySelected,
  BotanySelected,
  ZoologySelected,
};

const pathNames = {
  VIRTUAL_MEETING: '/virtual_meeting',
  TIME_TABLE: '/time_table',
  ALL_INSTITUTE_FILES: '/institute_files/all',
  ATTENDANCE: '/attendance',
  QUIZ_SCORES: '/quiz_scores',
  MOCK_TEST: '/mock',
  PREVIOUS_PAPER: '/previous_paper',
  INSTITUTE_TEST: '/institute?type=test',
  INSTITUTE_ASSIGNMENT: '/institute?type=assignment',
  MY_PROGRESS: '/my_progress',
  BOOKMARK: '/bookmark',
  VIDEO_LIBRARY: '/video_library?id=0&is_subject=true',
  OFFLINE_TEST: '/offline_tests/all',
};
class HomeSideBar extends Component {
  componentDidMount() {
    this.props.updatePageType(window.location.pathname.toLowerCase().split('/')[1]);
  }

  renderSideBarElements(path, text, borderColor, icon,
    isSelected, getHomeData = false, isInstituteTest = false) {
    return (
      <Link
        id={`SideBar-Path-${path}`}
        to={path}
        style={{ textDecoration: 'inherit' }}
        key={`SideBarPath${path}`}
        onClick={() => {
          if (getHomeData) {
            this.props.getHomeData(isInstituteTest ? 'tests' : 'chapter', 'institute', !isInstituteTest);
          }
          this.props.updatePageType(path.toLowerCase().split('/')[1]);
        }}
      >
        <div
          className="flexcontainer Sidebar_Padding"
          style={{
            alignItems: 'center', backgroundColor: isSelected && StyleConstants.backgroundColor.header, marginTop: 5, marginBottom: 5,
          }}
        >
          {icon && <img alt="NEET_JEE_subjectIcon" src={SUBJECT_IMAGES[isSelected ? `${icon}Selected` : icon]} width={19} height={19} style={{ marginRight: 20 }} />}
          {!icon && (
            <div
              className="Circle"
              style={{
                width: 10, height: 10, border: `3px solid ${borderColor}`, marginRight: 20,
              }}
            />
          )}
          <Text
            color={isSelected ? StyleConstants.color.primary : StyleConstants.textColor.secondary}
            fontWeight={isSelected ? StyleConstants.textWeight.bold : ''}
            text={text}
          />
        </div>
      </Link>
    );
  }

  renderSideBarHeader(text) {
    return (
      <div className="Sidebar_Padding">
        <Text
          color={StyleConstants.textColor.primary}
          fontWeight={StyleConstants.textWeight.bold}
          fontSize={StyleConstants.textSize.headerSmall}
          text={text}
        />
      </div>
    );
  }

  renderHigherEducationSideBar() {
    const pathName = window.location.pathname.split('/')[1];
    return (
      <div style={{ paddingTop: 25 }}>
        {this.renderSideBarElements(pathNames.VIRTUAL_MEETING, 'Live Classes', StyleConstants.sideBarBorderColor.color1, '', pathName === 'virtual_meeting')}
        {this.renderSideBarElements(pathNames.OFFLINE_TEST, 'Offline Tests', StyleConstants.sideBarBorderColor.color4, '', pathName === 'offline_tests')}
        {this.renderSideBarElements(pathNames.TIME_TABLE, 'Time Table', StyleConstants.sideBarBorderColor.color2, '', pathName === 'time_table')}
        {this.renderSideBarElements(pathNames.ATTENDANCE, 'Attendance', StyleConstants.sideBarBorderColor.color4, '', pathName === 'attendance')}
        {this.renderSideBarElements(pathNames.ALL_INSTITUTE_FILES, 'Files', StyleConstants.sideBarBorderColor.color3, '', pathName === 'institute_files')}
        {this.renderSideBarElements(pathNames.QUIZ_SCORES, 'Quiz Scores', StyleConstants.sideBarBorderColor.color5, '', pathName === 'quiz_scores')}
        {this.renderSideBarElements(pathNames.VIDEO_LIBRARY, 'Video Library', StyleConstants.sideBarBorderColor.color3, '', pathName === pathNames.VIDEO_LIBRARY)}
      </div>
    );
  }

  renderSideBar() {
    const { subjects } = this.props;
    const elementStyle = { marginTop: 20, borderColor: '#efefef' };
    const pathName = window.location.pathname;
    const query = new URLSearchParams(window.location.search);
    const hasOnlyTestBoardAccess = user.has_only_test_board_access;
    return (
      <>
        {!hasOnlyTestBoardAccess && (
          <>
            {subjects && subjects.length > 0
              && (
                <div className="bottomBorderView" style={{ ...elementStyle, marginTop: 0 }}>
                  {this.renderSideBarHeader('Train')}
                  {subjects.map((subject) => this.renderSideBarElements(`/${subject.name}`, subject.name, '', subject.name, (window.location.pathname.split('/')[1] === '' && subject.name === 'Physics') || window.location.pathname.split('/')[1] === subject.name, true))}
                </div>
              )}
            <div className="bottomBorderView" style={elementStyle}>
              {this.renderSideBarHeader('Test')}
              {this.renderSideBarElements(pathNames.MOCK_TEST, 'Test Series', StyleConstants.sideBarBorderColor.color1, '', pathName === pathNames.MOCK_TEST, true)}
              {this.renderSideBarElements(pathNames.PREVIOUS_PAPER, 'Previous Papers', StyleConstants.sideBarBorderColor.color2, '', pathName === pathNames.PREVIOUS_PAPER, true)}
            </div>
          </>
        )}
        <div className="bottomBorderView" style={elementStyle}>
          {!hasOnlyTestBoardAccess && this.renderSideBarHeader('Institute')}
          {this.renderSideBarElements(pathNames.INSTITUTE_TEST, 'Test', StyleConstants.sideBarBorderColor.color3, '', query.get('type') === 'test', true, true)}
          {this.renderSideBarElements(pathNames.INSTITUTE_ASSIGNMENT, 'Assignment', StyleConstants.sideBarBorderColor.color4, '', query.get('type') === 'assignment', true, false)}
          {!user.isIdeaboardStudent && !hasOnlyTestBoardAccess && this.renderSideBarElements(pathNames.VIRTUAL_MEETING, 'Live Classes', StyleConstants.sideBarBorderColor.color5, '', pathName === pathNames.VIRTUAL_MEETING)}
          {!user.isIdeaboardStudent && !hasOnlyTestBoardAccess && this.renderSideBarElements(pathNames.TIME_TABLE, 'Time Table', StyleConstants.sideBarBorderColor.color1, '', pathName === pathNames.TIME_TABLE)}
          {!user.isIdeaboardStudent && !hasOnlyTestBoardAccess && this.renderSideBarElements(pathNames.VIDEO_LIBRARY, 'Video Library', StyleConstants.sideBarBorderColor.color3, '', pathName === pathNames.VIDEO_LIBRARY )}
        </div>
        {!hasOnlyTestBoardAccess && (
          <div style={elementStyle}>
            {this.renderSideBarHeader('More')}
            {!user.isIdeaboardStudent && this.renderSideBarElements(pathNames.ALL_INSTITUTE_FILES, 'Files', StyleConstants.sideBarBorderColor.color1, '', pathName === pathNames.ALL_INSTITUTE_FILES)}
            {this.renderSideBarElements(pathNames.MY_PROGRESS, 'My Progress', StyleConstants.sideBarBorderColor.color2, '', pathName === pathNames.MY_PROGRESS)}
            {this.renderSideBarElements(pathNames.BOOKMARK, 'Bookmark', StyleConstants.sideBarBorderColor.color3, '', pathName === pathNames.BOOKMARK)}
            {!user.isIdeaboardStudent && this.renderSideBarElements(pathNames.ATTENDANCE, 'Attendance', StyleConstants.sideBarBorderColor.color4, '', pathName === pathNames.ATTENDANCE)}
            {!user.isIdeaboardStudent && this.renderSideBarElements(pathNames.QUIZ_SCORES, 'Quiz Scores', StyleConstants.sideBarBorderColor.color5, '', pathName === pathNames.QUIZ_SCORES)}
          </div>
        )}
      </>
    );
  }

  render() {
    const isHigherEducation = user.is_higher_education;
    const { subjects } = this.props;
    return (
      <div>
        <div className="HorizontalScroll" style={{ paddingBottom: 10 }}>
          {isHigherEducation ? this.renderHigherEducationSideBar() : this.renderSideBar()}
        </div>
      </div>
    );
  }
}

HomeSideBar.propTypes = {
  updatePageType: PropTypes.func.isRequired,
  subjects: PropTypes.array,
};

HomeSideBar.defaultProps = {
  subjects: [],
};

const mapStateToProps = ({ home }) => ({
  isInstituteAssignment: home.isInstituteAssignment,
  pageType: home.pageType,
  subjects: home.subjects,
});

export default connect(mapStateToProps, { updatePageType })(HomeSideBar);
