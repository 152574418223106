import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import ReactTooltip from 'react-tooltip';
import { showToast } from '../actions/global';
import Text from './text';
import Layout from './layout';
import Loader from './loader';
import Modal from './modal';
import SideBar from './sidebar';
import { SIDEBAR_TYPES } from '../shared/constants/fieldTypes';
import Feedback from '../shared/images/common/feedback.svg';
import Files from '../shared/images/common/files.svg';
import Recording from '../shared/images/common/recording.svg';
import constants from '../shared/constants/index';
import {
  createMeetingSignature, getVirtualMeetings,
  setShowQuizQuestionStatus, showReport,
  MAX_DAYS_GOING_BACK, getQuestionData,
  MAX_DAYS_GOING_FRONT, sendMeetingFeedback,
  markStudentAttendance,
} from '../actions/virtualMeeting';
import { DateNavigator } from './common';
import VirtualMeetingFeedback from './virtualMeetingFeedback';
import StyleConstants from '../shared/styleConstants/styles';

const MINUTES_BEFORE_STUDENTS_ALLOWED_TO_JOIN = 10;
const LIVE_QUIZ_QUESTION_UPDATE_CHANNEL = 'LiveQuizQuestionUpdateChannel';
const cookies = new Cookies();
const USER = cookies.get('user');

class VirtualMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      pageContentHeight: 0,
      userEmail: '',
      showFeedbackModal: {},
      daysAgo: 0,
      meetingLaunched: false,
    };
    this.joinZoomMeeting = this.joinZoomMeeting.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.renderImageIcon = this.renderImageIcon.bind(this);
    this.showFeedback = this.showFeedback.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
  }

  componentDidMount() {
    // Not a good practice
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://source.zoom.us/zoom-meeting-1.8.0.min.js';
    script.addEventListener('load', this.onLoad);
    document.body.appendChild(script);
    this.props.getVirtualMeetings();
  }

  componentWillUnmount() {
    window.location.reload();
  }

  onLoad() {
    this.setState({ showLoader: true });
    window.ZoomMtg.setZoomJSLib('https://source.zoom.us/1.8.0/lib', '/av');
    window.ZoomMtg.preLoadWasm();
    window.ZoomMtg.prepareJssdk();
    this.turnZoomMeetingOff();
    this.setState({ pageContentHeight: window.innerHeight - 70, showLoader: false });
  }

  turnZoomMeetingOn() {
    const zoomMeeting = document.getElementById('zmmtg-root');
    if (zoomMeeting) {
      zoomMeeting.style.display = 'flex';
      zoomMeeting.style.height = '100%';
      zoomMeeting.style.width = '100%';
      zoomMeeting.style.position = 'fixed';
      zoomMeeting.style.zIndex = '1';
      zoomMeeting.style.backgroundColor = 'black';
    }
  }

  turnZoomMeetingOff() {
    const zoomMeeting = document.getElementById('zmmtg-root');
    if (zoomMeeting) {
      zoomMeeting.style.display = 'none';
    }
  }

  adjustZoomFooter() {
    const footer = document.getElementById('wc-footer');
    if (footer) {
      footer.style.bottom = '6px';
    }
  }

  joinZoomMeeting(meeting) {
    const {
      userName, useZoom, markStudentAttendance, createMeetingSignature,
    } = this.props;
    const isQuizPresent = !!meeting.live_quiz_id;
    const virtualMeet = this;
    const meetConfig = {
      meetingNumber: meeting.meeting_id,
      leaveUrl: `/virtual_meeting/${meeting.id}/feedback?title=${meeting.title}`,
      userName: userName || 'user',
      passWord: meeting.password,
      role: 0,
    };
    markStudentAttendance(meeting.id);
    if (useZoom) {
      window.open(meeting.join_url);
      return;
    }
    createMeetingSignature(meetConfig, meeting.id).then((response) => {
      if (response.success) {
        this.setState({ meetingLaunched: true }, () => {
          this.turnZoomMeetingOn();
        });
        window.ZoomMtg.init({
          leaveUrl: meetConfig.leaveUrl,
          success() {
            window.ZoomMtg.join({
              meetingNumber: meetConfig.meetingNumber,
              userName: meetConfig.userName,
              signature: response.signature,
              apiKey: response.api_key,
              passWord: meetConfig.passWord,
              success() {
                if (isQuizPresent) {
                  virtualMeet.props.getQuestionData(meeting.id).then((data) => {
                    if (data && data.question_data && data.question_data.length > 0) {
                      virtualMeet.props.setShowQuizQuestionStatus(data.question_data,
                        data.response_data, meeting.live_quiz_id, false);
                    }
                  });
                }
                virtualMeet.adjustZoomFooter();
                if (meeting.live_quiz_id) {
                  const actionCable = require('actioncable');
                  const cable = actionCable.createConsumer(constants().api.ws_url);
                  const subscription = cable.subscriptions.create({
                    channel: LIVE_QUIZ_QUESTION_UPDATE_CHANNEL,
                    institute_id: USER.institute_id,
                    meeting_id: meeting.id,
                    quiz_id: meeting.live_quiz_id,
                    number: meetConfig.meetingNumber,
                  }, {
                    received: (data) => {
                      if (data.complete && meeting.live_quiz_id) {
                        virtualMeet.props.showReport(meeting.live_quiz_id);
                      } else {
                        virtualMeet.props.setShowQuizQuestionStatus(data.questions,
                          [], meeting.live_quiz_id, false);
                      }
                    },
                  });
                  virtualMeet.setState({ subscription, cable });
                }
              },
            });
          },
        });
      }
    });
  }

  validateDataandJoin(meeting) {
    // email required for webinars, validate
    this.joinZoomMeeting(meeting);
  }

  reloadPage(daysAgo) {
    this.setState({ daysAgo, showLoader: true }, () => {
      const { daysAgo } = this.state;
      this.props.getVirtualMeetings(daysAgo).then(() => {
        this.setState({ showLoader: false });
      });
    });
  }

  goToToday() {
    this.setState({ daysAgo: 0, showLoader: true }, () => {
      this.props.getVirtualMeetings(this.state.daysAgo).then(() => {
        this.setState({ showLoader: false });
      });
    });
  }

  goToPreviousDay() {
    let { daysAgo } = this.state;
    if (daysAgo !== MAX_DAYS_GOING_BACK) {
      daysAgo += 1;
      this.setState({ daysAgo, showLoader: true }, () => {
        this.props.getVirtualMeetings(daysAgo).then(() => {
          this.setState({ showLoader: false });
        });
      });
    }
  }

  goToNextDay() {
    let { daysAgo } = this.state;
    if (daysAgo !== MAX_DAYS_GOING_FRONT) {
      daysAgo -= 1;
      this.setState({ daysAgo, showLoader: true }, () => {
        this.props.getVirtualMeetings(daysAgo).then(() => {
          this.setState({ showLoader: false });
        });
      });
    }
  }

  showFeedback(id, state) {
    const { showFeedbackModal } = this.state;
    showFeedbackModal[id] = state;
    this.setState({ showFeedbackModal: { ...showFeedbackModal } });
  }

  renderImageIcon(text, img, method) {
    return (
      <img
        role="presentation"
        data-tip={text}
        src={img}
        height="25px"
        style={{ marginRight: 25, cursor: 'pointer' }}
        width="25px"
        alt=""
        onClick={method}
      />
    );
  }

  renderNoMeetingsAvailable() {
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <Text
          fontSize={StyleConstants.textSize.headerSmall}
          fontWeight={StyleConstants.textWeight.semiBold}
          text="No live classes have been assigned for this day!"
        />
      </div>
    );
  }

  renderMeetingUI(meeting) {
    const { showFeedbackModal } = this.state;
    const timeToAllowJoineesStart = new Date(meeting.start_time);
    const timeToAllowJoineesEnd = new Date(meeting.start_time);
    timeToAllowJoineesStart.setMinutes(timeToAllowJoineesStart.getMinutes() - MINUTES_BEFORE_STUDENTS_ALLOWED_TO_JOIN);
    timeToAllowJoineesEnd.setMinutes(timeToAllowJoineesEnd.getMinutes() + meeting.duration);
    const isLive = new Date() < timeToAllowJoineesEnd && timeToAllowJoineesStart < new Date();
    const isCompleted = (meeting.status === 'LIVE' && new Date() > timeToAllowJoineesEnd) || meeting.status === 'COMPLETED';
    const isTitlePresent = meeting.module || meeting.lecture || meeting.title;
    return (
      <div
        style={{
          display: 'flex', height: 80, justifyContent: 'space-between', padding: '0px 50px', marginBottom: 20, fontFamily: 'Roboto', color: isCompleted ? '#ABABAB' : '#343737', backgroundColor: isLive && StyleConstants.virtualMeetings.primaryColor, border: isLive ? '1px solid #4F7827' : '1px solid #000000', alignItems: 'center',
        }}
      >
        <ReactTooltip />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex', justifyContent: 'center', fontWeight: 'bold', alignItems: 'center', fontSize: 22, color: '#343737', whiteSpace: 'nowrap', marginRight: 30,
            }}
          >
            {meeting.start_time ? new Date(meeting.start_time).toLocaleString('en-US', {
              timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: '2-digit',
            }).toLowerCase() : ''}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}>
            <div style={{ fontSize: 18, fontWeight: 'bolder', colod: '#343737' }}>
              {meeting.subject_title}
            </div>
            {isTitlePresent
              && (
                <div style={{ marginTop: 5, fontSize: 12 }}>
                  {meeting.module ? `Module ${meeting.module} ${(meeting.lecture || meeting.title) ? ' | ' : ' '}` : ''}
                  {meeting.lecture ? `Lecture ${meeting.lecture} ${meeting.title ? ' | ' : ' '}` : ''}
                  {meeting.title ? meeting.title : ''}
                </div>
              )}
          </div>
        </div>
        <div
          style={{
            display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
          }}
        >
          <Modal
            isModalVisible={showFeedbackModal[meeting.id]}
            onClose={() => this.showFeedback(meeting.id, false)}
          >
            <VirtualMeetingFeedback
              isModal
              id={meeting.id}
              title={meeting.title}
              onSubmit={
              (rating, feedbackMessage) => {
                this.props.sendMeetingFeedback(meeting.id, rating, feedbackMessage);
                this.showFeedback(meeting.id, false);
              }
            }
            />
          </Modal>
          {(isCompleted || isLive) && this.renderImageIcon('Give Feedback', Feedback, () => this.showFeedback(meeting.id, true))}
          {meeting.file_count > 0 && this.renderImageIcon('View Files', Files, () => window.open(`/institute_files/virtual_meeting/${meeting.id}`))}
          {meeting.recording_url && meeting.recording_url.length > 0 && this.renderImageIcon('View Class', Recording, () => window.open(`/view_class/${meeting.id}`))}
          {isLive
            ? (
              <a
                href={(meeting.live_quiz_id) ? `/attend_quiz/${meeting.id}` : ''}
                className="black-white-button"
                onClick={() => this.joinZoomMeeting(meeting)}
                style={{
                  width: 80,
                  height: 32,
                  color: StyleConstants.virtualMeetings.primaryColor,
                  background: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                JOIN
              </a>
            )
            : (
              <div
                style={{
                  display: 'flex', alignItems: 'center', color: '#ABABAB', fontSize: 12, textAlign: 'right', minWidth: 80, justifyContent: 'flex-end',
                }}
              >
                {isCompleted ? 'Completed' : meeting.status.replace(/_/g, ' ')}
              </div>
            )}
        </div>

      </div>
    );
  }

  renderHead() {
    const { daysAgo } = this.state;
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);

    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 60, width: '100%',
      }}
      >
        <div>
          <div
            style={{
              display: 'flex', justifyContent: 'center', height: '50%', fontSize: 24, fontWeight: 'bold', width: '100%',
            }}
          >
            <span style={{ paddingRight: 10 }}>
              {date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', day: '2-digit' })}
            </span>
            <span>
              {date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', month: 'short' })}
            </span>
            <span>
              ,&nbsp;&nbsp;
            </span>
            <span>
              { date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', weekday: 'long' })}
            </span>
          </div>
        </div>
        <div>
          <DateNavigator
            daysAgo={daysAgo}
            text="Today"
            reloadPage={this.reloadPage}
          />
        </div>
      </div>
    );
  }

  renderUI() {
    const { meetings } = this.props;
    return (
      <div
        style={{
          padding: '50px 100px', display: 'flex', alignContent: 'center', flexDirection: 'column', fontFamily: 'Roboto', color: '#4C5E6F', marginLeft: '18%',
        }}
      >
        {this.renderHead()}
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 25,
        }}
        >
          {meetings.length ? meetings.map((meeting) => { return this.renderMeetingUI(meeting); })
            : this.renderNoMeetingsAvailable()}
        </div>
      </div>
    );
  }

  render() {
    const {
      showLoader, meetingLaunched, pageContentHeight,
    } = this.state;

    if (showLoader) {
      return (
        <Layout hideFooter>
          <div style={{ height: pageContentHeight }}>
            <SideBar pageType={SIDEBAR_TYPES.VIRTUAL_MEETINGS} />
            <Loader />
          </div>
        </Layout>
      );
    }

    if (meetingLaunched) {
      return <div />;
    }
    return (
      <Layout hideFooter>
        <div style={{ height: pageContentHeight }}>
          <SideBar pageType={SIDEBAR_TYPES.VIRTUAL_MEETINGS} />
          {this.renderUI()}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ virtualMeeting }) => ({
  meetings: virtualMeeting.meetings,
  userName: virtualMeeting.userName,
  useZoom: virtualMeeting.useZoom,
});

export default connect(mapStateToProps,
  {
    showReport,
    getQuestionData,
    getVirtualMeetings,
    createMeetingSignature,
    setShowQuizQuestionStatus,
    sendMeetingFeedback,
    showToast,
    markStudentAttendance,
  })(VirtualMeeting);
