import React from 'react';
import Home from './home';

class LandingHome extends React.Component {
  render() {
    return (
      <Home />
    );
  }
}
export default LandingHome;
