import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import Text from './text';
import StyleConstants from '../shared/styleConstants/styles';

class ModalView extends PureComponent {
  render() {
    const {
      isModalVisible, children, header, maxWidth, image, height, width, iconHeight, iconWidth,
    } = this.props;
    return (
      <Modal
        center
        open={isModalVisible}
        onClose={() => this.props.onClose()}
        styles={{
          closeIcon: {
            opacity: 0.5,
            cursor: 'pointer',
            width: iconHeight,
            height: iconWidth,
            position: 'absolute',
            right: 20,
            top: 10,
          },

          modal: {
            padding: '20px 30px 30px 30px',
            height,
            width,
            minWidth: 300,
            maxWidth,
            borderRadius: 4,
          },
        }}
        classNames={{ closeButton: true }}
      >
        {header
          && (
          <div style={{
            paddingRight: 50, paddingBottom: 15, display: 'flex', alignItems: 'center',
          }}
          >
            <Text
              color={StyleConstants.textColor.primary}
              fontSize={StyleConstants.textSize.headerSmall}
              fontWeight={StyleConstants.textWeight.bold}
              text={header}
            />
            {image && <img alt="NEET_JEE_headerImage" src={image} height={18} width={18} style={{ marginLeft: 10 }} />}
          </div>
          )}
        {children}
      </Modal>
    );
  }
}

ModalView.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  image: PropTypes.string,
  isModalVisible: PropTypes.bool,
};

ModalView.defaultProps = {
  isModalVisible: false,
  maxWidth: 600,
  iconHeight: 20,
  iconWidth: 20,
  image: '',
};

export default ModalView;
