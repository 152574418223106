import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TrainOverview from './trainOverview';
import TestOverview from './testOverview';
import { SIDEBAR_TYPES } from '../../shared/constants/fieldTypes';

class Overview extends PureComponent {
  renderOverview() {
    const { pageType, objectType, sessionType } = this.props;
    if (pageType === SIDEBAR_TYPES.TRAIN) {
      return <TrainOverview objectType={objectType} sessionType={sessionType} />;
    }
    return <TestOverview />;
  }

  render() {
    return (
      <div className="Overview">
        {this.renderOverview()}
      </div>
    );
  }
}

Overview.propTypes = {
  pageType: PropTypes.string,
  objectType: PropTypes.number,
  sessionType: PropTypes.number,
};

export default Overview;
