import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Text from '../text';
import ProgressBar from '../progressBar';
import StyleConstants from '../../shared/styleConstants/styles';
import { updateRecentChapters, getUserTypes } from '../../actions/index';
import Layout from '../layout';
import Loader from '../loader';

class TrainPage extends PureComponent {

  resize = () => this.forceUpdate();

  componentDidMount() {
    const { userTypes } = this.props;
    window.addEventListener('resize', this.resize);
    if((!userTypes) || (userTypes && userTypes.length === 0)){
      this.props.getUserTypes();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  renderChapterProgressDetails(value, field) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around'}}>
        <Text
          fontSize={StyleConstants.textSize.small}
          fontWeight={StyleConstants.textWeight.bold}
          color={StyleConstants.textColor.primary}
          text={value}
        />
        <Text
          fontSize={StyleConstants.textSize.small}
          text={field}
        />
      </div>
    );
  }

  renderChapterView(chapter) {
    return (
      <Link id={`Recent-Chapter-${chapter.id}`} to={`/chapter/${chapter.id}`} key={`ChapterCardView${chapter.id}`} className="cardView cardViewHover" style={{ width: 290, height: 150, marginRight: 30, marginBottom: 30, padding: 0 }} onClick={() => this.props.updateRecentChapters(chapter.id)}>
        <div style={{ height: 110, padding: "20px 20px 10px 20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <Text
              fontSize={StyleConstants.textSize.small}
              fontWeight={StyleConstants.textWeight.bold}
              color={StyleConstants.textColor.primary}
              text={chapter.name}
            />
            <Text
              fontSize={StyleConstants.textSize.small}
              text={`${chapter.topic_count} topics`}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ProgressBar width={210} height={5} fillPercentage={chapter.progress} />
            <Text
              fontSize={StyleConstants.textSize.small}
              fontWeight={StyleConstants.textWeight.semiBold}
              color={StyleConstants.textColor.primary}
              text={chapter.progress}
            />
          </div>
        </div>
        <div style={{ height: 40, backgroundColor: '#E3E3E3', display: 'flex', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'space-between', borderRadius: '0px 0px 4px 4px' }}>
            {this.renderChapterProgressDetails(chapter.accuracy, 'Accuracy')}
            {this.renderChapterProgressDetails(chapter.incorrect, 'Incorrect')}
            {this.renderChapterProgressDetails(chapter.avg_speed, 'Speed')}
        </div>
      </Link>
    );
  }

  renderRecentChapters() {
    const { chapters, recentChapterList } = this.props;
    const chapterData = {}
    chapters.forEach(chapter => chapterData[chapter.id] = chapter);
    return (
      <div style={{
          height: 200,
          width: window.innerWidth - 300,
          paddingTop: 50,
          paddingBottom: 30,
          backgroundImage: "linear-gradient(260deg, rgb(74, 159, 245) -15%, rgb(42, 245, 152) 130%)",
      }}>
        <div style={{ paddingLeft: 100, paddingRight: 100 }} >
          <Text text="Continue Training" color={StyleConstants.color.white} fontSize={StyleConstants.textSize.headerSmall} fontWeight={StyleConstants.textWeight.bold} />
        </div>
        <div style={{ paddingLeft: 100, paddingRight: 100, width: '100%', display: 'flex', marginTop: 10 }}>
          {recentChapterList.map(chapterId => (
            <div style={{ width: '33%', marginRight: 10 }} key={`RecentChapter_${chapterId}`}>
              <Link id={`Chapter-${chapterId}`} to={`/chapter/${chapterId}`} key={`ChapterCardView${chapterId}`}>
                <div className="cardView hideBoxShadowHover">
                  <div style={{textOverflow: 'ellipsis', display: 'inline-block', overflow: "hidden", whiteSpace: 'nowrap', width: '100%'}}>
                  <Text
                    color={StyleConstants.textColor.primary}
                    fontSize={StyleConstants.textSize.small}
                    fontWeight={StyleConstants.textWeight.bold}
                    text={chapterData[chapterId].name}
                  />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{ marginRight: 20, width: '100%' }}>
                      <ProgressBar width={'100%'} height={5} fillPercentage={chapterData[chapterId].progress} />
                    </div>
                    <Text
                      color={StyleConstants.textColor.primary}
                      fontSize={StyleConstants.textSize.small}
                      fontWeight={StyleConstants.textWeight.semiBold}
                      text={chapterData[chapterId].progress}
                    />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    )
  }

  render() {
    const { chapters, recentChapterList, trainLoader } = this.props;
    if(trainLoader){
      return (
        <Layout hideFooter={true} >
          <div className="MainPage">
            <Loader />;
          </div>
        </Layout>
      )
    }
    return (
      <Layout hideFooter={true} >
        <div className="MainPage" style={{ marginTop: recentChapterList.length > 0 && 0, padding: recentChapterList.length > 0 && 0 }}>
          {recentChapterList.length > 0 && this.renderRecentChapters()}
          <div style={{ marginTop: recentChapterList.length > 0 && 50, marginLeft: recentChapterList.length > 0 && 100 }}>
            <Text
              fontSize={StyleConstants.textSize.headerSmall}
              fontWeight={StyleConstants.textWeight.bold}
              color={StyleConstants.textColor.primary}
              text={"Select a chapter to start practicing!"}
            />
            <div className="flexcontainer" style={{ marginTop: 10, paddingRight: 20 }}>
              {chapters.map(chapter => this.renderChapterView(chapter))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

TrainPage.propTypes = {
  updateRecentChapters: PropTypes.func.isRequired,
  recentChapterList: PropTypes.array,
  chapters: PropTypes.array,
  getUserTypes: PropTypes.func.isRequired,
  userTypes: PropTypes.array.isRequired,
}

TrainPage.defaultProps = {
  chapters: [],
  recentChapterList: []
}

const mapStateToProps = ({ home, auth, global }) => ({
  recentChapterList: home.recentChapterList,
  trainLoader: global.trainLoader,
  chapters: home.chapters,
  userTypes: auth.auth,
});

export default connect(mapStateToProps, { updateRecentChapters, getUserTypes })(TrainPage);
