import Api from '../helpers/Api';
import searchApi from '../helpers/searchApi';

export const FILTER_CATEGORIES = {
  COURSE: 'selectedCourse',
  SUBJECT: 'selectedSubject',
  CHAPTER: 'selectedChapter',
  TOPIC: 'selectedTopic',
  PREVIOUS_PAPERS: 'selectedPreviousPaper',
}

export const UPDATE_SEARCH_RESULTS = 'solutionBoard/UPDATE_SEARCH_RESULTS';
export const UPDATE_SEARCH_STRING = 'solutionBoard/UPDATE_SEARCH_STRING';
export const RESET_QUESTION_LIST = 'solutionBoard/RESET_QUESTION_LIST';
export const GET_SUBJECT_CHAPTER_DATA = 'solutionBoard/GET_SUBJECT_CHAPTER_DATA';
export const GET_COURSES_AND_PREVIOUS_PAPER = 'solutionBoard/GET_COURSES_AND_PREVIOUS_PAPER';
export const UPDATE_APPLIED_FILTERS = 'solutionBoard/UPDATE_APPLIED_FILTERS';
export const RESET_COURSES = 'solutionBoard/RESET_COURSES';
export const RESET_CHAPTERS = 'solutionBoard/RESET_CHAPTERS';
export const RESET_SUBJECTS = 'solutionBoard/RESET_SUBJECTS';
export const RESET_TOPICS = 'solutionBoard/RESET_TOPICS';
export const UPDATE_PREVIOUS_PAPERS = 'solutionBoard/UPDATE_PREVIOUS_PAPERS';
export const UPDATE_FILTER_LIST = 'solutionBoard/UPDATE_FILTER_LIST';
export const UPDATE_SOLUTION_BOARD_LOADER_STATE = 'solutionBoard/UPDATE_SOLUTION_BOARD_LOADER_STATE';

export const searchQuestions = (shouldAppendData, payload={}) => async (dispatch, getState)  => {
  const { searchText, questions: oldQuestions, page: oldPage, questionIds: oldQuestionIds, appliedFilters } = getState().solutionBoard;

  let url_params = '';
  if (appliedFilters["selectedCourse"].key) {
    url_params +=`&test_type_id=${appliedFilters["selectedCourse"].key}`;
  }
  if (appliedFilters["selectedSubject"].key) {
    url_params +=`&subject_id=${appliedFilters["selectedSubject"].key}`;
  }
  if (appliedFilters["selectedChapter"].key) {
    url_params +=`&chapter_id=${appliedFilters["selectedChapter"].key}`;
  }
  if (appliedFilters["selectedTopic"].key) {
    url_params +=`&checkpoint_id=${appliedFilters["selectedTopic"].key}`;
  }
  if(appliedFilters["selectedPreviousPaper"].length) {
    url_params +=`&previous_papers=${appliedFilters["selectedPreviousPaper"]}`;
  }
  (typeof window !== "undefined") && window.history.pushState({}, null, `solutions?search_question_text=${searchText}${url_params}`);

  if (!searchText && !appliedFilters["selectedPreviousPaper"].length) {
    dispatch(resetQuestionsListReducer());
    return;
  }

  !shouldAppendData && dispatch(updateLoaderState(true));

  const page = shouldAppendData ? oldPage + 1 : 1;
  url_params +=`&page=${page}`;
  {/* Removed Solutions Board Api Usage from Web since many students are misusing it during exams */}
  // const response = await searchApi({
  //   method: 'get',
  //   url: `/search_questions_by_text?search_question_text=${searchText}${url_params}`,
  // });
  const response = {};
  
  if (response.success) {
    if (response.data.questions.length > 0) {
      const { totalQuestions: totalSearchCount, questions: newQuestions } = response.data;
      const renderNext = !(newQuestions.length < 20);
      const newQuestionIds = newQuestions.map(({ id }) => id);
      const questions = shouldAppendData ? [...oldQuestions, ...newQuestions] : newQuestions;
      const questionIds = shouldAppendData ? [...oldQuestionIds, ...newQuestionIds] : newQuestionIds;
      const payload = {
        questions,
        renderNext,
        page,
        questionIds,
        totalSearchCount,
        fetching: false,
      };
      dispatch({
        type: UPDATE_SEARCH_RESULTS,
        payload,
      });
    } else {
      const payload = {
        fetching: false,
      };
      dispatch({
        type: UPDATE_SEARCH_RESULTS,
        payload,
      });
    }
    (!shouldAppendData) && dispatch(updateLoaderState(false));
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }
};

export const updateLoaderState = (state) => {
  const payload = { loaderState: state };
  return {
    type: UPDATE_SOLUTION_BOARD_LOADER_STATE,
    payload,
  };
};

export const updateSearchString = (response) => {
  return {
    type: UPDATE_SEARCH_STRING,
    payload: response,
  };
};

export const resetQuestionsListReducer = () => async (dispatch) => {
  dispatch({
    type: RESET_QUESTION_LIST,
  });
};

export const getAllFilterCategories = () => async (dispatch, getState) => {
  const { searchText, appliedFilters } = getState().solutionBoard;

  let url_params =`search_question_text=${searchText}`;
  if (appliedFilters["selectedCourse"].key) {
    url_params +=`&test_type_id=${appliedFilters["selectedCourse"].key}`;
  }
  if (appliedFilters["selectedSubject"].key) {
    url_params +=`&subject_id=${appliedFilters["selectedSubject"].key}`;
  }
  if (appliedFilters["selectedChapter"].key) {
    url_params +=`&chapter_id=${appliedFilters["selectedChapter"].key}`;
  }
  if (appliedFilters["selectedTopic"].key) {
    url_params +=`&checkpoint_id=${appliedFilters["selectedTopic"].key}`;
  }
  const response = await Api({
    method: 'get',
    url: `/search/get_all_filter_categories?${url_params}`,
  });

  if (response.success) {
    const payload = {
      courses: response.courses ? response.courses : [],
      subjectsList: response.subjects ? response.subjects : [],
      chaptersList: response.chapters ? response.chapters : [],
      topics: response.topics ? response.topics : [],
      previousPapers: response.previous_papers ? response.previous_papers : [],
    };
    dispatch({
      type: UPDATE_FILTER_LIST,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
}

export const getCourses = () => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: '/search/get_courses_and_previous_paper?send=true',
  });
  if (response.success) {
    const payload = {
      courses: response.courses,
    };
    dispatch({
      type: GET_COURSES_AND_PREVIOUS_PAPER,
      payload,
    });
  }
  dispatch(updateLoaderState(false));
};

export const updateFilters = (filterData) => async (dispatch, getState) => {
  const { appliedFilters } = getState().solutionBoard;
  let key = '';
  let text = '';
  let payload = {};
  let isFilterApplied = false;

  if (filterData[FILTER_CATEGORIES.COURSE]) {
    isFilterApplied = true;
    let courseData = filterData[FILTER_CATEGORIES.COURSE];
    key = courseData.value;
    text = courseData.text;
    if (appliedFilters[FILTER_CATEGORIES.COURSE].key !== courseData.value) {
      dispatch({
        type: RESET_SUBJECTS,
      })
    payload[FILTER_CATEGORIES.COURSE] = {
      key: courseData.value ? parseInt(courseData.value) : null,
      value: text || ''
      }
    }
  }

  if (filterData[FILTER_CATEGORIES.SUBJECT]) {
    isFilterApplied = true;
    let subjectData = filterData[FILTER_CATEGORIES.SUBJECT];
    key = subjectData.value;
    text = subjectData.text;
    dispatch({
      type: RESET_CHAPTERS,
    })
    payload[FILTER_CATEGORIES.SUBJECT] = {
      key: subjectData.value ? parseInt(subjectData.value) : null,
      value: text || ''
    }
  }

  if (filterData[FILTER_CATEGORIES.CHAPTER]) {
    isFilterApplied = true;
    let chapterData = filterData[FILTER_CATEGORIES.CHAPTER];
    key = chapterData.value;
    text = chapterData.text;
    dispatch({
      type: RESET_TOPICS,
    })
    payload[FILTER_CATEGORIES.CHAPTER] = {
      key: chapterData.value ? parseInt(chapterData.value) : null,
      value: text || ''
    }
  }

  if (filterData[FILTER_CATEGORIES.TOPIC]) {
    isFilterApplied = true;
    let topicCategory = filterData[FILTER_CATEGORIES.TOPIC];
    key = topicCategory.value;
    text = topicCategory.text;
    payload[FILTER_CATEGORIES.TOPIC] = {
      key: topicCategory.value ? parseInt(topicCategory.value) : null,
      value: text || ''
   }
  }

  if(filterData[FILTER_CATEGORIES.PREVIOUS_PAPERS]) {
    let key = filterData[FILTER_CATEGORIES.PREVIOUS_PAPERS].value;
    let previousPapers = appliedFilters[FILTER_CATEGORIES.PREVIOUS_PAPERS] || [];
    let isSelected = previousPapers.length > 0 && previousPapers.includes(key);
    let testNames = isSelected ? (previousPapers.filter((selectedPaper) => selectedPaper !== key)) : (key ? [ ...previousPapers, ...key.split(",")] : []);
    const payload = {
      testNames
    }
    dispatch({
      type: UPDATE_PREVIOUS_PAPERS,
      payload,
    });
    dispatch(searchQuestions(false))
  }

  if (isFilterApplied) {
    dispatch({
      type: UPDATE_APPLIED_FILTERS,
      payload,
    });
    dispatch(getAllFilterCategories());
  }
}
