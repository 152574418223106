import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SideBar from './sidebar';
import Text from './text';
import QuestionView from './questionView';
import { getImprovementsData } from '../actions/index';
import StyleConstants from '../shared/styleConstants/styles';
import { SIDEBAR_TYPES } from '../shared/constants/fieldTypes';
import Layout from './layout';

class Test extends PureComponent {
  componentDidMount() {
    const { id, type } = this.props.match.params;
    this.props.getImprovementsData(id, type);
  }

  renderQuestions(question, selectedImprovementType) {
    const {
      improvements, optionSelectedQuestionList, questionsVisited, questionTimeSpent,
    } = this.props;
    if (improvements[selectedImprovementType].questions.includes(question.id)) {
      return (
        <div key={`QuestionView_${question.id}`} style={{ marginBottom: 100 }}>
          <QuestionView
            showResult
            isCurrentQuestionVisited={questionsVisited.includes(question.id)}
            currentQuestion={question}
            currentIndex={question.position}
            selectedOption={optionSelectedQuestionList[question.id]}
            shouldUploadResponses={false}
            currentQuestionTimeSpent={questionTimeSpent[question.id]}
          />
        </div>
      );
    }
  }

  render() {
    const { id } = this.props.match.params;
    const { questions, improvements } = this.props;
    let { selectedImprovementType } = this.props;
    if (!selectedImprovementType) {
      selectedImprovementType = Object.keys(improvements)[0];
    }
    if (Object.keys(improvements).length === 0) {
      return (
        <Layout hideFooter>
          <SideBar pageType={SIDEBAR_TYPES.IMPROVEMENTS} showBackNavigation navigateTo={`/test_report/${id}`} />
        </Layout>
      );
    }
    window.scrollTo(0, 0);
    return (
      <Layout hideFooter>
        <div>
          <SideBar pageType={SIDEBAR_TYPES.IMPROVEMENTS} showBackNavigation navigateTo={`/test_report/${id}`} />
          <div className="MainPage" style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: 800 }}>
              <Text
                color={StyleConstants.textColor.primary}
                fontWeight={StyleConstants.textWeight.bold}
                fontSize={StyleConstants.textSize.headerSmall}
                text={selectedImprovementType}
              />
              <div style={{ marginTop: 20 }} />
              {questions.map((question) => this.renderQuestions(question, selectedImprovementType))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

Test.propTypes = {
  getImprovementsData: PropTypes.func.isRequired,
  match: PropTypes.object,
  questionTimeSpent: PropTypes.object,
  improvements: PropTypes.object,
  questions: PropTypes.array,
  selectedImprovementType: PropTypes.string,
  questionsVisited: PropTypes.array,
  optionSelectedQuestionList: PropTypes.object,
};

Test.defaultProps = {
  questionTimeSpent: {},
  improvements: {},
  questions: [],
  selectedImprovementType: '',
  questionsVisited: [],
  optionSelectedQuestionList: {},
};

const mapStateToProps = ({ report }) => ({
  questionTimeSpent: report.questionTimeSpent,
  improvements: report.improvements,
  questions: report.questions,
  optionSelectedQuestionList: report.optionSelectedQuestionList,
  selectedImprovementType: report.selectedImprovementType,
});

export default connect(mapStateToProps, { getImprovementsData })(Test);
