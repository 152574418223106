import React from 'react';
import '../../../shared/styleConstants/staticPages/aboutUs.css';
import { data } from './aboutUs.json';

class AboutUsWeb extends React.Component {
  constructor() {
    super();
    this.state = {
      selected: false,
      currentEmployeeIndex: 0,
      currentTeam: 0,

    };
  }

  render() {
    return (
      <div className="about-us-web-view">
        <div className="problem-web-view">
          <div>We are Problem</div>
          <div>Solver, Literaly!</div>
          <div className="problem-sub-heading-web-view">
            <div>
A bunch of creative people working on solutions to make life
              <div />
easier for students , parents and teachers.
            </div>
          </div>
        </div>
        <div className="human-web-view">
                    Humans Inside
          <div className="human-sub-heading-web-view">
            <div>Our in-house team consists of Software Engineers, User,</div>
            <div> Experience Designers, Buisness Development Associate,</div>
            <div>Business Development Manager, Machine Learning Engineer,</div>
            <div>Data Scientist</div>
          </div>
        </div>
        <div className="employee-details-web">
          <div className="left-portion-web">
            <div className="colored-portion" />
            <div className="uncolored-portion" />
          </div>
          <div className="main-container">
            {
                            data.map((teamInfo, team) => {
                              return (
                                <div key={team} className="title">
                                  {teamInfo.title}
                                  <div className="image-container-web">
                                    {teamInfo.names.map((name, employee) => {
                                      return (
                                        <div key={employee} className="text-over-image-web">
                                          <div className="image-web" onMouseOver={() => { this.setState({ selected: false, currentEmployeeIndex: employee, currentTeam: team }); }} onMouseOut={() => { this.setState({ selected: true, currentEmployeeIndex: employee, currentTeam: team }); }}>
                                            {(this.state.selected === false) && (team === this.state.currentTeam) && (employee === this.state.currentEmployeeIndex) ? (<div className="image-web" style={{ background: 'url(/images/employees/Human2.png)' }} />) : (<div className="image-web" style={{ background: 'url(/images/employees/Human.png)' }} />)}

                                            <div className="layer" />
                                          </div>
                                          <div className="name-web">{name}</div>
                                          <div className="post-web">{data[team].posts[employee]}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })
                        }
          </div>
          <div className="right-portion-web">
            <div className="colored-portion" />
            <div className="uncolored-portion" />
          </div>
        </div>
      </div>
    );
  }
}
export default AboutUsWeb;
